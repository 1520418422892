import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import UserHelper from '~/redux/modules/users/UserHelper';
import {
  updatePoptrackerVersionThunk,
} from '~/redux/thunks/users';
import BetaTogglePresenter from '../BetaTogglePresenter/BetaTogglePresenter';

const BetaToggleContainer = (props) => <BetaTogglePresenter {...props} />;

BetaToggleContainer.propTypes = {
  hasLegacyPoptracker: PropTypes.bool.isRequired,
  dispatchUpdatePoptrackerVersionThunk: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  hasLegacyPoptracker: UserHelper.hasLegacyPoptracker(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  dispatchUpdatePoptrackerVersionThunk: updatePoptrackerVersionThunk,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BetaToggleContainer);
