import deepMerge from 'deepmerge';

export const baseStackedColumnConfig = {
  chart: {
    type: 'column',
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    height: 120,
    width: 24,
    showInLegend: false,
    borderRadius: 2,
  },
  credits: false,
  plotOptions: {
    allowPointSelect: false,
    column: {
      borderWidth: 0,
      stacking: 'percent',
      enabled: true,
      verticalAlign: 'middle',
      x: 30,
    },
    series: {
      pointWidth: 24,
      states: {
        hover: false,
      },
    },
  },
  legend: {
    enabled: false,
  },
  title: {
    text: null,
  },
  tooltip: false,
  xAxis: {
    gridLineWidth: 0,
    minorGridLineWidth: 0,
    lineColor: 'transparent',
    labels: {
      enabled: false,
      style: {
        fontWeight: 500,
        fontSize: 14,
      },
    },
    minorTickLength: 0,
    tickLength: 0,
  },
  yAxis: {
    lineWidth: 0,
    gridLineWidth: 0,
    minorGridLineWidth: 0,
    lineColor: 'transparent',
    labels: {
      enabled: false,
    },
    minorTickLength: 0,
    tickLength: 0,
    title: null,
  },
};

export function singleGlucoseColumnConfig(data, customConfig = {}) {
  return deepMerge(baseStackedColumnConfig, customConfig);
}

export function singleGlucoseColumnSeries(data, inPdf = false) {
  if (data.count === 0) {
    return [
      { name: 'bgColumnEmptyVeryHigh', data: [0] },
      { name: 'bgColumnEmptyHigh', data: [33.3] },
      { name: 'bgColumnEmptyNormal', data: [33.3] },
      { name: 'bgColumnEmptyLow', data: [33.3] },
      { name: 'bgColumnEmptyVeryLow', data: [0] },
    ];
  } else {
    return [
      {
        name: (inPdf ? 'bgColumnVeryHighPdf' : 'bgColumnVeryHigh'),
        data: [data.veryHighPercentage],
      },
      {
        name: (inPdf ? 'bgColumnHighPdf' : 'bgColumnHigh'),
        data: [data.highPercentage],
      },
      {
        name: (inPdf ? 'bgColumnNormalPdf' : 'bgColumnNormal'),
        data: [data.normalPercentage],
      },
      {
        name: (inPdf ? 'bgColumnLowPdf' : 'bgColumnLow'),
        data: [data.lowPercentage],
      },
      {
        name: (inPdf ? 'bgColumnVeryLowPdf' : 'bgColumnVeryLow'),
        data: [data.veryLowPercentage],
      },
    ];
  }
}
