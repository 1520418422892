export const FAVORITES_HAS_ERRORED = 'FAVORITES_HAS_ERRORED';
export const FAVORITES_IN_PROGRESS = 'FAVORITES_IN_PROGRESS';
export const FAVORITES_FETCH_SUCCESS = 'FAVORITES_FETCH_SUCCESS';

export function favoritesHasErrored() {
  return { type: FAVORITES_HAS_ERRORED };
}

export function favoritesInProgress() {
  return { type: FAVORITES_IN_PROGRESS };
}

export function favoritesFetchSuccess(favorites) {
  return { type: FAVORITES_FETCH_SUCCESS, favorites };
}

export const initialState = {
  favorites: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FAVORITES_FETCH_SUCCESS: {
      return { ...state, favorites: action.favorites };
    }
    case FAVORITES_HAS_ERRORED:
    case FAVORITES_IN_PROGRESS:
    default: {
      return state;
    }
  }
}
