import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '@glooko/common-ui';
import moment from 'moment';
import PropTypes from 'prop-types';
import { validatePatientDobThunk } from '~/redux/thunks/terminalSyncs';
import ImageStore from '~/utils/ImageStore';
import { trackConfirmDOBModalButtonClicked } from '~/services/eventLogging';
import DropdownDatepicker from '~/bundles/shared/components/DropdownDatepicker/DropdownDatepicker';
import { WhiteOverlay } from '~/bundles/shared/components/Overlay/Overlay';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate';
import Style from './ConfirmDobModal.scss';

const mapStateToProps = (state) => ({
  assignStatus: state.page.assignStatus,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    validatePatientDobThunk,
  }, dispatch),
});

class ConfirmDobModal extends React.Component {
  static propTypes = {
    actions: PropTypes.shape({
      validatePatientDobThunk: PropTypes.func,
    }),
    visible: PropTypes.bool.isRequired,
    visibilityCallback: PropTypes.func.isRequired,
    patient: PropTypes.shape({}),
    terminal: PropTypes.shape({}),
    t: PropTypes.func.isRequired,
    updatePatientDob: PropTypes.func.isRequired,
    setMedicationOverrideList: PropTypes.func,
  };

  static defaultProps = {
    actions: {
      validatePatientDobThunk: () => {},
    },
    patient: {},
    terminal: {},
    t: () => {},
    setMedicationOverrideList: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      dob: '',
      dobFail: false,
    };
  }

  componentDidMount() {
    this.dobDatepickerData('');
  }

  componentDidUpdate(prevProps) {
    this.assignToPatientHandler(prevProps);
  }

  setDobFailState = (dobFail) => {
    this.setState({ dobFail });
  }

  dobDatepickerData = (dob) => {
    this.setState({ dob });
  }

  assignToPatientHandler = (prevProps) => {
    const { assignStatus, terminal, patient } = this.props;
    const dob = this.state.dob;
    if (prevProps.assignStatus !== assignStatus) {
      if (assignStatus.status === 'DOB_FAIL') {
        trackConfirmDOBModalButtonClicked(terminal, patient.glookoCode, 'assign', false);
        this.setDobFailState(true);
        this.dobDatepickerData('');
      }
      if (assignStatus.status === 'DOB_SUCCESS') {
        trackConfirmDOBModalButtonClicked(terminal, patient.glookoCode, 'assign', true);
        this.props.updatePatientDob(dob);
        this.visibilityCallback();
      }
    }
  }

  visibilityCallback = () => {
    this.setDobFailState(false);
    this.dobDatepickerData('');
    this.props.visibilityCallback(false);
  }

  hideModal = () => {
    const { terminal, patient, setMedicationOverrideList } = this.props;
    trackConfirmDOBModalButtonClicked(terminal, patient.glookoCode, 'cancel', false);
    setMedicationOverrideList(null);
    this.visibilityCallback();
  }

  dobValidationHandler = () => {
    const { patient } = this.props;
    const dob = this.state.dob;
    const payload = {
      glookoCode: patient.glookoCode,
      dateOfBirth: dob,
    };
    const rowId = patient.rowId;
    this.props.actions.validatePatientDobThunk(payload, rowId);
  }

  isDateValid() {
    return moment.utc(this.state.dob, 'YYYY-MM-DD', true).isValid();
  }

  render() {
    if (!this.props.visible) {
      return null;
    }
    const { patient, t } = this.props;
    const { dobFail } = this.state;
    const initialDateProps = {
      dobFail: this.state.dobFail,
      year: t('year'),
      month: t('month'),
      monthNum: '',
      day: t('day'),
    };

    return (
      <div>
        <WhiteOverlay onClick={this.visibilityCallback} />
        <div class={Style.confirmDobModal}>
          <div class={Style.fadeMask} />
          <div
            class={Style.btnClose}
            onClick={this.hideModal}
            role="presentation"
          >
            <img src={ImageStore.dobModalClose} alt="X" />
          </div>
          <div class={Style.contentContainer}>
            <div class={Style.titleConfirm}>{t('confirmBirthdate')}</div>
            <div class={Style.descriptionConfirm}>
              {t('confirm')} {patient.firstName} {patient.lastName}{t('confirmRest')}
            </div>
            <div class={dobFail ? Style.birthdateFail : Style.birthdate}>{t('birthdate')}</div>
            <DropdownDatepicker
              dobUpdateCallback={this.dobDatepickerData}
              initialDateProps={initialDateProps}
            />
            <div class={Style.errorMessage}>{dobFail ? t('invalidBirthdate') : '' } </div>
            <div class={Style.buttonContainer}>
              <Button
                className={Style.btnDob}
                onClick={this.hideModal}
                variation='secondary'
                dataAttributes={{ testid: 'confirm-dob-cancel' }}
              >
                {t('cancel')}
              </Button>
              <div class={Style.btnSpacer} />
              <Button
                className={Style.btnDob}
                disabled={!this.isDateValid()}
                onClick={this.dobValidationHandler}
                dataAttributes={{ testid: 'confirm-dob-assign' }}
              >
                {t('assign')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const TranslatedConfirmDobModal = translate('ConfirmDobModal')(ConfirmDobModal);
export default connect(mapStateToProps, mapDispatchToProps)(TranslatedConfirmDobModal);
