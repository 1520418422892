import axiosOriginal from 'axios';
import envConfig from '../../../config/GlobalEnvConfig';

const axiosOptions = {
  baseURL: envConfig.apiUrl,
  withCredentials: true,
};

const axios = axiosOriginal.create(axiosOptions);

export default axios;
