import {
  fetchTerminalSyncs,
  assignToPatient,
  unassignTerminalSync,
  validatePatientDob,
} from '~/services/terminalSyncsApi';
import {
  updateTerminalSyncs,
  updateSerialNumberCache,
  updateTerminalSync,
  unexpectedTerminalSyncFail,
  updatePatientEmail } from '~/redux/modules/providerGroupSite/providerGroupSite';
import { deviceAssignStatus } from '~/redux/modules/page/page';
import { updatePatient as updatePatientApiCall } from '~/services/usersApi';

export const DOB_SUCCESS = 'DOB_SUCCESS';
export const DOB_FAIL = 'DOB_FAIL';
export const ASSIGN_SUCCESS = 'ASSIGN_SUCCESS';
export const ASSIGN_FAIL = 'ASSIGN_FAIL';
export const UNASSIGN_SUCCESS = 'UNASSIGN_SUCCESS';
export const UNASSIGN_FAIL = 'UNASSIGN_FAIL';
export const LOADING = 'LOADING';
export const SUCCESS = 'SUCCESS';
export const UNASSIGNED = 'UNASSIGNED';

export function fetchTerminalSyncsThunk(params, refreshed) {
  return (dispatch) => fetchTerminalSyncs(params)
    .then((response) => response.data)
    .then((data) => {
      if (refreshed) {
        dispatch(updateTerminalSyncs(data));
      } else {
        dispatch(updateSerialNumberCache(data));
      }
    });
}

export function validatePatientDobThunk(params, rowId) {
  return (dispatch) => validatePatientDob(params)
    .then((response) => response.data)
    .then((data) => {
      dispatch(deviceAssignStatus(
        {
          status: data.success ? DOB_SUCCESS : DOB_FAIL,
          rowId,
        },
      ));
    })
    .catch((data) => {
      dispatch(deviceAssignStatus({
        status: DOB_FAIL,
        rowId,
        statusCode: data.response.status,
      }));
    });
}

export function assignToPatientThunk(
  params, rowId, onDone, onFailure = undefined, modalClosed,
) {
  return (dispatch) => {
    dispatch(deviceAssignStatus({ status: LOADING, rowId }));
    return assignToPatient(params)
      .then((response) => response.data)
      .then((data) => {
        const validateOnly = Object.keys(params).includes('validateOnly');
        let args = [modalClosed];

        if (validateOnly) {
          const updatedParams = { ...params };
          delete updatedParams.validateOnly;

          args = [updatedParams, rowId];
        }

        onDone.apply(this, args)
          .then(() => {
            dispatch(deviceAssignStatus(
              {
                status: data.success ? ASSIGN_SUCCESS : ASSIGN_FAIL,
                rowId,
              },
            ));
            dispatch(updateTerminalSync(data.terminal_sync.terminal_sync, rowId, true));
          });
      })
      .catch((data) => {
        const error = data.response.data;
        if (['email_already_taken', 'mrn_already_taken'].includes(error.error_key) &&
            onFailure !== undefined) {
          dispatch(deviceAssignStatus({
            status: UNASSIGNED,
            rowId,
          }));
          onFailure(error.error_key);
        } else if (!error.terminal_sync) {
          dispatch(deviceAssignStatus({
            status: UNASSIGNED,
            rowId,
          }));
          dispatch(unexpectedTerminalSyncFail(rowId, data.response.status, true));
        } else if (error.terminal_sync) {
          dispatch(deviceAssignStatus({
            status: ASSIGN_FAIL,
            rowId,
          }));
          dispatch(updateTerminalSync(error.terminal_sync.terminal_sync, rowId, true));
        } else if (onFailure !== undefined) {
          dispatch(deviceAssignStatus({
            status: UNASSIGNED,
            rowId,
          }));
          onFailure(error.error_key);
        }
      });
  };
}

export function updatePatientEmailThunk(patient, patientParams, rowId, onInvite) {
  return (dispatch) => updatePatientApiCall(patient, patientParams)
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
    })
    .then(() => {
      dispatch(updatePatientEmail(rowId));
      onInvite();
    });
}

export function unassignTerminalSyncThunk(params, rowId, onDone) {
  return (dispatch) => {
    dispatch(deviceAssignStatus({ status: LOADING, rowId }));
    return unassignTerminalSync(params)
      .then((response) => response.data)
      .then((data) => {
        onDone()
          .then(() => {
            dispatch(deviceAssignStatus(
              {
                status: data.success ? UNASSIGN_SUCCESS : UNASSIGN_FAIL,
                rowId,
              },
            ));
            dispatch(updateTerminalSync(data.terminal_sync.terminal_sync, rowId, false));
          });
      })
      .catch((data) => {
        const error = data.response.data.error;
        dispatch(deviceAssignStatus({
          status: UNASSIGN_FAIL,
          rowId,
          statusCode: `#${error.code}`,
        }));
      });
  };
}
