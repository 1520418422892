import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogHeader } from '@glooko/common-ui';
import Style from './InfoDialog.scss';
import WithTranslate from '../WithTranslate/WithTranslate';

export interface InfoDialogProps extends WithTranslationFunction {
  closeDialog: () => void;
  content: React.ReactNode;
  isOpen: boolean;
  title: React.ReactNode;
}

const InfoDialog = (props: InfoDialogProps) => {
  const {
    closeDialog,
    content,
    isOpen,
    t,
    title,
  } = props;

  return (
    <Dialog
      onClose={closeDialog}
      open={isOpen}
      size="medium"
    >
      <DialogHeader>
        <h2 className={Style.title}>{title}</h2>
      </DialogHeader>

      <DialogContent>
        {content}
      </DialogContent>

      <DialogActions>
        <Button onClick={closeDialog}>
          {t('ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WithTranslate('InfoDialog')(InfoDialog);
