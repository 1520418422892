import React from 'react';
import PropTypes from 'prop-types';
import { ToggleSwitch } from '@glooko/common-ui';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import Styles from './BetaTogglePresenter.scss';

const BetaTogglePresenter = (props) => {
  const { t } = props;

  const setPoptrackerVersion = () => {
    const version = props.hasLegacyPoptracker ? 'beta' : 'legacy';

    props.dispatchUpdatePoptrackerVersionThunk({ poptrackerVersion: version });
  };

  const betaToggleLabelText = () => (
    props.hasLegacyPoptracker ? t('switchToNewPatientList') : t('switchToLegacyPatientList')
  );

  return (
    <div className={Styles.toggleContainer}>
        <ToggleSwitch
          checked={!props.hasLegacyPoptracker}
          onChange={setPoptrackerVersion}
          dataAttributes={{ testid: 'beta-toggle' }}
        />
      <div className={Styles.toggleLabelContainer}>
        {betaToggleLabelText()}
      </div>
    </div>
  );
};

BetaTogglePresenter.propTypes = {
  t: PropTypes.func.isRequired,
  hasLegacyPoptracker: PropTypes.bool.isRequired,
  dispatchUpdatePoptrackerVersionThunk: PropTypes.func.isRequired,
};

export default translate('BetaTogglePresenter')(BetaTogglePresenter);
