import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from '@glooko/common-ui';
import Style from './DropdownButton.scss';

const DropdownButton = (props) => {
  const {
    onClick, expanded, text, children,
  } = props;

  const dropdownContentClasses = classNames(
    Style.dropdownContent,
    { [Style.expandedDropdownContent]: expanded },
  );

  const dropdownButtonClasses = classNames(
    Style.dropdownButton,
    { [Style.activeDropdownButton]: expanded },
  );

  return (
    <div className={Style.container}>
      <button
        type="button"
        onClick={onClick}
        className={dropdownButtonClasses}
        data-testid="dropdown-button"
      >
        <div
          className={Style.dropdownText}
          data-testid="dropdown-button-text"
          aria-label={text}
        >
          {text}
        </div>
        <Icon
          name="dropdown-arrow"
          className={Style.dropdownIcon}
          dataAttributes={{ testid: 'dropdown-button-arrow' }}
        />
      </button>
      <div
        className={dropdownContentClasses}
        data-testid="dropdown-button-content"
        aria-hidden={!expanded}
      >
        {children}
      </div>
    </div>
  );
};

DropdownButton.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
  expanded: PropTypes.bool,
};

DropdownButton.defaultProps = {
  expanded: false,
};

export default DropdownButton;
