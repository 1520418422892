import * as _ from 'lodash';
import { ExecuteUnchangedApiCall } from './apiClient';

export function insuletConsentResponse(consent) {
  return ExecuteUnchangedApiCall(
    'get', 'api/v2/insulet_consent/update', {
      consentStatus: consent,
    });
}

export function insuletCheckProconnectCode(proconnectCode) {
  return ExecuteUnchangedApiCall(
    'get', 'insulet/check_pgs_code', {
      proconnectCode,
    });
}

export async function findPGSByCode(code) {
  const resp = await insuletCheckProconnectCode(code);
  return {
    code,
    pgs: _.get(resp.data, 'provider_group_site', null),
  };
}
