import i18next from '~/utils/I18n/I18n';

const t = i18next.getFixedT(null, 'AlarmKeys');

export default (key, _) => {
  switch (key) {
    case 'mallya_increments_missing':
      return t('mallyaIncrementsMissing');
    case 'mallya_increments_missing_popup':
      return t('mallyaIncrementsMissingPopup');
    default:
      return null;
  }
};
