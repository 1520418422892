import PropTypes from 'prop-types';
import { AVAILABLE_METER_UNITS } from '~/services/Readings';

export const preferencePropTypes = {
  meterUnits: PropTypes.oneOf(AVAILABLE_METER_UNITS).isRequired,
  normalGlucoseMin: PropTypes.number.isRequired,
  beforeMealNormalGlucoseMax: PropTypes.number.isRequired,
  afterMealNormalGlucoseMax: PropTypes.number.isRequired,
  breakfastBegin: PropTypes.number.isRequired,
  lunchBegin: PropTypes.number.isRequired,
  dinnerBegin: PropTypes.number.isRequired,
  midnightSnackBegin: PropTypes.number.isRequired,
  shareDataWithLifescan: PropTypes.bool,
  language: PropTypes.string.isRequired,
};
