import React from 'react';
import PropTypes from 'prop-types';
import Style from './SettingsTitle.scss';

const SettingsTitle = (props) => (
  <h2 class={Style.SettingsTitle}>{props.label}</h2>
);

SettingsTitle.propTypes = {
  label: PropTypes.string.isRequired,
};

export default SettingsTitle;
