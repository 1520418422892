import { WEEKDAYS } from '~/utils/syncTimestamps';
import { TYPE_BG, TYPE_CGM } from '~/bundles/shared/constants/readings';
import { fromPairs, map, uniq } from 'lodash';
import { FETCH_STATUS_IN_PROGRESS, FETCH_STATUS_FAILED } from '~/bundles/shared/constants/graphs';
import {
  trackGraphsOverlayDayOfWeekClicked,
} from '~/services/eventLogging';
import {
  callGraphsChangeTypeTracker,
  callGraphsUpdateOptionsTracker,
} from '~/services/eventLoggingHelpers/graphs';

export const GRAPHS_HAS_ERRORED = 'gl/graphs/GRAPHS_HAS_ERRORED';
export const GRAPHS_IN_PROGRESS = 'gl/graphs/GRAPHS_IN_PROGRESS';
export const GRAPHS_FETCH_SUCCESS = 'gl/graphs/GRAPHS_FETCH_SUCCESS';
export const GRAPHS_CLEANUP = 'gl/graphs/GRAPHS_CLEANUP';
export const GRAPHS_CLEANUP_KEYS = 'gl/graphs/GRAPHS_CLEANUP_KEYS';
export const GRAPHS_DEVICES = 'gl/graphs/GRAPHS_DEVICES';
export const GRAPHS_LEGEND_TOGGLE = 'gl/graphs/GRAPHS_LEGEND_TOGGLE';
export const OVERVIEW_HOURS_LEGEND_CONFIG = 'gl/graphs/OVERVIEW_HOURS_LEGEND_CONFIG';
export const OVERVIEW_DAYS_LEGEND_CONFIG = 'gl/graphs/OVERVIEW_DAYS_LEGEND_CONFIG';
export const GRAPHS_WEEKDAY_TOGGLE_SELECT_STATE = 'gl/graphs/GRAPHS_WEEKDAY_TOGGLE_SELECT_STATE';
export const GRAPHS_WEEKDAY_SELECT = 'gl/graphs/GRAPHS_WEEKDAY_SELECT';
export const GRAPHS_WEEKDAY_SELECT_CLEAR = 'gl/graphs/GRAPHS_WEEKDAY_SELECT_CLEAR';
export const GRAPHS_WEEKDAY_SELECT_START_LOADING = 'GRAPHS_WEEKDAY_SELECT_START_LOADING';
export const GRAPHS_CHANGE_TYPE = 'gl/graphs/GRAPHS_CHANGE_TYPE';
export const GRAPHS_UPDATE_OPTIONS = 'gl/graphs/GRAPHS_UPDATE_OPTIONS';
export const GRAPHS_UPDATE_SERIES = 'gl/graphs/GRAPHS_UPDATE_SERIES';
export const GRAPHS_UPDATE_WEEK_VIEW_SERIES = 'gl/graphs/GRAPHS_UPDATE_WEEK_VIEW_SERIES';
export const OVERVIEW_HOURS_MAXIMUM = 'gl/graphs/OVERVIEW_HOURS_MAXIMUM';

export const OVERLAY_SPAGHETTI = 'OVERLAY_SPAGHETTI';
export const OVERLAY_AGP = 'OVERLAY_AGP';
export const OVERLAY_SCATTER = 'OVERLAY_SCATTER';
export const OVERLAY_BOX_PLOT = 'OVERLAY_BOX_PLOT';
export const OVERVIEW = 'OVERVIEW';

export const OVERVIEW_TAB_INDEX = 0;
export const OVERLAY_TAB_INDEX = 1;
export const WEEK_VIEW_TAB_INDEX = 2;
export const CALENDAR_TAB_INDEX = 3;

export const OVERLAY_HISTORY_UPDATE = 'OVERLAY_HISTORY_UPDATE';

export const logger = window.eventLogging;

export const OVERLAY_GRAPH_TYPES = [
  {
    id: OVERLAY_SPAGHETTI,
    label: 'Spaghetti',
    isEnabled: (readingsType) => (readingsType === TYPE_CGM),
  },
  {
    id: OVERLAY_AGP,
    label: 'AGP',
    isEnabled: (readingsType) => (readingsType === TYPE_CGM),
  },
  {
    id: OVERLAY_SCATTER,
    label: 'Scatter',
    isEnabled: (readingsType) => (readingsType === TYPE_BG),
  },
  {
    id: OVERLAY_BOX_PLOT,
    label: 'Box Plot',
    isEnabled: (readingsType) => (readingsType === TYPE_BG),
  },
];
export const STATISTICS_HAS_ERRORED = 'gl/graphs/STATISTICS_HAS_ERRORED';
export const STATISTICS_FETCH_DATA_SUCCESS = 'gl/graphs/STATISTICS_FETCH_DATA_SUCCESS';

export function graphsHasErrored(params) {
  return { type: GRAPHS_HAS_ERRORED, params };
}

export function graphsInProgress(params) {
  return { type: GRAPHS_IN_PROGRESS, params };
}

export function graphsFetchSuccess(params, series) {
  return { type: GRAPHS_FETCH_SUCCESS, params, series };
}

export function graphsDevices(params, devices) {
  return { type: GRAPHS_DEVICES, params, devices };
}

export function overviewHoursMaximum(params, maximum) {
  return { type: OVERVIEW_HOURS_MAXIMUM, params, maximum };
}

export function graphsLegendToggle() {
  return { type: GRAPHS_LEGEND_TOGGLE };
}

export function graphsWeekdayToggleSelectState(day, newState) {
  trackGraphsOverlayDayOfWeekClicked();
  return { type: GRAPHS_WEEKDAY_TOGGLE_SELECT_STATE, day, newState };
}

export function graphsWeekdaySelect(day) {
  return { type: GRAPHS_WEEKDAY_SELECT, day };
}

export function graphsWeekdaySelectClear() {
  return { type: GRAPHS_WEEKDAY_SELECT_CLEAR };
}

export function graphsWeekdaySelectStartLoading() {
  return { type: GRAPHS_WEEKDAY_SELECT_START_LOADING };
}

export function graphsChangeType(typeId) {
  callGraphsChangeTypeTracker(typeId);
  return { type: GRAPHS_CHANGE_TYPE, typeId };
}

export function graphsUpdateOptions(tabType, options) {
  callGraphsUpdateOptionsTracker(tabType, options);
  return { type: GRAPHS_UPDATE_OPTIONS, options };
}

export function graphsUpdateSeries(plotLookup, lookup, series) {
  return { type: GRAPHS_UPDATE_SERIES, plotLookup, lookup, series };
}

export function graphsUpdateWeekViewSeries(reduxKey, day, seriesName, series) {
  return {
    type: GRAPHS_UPDATE_WEEK_VIEW_SERIES, reduxKey, day, seriesName, series,
  };
}

export function statisticsHasErrored(params) {
  return { type: GRAPHS_HAS_ERRORED, params };
}

export function statisticsFetchSuccess(params, overall) {
  return { type: STATISTICS_FETCH_DATA_SUCCESS, overall, params };
}

export function cleanUp(params) {
  return { type: GRAPHS_CLEANUP, params };
}

export function cleanUpKeys(keys) {
  return { type: GRAPHS_CLEANUP_KEYS, keys };
}

export function historyTooltipShow(payload) {
  return {
    type: OVERLAY_HISTORY_UPDATE,
    payload: { ...payload, showTooltip: true },
  };
}

export function historyTooltipHide() {
  return {
    type: OVERLAY_HISTORY_UPDATE,
    payload: { showTooltip: false },
  };
}

export function overviewHoursLegendConfig(config) {
  return { type: OVERVIEW_HOURS_LEGEND_CONFIG, config };
}

export function overviewDaysLegendConfig(config) {
  return { type: OVERVIEW_DAYS_LEGEND_CONFIG, config };
}

export const initialState = {
  legendExpanded: true,
  weekDaysSelected: fromPairs(map(WEEKDAYS, (weekday) => [weekday, false])),
  overlayGraphTypeOrder: [ // first available option will be selected
    OVERLAY_SPAGHETTI,
    OVERLAY_AGP,
    OVERLAY_SCATTER,
    OVERLAY_BOX_PLOT,
  ],
  median: false,
  hiLo: false,
  connectDays: false,
  average: true,
  percentiles: false,
  weekDayClicked: null,
  hoursGraphMax: {},
  historyItemsTooltip: {
    pageX: 0,
    pageY: 0,
    timestamp: null,
    readingId: null,
    showTooltip: false,
  },
  weekdayLoading: false,
  overviewGraphHoursLegendConfig: {
    configured: false,
    data: null,
  },
  overviewGraphDaysLegendConfig: {
    configured: false,
    data: null,
  },
};

export const keyFromParams = (params) => (`${params.id}_${params.startTimestamp || params.startDate}_${params.endTimestamp || params.endDate}`);

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GRAPHS_FETCH_SUCCESS: {
      return { ...state, [keyFromParams(action.params)]: action.series };
    }
    case GRAPHS_HAS_ERRORED: {
      return Object.assign({}, state, { [keyFromParams(action.params)]: FETCH_STATUS_FAILED });
    }
    case GRAPHS_IN_PROGRESS: {
      return Object.assign({}, state, { [keyFromParams(action.params)]: FETCH_STATUS_IN_PROGRESS });
    }
    case OVERVIEW_HOURS_MAXIMUM: {
      return Object.assign(
        {},
        state,
        { hoursGraphMax: {
          ...state.hoursGraphMax,
          [keyFromParams(action.params)]: action.maximum,
        } });
    }
    case GRAPHS_LEGEND_TOGGLE: {
      return { ...state, legendExpanded: !state.legendExpanded };
    }
    case GRAPHS_CLEANUP: {
      const newState = { ...state };
      const hoursGraphMax = { ...state.hoursGraphMax };
      const key = keyFromParams(action.params);
      delete newState[key];
      delete hoursGraphMax[key];

      return {
        ...newState,
        hoursGraphMax,
        overviewGraphHoursLegendConfig: {
          configured: false,
          data: null,
        },
        overviewGraphDaysLegendConfig: {
          configured: false,
          data: null,
        },
      };
    }
    case GRAPHS_CLEANUP_KEYS: {
      const newState = { ...state };
      action.keys.forEach((key) => delete newState[key]);

      return {
        ...newState,
      };
    }
    case GRAPHS_WEEKDAY_TOGGLE_SELECT_STATE: {
      const day = action.day;
      return {
        ...state,
        weekDaysSelected: {
          ...state.weekDaysSelected,
          [day]: action.newState,
        },
      };
    }
    case GRAPHS_WEEKDAY_SELECT: {
      return { ...state, weekDayClicked: action.day, weekdayLoading: false };
    }
    case GRAPHS_WEEKDAY_SELECT_CLEAR: {
      return { ...state, weekDayClicked: null, weekdayLoading: false };
    }
    case GRAPHS_WEEKDAY_SELECT_START_LOADING: {
      return { ...state, weekdayLoading: true };
    }
    case GRAPHS_CHANGE_TYPE: {
      return {
        ...state, overlayGraphTypeOrder: uniq([action.typeId, ...state.overlayGraphTypeOrder]),
      };
    }
    case GRAPHS_UPDATE_OPTIONS: {
      return { ...state, ...action.options };
    }
    case GRAPHS_UPDATE_SERIES: {
      const plotName = action.plotLookup;
      if (state[plotName]) {
        const newSeries = state[plotName].map((s) => (s.name === action.lookup ? action.series : s));
        return {
          ...state,
          [plotName]: newSeries,
        };
      }
      return { ...state };
    }
    case GRAPHS_UPDATE_WEEK_VIEW_SERIES: {
      const { reduxKey, day, seriesName, series } = action;
      if (state[reduxKey]) {
        const weekViewData = JSON.parse(JSON.stringify(state[reduxKey])).map((dayData) => {
          if (
            dayData.startTimestamp !== day.startTimestamp ||
            dayData.endTimestamp !== day.endTimestamp
          ) return dayData;

          const dayDataSeries = dayData.series.map((s) => (s.name === seriesName ? series : s));

          return { ...day, series: dayDataSeries };
        });
        return {
          ...state,
          [reduxKey]: weekViewData,
        };
      }
      return { ...state };
    }
    case STATISTICS_FETCH_DATA_SUCCESS: {
      return { ...state, [keyFromParams(action.params)]: action.overall };
    }
    case STATISTICS_HAS_ERRORED: {
      return state;
    }
    case OVERLAY_HISTORY_UPDATE: {
      return {
        ...state,
        historyItemsTooltip: {
          ...state.historyItemsTooltip,
          ...action.payload,
        },
      };
    }
    case GRAPHS_DEVICES: {
      const params = action.params;
      const key = `Devices_${params.startTimestamp || params.startDate}_${params.endTimestamp || params.endDate}`;
      return { ...state, [key]: action.devices };
    }
    case OVERVIEW_HOURS_LEGEND_CONFIG: {
      return {
        ...state,
        overviewGraphHoursLegendConfig: {
          configured: true,
          data: action.config,
        },
      };
    }
    case OVERVIEW_DAYS_LEGEND_CONFIG: {
      return {
        ...state,
        overviewGraphDaysLegendConfig: {
          configured: true,
          data: action.config,
        },
      };
    }
    default: {
      return state;
    }
  }
}
