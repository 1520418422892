import React from 'react';
import PropTypes from 'prop-types';
import Colors from '~/../assets/styles/export/colors';
import Style from './GraphOptionsItem.scss';

const GraphOptionsItem = (props) => (
  (props.visible) ? (
    <div role="presentation" class={Style.GraphOptionsItem} style={{ color: props.fontColor }}>
      <input
        type="checkbox"
        name={props.id}
        value={props.label}
        checked={props.selected}
        readOnly
      />
      <label
        htmlFor={props.id}
        onClick={() => (props.onClick({ [props.id]: !props.selected }))}
      >
        {props.label}
      </label>
    </div>
  ) : null
);

GraphOptionsItem.propTypes = {
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  fontColor: PropTypes.string,
};

GraphOptionsItem.defaultProps = {
  label: '',
  selected: false,
  visible: false,
  id: '',
  fontColor: Colors.fontLight,
};

export default GraphOptionsItem;
