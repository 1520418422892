import { map } from 'lodash';
import i18next from '~/utils/I18n/I18n';
import { createPdfTarget } from '~/utils/I18n/FromRails';
import { TYPE_BG, TYPE_CGM } from '~/bundles/shared/constants/readings';
import { PATH_GRAPHS } from '~/bundles/shared/constants/navigation';
import ExecuteApiCall, { OpenPageGenericWithLocale } from './apiClient';

export function fetchCalendarGraphSeries(payload) {
  return ExecuteApiCall('get', 'api/v3/graph/data', {
    startDate: payload.startTimestamp,
    endDate: payload.endTimestamp,
    series: payload.series,
    locale: i18next.language,
    insulinTooltips: payload.insulinTooltips || false,
    filterBgReadings: payload.filterBgReadings || false,
    splitByDay: payload.splitByDay || false,
  });
}

export function fetchOverlayScatterDowGraphSeries(params) {
  return ExecuteApiCall('get', 'api/v2/graph/readings/dow_scatter_overlay', {
    startDate: params.startTimestamp,
    endDate: params.endTimestamp,
    type: params.type === TYPE_BG ? 'bg' : 'egv',
  });
}

export function fetchOverlayBoxPlotOverallData(params) {
  return ExecuteApiCall('get', 'api/v2/graph/readings/box_plot_overlay_overall', {
    startDate: params.startTimestamp,
    endDate: params.endTimestamp,
    type: params.type === TYPE_BG ? 'bg' : 'egv',
    dow: map(params.dow, (day) => day.toLowerCase()).join(','),
  });
}

export function fetchOverlayBoxPlotDowData(params) {
  return ExecuteApiCall('get', 'api/v2/graph/readings/box_plot_overlay_dow', {
    startDate: params.startTimestamp,
    endDate: params.endTimestamp,
    type: params.type === TYPE_BG ? 'bg' : 'egv',
  });
}

export function fetchOverlayAgpOverallData(params) {
  return ExecuteApiCall('get', 'api/v2/graph/agp/overall', {
    startDate: params.startTimestamp,
    endDate: params.endTimestamp,
    type: params.type === TYPE_BG ? 'bg' : 'egv',
    dow: map(params.dow, (day) => day.toLowerCase()).join(','),
  });
}

export function fetchOverlayAgpDowData(params) {
  return ExecuteApiCall('get', 'api/v2/graph/agp/dow', {
    startDate: params.startTimestamp,
    endDate: params.endTimestamp,
    type: params.type === TYPE_BG ? 'bg' : 'egv',
  });
}

export function fetchOverviewExercise(payload) {
  return ExecuteApiCall('get', 'api/v3/graph/exercise_data', {
    startDate: payload.startTimestamp,
    endDate: payload.endTimestamp,
  });
}

export function fetchLgsPlgsData(params) {
  return ExecuteApiCall('get', 'api/v3/graph/statistics/lgs_plgs', {
    startDate: params.startTimestamp,
    endDate: params.endTimestamp,
    patient: params.patient,
  });
}

export function fetchStatisticsData(params) {
  return ExecuteApiCall('get', 'api/v3/graph/statistics/overall', {
    startDate: params.startTimestamp,
    endDate: params.endTimestamp,
    egv: params.readingsType === TYPE_CGM,
    normalized: params.normalized,
    includeInsulin: params.includeInsulin,
    includeExercise: params.includeExercise,
    excludeManual: params.excludeManual,
    dow: map(params.dow, (day) => day.toLowerCase()).join(','),
    includePumpModes: params.includePumpModes,
  });
}

export function fetchOverviewAverageAndPercentiles(params) {
  return ExecuteApiCall('get', 'api/v3/graph/daily_data', {
    series: params.series,
    startDate: params.startTimestamp,
    endDate: params.endTimestamp,
  });
}

export function fetchHistoryItems(params) {
  return ExecuteApiCall('get', 'dashboard/history_items', {
    start_date: params.startDate, // eslint-disable-line camelcase
    end_date: params.endDate, // eslint-disable-line camelcase
  });
}

export function createPDF(params) {
  return OpenPageGenericWithLocale('/api/v3/pdf/download', params, createPdfTarget);
}

export function createPDFWithLoader(params) {
  return OpenPageGenericWithLocale('/pdf_loading', params, createPdfTarget);
}

export function openGraphsPage(params) {
  return OpenPageGenericWithLocale(PATH_GRAPHS, params, '_self');
}
