// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".InsulinGraph_InsulinGraph{width:100%;display:block}", "",{"version":3,"sources":["InsulinGraph.scss"],"names":[],"mappings":"AAAA,2BACE,UAAA,CACA,aAAA","file":"InsulinGraph.scss","sourcesContent":[".InsulinGraph {\n  width: 100%;\n  display: block;\n}\n"]}]);
// Exports
exports.locals = {
	"InsulinGraph": "InsulinGraph_InsulinGraph"
};
module.exports = exports;
