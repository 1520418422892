import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import { keyFromParams } from '~/redux/modules/graphs/graphs';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import Readings from '~/services/Readings';
import { getAxisMaxFromRedux } from '~/bundles/graphs/components/OverviewTabContainer/OverviewGraphHours/OverviewGraphHoursContainer/HoursGraphHelpers';
import AxisPresenter from '~/bundles/graphs/components/AxisPresenter/AxisPresenter';
import Style from './WeekViewAxis.scss';
import { WEEK_VIEW_PDF_GRAPH_NAME } from '../../WeekViewPdfContainer/WeekViewPdfContainer';

const mapStateToProps = (state, ownProps) => {
  const seriesKey = keyFromParams({
    startTimestamp: ownProps.startTimestamp,
    endTimestamp: ownProps.endTimestamp,
    id: WEEK_VIEW_PDF_GRAPH_NAME,
  });

  return {
    axisMaxData: ownProps.axisMaxData || getAxisMaxFromRedux(state, seriesKey),
  };
};

const WeekViewAxis = (props) => {
  const {
    t, hasPump, hasControlIQ, hasInsulinData, axisMaxData, meterUnits,
    heightOverrideFactor, hasOmnipod5, hasBasalIqData,
    hasMedtronicClosedLoopData, hasCamapsData, weekViewWeb,
  } = props;
  const title = (hasPump && !hasInsulinData) ? t('bolusTitle') : t('manualTitle');
  const axisTitle = (hasPump && hasInsulinData) ? t('insulinTitleWeekView') : title;
  const glucoseTickStep = meterUnits === Readings.MMOLL ? 5 : 100;

  const axisClass = classNames({
    [Style.axis]: true,
    [Style.weekViewWeb]: weekViewWeb,
  });

  return (
    <div className={axisClass}>
      <div className={Style.borderBottom}>
        <div className={Style.first}>
          &nbsp;
        </div>
      </div>
      <div className={Style.borderBottom}>
        <AxisPresenter
          startIndex={glucoseTickStep}
          endIndex={axisMaxData.bgGraphAxisEndIndex}
          tickStep={glucoseTickStep}
          windowHeight={240 - (500 * heightOverrideFactor)}
          title={t('glucoseTitle')}
          subtitle={`(${Readings.displayMeterUnits(props.meterUnits)})`}
          windowWidth={130}
          weekView
        />
      </div>
      <div className={Style.borderBottom}>
        <AxisPresenter
          startIndex={0}
          endIndex={10}
          tickStep={5}
          windowHeight={50 - (80 * heightOverrideFactor)}
          title={t('carbsTitle')}
          subtitle={`(${t('grams')})`}
          windowWidth={130}
          hideAxis
          carbsWeekView
          weekView
        />
      </div>
      <div className={Style.borderBottom}>
        <AxisPresenter
          startIndex={0}
          endIndex={axisMaxData.bolusAxisEndIndex}
          tickStep={axisMaxData.bolusAxisEndIndex / 2}
          windowHeight={72 - (80 * heightOverrideFactor)}
          title={axisTitle}
          subtitle={`(${t('units')})`}
          windowWidth={130}
          weekView
        />
      </div>
      {hasPump &&
        <div className={Style.borderBottom}>
          <AxisPresenter
            startIndex={0}
            endIndex={axisMaxData.basalAxisEndIndex}
            tickStep={axisMaxData.basalAxisEndIndex / 2}
            windowHeight={72 - (80 * heightOverrideFactor)}
            title={t('basalTitle')}
            subtitle={`(${t('units')})`}
            windowWidth={130}
            weekView
          />
        </div>}
      {(hasControlIQ || hasBasalIqData) &&
        <div className={Style.borderBottom}>
          <AxisPresenter
            startIndex={0}
            endIndex={1}
            tickStep={0.5}
            windowHeight={39}
            title={t('systemTitle')}
            subtitle={`(${t('controlIQ')})`}
            windowWidth={130}
            hideAxis
            weekView
          />
        </div>}
      {hasMedtronicClosedLoopData &&
        <div className={Style.borderBottom}>
          <AxisPresenter
            startIndex={0}
            endIndex={1}
            tickStep={0.5}
            windowHeight={39}
            title={t('systemTitle')}
            subtitle={`(${t('medtronic', { model: '670G' })})`}
            windowWidth={130}
            hideAxis
            weekView
          />
        </div>}
      {hasOmnipod5 &&
        <div class={[Style.borderBottom]}>
          <AxisPresenter
            startIndex={0}
            endIndex={1}
            tickStep={0.5}
            windowHeight={39}
            title={t('systemTitle')}
            subtitle={`(${t('omnipod5')})`}
            windowWidth={130}
            hideAxis
            weekView
          />
        </div>}
      {hasOmnipod5 &&
        <div className={[Style.borderBottom]}>
          <AxisPresenter
            startIndex={0}
            endIndex={1}
            tickStep={0.5}
            windowHeight={20 - Math.round(10 * heightOverrideFactor)}
            title={t('basalBar')}
            windowWidth={130}
            hideAxis
            weekView
          />
        </div>}
      {hasCamapsData && (
        <div className={[Style.borderBottom]}>
          <AxisPresenter
            startIndex={0}
            endIndex={1}
            tickStep={0.5}
            windowHeight={39}
            title={t('systemTitle')}
            subtitle={`(${t('CamAPSFX')})`}
            windowWidth={130}
            hideAxis
            weekView
          />
        </div>
      )}
      <div style={{ clear: 'both' }} />
    </div>
  );
};

WeekViewAxis.propTypes = {
  t: PropTypes.func.isRequired,
  hasPump: PropTypes.bool.isRequired,
  hasControlIQ: PropTypes.bool.isRequired,
  hasBasalIqData: PropTypes.bool.isRequired,
  hasCamapsData: PropTypes.bool.isRequired,
  hasInsulinData: PropTypes.bool.isRequired,
  axisMaxData: PropTypes.object.isRequired,
  startTimestamp: PropTypes.string.isRequired,
  endTimestamp: PropTypes.string.isRequired,
  meterUnits: PropTypes.string,
  heightOverrideFactor: PropTypes.number.isRequired,
  hasOmnipod5: PropTypes.bool.isRequired,
  hasMedtronicClosedLoopData: PropTypes.bool,
  weekViewWeb: PropTypes.bool,
};

WeekViewAxis.defaultProps = {
  hasPump: false,
  hasControlIQ: false,
  hasBasalIqData: false,
  hasCamapsData: false,
  hasInsulinData: false,
  axisMaxData: {},
  startTimestamp: moment().toISOString(),
  endTimestamp: moment().toISOString(),
  meterUnits: 'mgdl',
  heightOverrideFactor: 0,
  hasOmnipod5: false,
  hasMedtronicClosedLoopData: false,
  weekViewWeb: false,
};

export const TranslatedWeekViewAxis = translate('OverviewGraphHoursWithAxis')(WeekViewAxis);
export default connect(mapStateToProps)(TranslatedWeekViewAxis);
