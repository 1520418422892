import { fetchSubscription } from '~/services/subscriptionApi';
import { updateSubscriptionModel } from '~/redux/modules/providerGroupSite/providerGroupSite';
import throwIfStatusCodeNotExpected from '~/utils/throwIfStatusCodeNotExpected';

export function fetchSubscriptionThunk() {
  return (dispatch) => fetchSubscription()
    .then(throwIfStatusCodeNotExpected(200))
    .then((response) => response.data)
    .then((subscription) => {
      dispatch(updateSubscriptionModel(subscription));
    })
    .catch((response) => response);
}
