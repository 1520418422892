import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogHeader } from '@glooko/common-ui';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import Styles from './GuardianConsentPending.scss';

export const GuardianConsentPending = (props) => {
  const {
    isMinor,
    guardianEmail,
    guardianConsented,
    sendGuardianEmailStatus,
    actions,
    t,
  } = props;

  const getSendEmailButtonLabel = () => {
    const sendMailButtonLabels = {
      notSent: t('resendEmail'),
      initiated: t('guardianEmailSending'),
      sent: t('guardianEmailSent'),
    };
    return sendMailButtonLabels[sendGuardianEmailStatus] ?
      sendMailButtonLabels[sendGuardianEmailStatus] : sendMailButtonLabels.notSent;
  };

  const handleTryLater = () => {
    window.getDataCache().logout().then(() => {
      window.location.assign('/');
    });
  };

  const handleSendGuardianEmail = () => {
    if (!sendGuardianEmailStatus) {
      actions.sendGuardianEmail();
    }
  };

  if (!isMinor || guardianConsented) {
    return null;
  }

  return (
    <Dialog showCancelButton={false} size="medium" open={true} dataAttributes={{ testid: 'guardian-consent-pending' }}>
      <DialogHeader dataAttributes={{ testid: 'guardian-consent-pending' }}>
        <h2>{t('guardianConsentRequired')}</h2>
      </DialogHeader>
      <DialogContent dataAttributes={{ testid: 'guardian-consent-pending' }}>
        <div className={Styles.consentText}>
          {t('guardianConsentInfo', { guardianEmail })}
        </div>
      </DialogContent>
      <DialogActions dataAttributes={{ testid: 'guardian-consent-pending' }}>
        <div className={Styles.actionBar}>
          <Button
            className={Styles.actionButton}
            onClick={handleSendGuardianEmail}
            disabled={sendGuardianEmailStatus}
            variation='secondary'
            dataAttributes={{ testid: 'guardian-consent-pending-send-email' }}
          >
            {getSendEmailButtonLabel()}
          </Button>
          <Button
            className={Styles.actionButton}
            onClick={handleTryLater}
            dataAttributes={{ testid: 'guardian-consent-pending-try-later' }}
          >
            {t('tryLater')}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

GuardianConsentPending.propTypes = {
  isMinor: PropTypes.bool.isRequired,
  guardianEmail: PropTypes.string,
  guardianConsented: PropTypes.bool.isRequired,
  sendGuardianEmailStatus: PropTypes.string,
  actions: PropTypes.shape({
    sendGuardianEmail: PropTypes.func.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

GuardianConsentPending.defaultProps = {
  isMinor: false,
  guardianEmail: undefined,
  guardianConsented: false,
  sendGuardianEmailStatus: undefined,
};

export default translate('GuardianConsentPending')(GuardianConsentPending);
