import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { map } from 'lodash';
import { TIME_FORMATS } from '~/utils/i18nFormats';
import Style from './OverviewAxisAbove.scss';

const hours = () => ([
  moment.utc().startOf('day').add(0, 'hours').format(TIME_FORMATS.H_AA),
  moment.utc().startOf('day').add(3, 'hours').format(TIME_FORMATS.H),
  moment.utc().startOf('day').add(6, 'hours').format(TIME_FORMATS.H),
  moment.utc().startOf('day').add(9, 'hours').format(TIME_FORMATS.H),
  moment.utc().startOf('day').add(12, 'hours').format(TIME_FORMATS.H_AA),
  moment.utc().startOf('day').add(15, 'hours').format(TIME_FORMATS.H),
  moment.utc().startOf('day').add(18, 'hours').format(TIME_FORMATS.H),
  moment.utc().startOf('day').add(21, 'hours').format(TIME_FORMATS.H),
]);
const nextMidnight = (weekView) => (
  weekView ? [moment.utc().startOf('day').add(0, 'hours').format(TIME_FORMATS.H_AA)] : []
);

const OverviewAxisAbove = (props) => {
  const weekViewClass = classNames({
    [Style.weekViewDay]: props.weekView,
    [Style.weekViewDayWeb]: props.weekViewWeb,
  });

  return (
    <div class={Style.OverviewAxisAbove}>
      {map(hours().concat(nextMidnight(props.weekView)), (element, index) => (
        <div key={index} class={props.weekView ? weekViewClass : Style.day}>
          {element}
        </div>
      ))}
    </div>
  );
};

OverviewAxisAbove.propTypes = {
  weekView: PropTypes.bool,
  weekViewWeb: PropTypes.bool,
};

OverviewAxisAbove.defaultProps = {
  weekView: false,
  weekViewWeb: false,
};

export default OverviewAxisAbove;
