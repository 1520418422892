import React from 'react';
import PropTypes from 'prop-types';
import PregnancyUtils from '~/utils/pregnancyUtils';
import Images from '~/utils/ImageStore';
import { translate } from '../../../shared/components/WithTranslate/WithTranslate';
import Style from './PregnancyBadge.scss';

export const PregnancyBadge = (props) => {
  const { startDate, endDate, patientDueDate, hasPregnancyPackage, t } = props;

  if (!hasPregnancyPackage || !startDate || !endDate || !patientDueDate) {
    return null;
  }

  const rangeString = PregnancyUtils.getRangeString(
    patientDueDate, startDate, endDate);

  if (!rangeString) {
    return null;
  }

  return (
    <div className={Style.PregnancyBadge}>
      <div className={Style.pregnancyImgContainer}>
        <img className={Style.pregnancyImg} src={Images.pregnancyIcon} alt="pregnancy" />
      </div>
      <div className={Style.pregnancyText}>{`${t('pregnancyWeek')} ${rangeString}`}</div>
    </div>
  );
};

PregnancyBadge.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  patientDueDate: PropTypes.string,
  hasPregnancyPackage: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

PregnancyBadge.defaultProps = {
  startDate: undefined,
  endDate: undefined,
  patientDueDate: undefined,
  hasPregnancyPackage: true,
};

export default translate('PregnancyBadge')(PregnancyBadge);
