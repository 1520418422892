import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { TIME_FORMATS } from '~/utils/i18nFormats';
import { map, flatMap } from 'lodash';
import {
  CALENDAR_AXIS_WIDTH_PDF,
  CALENDAR_AXIS_WIDTH_WEB,
  CALENDAR_ONE_DAY_WIDTH_PDF,
  CALENDAR_ONE_DAY_WIDTH_WEB,
} from '~/bundles/shared/constants/pages/calendar';
import Style from './CalendarWeekAxisBelow.scss';

const elementsWeb = () => flatMap(Array(8), () => ([
  moment.utc(0).format(TIME_FORMATS.CALENDAR_HOURS),
  moment.utc(0).add(12, 'hours').format(TIME_FORMATS.CALENDAR_HOURS),
]));
const elementsPDf = () => flatMap(Array(8), () => ([
  moment.utc(0).format(TIME_FORMATS.H_AA),
  moment.utc(0).add(6, 'hours').format(TIME_FORMATS.H),
  moment.utc(0).add(12, 'hours').format(TIME_FORMATS.H_AA),
  moment.utc(0).add(18, 'hours').format(TIME_FORMATS.H),
]));

const mapStateToProps = (state) => {
  const pdfProps = (state.pdf.inPDF) ? {
    oneDayWidth: CALENDAR_ONE_DAY_WIDTH_PDF,
    axisWidth: CALENDAR_AXIS_WIDTH_PDF,
    fontSize: 14,
    elements: elementsPDf(),
    width: CALENDAR_ONE_DAY_WIDTH_PDF / 4,
    inPdf: true,
  } : {
    elements: elementsWeb(),
    inPdf: false,
  };

  return { ...pdfProps };
};

export class CalendarWeekAxisBelow extends React.Component {
  visibleElements(elements) {
    const startMoment = moment.unix(this.props.startTimestamp).utc();
    const endMoment = moment.unix(this.props.endTimestamp).utc();
    const multiplier = this.props.inPdf ? 6 : 12;

    return elements.map((element, index) => {
      const time = moment.utc(startMoment).startOf('isoWeek').add(index * multiplier, 'hours');

      // enusures last timestamp for last active day will be included
      if (time.isAfter(endMoment) &&
          time.subtract(multiplier, 'hours').isSameOrBefore(endMoment)) {
        return element;
      }

      return (time.isSameOrAfter(startMoment) && time.isSameOrBefore(endMoment)) ? element : '';
    });
  }

  render() {
    return (
      <div
        class={classNames({
          [Style.CalendarWeekAxisBelow]: true,
        })}
        style={{ width: (this.props.axisWidth + (this.props.oneDayWidth * 7)) }}
      >
        <div class={Style.wrapper}>
          <div
            class={Style.leftPadding}
            style={{ width: this.props.axisWidth - (this.props.width / 2) }}
          />
          {map(this.visibleElements(this.props.elements), (elem, index) => (
            <div
              key={index}
              class={Style.element}
              style={{ width: this.props.width, fontSize: this.props.fontSize }}
            >
              {elem}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(CalendarWeekAxisBelow);

CalendarWeekAxisBelow.propTypes = {
  width: PropTypes.number.isRequired,
  axisWidth: PropTypes.number.isRequired,
  oneDayWidth: PropTypes.number.isRequired,
  elements: PropTypes.node.isRequired,
  fontSize: PropTypes.number,
  startTimestamp: PropTypes.number.isRequired,
  endTimestamp: PropTypes.number.isRequired,
  inPdf: PropTypes.bool.isRequired,
};

CalendarWeekAxisBelow.defaultProps = {
  addBorder: true,
  oneDayWidth: CALENDAR_ONE_DAY_WIDTH_WEB,
  axisWidth: CALENDAR_AXIS_WIDTH_WEB,
  fontSize: null,
  elements: [],
  width: CALENDAR_ONE_DAY_WIDTH_WEB / 2,
  startTimestamp: 0,
  endTimestamp: 0,
  inPdf: false,
};
