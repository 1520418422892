import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Images from '~/utils/ImageStore';
import { useReferenceDeviceList } from '~/bundles/shared/hooks/useReferenceDeviceList';
import { translate } from '../../WithTranslate/WithTranslate';
import ButtonStyle from '../../../../settings/PartnerIntegrations/components/IntegrationButton/IntegrationButton.scss';
import FileUploaderPopup from '../../FileUploaderPopup/FileUploaderPopup';
import SupportLinks from '../../../../../utils/support';
import UserHelper from '../../../../../redux/modules/users/UserHelper';
import Style from './AbbottCSVButton.scss';
import { trackSelectedAppsDevices } from '../../../../../services/eventLogging';

const mapStateToProps = (state) => {
  const currentUser = UserHelper.currentUser(state);
  const currentUserIsPatient = UserHelper.currentUserIsPatient(state);
  const hasAbbottCsv = currentUser.hasAbbottCsv;
  const countryOfResidence = currentUser.countryOfResidence;
  return { currentUserIsPatient, countryOfResidence, hasAbbottCsv };
};

const AbbottCSVButton = (props) => {
  const {
    countryOfResidence,
    currentUserIsPatient,
    hasAbbottCsv,
    type,
    t,
  } = props;
  const [showPopup, setShowPopup] = useState(false);
  const deviceName = 'Abbott Data';

  const { data, error } = useReferenceDeviceList();

  const startStep = (
    <ol>
      <li>{t('startStepLine11Abbott')}{' '}
        <a
          href={SupportLinks.article('4531230605843')}
          target="_blank"
        >{t('startStepLine12')}
        </a>
      </li>
      <li>
        {t('startStepLine21')}{' '}
        <strong>{t('startStepLine22')}</strong>{' '}
        {t('startStepLine23')}
      </li>
      <li>{t('startStepLine3')}</li>
      <li>
        {t('startStepLine41')}{' '}
        <strong>{t('startStepLine42')}</strong>{' '}
        {t('startStepLine43')}{' '}
        <strong>{t('startStepLine44')}</strong>{' '}
        {t('startStepLine45')}
      </li>
    </ol>
  );

  const openPopup = () => setShowPopup(true);
  const closePopup = () => setShowPopup(false);
  const onClickIntegration = () => {
    trackSelectedAppsDevices(deviceName);
    openPopup();
  };

  const device = data?.devices.find((device) => device.id === 'ABBOTT_LIBREVIEW_CSV');
  const showButton = device?.supportedCountries.includes(countryOfResidence);

  if (!showButton || error || !currentUserIsPatient || type === 'link' && !hasAbbottCsv) {
    return null;
  }

  return (
    <>
      {showPopup && (
        <FileUploaderPopup
          closePopup={closePopup}
          acceptedExtensions={['.csv']}
          extractorName="LibreviewCSV"
          startTitle={t('uploadAbbottDataTitle')}
          startStep={startStep}
        />
      )}
      {type === 'button' && (
        <div
          className={ButtonStyle.IntegrationButton}
          role="button"
          tabIndex={0}
          onClick={onClickIntegration}
        >
          <div className={ButtonStyle.content}>
            <img className={ButtonStyle.image} src={Images.integrationLogoAbbotCsv} alt="integration" />
            <div className={ButtonStyle.name}>
              <span>{t('uploadAbbottDataButton')}</span>
            </div>
          </div>
        </div>
      )}
      {type !== 'button' && (
        <div>
          <div className={Style.abbottCsvUpload} onClick={openPopup}>
            <img src={Images.uploadIcon} alt="Abbott CSV upload" />
            {t('uploadAbbottDataButton')}
          </div>
        </div>
      )}
    </>
  );
};

AbbottCSVButton.propTypes = {
  t: PropTypes.func.isRequired,
  type: PropTypes.string,
  currentUserIsPatient: PropTypes.bool.isRequired,
  countryOfResidence: PropTypes.string.isRequired,
  hasAbbottCsv: PropTypes.bool.isRequired,
};

AbbottCSVButton.defaultProps = {
  type: 'button',
  currentUserIsPatient: false,
  countryOfResidence: '',
  hasAbbottCsv: false,
};

export default connect(mapStateToProps, null)(translate('AbbottCSVButton')(AbbottCSVButton));
