import { base, readingTypesBaseSwitch } from '../utils/baseLegendConfig';

const colValueExists = (data) => data.data.some((e) => e.y > 0);
const colValuePdfExists = (data) => data.data.some((e) => e.yOrig !== '-' && e.yOrig > 0);
const avgExists = (data) => data.data.some((e) => e.x > 0 && e.y > 0);
const percentileExists = (data) => data.data.some((e) => e.x > 0 && e.high > 0 && e.low > 0);

const glucose = (series, forceDisplay = true) => base(series, 'glucose', (data, dataExists, config) => {
  switch (data.name) {
    case 'bgHigh':
    case 'bgHighManual':
    case 'bgAbove400':
    case 'bgAbove400Manual':
      config.bgAbove = config.bgAbove || dataExists;
      break;
    case 'bgNormal':
    case 'bgNormalManual':
      config.bgNormal = config.bgNormal || dataExists;
      break;
    case 'bgLow':
    case 'bgLowManual':
      config.bgLow = config.bgLow || dataExists;
      break;
    case 'bgDailyAverage':
      config.bgAvg = avgExists(data);
      break;
    case 'bgDailyPercentile10To90':
      config.bg10To90 = percentileExists(data);
      break;
    case 'bgDailyPercentile25To75':
      config.bg25To75 = percentileExists(data);
      break;
    case 'cgmHigh':
    case 'cgmHigh5':
    case 'cgmCalibrationHigh':
    case 'cgmCalibrationHigh5':
      config.cgmAbove = config.cgmAbove || dataExists;
      break;
    case 'cgmNormal':
    case 'cgmNormal5':
    case 'cgmCalibrationNormal':
    case 'cgmCalibrationNormal5':
      config.cgmNormal = config.cgmNormal || dataExists;
      break;
    case 'cgmLow':
    case 'cgmLow5':
    case 'cgmCalibrationLow':
    case 'cgmCalibrationLow5':
      config.cgmLow = config.cgmLow || dataExists;
      break;
    case 'cgmDailyAverage':
      config.cgmAvg = avgExists(data);
      break;
    case 'cgmDailyPercentile10To90':
      config.cgm10To90 = percentileExists(data);
      break;
    case 'cgmDailyPercentile25To75':
      config.cgm25To75 = percentileExists(data);
      break;
  }

  return config;
}, forceDisplay);

const readingTypes = (series) => base(series, 'readingTypes', (data, dataExists, config) => {
  const cfg = readingTypesBaseSwitch(data, dataExists, config);

  switch (data.name) {
    case 'cgmCalibrationHigh5':
    case 'cgmCalibrationNormal5':
    case 'cgmCalibrationLow5':
      cfg.calibrations = cfg.calibrations || dataExists;
      break;
    case 'cgmHigh5':
    case 'cgmNormal5':
    case 'cgmLow5':
      cfg.cgm = cfg.cgm || data.data.some((e) => !e.calculated);
      cfg.calculatedCgm = cfg.calculatedCgm || data.data.some((e) => e.calculated);
      break;
    case 'carbAllColumnNoPointWidth':
      cfg.carbs = cfg.carbs || colValueExists(data);
      break;
    case 'carbAllInline':
      cfg.carbs = cfg.carbs || colValuePdfExists(data);
      break;
    case 'exerciseColumnNoPointWidth':
      cfg.exercise = cfg.exercise || colValueExists(data);
      break;
    case 'exerciseInline':
      cfg.exercise = cfg.exercise || colValuePdfExists(data);
      break;
  }

  return cfg;
});

const insulin = (series) => base(series, 'insulin', (data, _, config) => {
  const dataExists = colValueExists(data);

  switch (data.name) {
    case 'insulinBolusColumnNoPointWidth':
    case 'gkInsulinBolusColumnNoPointWidth':
      config.bolus = config.bolus || dataExists;
      break;
    case 'insulinBasalColumnNoPointWidth':
    case 'gkInsulinBasalColumnNoPointWidth':
      config.basal = config.basal || dataExists;
      break;
    case 'insulinPremixedColumnNoPointWidth':
    case 'gkInsulinPremixedColumnNoPointWidth':
      config.premixed = config.premixed || dataExists;
      break;
    case 'gkInsulinOtherColumnNoPointWidth':
      config.custom = config.custom || dataExists;
      break;
  }

  return config;
});

const preprocessSeries = (series) => {
  const safeSeries = (name) => series[name] || [];

  const glucoseSeries = [
    ...safeSeries('bgCgmGraphSeries'),
    ...safeSeries('bgCgmAveragesGraphSeries'),
  ];

  const readingTypesSeries = [
    ...safeSeries('bgCgmGraphSeries'),
    ...safeSeries('carbsSeries'),
    ...safeSeries('exerciseSeries'),
  ];

  const insulinSeries = [
    ...safeSeries('insulinPumpSeries'),
    ...safeSeries('insulinManualSeries'),
  ];

  return {
    glucose: glucoseSeries,
    readingTypes: readingTypesSeries,
    insulin: insulinSeries,
  };
};

const overviewLegendConfig = (series) => {
  const processedSeries = preprocessSeries(series);

  return {
    glucose: glucose(processedSeries),
    readingTypes: readingTypes(processedSeries),
    insulin: insulin(processedSeries),
  };
};

export default overviewLegendConfig;
