import React from 'react';
import { connect } from 'react-redux';
import UserHelper from '~/redux/modules/users/UserHelper';
import OmnipodUploadContainer from '../OmnipodUploadContainer/OmnipodUploadContainer';

const mapStateToProps = (state) => ({
  dialogVisible: state.omnipod.dialogVisible,
  currentPatient: UserHelper.currentPatient(state),
});

const OmnipodStandaloneContainer = ({ currentPatient, dialogVisible }) => {
  if (!dialogVisible || (currentPatient && currentPatient.insuletConsent === undefined)) {
    return null;
  }
  return <OmnipodUploadContainer />;
};

export default connect(mapStateToProps, null)(OmnipodStandaloneContainer);
