import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import Select from 'react-select';
import FaAngleDown from 'react-icons/lib/fa/angle-down';
import { ALL_TIMEFRAMES } from '~/bundles/shared/constants/time';
import GraphsHelper from '~/redux/modules/graphs/GraphsHelper';
import 'react-select/dist/react-select.css';
import Style from './DateRangeSelect.scss';

class DateRangeSelect extends Component {
  static propTypes = {
    availableTimeFrames: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired,
      })),
    timeFrameIndex: PropTypes.number.isRequired,
    actions: PropTypes.shape({
      updateTimeframe: PropTypes.func.isRequired,
    }),
  };

  static defaultProps = {
    availableTimeFrames: ALL_TIMEFRAMES,
    timeFrameIndex: 0,
    actions: { updateTimeframe: () => {} },
  };

  constructor(props) {
    super(props);
    this.onSelectChange = this.onSelectChange.bind(this);
  }

  onSelectChange(e) {
    this.props.actions.updateTimeframe(e.value);
  }

  arrowRenderer() {
    return (
      <div class={Style.arrowDown}>
        <p><FaAngleDown /></p>
      </div>
    );
  }

  render() {
    const { availableTimeFrames, timeFrameIndex } = this.props;

    const timeframes = availableTimeFrames.map((timeframe) => ({
      value: timeframe.value,
      label: GraphsHelper.retrieveLabel(timeframe.value),
    }));
    const value = availableTimeFrames[timeFrameIndex].value;

    return (
      <div className={Style.DateRangeSelect}>
        <Select
          arrowRenderer={this.arrowRenderer}
          className={Style.select}
          clearable={false}
          multi={false}
          onChange={this.onSelectChange}
          options={timeframes}
          searchable={false}
          value={value}
        />
      </div>
    );
  }
}

export default DateRangeSelect;
