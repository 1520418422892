// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".PatientTirColumn_patientTirColumn{font-size:14px}.PatientTirColumn_tooltip{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;gap:10px;font-size:14px}.PatientTirColumn_tooltipGroup{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.PatientTirColumn_tooltipHeader{font-size:16px;font-weight:700}.PatientTirColumn_tooltipSubheader{font-weight:700}", "",{"version":3,"sources":["PatientTirColumn.scss"],"names":[],"mappings":"AAAA,mCACI,cAAA,CAGJ,0BACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,QAAA,CACA,cAAA,CAEJ,+BACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CAGJ,gCACI,cAAA,CACA,eAAA,CAEJ,mCACI,eAAA","file":"PatientTirColumn.scss","sourcesContent":[".patientTirColumn {\n    font-size: 14px;\n}\n\n.tooltip {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    font-size: 14px;\n}\n.tooltipGroup {\n    display: flex;\n    flex-direction: column;\n}\n\n.tooltipHeader {\n    font-size: 16px;\n    font-weight: 700;\n}\n.tooltipSubheader {\n    font-weight: 700;\n}\n\n"]}]);
// Exports
exports.locals = {
	"patientTirColumn": "PatientTirColumn_patientTirColumn",
	"tooltip": "PatientTirColumn_tooltip",
	"tooltipGroup": "PatientTirColumn_tooltipGroup",
	"tooltipHeader": "PatientTirColumn_tooltipHeader",
	"tooltipSubheader": "PatientTirColumn_tooltipSubheader"
};
module.exports = exports;
