/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@glooko/common-ui';
import classNames from 'classnames';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import Images from '~/utils/ImageStore';
import Style from '../PartnerIntegrationsPresenter/PartnerIntegrationsPresenter.scss';
import { GreyOverlay } from '../../../../shared/components/Overlay/Overlay';

const ConnectionNotPossible = ({ data, closePopup, t }) => {
  const { integration } = data;
  const text = {
    ABBOTT_LIBRELINK_CLOUD: t('integrationAbbottLibrelink'),
    YPSOMED_MYLIFE_CLOUD_CAN: t('integrationMylifeCanada'),
    YPSOMED_MYLIFE_CLOUD_FRA: t('integrationMylifeFrance'),
    YPSOMED_MYLIFE_CLOUD_GLOBAL: t('integrationMylifeGlobal'),
  };

  return (
    <div>
      <GreyOverlay />
      <div className={Style.popup}>
        <div
          className={Style.closeButton}
          role="button"
          tabIndex={0}
          onClick={closePopup}
        >
          <img src={Images.closeButton} alt="x" />
        </div>

        <div className={Style.innerPopup}>
          <div className={Style.header}>
            <div className={classNames(Style.brandImage)}>
              <img src={data.iconUrl} alt={data.name} />
            </div>
            <span>{t('failure')}</span>
          </div>

          <div className={Style.content}>
            <p>{t('connectionNotPossible')}</p>
            <div className={Style.integrationText}>
              <p dangerouslySetInnerHTML={{ __html: t('connectionNotPossibleDescription', {
                  integration: text[integration],
                }) }}
              />
              <p>
                {t('connectionNotPossibleLearnMore')}&nbsp;
                <a
                  href="https://support.glooko.com/hc/en-us/articles/4408171874707"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={Style.link}
                >
                  {t('here')}.
                </a>
              </p>
            </div>

          </div>

          <div className={Style.actions}>
            <Button
              onClick={closePopup}
              dataAttributes={{ testid: 'connection-not-possible-ok' }}
            >
              {t('ok')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

ConnectionNotPossible.propTypes = {
  data: PropTypes.object.isRequired,
  closePopup: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

ConnectionNotPossible.defaultProps = {
  data: {},
  closePopup: /* istanbul ignore next */ () => {},
  t: /* istanbul ignore next */ () => {},
};

export default translate('PartnerIntegrationsPresenter')(ConnectionNotPossible);
