import { FETCH_STATUS_FAILED, FETCH_STATUS_IN_PROGRESS } from '~/bundles/shared/constants/graphs';

export const HISTORY_HAS_ERRORED = 'HISTORY_HAS_ERRORED';
export const HISTORY_IN_PROGRESS = 'HISTORY_IN_PROGRESS';
export const HISTORY_FETCH_SUCCESS = 'HISTORY_FETCH_SUCCESS';

export function historyHasErrored(params) {
  return { type: HISTORY_HAS_ERRORED, params };
}

export function historyInProgress(params) {
  return { type: HISTORY_IN_PROGRESS, params };
}

export function historyFetchSuccess(params, history) {
  return { type: HISTORY_FETCH_SUCCESS, history, params };
}

export const initialState = {};

export const keyFromParams = (params) => (`${params.id}_${params.startTimestamp ||
  params.startDate}_${params.endTimestamp || params.endDate}`);

export default function reducer(state = initialState, action = []) {
  switch (action.type) {
    case HISTORY_FETCH_SUCCESS: {
      return { ...state, [keyFromParams(action.params)]: action.history };
    }
    case HISTORY_HAS_ERRORED: {
      return Object.assign({}, state, { [keyFromParams(action.params)]: FETCH_STATUS_FAILED });
    }
    case HISTORY_IN_PROGRESS: {
      return Object.assign({}, state, { [keyFromParams(action.params)]: FETCH_STATUS_IN_PROGRESS });
    }
    default: {
      return state;
    }
  }
}
