// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".PrimeWarningDialog_content{margin-bottom:30px}", "",{"version":3,"sources":["PrimeWarningDialog.scss"],"names":[],"mappings":"AAAA,4BACE,kBAAA","file":"PrimeWarningDialog.scss","sourcesContent":[".content {\n  margin-bottom: 30px;\n}\n"]}]);
// Exports
exports.locals = {
	"content": "PrimeWarningDialog_content"
};
module.exports = exports;
