import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { map } from 'lodash';
import Readings from '~/services/Readings';
import ImageStore from '~/utils/ImageStore';
import i18next from '~/utils/I18n/I18n';
import { numberFormatterOneDecimal, TIME_FORMATS } from '~/utils/i18nFormats';
import Style from './AGPtooltip.scss';

const t = i18next.getFixedT(null, 'AGPtooltip');

export default class AGPtooltip extends React.Component {
  render() {
    const { point, meterUnits, timestamp, inSummaryPage } = this.props;
    const fromTime = moment.unix(timestamp).utc().startOf('hour');
    const toTime = moment.unix(timestamp).utc().add(1, 'hour').startOf('hour');
    const meterUnitsText = Readings.displayMeterUnits(meterUnits);
    const rows = [
      {
        text: t('middlePercentiles'),
        iconSrc: ImageStore.Percentile2575,
        value: `${numberFormatterOneDecimal(point.stats[25])} - ${numberFormatterOneDecimal(point.stats[75])}`,
      },
      {
        text: t('endPercentiles'),
        iconSrc: ImageStore.Percentile1090,
        value: `${numberFormatterOneDecimal(point.stats[10])} - ${numberFormatterOneDecimal(point.stats[90])}`,
      },
      {
        text: t('median'),
        iconSrc: ImageStore.Median,
        value: numberFormatterOneDecimal(point.stats.median),
      },
      {
        text: t('loHi'),
        iconSrc: ImageStore.LowestHighest,
        value: `${numberFormatterOneDecimal(point.stats.min)} - ${numberFormatterOneDecimal(point.stats.max)}`,
      },
    ];

    return (
      <div className={classNames({
        [Style.AGPtooltip]: true,
        [Style.inSummaryPage]: inSummaryPage,
      })}
      >
        {map(rows, (row) => (
          <div
            className={classNames({
              [Style.row]: true,
              [Style.inSummaryPage]: inSummaryPage,
            })}
            key={row.text}
          >
            <div className={Style.left}>
              <img className={Style.asset} src={row.iconSrc} alt="icon" />
              <span>{row.text}</span>
            </div>
            <div className={Style.right}>
              <span className={Style.number}>{row.value}</span>
              <span>{meterUnitsText}</span>
            </div>
          </div>
        ))}
        <div className={classNames({
          [Style.row]: true,
          [Style.inSummaryPage]: inSummaryPage,
        })}
        >
          <div className={Style.left}>
            <span className={Style.date}>
              {t('between', { fromTime: fromTime.format(TIME_FORMATS.H_MM_A), toTime: toTime.format(TIME_FORMATS.H_MM_A) })}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

AGPtooltip.propTypes = {
  point: PropTypes.shape({
    stats: PropTypes.shape({
      25: PropTypes.number.isRequired,
      75: PropTypes.number.isRequired,
      10: PropTypes.number.isRequired,
      90: PropTypes.number.isRequired,
      median: PropTypes.number.isRequired,
      min: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  timestamp: PropTypes.number.isRequired,
  meterUnits: PropTypes.string.isRequired,
  inSummaryPage: PropTypes.bool,
};

AGPtooltip.defaultProps = {
  inSummaryPage: false,
};
