import React from 'react';
import PropTypes from 'prop-types';
import deepMerge from 'deepmerge';
import GraphPresenter from '~/bundles/shared/components/graphs/GraphPresenter/GraphPresenter.jsx';
import Style from './BasalBarGraph.scss';

const BasalBarGraph = ({ yAxisMax, configOverride, ...otherProps }) => (
  <div class={Style.BasalBarGraph}>
    <GraphPresenter
      {...otherProps}
      configOverride={deepMerge({
        tooltip: {
          enabled: false,
        },
        yAxis: {
          max: yAxisMax,
          min: 0,
          marginBottom: 0,
        },
      }, configOverride)}
    />
  </div>
);

BasalBarGraph.propTypes = {
  startTimestamp: PropTypes.number.isRequired,
  endTimestamp: PropTypes.number.isRequired,
  series: PropTypes.arrayOf(PropTypes.object).isRequired,
  yAxisMax: PropTypes.number.isRequired,
  windowHeight: PropTypes.number.isRequired,
  windowWidth: PropTypes.number.isRequired,
  configOverride: PropTypes.shape({}).isRequired,
  updateSeries: PropTypes.func.isRequired,
};

BasalBarGraph.defaultProps = {
  yAxisMax: 1,
  configOverride: {},
  updateSeries: () => null,
};

export default BasalBarGraph;
