import { FETCH_STATUS_NOT_CALLED, FETCH_STATUS_SUCCESSFUL } from
  '~/bundles/shared/constants/graphs';
// import { BLANK_STATS_VALUE } from '~/bundles/shared/constants/statistics';

export const UPDATE_COPY_KEY_STATS_PUMP_SETTINGS = 'UPDATE_COPY_KEY_STATS_PUMP_SETTINGS';
export const UPDATE_PUMP_SETTINGS_STATUS = 'UPDATE_PUMP_SETTINGS_STATUS';

export function updatePumpSettings(pumpSettings, params) {
  return { type: UPDATE_COPY_KEY_STATS_PUMP_SETTINGS, pumpSettings, params };
}

export function updatePumpSettingsStatus(status) {
  return { type: UPDATE_PUMP_SETTINGS_STATUS, status };
}

const initialState = {
  status: FETCH_STATUS_NOT_CALLED,
  params: {},
  pumpSettings: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_COPY_KEY_STATS_PUMP_SETTINGS:
      return {
        ...state,
        ...action.pumpSettings,
        status: FETCH_STATUS_SUCCESSFUL,
        params: action.params,
      };
    case UPDATE_PUMP_SETTINGS_STATUS:
      return { ...state, status: action.status };
    default:
      return state;
  }
}
