import { FETCH_STATUS_SUCCESSFUL, FETCH_STATUS_FAILED, FETCH_STATUS_IN_PROGRESS } from '~/bundles/shared/constants/graphs';

// Actions
export const AUDIT_EVENTS_REQUESTED =
  'audit/AUDIT_EVENTS_REQUESTED';
export const AUDIT_EVENTS_RECEIVED =
  'audit/AUDIT_EVENTS_RECEIVED';
export const AUDIT_EVENTS_FAILED =
  'audit/AUDIT_EVENTS_RECEIVED';
export const AUDIT_UPDATE_SEARCHPARAM =
  'audit/AUDIT_UPDATE_SEARCHSTRING';
export const AUDIT_UPDATE_DATERANGEPARAM =
  'audit/AUDIT_UPDATE_DATERANGEPARAM';

// Action creators
export function auditEventsRequested() {
  return { type: AUDIT_EVENTS_REQUESTED };
}

export function auditEventsReceived(payload) {
  return {
    type: AUDIT_EVENTS_RECEIVED,
    paginationMetadata: payload.paginationMetadata,
    auditEvents: payload.auditEvents,
  };
}

export function auditEventsFailed() {
  return { type: AUDIT_EVENTS_FAILED };
}

export function updateSearchParam(searchParam, value) {
  return {
    type: AUDIT_UPDATE_SEARCHPARAM,
    searchParam,
    value,
  };
}
export function updateDateRangeParam(startDate, endDate) {
  return {
    type: AUDIT_UPDATE_DATERANGEPARAM,
    startDate,
    endDate,
  };
}

const initialState = {
  pageSize: 15,
  paginationData: {
    currentPage: -1,
    totalPages: -1,
  },
  auditEvents: [],
  auditPageInfo: {},
  auditEventsFetchStatus: 'unknown',
  searchParams: {
    searchBy: 'changed_user_name',
  },
  startDate: '',
  endDate: '',
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case AUDIT_EVENTS_REQUESTED:
      return {
        ...state,
        auditEventsFetchStatus: FETCH_STATUS_IN_PROGRESS,
        auditEvents: [],
      };
    case AUDIT_EVENTS_RECEIVED: {
      return {
        ...state,
        auditEventsFetchStatus: FETCH_STATUS_SUCCESSFUL,
        auditEvents: action.auditEvents,
        paginationData: action.paginationMetadata,
      };
    }
    case AUDIT_EVENTS_FAILED: {
      return {
        ...state,
        auditEventsFetchStatus: FETCH_STATUS_FAILED,
        auditEvents: [],
        paginationData: undefined,
      };
    }
    case AUDIT_UPDATE_SEARCHPARAM: {
      return {
        ...state,
        searchParams: Object.assign({}, state.searchParams, { [action.searchParam]: action.value }),
      };
    }
    case AUDIT_UPDATE_DATERANGEPARAM: {
      return {
        ...state,
        startDate: action.startDate,
        endDate: action.endDate,
      };
    }
    default:
      return state;
  }
}
