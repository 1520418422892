/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Button } from '@glooko/common-ui';
import UserHelper from 'redux/modules/users/UserHelper';
import { proceedToInsuletUrl } from 'services/insuletOmnipod5';
import Images from '~/utils/ImageStore';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import useSanitizer from '~/hooks/useSanitizer';
import Style from '../PartnerIntegrationsPresenter/PartnerIntegrationsPresenter.scss';
import { GreyOverlay } from '../../../../shared/components/Overlay/Overlay';
import {
  connectToIntegrationWithCredentials,
  connectToIntegrationWithoutCredentials,
} from '../../../../../services/partnerIntegrationsApi';
import {
  POPUP_CONNECT_FAILED,
  POPUP_CONNECT_SUCCEEDED,
  POPUP_CONNECTION_NOT_POSSIBLE,
} from '../PartnerIntegrationsPresenter/PartnerIntegrationsPresenter';

const mapStateToProps = (state) => ({
  userCountry: UserHelper.currentUserCountry(state),
});

@translate('PartnerIntegrationsPresenter')
@connect(mapStateToProps)
export default class PopupConnect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    if (this.props.data.integration === 'ABBOTT_LIBRELINK_CLOUD' &&
      window.getCookie('ABBOTT_LIBRELINK_CLOUD')) {
      this.props.setPopupState(POPUP_CONNECTION_NOT_POSSIBLE);
    }
  }

  render() {
    const { actions, data, setPopupState, closePopup, t, userCountry } = this.props;
    const { fetchPartnerIntegrationsThunk } = actions;
    const sanitizer = useSanitizer();

    const updateValue = (event) => this.setState({ [event.target.name]: event.target.value });

    const formValid = () => {
      if (!data.inputFields) {
        return true;
      }

      const states = data.inputFields
        .map((field) => field.id)
        .map((key) => this.state[key])
        .filter((state) => state);

      return data.inputFields.length === states.length;
    };

    const onConnection = () => {
      if (!formValid()) {
        return;
      }

      if (data.integration === 'ABBOTT_LIBRELINK_CLOUD') {
        window.saveCookie('ABBOTT_LIBRELINK_CLOUD', 'connected', 6 * 31);
      }

      if (data.integration === 'INSULET_OMNIPOD_5_CLOUD') {
        const proceedUrl = proceedToInsuletUrl(userCountry);
        window.open(proceedUrl, '_blank');
        return;
      }

      this.setState({ loading: true });

      if (data.inputFields) {
        const credentials = data.inputFields
          .map((field) => field.id)
          .reduce((acc, cur) => ({ ...acc, [cur]: this.state[cur] }), {});

        connectToIntegrationWithCredentials(data.integration, credentials)
          .then(() => fetchPartnerIntegrationsThunk())
          .then(() => setPopupState(POPUP_CONNECT_SUCCEEDED))
          .catch(() => setPopupState(POPUP_CONNECT_FAILED));

        return;
      }

      const returnUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
      connectToIntegrationWithoutCredentials(data.integration, encodeURI(returnUrl))
        .then((response) => {
          window.location.href = response.data.redirectUrl;
        })
        .catch(() => {
          setPopupState(POPUP_CONNECT_FAILED);
        });
    };

    return (
      <div>
        <GreyOverlay />
        {!this.state.loading && (
          <div className={Style.popup}>
            <div
              className={Style.closeButton}
              role="button"
              tabIndex={0}
              onClick={closePopup}
            >
              <img src={Images.closeButton} alt="x" />
            </div>

            <div className={Style.innerPopup}>
              <div className={Style.header}>
                <div className={classNames(Style.brandImage)}>
                  <img src={data.iconUrl} alt={data.name} />
                </div>
                <span>{data.name}</span>
              </div>

              <div className={Style.content}>
                <p dangerouslySetInnerHTML={{ __html: sanitizer(data.text) }} />
              </div>

              {data.inputFields && (
                <div className={Style.content}>

                  {data.inputFields.map((field) => (
                    <div key={field.id}>
                      <div className={Style.inputLabel}>{field.title}</div>
                      <input
                        name={field.id}
                        className={Style.input}
                        type={field.type === 'PASSWORD' ? 'password' : 'email'}
                        autoComplete="new-password" // Disables autofill
                        aria-autocomplete="none" // Disables autofill
                        onChange={updateValue}
                      />
                    </div>
                  ))}
                </div>
              )}

              <div className={Style.actions}>
                <Button
                  variation='secondary'
                  onClick={closePopup}
                  dataAttributes={{ testid: 'popup-connect-cancel' }}
                >
                  {t('cancel')}
                </Button>

                <Button
                  onClick={onConnection}
                  disabled={!formValid()}
                  dataAttributes={{ testid: 'popup-connect-connect' }}
                >
                  {t('connect')}
                </Button>
              </div>
            </div>
          </div>
        )}
        {this.state.loading && (
          <div className={Style.popup}>
            <div className={Style.innerPopup}>
              <div className={Style.loading}>
                <svg viewBox="0 0 100 100">
                  <circle cx="50" cy="50" r="45" />
                </svg>
                <span>{t('connecting')}...</span>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

PopupConnect.propTypes = {
  actions: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  setPopupState: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  userCountry: PropTypes.string.isRequired,
};

PopupConnect.defaultProps = {
  actions: {},
  data: {},
  setPopupState: /* istanbul ignore next */ () => {},
  closePopup: /* istanbul ignore next */ () => {},
  t: /* istanbul ignore next */ () => {},
  userCountry: '',
};
