import ExecuteApiCall, { ExecuteUnchangedApiCall } from './apiClient';

export function fetchDevicesAndSettings() {
  return ExecuteApiCall('get', 'api/v3/devices_and_settings');
}

export function fetchDevices(params) {
  return ExecuteApiCall('get', 'api/v2/device_settings/devices', params);
}

export function fetchPumpSettings(params) {
  return ExecuteApiCall('get', 'api/v2/device_settings/pump_settings', params);
}

export function fetchMeterTimeOffsets(params) {
  return ExecuteApiCall('get', 'api/v3/devices/time_offsets', params);
}

export function fetchDeviceCategoryTree() {
  return ExecuteUnchangedApiCall('get', 'api/v2/device_category_tree');
}
