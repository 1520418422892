import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import UserHelper from '~/redux/modules/users/UserHelper';
import { timeframeToSingleDayChunks } from '~/utils/syncTimestamps';
import {
  groupedSeries,
} from '~/bundles/graphs/components/OverviewTabContainer/OverviewGraphHours/OverviewGraphHoursContainer/HoursGraphHelpers';
import { multipleDaysMergedConfig } from '~/bundles/graphs/components/OverviewTabContainer/OverviewGraphHours/OverviewGraphHoursContainer/hoursLegendConfig';
import PageBreak from './../../PageBreak/PageBreak';
import WeekViewPdfSingleDay from '../WeekViewPdfSingleDay/WeekViewPdfSingleDay';
import Style from './WeekViewPdfContainer.scss';
import OverviewHoursLegend from '~/bundles/shared/components/graphs/GraphsLegend/OverviewHoursLegend';
import {
  hasControlIqData, hasBasalIqData, hasOp5Data, hasMedtronicData, hasCamapsData,
} from '../../../../../services/hasDeviceData';

export const WEEK_VIEW_PDF_GRAPH_NAME = 'WeekViewGraphContainer';
export const WEEK_VIEW_PDF_GRAPH_WIDTH = 1000;
export const HEIGHT_OVERRIDE_FACTOR = 0.35;

export const pageCountCalculator = (numberOfDay) => {
  const fullWeeks = Math.floor(numberOfDay / 7);
  const numberOfPages = fullWeeks * 3;
  const remainingDays = numberOfDay - (fullWeeks * 7);

  if (remainingDays === 0) return numberOfPages;

  return numberOfPages + (remainingDays < 3 ? 1 : 2);
};

const mapStateToProps = (state) => {
  const startDate = state.page.startDate;
  const endDate = state.page.endDate;

  const displayUser = UserHelper.displayUser(state);
  const meterUnits = UserHelper.displayUser(state).preference.meterUnits;

  let graphs = [];
  Object.keys(state.graphs).forEach((key) => {
    if (!key.startsWith(WEEK_VIEW_PDF_GRAPH_NAME)) return;
    graphs.push(groupedSeries(state.graphs[key], meterUnits));
  });
  graphs = graphs.reverse();

  return {
    statistics: state.statistics.overall,
    displayUser,
    graphs,
    startDate,
    endDate,
    meterUnits: displayUser.preference.meterUnits,
    hasInsulinData: UserHelper.hasPenDevice(state) || UserHelper.hasManualData(state),
    hasPump: UserHelper.hasPump(state),
  };
};

class WeekViewPdfContainer extends React.Component {
  legend = (index, graphs, systemData) => {
    const legendData = [graphs[index]];

    if (index > 0) {
      let prevIndex = index - 1;

      while (((prevIndex + 1) % 7) !== 0) {
        legendData.push(graphs[prevIndex]);
        prevIndex -= 1;
      }
    }

    const mergedConfig = multipleDaysMergedConfig(legendData);

    return <OverviewHoursLegend
      key={`WeekView_legend_${index}`}
      isWeekView
      isConfigured
      legendConfig={mergedConfig}
      {...systemData}
    />;
  }

  setPageBreakAndLegend = (index, graphsCount, systemData, graphs) => {
    // If page is filled up (4 graphs case)
    if ((index + 4) % 7 === 0) {
      // If there are more graph/s needed for a full week
      if ((index + 1) < graphsCount) {
        // Add page break
        return [<PageBreak key={`PageBreak_${index}`} />];
      }

      // Last graph wast added to pdf so we are adding page break and legend
      return [
        <PageBreak key={`PageBreak_${index}`} />,
        this.legend(index, graphs, systemData),
      ];
    }

    // If page is not filled up (last 3 graphs out of a group of 7 case)
    // and we have additional graph/s (week group/s)
    if ((index + 1) % 7 === 0 && (index + 1) < graphsCount) {
      // Add page break, legend and anothe page break
      return [
        <PageBreak key={`PageBreak_${index}_1`} />,
        this.legend(index, graphs, systemData),
        <PageBreak key={`PageBreak_${index}_2`} />,
      ];
    }

    // If last graph was added to the pdf and the page still has place
    if (index + 1 === graphsCount) {
      // Add legend and page break
      const result = [this.legend(index, graphs, systemData)];

      const modulo = (index + 1) % 7;
      // If there are 3 graphs on last page (group of all 7 graphs or group of 3 graphs case)
      if (modulo === 3 || modulo === 0) {
        // Add additional page break before legend
        result.unshift(<PageBreak key={`PageBreak_${index}`} />);
      }

      return result;
    }

    return null;
  };

  render = () => {
    const {
      statistics, hasInsulinData, hasPump, displayUser,
      startDate, endDate, graphs, meterUnits,
    } = this.props;
    const days = timeframeToSingleDayChunks(startDate, endDate);
    const graphsCount = days.length;
    let systemData = {};

    return (
      <div class={Style.WeekViewPdfContainer}>
        {days.reverse().map((timeframe, index) => {
          const graphKey =
            `${WEEK_VIEW_PDF_GRAPH_NAME}_${timeframe.startTimestamp}_${timeframe.endTimestamp}`;

          const stats = statistics[graphKey];

          const controlIqData = hasControlIqData(displayUser, stats);
          const basalIqData = hasBasalIqData(displayUser, stats);
          const omnipod5Data = hasOp5Data(displayUser, stats);
          const medtronicClosedLoopData = hasMedtronicData(displayUser, stats);
          const camapsData = hasCamapsData(stats);

          if (controlIqData) systemData['controlIqData'] = true;
          if (basalIqData) systemData['basalIqData'] = true;
          if (omnipod5Data) systemData['omnipod5Data'] = true;
          if (medtronicClosedLoopData) systemData['medtronicClosedLoopData'] = true;
          if (camapsData) systemData['camapsData'] = true;

          const result = [
            <WeekViewPdfSingleDay
              key={graphKey}
              statistics={stats}
              startTimestamp={timeframe.startTimestamp}
              endTimestamp={timeframe.endTimestamp}
              hasInsulinData={hasInsulinData}
              hasPump={hasPump}
              hasControlIQ={controlIqData}
              hasBasalIqData={basalIqData}
              hasCamapsData={camapsData}
              hasOmnipod5={omnipod5Data}
              hasMedtronicClosedLoopData={medtronicClosedLoopData}
              meterUnits={meterUnits}
            />,
            this.setPageBreakAndLegend(index, graphsCount, systemData, graphs),
          ];

          if (result[1]) {
            result[1].forEach((e) => {
              if (e && e.key.startsWith('WeekView_legend_')) {
                systemData = {};
              }
            });
          }

          return result;
        })}
      </div>
    );
  }
}

WeekViewPdfContainer.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  statistics: PropTypes.object.isRequired,
  displayUser: PropTypes.object.isRequired,
  graphs: PropTypes.array.isRequired,
  hasInsulinData: PropTypes.bool,
  hasPump: PropTypes.bool,
  meterUnits: PropTypes.string,
};

WeekViewPdfContainer.defaultProps = {
  startDate: moment().toISOString(),
  endDate: moment().toISOString(),
  hasInsulinData: false,
  hasPump: false,
  graphs: [],
  meterUnits: 'mgdl',
  statistics: {},
  displayUser: {},
};

export default connect(mapStateToProps, null)(WeekViewPdfContainer);
