import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import Styles from './FilterSectionHeader.scss';

const FilterSectionHeader = (props) => {
  const { t, title, expanded, dataCount, onExpanderClick, checkedFilters } = props;
  const expanderArrowClasses = classNames(
    Styles.expander__arrow,
    {
      [Styles['expander__arrow--down']]: !expanded,
      [Styles['expander__arrow--up']]: expanded,
    },
  );

  const expander = () => {
    const showMoreText = expanded ? t('showFewer') : `${t('showMore')} (${dataCount - (5 + checkedFilters)})`;

    if (dataCount < 6) return null;

    return (
      <div className={Styles.filterHeader}>
        <span
          onClick={onExpanderClick}
          onKeyPress={(e) => e.preventDefault()}
          tabIndex="0"
          role="button"
          className={Styles.header__expander}
          data-testid="headerExpander"
        >
          {showMoreText}
        </span>
        <div
          onClick={onExpanderClick}
          onKeyPress={(e) => e.preventDefault()}
          tabIndex="0"
          role="button"
          className={expanderArrowClasses}
          data-testid="expanderArrow"
        />
      </div>
    );
  };

  return (
    <div className={Styles.header}>
      <span className={Styles.header__title}>{title}</span>
      {expander()}
    </div>
  );
};

FilterSectionHeader.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  dataCount: PropTypes.number.isRequired,
  onExpanderClick: PropTypes.func.isRequired,
  checkedFilters: PropTypes.number.isRequired,
};

export default translate('FilterSectionHeader')(FilterSectionHeader);
