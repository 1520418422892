export const PLANS_FETCH_SUCCESS = 'PLANS_FETCH_SUCCESS';
export const PLANS_HAS_ERRORED = 'PLANS_HAS_ERRORED';
export const PLANS_IS_LOADING = 'PLANS_IS_LOADING';
export const PLANS_STATUS_SAVE_ERRORED = 'PLANS_STATUS_SAVE_ERRORED';
export const PLANS_STATUS_SAVING = 'PLANS_STATUS_SAVING';
export const UPDATE_PLAN_STATUS_STATE = 'UPDATE_PLAN_STATUS_STATE';

export function treatmentPlansHasErrored(bool) {
  return { type: PLANS_HAS_ERRORED, hasErrored: bool };
}

export function treatmentPlansIsLoading(bool) {
  return { type: PLANS_IS_LOADING, isLoading: bool };
}

export function treatmentPlansFetchSuccess(treatmentPlan) {
  return { type: PLANS_FETCH_SUCCESS, treatmentPlan };
}

export function treatmentPlanStatusErrored(bool) {
  return { type: PLANS_HAS_ERRORED, hasErrored: bool };
}

export function treatmentPlanStatusIsSaving(bool) {
  return { type: PLANS_IS_LOADING, isLoading: bool };
}

export function updatePlanStatusState(newState) {
  const { status, displayStatus, displaySubstatus } = newState;
  return {
    type: UPDATE_PLAN_STATUS_STATE,
    status,
    displayStatus,
    displaySubstatus,
  };
}

const initialState = {
  plans: {},
  isLoading: false,
  hasErrored: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case PLANS_FETCH_SUCCESS:
      return Object.assign(
        {}, state, { plans: action.treatmentPlan },
      );
    case PLANS_IS_LOADING:
      return Object.assign(
        {}, state, { isLoading: action.isLoading },
      );
    case PLANS_HAS_ERRORED:
      return Object.assign(
        {}, state, { hasErrored: action.hasErrored },
      );
    case PLANS_STATUS_SAVING:
      return Object.assign(
        {}, state, { isLoading: action.isLoading },
      );
    case PLANS_STATUS_SAVE_ERRORED:
      return Object.assign(
        {}, state, { hasErrored: action.hasErrored },
      );
    case UPDATE_PLAN_STATUS_STATE:
      return Object.assign(
        {}, state,
        {
          plans: {
            ...state.plans,
            status: action.status,
            displayStatus: action.displayStatus,
            displaySubstatus: action.displaySubstatus,
          },
        },
      );
    default:
      return state;
  }
}
