import disallowUndefinedProperties from '~/utils/disallowUndefined';
import { each } from 'lodash';
import deepMerge from 'deepmerge';
import { trackGraphsOverlayScatterBgReadingClicked, trackGraphsOverlaySpaghettiTracingClicked } from '~/services/eventLogging';

const importMainStore = () => require('~/redux/store/MainStore').default;
const importGraphsModule = () => require('~/redux/modules/graphs/graphs');

class Events {
  static clearWeekdaySeriesSelected() {
    const MainStore = importMainStore();
    const GraphsModule = importGraphsModule();

    MainStore.dispatch(GraphsModule.graphsWeekdaySelectStartLoading());
    setTimeout(() => {
      each(this.series, (series, index) => {
        series.update(
          series.options.glookoCustomOptions.state.defaults,
          this.series.length - 1 === index,
        );
      });
      MainStore.dispatch(GraphsModule.graphsWeekdaySelectClear());
    }, 1);
  }

  static setWeekdaySelected() {
    const MainStore = importMainStore();
    const GraphsModule = importGraphsModule();
    const weekday = this.name.replace(/^[a-z]*DowConnected/, '');

    if (this.name.includes('cgmDowConnected')) {
      console.log('cgmDowConnected');
      trackGraphsOverlaySpaghettiTracingClicked();
    }

    MainStore.dispatch(GraphsModule.graphsWeekdaySelectStartLoading());

    setTimeout(() => {
      each(this.chart.series, (s, index) => {
        let updates;
        if (s.name === this.name) {
          updates = s.options.glookoCustomOptions.state.selected;
        } else {
          updates = deepMerge(
            s.options.glookoCustomOptions.state.defaults || {},
            s.options.glookoCustomOptions.state.otherSelected || {},
          );
        }
        s.update(updates, this.chart.series.length - 1 === index);
      });

      MainStore.dispatch(GraphsModule.graphsWeekdaySelect(weekday));
    }, 1);
  }

  static showHistoryItemsTooltip(event) {
    trackGraphsOverlayScatterBgReadingClicked();
    const GraphsModule = importGraphsModule();
    const MainStore = importMainStore();
    MainStore.dispatch(
      GraphsModule.historyTooltipShow({
        pageX: event.pageX + 10,
        pageY: event.pageY + 10,
        timestamp: event.point.timestamp,
        readingId: event.point.id,
      }));
  }
}

export default disallowUndefinedProperties(Events);
