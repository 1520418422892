import { map, sum, compact, each, fromPairs, filter } from 'lodash';
import { WEEKDAYS } from '~/utils/syncTimestamps';
import {
  keyFromParams,
  OVERLAY_GRAPH_TYPES,
  OVERLAY_AGP,
  OVERLAY_SCATTER,
  OVERLAY_BOX_PLOT,
  OVERLAY_SPAGHETTI,
} from '~/redux/modules/graphs/graphs';
import {
  FETCH_STATUS_NOT_CALLED,
  FETCH_STATUS_IN_PROGRESS,
  FETCH_STATUS_SUCCESSFUL,
  FETCH_STATUS_FAILED,
} from '~/bundles/shared/constants/graphs';
import {
  ONE_DAY,
  ONE_WEEK,
  TWO_WEEKS,
  THIRTY_DAYS,
  NINETY_DAYS,
  NINETY_DAYS_BREADCRUMBS,
  CUSTOM_RANGE,
} from '~/bundles/shared/constants/time';
import I18n from '~/utils/I18n/I18n';
import { GRAPH_NAME_EXERCISE, GRAPH_NAME_HOURS_EXERCISE } from '~/bundles/shared/constants/exercise';
import { exerciseHoursSeriesNameProcessed } from 'bundles/shared/constants/pages/overview';
import {
  carbsSeriesNameProcessed,
  exerciseSeriesNameProcessed,
  stepsSeriesNameProcessed,
  insulinPumpSeriesNameProcessed,
  insulinManualSeriesNameProcessed,
} from '../../../bundles/shared/constants/overviewDaysSeries';
import {
  GRAPH_NAME,
} from '~/bundles/graphs/components/OverviewTabContainer/OverviewGraphContainer/OverviewGraphContainer';

const t = I18n.getFixedT(null, 'GraphsHelper');

class GraphsHelper {
  static interpreteFetchStatusAndSeries(response, defaultResponse = [], inPdf = false) {
    let status, series;

    if (response === undefined) {
      series = defaultResponse;
      status = FETCH_STATUS_NOT_CALLED;
    } else if (response === FETCH_STATUS_FAILED) {
      series = defaultResponse;
      status = FETCH_STATUS_FAILED;
    } else if (response === FETCH_STATUS_IN_PROGRESS) {
      series = defaultResponse;
      status = FETCH_STATUS_IN_PROGRESS;
    } else {
      series = response;
      status = FETCH_STATUS_SUCCESSFUL;
    }

    return { series, status: inPdf ? FETCH_STATUS_SUCCESSFUL : status };
  }

  static retrieveFetchStatus(response) {
    return GraphsHelper.interpreteFetchStatusAndSeries(response).status;
  }

  static retrieveFetchStatusAndSeries(state, seriesKey, defaultResponse = []) {
    const response = state.graphs[seriesKey];
    return GraphsHelper.interpreteFetchStatusAndSeries(response, defaultResponse, state.pdf?.inPDF);
  }

  static selectedGraphType(state) {
    const overlayGraphIds = GraphsHelper.availableOverlayGraphTypes(state).map((type) => type.id);
    return state.graphs.overlayGraphTypeOrder.find((typeId) => (overlayGraphIds.indexOf(typeId) > -1));
  }

  static availableOptions(state) {
    const type = GraphsHelper.selectedGraphType(state);

    return {
      median: (type === OVERLAY_BOX_PLOT || type === OVERLAY_AGP),
      hiLo: (type === OVERLAY_BOX_PLOT || type === OVERLAY_AGP),
      connectDays: (type === OVERLAY_SCATTER),
      average: (type === OVERLAY_SCATTER),
    };
  }

  static selectedDaysHash(state, checkClicked = false) {
    if (checkClicked && state.graphs.weekDayClicked) {
      return fromPairs(map(WEEKDAYS, (weekday) => [weekday, weekday === state.graphs.weekDayClicked]));
    }
    return state.graphs.weekDaysSelected;
  }

  static selectedDaysArray(state, checkClicked = false) {
    const selectedDaysHash = GraphsHelper.selectedDaysHash(state, checkClicked);
    return compact(map(selectedDaysHash, (value, key) => (value && key)));
  }

  static selectedDaysSum(state, checkClicked = false) {
    const selectedDaysHash = GraphsHelper.selectedDaysHash(state, checkClicked);

    return sum(map(WEEKDAYS, (value, index) => (
      // eslint-disable-next-line no-bitwise
      (selectedDaysHash[value] && (1 << index)) || 0
    )));
  }

  static scatterAverageSeries(seriesArray) {
    if (!seriesArray.length) {
      return [];
    }

    const counts = Array(24).fill(0);
    const sums = Array(24).fill(0);
    each(
      seriesArray,
      (series) => each(
        series.data,
        (reading) => {
          counts[reading.hour] += 1;
          sums[reading.hour] += reading.y;
        },
      ));
    return [{
      name: 'bgAverage',
      data: compact(map(counts, (_elem, index) => {
        if (counts[index] === 0) {
          return null;
        }
        return {
          x: 60 * 60 * (index + 0.5),
          y: sums[index] / counts[index],
          yOrig: sums[index] / counts[index],
        };
      })),
    }];
  }

  static availableOverlayGraphTypes(state) {
    return filter(
      OVERLAY_GRAPH_TYPES, (option) => (option.isEnabled(state.page.readingsType)),
    );
  }

  static showExerciseHourGraph(state) {
    return GraphsHelper.hasDataInCache(state, GRAPH_NAME_HOURS_EXERCISE, exerciseHoursSeriesNameProcessed);
  }

  static showExerciseGraphOnOverview(state) {
    return GraphsHelper.hasDataInCache(state, GRAPH_NAME_EXERCISE, exerciseSeriesNameProcessed);
  }

  static showStepsGraphOnOverview(state) {
    return GraphsHelper.hasDataInCache(state, GRAPH_NAME_EXERCISE, stepsSeriesNameProcessed);
  }

  static showCarbsGraphOnOverview(state) {
    return GraphsHelper.hasDataInCache(state, GRAPH_NAME, carbsSeriesNameProcessed);
  }

  static showInsulinGraphOnOverview(state) {
    return GraphsHelper.showInsulinPumpGraphOnOverview(state) || GraphsHelper.showInsulinManualGraphOnOverview(state);
  }

  static showInsulinPumpGraphOnOverview(state, start = null, end = null) {
    return GraphsHelper.hasDataInCache(state, GRAPH_NAME, insulinPumpSeriesNameProcessed, start, end);
  }

  static showInsulinManualGraphOnOverview(state, start = null, end = null) {
    return GraphsHelper.hasDataInCache(state, GRAPH_NAME, insulinManualSeriesNameProcessed, start, end);
  }

  static hasNonZeroDataInSeries(series, seriesNames) {
    return series.some((s) => (
      seriesNames.includes(s.name) &&
      s.data.length > 0 &&
      s.data.some((p) => (p.yOrig || p.y) > 0)),
    );
  }

  static hasDataInCache(state, id, seriesNames, start = null, end = null) {
    const startTimestamp = start || state.page.startDate;
    const endTimestamp = end || state.page.endDate;
    const { series } = GraphsHelper.retrieveFetchStatusAndSeries(state, keyFromParams({
      id, startTimestamp, endTimestamp,
    }));

    return GraphsHelper.hasNonZeroDataInSeries(series, seriesNames);
  }

  static retrieveLabel(key) {
    switch (key) {
      case ONE_DAY:
        return t('ONE_DAY');
      case ONE_WEEK:
        return t('ONE_WEEK');
      case TWO_WEEKS:
        return t('TWO_WEEKS');
      case THIRTY_DAYS:
        return t('THIRTY_DAYS');
      case NINETY_DAYS:
        return t('NINETY_DAYS');
      case NINETY_DAYS_BREADCRUMBS:
        return t('NINETY_DAYS_BREADCRUMBS');
      case CUSTOM_RANGE:
        return t('CUSTOM_RANGE');
      case OVERLAY_SPAGHETTI:
        return t('overlaySpaghetti');
      case OVERLAY_AGP:
        return t('overlayAgp');
      case OVERLAY_SCATTER:
        return t('overlayScatter');
      case OVERLAY_BOX_PLOT:
        return t('overlayBoxPlot');
      default:
        // eslint-disable-next-line no-process-env
        if (process.env.DEBUG === 'true') {
          throw new Error('GraphsHelper.retrieveLabel', key);
        }
        return null;
    }
  }
}

export default GraphsHelper;
