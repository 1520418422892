import i18next from '~/utils/I18n/I18n';

const SupportLinks = (() => {
  const BASE_URL = 'https://support.glooko.com';

  function currentLocale() {
    return (i18next.language.toLowerCase() || 'en');
  }

  return {
    helpCenter: () => `${BASE_URL}/hc/${currentLocale()}`,
    article: (articleNumber) => `${BASE_URL}/hc/${currentLocale()}/articles/${articleNumber}`,
    fileTicket: () => `${BASE_URL}/hc/${currentLocale()}/requests/new?ticket_form_id=1500000948042`,
  };
})();

export default SupportLinks;
