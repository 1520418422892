import { FETCH_STATUS_NOT_CALLED, FETCH_STATUS_SUCCESSFUL } from '~/bundles/shared/constants/graphs';

export const UPDATE_DEVICES = 'UPDATE_DEVICES';
export const UPDATE_DEVICES_STATUS = 'UPDATE_DEVICES_STATUS';
export const UPDATE_METER_TIME_OFFSETS = 'UPDATE_METER_TIME_OFF_SETS';
export const UPDATE_METER_TIME_OFFSETS_FAILED = 'UPDATE_METER_TIME_OFF_SETS_FAILED';

export function updateDevices(devices, params) {
  return { type: UPDATE_DEVICES, devices, params };
}

export function updateDevicesStatus(status) {
  return { type: UPDATE_DEVICES_STATUS, status };
}

export function updateDeviceTimeOffsets(deviceTimeOffsets) {
  return { type: UPDATE_METER_TIME_OFFSETS, deviceTimeOffsets };
}

export function updateDeviceTimeOffsetsFailed() {
  return { type: UPDATE_METER_TIME_OFFSETS_FAILED };
}

const initialState = {
  status: FETCH_STATUS_NOT_CALLED,
  params: {},
  // eslint-disable-next-line camelcase
  device_settings: [],
  deviceTimeOffsets: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_DEVICES:
      return {
        ...state,
        ...action.devices,
        status: FETCH_STATUS_SUCCESSFUL,
        params: action.params,
      };
    case UPDATE_DEVICES_STATUS:
      return { ...state, status: action.status };
    case UPDATE_METER_TIME_OFFSETS:
      return { ...state, deviceTimeOffsets: [...action.deviceTimeOffsets] };
    default:
      return state;
  }
}
