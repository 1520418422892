export const SECONDS_IN_DAY = 24 * 60 * 60;
export const SECONDS_IN_TWO_DAYS = SECONDS_IN_DAY * 2;
export const SECONDS_IN_30_DAYS = SECONDS_IN_DAY * (30 / 7);
export const SECONDS_IN_90_DAYS = SECONDS_IN_DAY * (90 / 7);
export const TIME_FORMAT_M_D = 'M/D';

export const CALENDAR_ONE_DAY_WIDTH_WEB = (958 - 176) / 7.0;
export const CALENDAR_ONE_DAY_WIDTH_PDF = (1500 - 175 - 2) / 7;
export const CALENDAR_AXIS_WIDTH_WEB = 175;
export const CALENDAR_AXIS_WIDTH_PDF = 175;
