/* New service to handle all cookie related activities,
cookieExists checks whether the cookie exists or not in the session */
export const cookieExists = (cname, subgroup) => {
  const subgroupCode = {
    'performanceCookies': 'C0002:1',
    'targetingCookies': 'C0004:1',
    'functionalCookies': 'C0003:1',
  };
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  let isCookieEnabled = false;
  ca.forEach((c) => {
    // check if subgroup exists within the cookie object which onetrust provides us
    if (c.trim().startsWith(name) &&
        c.includes('groups') &&
        c.split('groups=')[1].includes(subgroupCode[subgroup])) {
      isCookieEnabled = true;
    }
  });
  return isCookieEnabled;
};
