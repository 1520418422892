import { map, flatMap, each, keys, groupBy, filter, orderBy } from 'lodash';
import moment from 'moment';
import Colors from '~/../assets/styles/export/colors';
import Charts from '~/bundles/shared/components/graphs';
import Readings from '~/services/Readings';
import { calculateAxisMaxFromProps } from
  '~/bundles/graphs/components/OverviewTabContainer/OverviewGraphHours/OverviewGraphHoursContainer/HoursGraphHelpers';
import { WEEKDAYS } from '~/utils/syncTimestamps';
import { TYPE_BG, READINGS_LOW, READINGS_HIGH } from '~/bundles/shared/constants/readings';
import { ONE_DAY } from '~/bundles/shared/constants/time';
import { exerciseHoursSeriesNameProcessed } from 'bundles/shared/constants/pages/overview';

class ApiWrappers {
  static scatterOverall(readings) {
    return [
      {
        name: 'bgNormal',
        data: map(readings, (reading) => {
          let color = null;
          let marker = {};

          switch (reading.bucket) {
            case READINGS_HIGH:
              color = Colors.bgReadingHigh;
              break;
            case READINGS_LOW:
              color = Colors.bgReadingLow;
              break;
            default:
              color = Colors.bgReadingNormal;
          }

          if (reading.type === 'manual') {
            marker = Charts.SeriesStore.bgSeriesManual.marker;
          }

          return {
            ...reading,
            marker: {
              ...marker,
              fillColor: color,
            },
          };
        }),
      },
    ];
  }

  static generateDowScatter(readings, weekday, readingsType, inPDF = false) {
    return {
      name: `${readingsType}DowScatter${weekday}`,
      data: map(readings, (reading) => {
        let color = null;
        let marker = {};

        switch (reading.bucket) {
          case READINGS_HIGH:
            color = Colors.bgReadingHigh;
            break;
          case READINGS_LOW:
            color = Colors.bgReadingLow;
            break;
          default:
            color = Colors.bgReadingNormal;
        }

        if (reading.type === 'manual' && reading.y === reading.yOrig) {
          marker = Charts.SeriesStore.bgSeriesManual.marker;
        } else if (reading.type === 'manual' && reading.y !== reading.yOrig) {
          marker = Charts.SeriesStore[`bgAbove400Manual${inPDF ? 'PdfFixed' : ''}`].marker;
        } else if (reading.type !== 'manual' && reading.y !== reading.yOrig) {
          marker = Charts.SeriesStore[`bgAbove400${inPDF ? 'PdfFixed' : ''}`].marker;
        }

        return {
          ...reading,
          marker: {
            ...marker,
            fillColor: color,
          },
        };
      }),
    };
  }

  static generateDowConnected(readings, weekday, readingsType) {
    return {
      name: `${readingsType}DowConnected${weekday}`,
      data: readings,
    };
  }

  static generateDowAverage(readings, weekday) {
    return {
      name: `bgAverage${weekday}`,
      data: readings,
    };
  }

  static scatterDow(data, type, inPDF = false) {
    return flatMap(WEEKDAYS, (weekday) => {
      const readingSeries = flatMap(data[weekday.toLowerCase()], (readings) => {
        if (type === TYPE_BG) {
          return [
            ApiWrappers.generateDowScatter(readings, weekday, 'bg', inPDF),
            ApiWrappers.generateDowConnected(readings, weekday, 'bg'),
          ];
        }
        return [
          ApiWrappers.generateDowConnected(readings, weekday, 'cgm'),
        ];
      });
      let averages = [];
      if (type === TYPE_BG) {
        averages = [ApiWrappers.generateDowAverage(data[`average${weekday}`], weekday)];
      }
      return [...averages, ...readingSeries];
    });
  }

  static generateAgpBoxPlotTooltipSeries(series, meterUnits) {
    const extraPadding = Readings.displayValue(400, meterUnits);
    const tooltipPos = (y) => Math.min(extraPadding, y);

    return series.median.map((elem, i) => ({
      x: elem[0],
      low: tooltipPos(elem[1]) - extraPadding, // to have tooltip above median line
      high: tooltipPos(elem[1]) + extraPadding, // to have tooltip above median line
      stats: {
        median: elem[1],
        25: series.percentilesTwentyFifthToSeventyFifth[i][1],
        75: series.percentilesTwentyFifthToSeventyFifth[i][2],
        10: series.percentilesTenthToNinetieth[i][1],
        90: series.percentilesTenthToNinetieth[i][2],
        min: series.minimum[i][1],
        max: series.maximum[i][1],
      },
    })).filter((elem) => elem.high > extraPadding);
  }

  static duplicatePointsForColumnRange(seriesArray) {
    const HALF_HOUR_MS = 30 * 60 * 1000;

    return flatMap(seriesArray, (item) => {
      const x = item[0];
      const y = item[1];

      return [
        { x: x - HALF_HOUR_MS, y },
        { x: x + HALF_HOUR_MS, y },
        { x: x + HALF_HOUR_MS, y: null },
      ];
    });
  }

  static agpOverall(agpOverall, meterUnits, type, day = '') {
    const readingsType = type === TYPE_BG ? 'bg' : 'cgm';
    const agpBoxPlotTooltipsSeries = (day === '') ?
      ApiWrappers.generateAgpBoxPlotTooltipSeries(agpOverall, meterUnits) : [];

    const minimumSeries = ApiWrappers.cleanUpPoints(agpOverall.minimum, 1);
    const maximumSeries = ApiWrappers.cleanUpPoints(agpOverall.maximum, 1);

    return ApiWrappers.removeEmptySeries([{
      name: `${readingsType}AgpMedian${day}`,
      data: ApiWrappers.cleanUpPoints(agpOverall.median, 1),
    },
    {
      name: `${readingsType}AgpPercentilesTenthToNinetieth${day}`,
      data: ApiWrappers.cleanUpPoints(agpOverall.percentilesTenthToNinetieth, 1),
    }, {
      name: `${readingsType}AgpPercentilesTwentyFifthToSeventyFifth${day}`,
      data: ApiWrappers.cleanUpPoints(agpOverall.percentilesTwentyFifthToSeventyFifth, 1),
    }, {
      name: 'agpBoxPlotTooltips',
      data: ApiWrappers.cleanUpPoints(agpBoxPlotTooltipsSeries, 'low', -500),
    }, {
      name: `${readingsType}AgpMinimum${day}`,
      data: ((type === TYPE_BG) ?
        ApiWrappers.duplicatePointsForColumnRange(minimumSeries) :
        minimumSeries
      ),
    }, {
      name: `${readingsType}AgpMaximum${day}`,
      data: ((type === TYPE_BG) ?
        ApiWrappers.duplicatePointsForColumnRange(maximumSeries) :
        maximumSeries
      ),
    },
    ]);
  }

  static agpDow(apgDow, meterUnits, type) {
    return flatMap(
      WEEKDAYS,
      (weekday) => (ApiWrappers.agpOverall(apgDow[weekday.toLowerCase()], meterUnits, type, weekday)),
    );
  }

  static calendarAll(responseHash) {
    const adjustedResponseHash = responseHash;
    if (responseHash.pumpAlarm) {
      adjustedResponseHash.pumpAdvisoryAlert = responseHash.pumpAlarm.filter(
        (alarm) => alarm.alarmSeverity === 'advisory',
      );
      adjustedResponseHash.pumpAlarm = responseHash.pumpAlarm.filter(
        (alarm) => alarm.alarmSeverity === 'hazard',
      );
    }
    return map(adjustedResponseHash, (data, name) => (
      { name, data }
    ));
  }

  static weekView(series, meterUnits) {
    const calculatedSeries = ApiWrappers.overviewHours(null, series);

    return { ...calculatedSeries, ...calculateAxisMaxFromProps(calculatedSeries, meterUnits) };
  }

  static overviewColumns(start, step, seriesData, value, cleanUpPoints = false) {
    const sums = Array(value).fill(0);
    each(seriesData, (elem) => {
      const id = Math.floor((elem.x - start) / step);
      sums[id] += elem.y;
    });

    const series = map(sums, (sum, index) => ({
      x: Math.round(start + ((index + 0.5) * step)),
      y: sum === 0 ? null : sum,
    }));

    if (cleanUpPoints) {
      return ApiWrappers.cleanUpPoints(series, 'y');
    }
    return series;
  }

  static overviewAll(params, responseHash) {
    const { value } = params.timeFrameConfig;

    const nonDeliveredFilter = (item) => !item.delivered;

    const additionalProcessingConfig = {
      gkInsulin: {
        retrieveElemValue: (elem) => (elem.y),
        name: 'gkInsulinColumnNoPointWidth',
        filter: nonDeliveredFilter,
      },
      gkInsulinBolus: {
        retrieveElemValue: (elem) => (elem.y),
        name: 'gkInsulinBolusColumnNoPointWidth',
        filter: nonDeliveredFilter,
      },
      gkInsulinBasal: {
        retrieveElemValue: (elem) => (elem.y),
        name: 'gkInsulinBasalColumnNoPointWidth',
        filter: nonDeliveredFilter,
      },
      gkInsulinPremixed: {
        retrieveElemValue: (elem) => (elem.y),
        name: 'gkInsulinPremixedColumnNoPointWidth',
        filter: nonDeliveredFilter,
      },
      gkInsulinOther: {
        retrieveElemValue: (elem) => (elem.y),
        name: 'gkInsulinOtherColumnNoPointWidth',
        filter: nonDeliveredFilter,
      },
      carbAll: {
        retrieveElemValue: (elem) => (elem.yOrig),
        name: 'carbAllColumnNoPointWidth',
      },
    };

    const requireAdditionalProcessing = keys(additionalProcessingConfig);
    const groupedByProcessing = groupBy(
      map(responseHash, (data, name) => ({ name, data })),
      (series) => requireAdditionalProcessing.indexOf(series.name) > -1,
    );

    const startTimestampUnix = moment.utc(params.startTimestamp).unix();
    const endTimestampUnix = moment.utc(params.endTimestamp).unix();
    const step = Math.floor((endTimestampUnix - startTimestampUnix) / value);
    const groupedBySeriesName = {};

    groupedByProcessing.true.forEach((series) => {
      const config = additionalProcessingConfig[series.name];
      const seriesData = config.filter ? series.data.filter(config.filter) : series.data;
      const values = seriesData.map((elem) => ({
        x: elem.x,
        y: config.retrieveElemValue(elem),
      }));

      groupedBySeriesName[config.name] = [
        ...(groupedBySeriesName[config.name] || []),
        ...values,
      ];
    });
    const insulinData = responseHash.dailyInsulinTotals;
    const totalBolusPerDay =
      Object.keys(insulinData)
        .map((x) => ({ x: parseInt(x, 10), y: insulinData[x].bolusUnitsPerDay || null }));
    const totalBasalPerDay =
      Object.keys(insulinData)
        .map((x) => ({ x: parseInt(x, 10), y: insulinData[x].basalUnitsPerDay || null }));
    const totalPremixedPerDay =
      Object.keys(insulinData)
        .map((x) => ({ x: parseInt(x, 10), y: insulinData[x].premixedUnitsPerDay || null }));
    const basalBolusInsulinColumnHash = [
      {
        name: 'insulinBolusColumnNoPointWidth',
        data: totalBolusPerDay,
      },
      {
        name: 'insulinBasalColumnNoPointWidth',
        data: totalBasalPerDay,
      },
      {
        name: 'insulinPremixedColumnNoPointWidth',
        data: totalPremixedPerDay,
      },
    ];
    const processedSeriesWithoutInsulin =
      map(groupedBySeriesName, (data, name) => ({
        name,
        data: ApiWrappers.overviewColumns(startTimestampUnix, step, data, value),

      }));
    const processedSeries = [...processedSeriesWithoutInsulin, ...basalBolusInsulinColumnHash];

    const carbAllColumn =
      processedSeries.find((series) => series.name.startsWith('carbAllColumn'));

    const cgmGraphSeriesName = [
      'cgmLow', 'cgmHigh', 'cgmNormal',
      'cgmCalibrationLow', 'cgmCalibrationNormal', 'cgmCalibrationHigh',
    ];

    const renamed = [
      ...(carbAllColumn ? [{
        name: 'carbAllInline',
        data: carbAllColumn.data.map((elem) => ({ x: elem.x, y: 50, yOrig: elem.y || '-' })),
      }] : []),
      ...(
        groupedByProcessing.false
          .filter((s) => cgmGraphSeriesName.indexOf(s.name) > -1)
          .map((s) => ({ name: `${s.name}5`, data: s.data }))
      ),
    ];

    const inlineXcoordinates =
      ApiWrappers.overviewColumns(startTimestampUnix, step, [], value, false).map((e) => e.x);
    const insulinAllInlinePump = {
      name: 'insulinAllInlinePump',
      data: Object.keys(insulinData).map((x) => ({
        x: parseInt(x, 10),
        y: 50,
        yOrig: insulinData[x].totalPumpInsulinPerDay || '-',
      })),
    };
    const processedInsulinData =
      ApiWrappers.processEmptyTotals(insulinData, totalBolusPerDay, totalBasalPerDay);

    const insulinAllInlineManual = {
      name: 'insulinAllInlineManual',
      data: Object.keys(insulinData).map((x) => ({
        x,
        y: 50,
        yOrig: insulinData[x].totalOtherInsulinPerDay || '-',
      })),
    };

    if (params.inPDF) {
      const gkInsulinBolus = processedSeries.find((series) => series.name.startsWith('gkInsulinBolusColumn'));
      const gkInsulinBasal = processedSeries.find((series) => series.name.startsWith('gkInsulinBasalColumn'));
      const gkInsulinPremixed = processedSeries.find((series) => series.name.startsWith('gkInsulinPremixedColumn'));

      const gkInsulinTotals =
        map(inlineXcoordinates, (_x, index) => (
          ((gkInsulinBolus.data[index] || {}).y || 0) +
          ((gkInsulinBasal.data[index] || {}).y || 0) +
          ((gkInsulinPremixed.data[index] || {}).y || 0)
        ));

      each(processedSeries, ({ name, data }) => {
        const requireCustomTotal =
          name.startsWith('gkInsulinBolusColumn') ||
          name.startsWith('gkInsulinBasalColumn') ||
          name.startsWith('gkInsulinPremixedColumn');

        if (requireCustomTotal) {
          each(inlineXcoordinates, (_x, index) => {
            if (data[index]) {
              /* eslint-disable no-param-reassign */
              data[index].customTotal = gkInsulinTotals[index];
              /* eslint-enable no-param-reassign */
            }
          });
        }
      });
    }

    return ApiWrappers.removeEmptySeries([
      ...(groupedByProcessing.false),
      ...processedSeries,
      ...(renamed),
      ...[insulinAllInlinePump],
      ...[insulinAllInlineManual],
      ...processedInsulinData,
    ]);
  }

  static overviewExercise(params, responseHash) {
    const {
      processedData: stepsData,
      renamedData: renamedSteps,
    } = ApiWrappers.processExerciseData(responseHash.steps, 'steps', params);
    const {
      processedData: exerciseData,
      renamedData: renamedExercise,
    } = ApiWrappers.processExerciseData(responseHash.exercise, 'exercise', params);

    return ApiWrappers.removeEmptySeries([...stepsData, ...exerciseData, ...renamedSteps, ...renamedExercise]);
  }

  static overviewExerciseHours(_params, responseHash) {
    const processedSeries = [{ name: exerciseHoursSeriesNameProcessed[0], data: responseHash.exercise }];
    return ApiWrappers.removeEmptySeries(processedSeries);
  }

  static overviewHours(_params, responseHash) {
    const unprocessed = ApiWrappers.calendarAll(responseHash);
    const renamed = [
      'deliveredBolus', 'extendedBolusStep', 'injectionBolus', 'interruptedBolus', 'automaticBolus',
      'overrideBelowBolus', 'overrideAboveBolus', 'suggestedBolus', 'temporaryBasal', 'scheduledBasal',
      'gkInsulin', 'gkInsulinBolus', 'gkInsulinBasal', 'gkInsulinPremixed', 'gkInsulinOther', 'bgTargets',
      'basalLabels', 'unusedScheduledBasal', 'calculatedBolus', 'carbAll',
    ];
    const processed = renamed.map((name) => ({
      name: `${name}12`,
      data: responseHash[name],
    }));

    return [
      ...unprocessed,
      ...processed,
    ];
  }

  static weekViewWeb(_, data) {
    return data.map((day) => ({
      startTimestamp: day.startTimestamp,
      endTimestamp: day.endTimestamp,
      series: ApiWrappers.overviewHours(null, day.response.series),
    }));
  }

  static removeEmptySeries(series) {
    return filter(series, (s) => s.data.length);
  }

  static cleanUpPoints(series, key, value = 0) {
    return filter(series, (elem) => elem[key] !== value);
  }

  static processEmptyTotals(data, totalBolusPerDay, totalBasalPerDay) {
    const insulinBolusTotal = {
      name: 'bolusColumnNoPointWidth',
      data: totalBolusPerDay,
    };
    const insulinBasalTotal = {
      name: 'basalColumnNoPointWidth',
      data: totalBasalPerDay,
    };
    const gkInsulinTotal = {
      name: 'gkInsulinColumnNoPointWidth',
      data: [],
    };
    const insulinAllInline = {
      name: 'insulinAllInline',
      data: [],
    };
    Object.keys(data).forEach((timestamp) => {
      const dayData = data[timestamp];
      const { bolusUnitsPerDay, basalUnitsPerDay, totalInsulinPerDay } = dayData;
      const gkInsulinPerDay = totalInsulinPerDay - basalUnitsPerDay - bolusUnitsPerDay;
      const timestampInt = parseInt(timestamp, 10);

      gkInsulinTotal.data.push({
        x: timestampInt,
        y: gkInsulinPerDay,
      });
      insulinAllInline.data.push({
        x: timestampInt,
        y: 50,
        yOrig: totalInsulinPerDay,
      });
    });
    return [
      insulinBolusTotal,
      insulinBasalTotal,
      gkInsulinTotal,
      insulinAllInline,
    ];
  }

  static processEmptyStatistics(data) {
    const allEmptyFields = Object.keys(data).reduce((accumulator, currentItem) => ({
      ...accumulator,
      [currentItem]: '-',
    }), {});
    const preservedFields = [
      {
        fields: [
          'averageBg', 'stdDev', 'readingsPerDay', 'seriousLowPercentage', 'seriousHighPercentage', 'lowPercentage',
          'inRangePercentage', 'highPercentage', 'normalPercentageIgnoreMealTag', 'highPercentageIgnoreMealTag',
          'lowPercentageWithoutSeriousLow', 'highPercentageWithoutSeriousHigh', 'max', 'median', 'min',
          'tewentyFifthPercentile', 'tenthPercentile', 'seventyFiftnPercentile', 'ninetiethPercentile', 'minBucket',
          'maxBucket',
        ],
        notEmpty: (all) => (all.averageBg !== 0),
      }, {
        fields: [
          'averageBg', 'stdDev', 'readingsPerDay', 'seriousLowPercentage', 'seriousHighPercentage', 'lowPercentage',
          'inRangePercentage', 'highPercentage', 'lowPercentageWithoutSeriousLow', 'highPercentageWithoutSeriousHigh',
          'normalPercentageIgnoreMealTag', 'highPercentageIgnoreMealTag', 'activeCgmTimePercentage',
          'coefficientOfVariation', 'max', 'median', 'min', 'tewentyFifthPercentile', 'tenthPercentile',
          'seventyFiftnPercentile', 'ninetiethPercentile', 'minBucket', 'maxBucket',
        ],
        notEmpty: (all) => (all.averageBg !== 0),
      }, {
        fields: [
          'totalCarbs', 'carbsPerDay',
        ],
        notEmpty: (all) => (all.totalCarbs !== 0),
      }, {
        fields: [
          'totalInsulinPerDay', 'bolusPercentage', 'basalPercentage', 'premixedPercentage', 'bolusUnitsPerDay',
          'basalUnitsPerDay', 'premixedUnitsPerDay', 'numOfBolusesPerDay', 'averageIndividualBolus',
          'numOfCorrectionBolusesPerDay', 'correctionBolusPercentage', 'activeCgmTimePercentage',
          'coefficientOfVariation', 'totalPumpInsulinPerDay', 'scheduledBasalsSum', 'hasPen', 'hasPump',
          'hasPrimeDeviceData', 'penTotalDosesPerDay',
        ],
        notEmpty: (all) => (all.totalPumpInsulinPerDay !== 0),
      }, {
        fields: [
          'otherBasalUnitsPerDay', 'otherBolusUnitsPerDay', 'otherPremixedUnitsPerDay', 'otherTotalDosesPerDay',
          'otherUnknownUnitsPerDay', 'totalOtherInsulinPerDay', 'otherBolusPercentage', 'otherPremixedPercentage',
          'otherBasalPercentage', 'otherUnknownUnitsPerDay',
        ],
        notEmpty: (all) => (all.totalOtherInsulinPerDay !== 0),
      }, {
        fields: ['stepSources', 'exerciseSources', 'exercises'],
        notEmpty: () => true,
      }, {
        fields: ['averageSteps'],
        notEmpty: (all) => (all.averageSteps !== 0),
      }, {
        fields: ['averageDuration'],
        notEmpty: (all) => (all.averageDuration !== 0),
      }, {
        fields: [
          'controlIqPumpModeManualPercentage', 'controlIqPumpModeAutomaticPercentage',
          'controlIqPumpModeExercisePercentage', 'controlIqPumpModeSleepPercentage',
          'controlIqPumpModeDurationString', 'controlIqPumpModePerModeDurationStrings',
        ],
        notEmpty: () => true,
      }, {
        fields: [
          'op5PumpModeManualPercentage', 'op5PumpModeAutomaticPercentage', 'op5PumpModeLimitedPercentage',
          'op5PumpModeHypoProtectPercentage', 'op5PumpModeDurationString', 'op5PumpModePerModeDurationStrings',
        ],
        notEmpty: () => true,
      }, {
        fields: [
          'genericPumpModeManualPercentage', 'genericPumpModeAutomaticPercentage', 'genericPumpModeDurationString',
          'genericPumpModePerModeDurationStrings',
        ],
        notEmpty: () => true,
      }, {
        fields: [
          'basalIqPumpModeManualPercentage', 'basalIqPumpModeAutomaticPercentage', 'basalIqPumpModeDurationString',
          'basalIqPumpModePerModeDurationStrings',
        ],
        notEmpty: () => true,
      },
      {
        fields: [
          'camapsPumpModeManualPercentage', 'camapsPumpModeAutomaticPercentage', 'camapsPumpModeBoostPercentage',
          'camapsPumpModeEaseOffPercentage', 'camapsPumpModeDurationString', 'camapsPumpModePerModeDurationStrings',
          'camapsPumpModeAttemptingPercentage',
        ],
        notEmpty: () => true,
      },
    ].filter((group) => (group.notEmpty(data)))
      .reduce((accumulator, currentItem) => ([
        ...accumulator,
        ...currentItem.fields,
      ]), [])
      .reduce((accumulator, currentItem) => ({
        ...accumulator,
        [currentItem]: data[currentItem],
      }), {});
    return Object.assign(allEmptyFields, preservedFields);
  }

  static favoriteWrapper(responseHash) {
    return responseHash.map((favorite) => ({
      ...favorite,
      label: favorite.name,
      value: favorite.name,
    }));
  }

  static historyWrapper(data) {
    return this.groupHistory(this.orderHistory(data));
  }

  static orderHistory(data) {
    const date = (item) => moment.utc(
      item.item.timestamp ??
      item.item.pumpTimestamp ??
      item.item.displayTime ??
      item.item.timestampLocal).toISOString();
    return orderBy(data, date, 'desc');
  }

  static groupHistory(data) {
    const date = (item) => moment.utc(
      item.item.timestamp ??
      item.item.pumpTimestamp ??
      item.item.displayTime ??
      item.item.timestampLocal, 'YYYY-MM-DD').startOf('day').toISOString();
    return groupBy(data, date);
  }

  static itemizeMedicalData(readings) {
    return readings.map((r) => ({
      type: r.reading.type,
      item: { ...r.reading },
    }));
  }

  static processExerciseData(exerciseData, series, params) {
    const { value } = params.timeFrameConfig;
    const startTimestampUnix = moment.utc(params.startTimestamp).unix();
    const endTimestampUnix = moment.utc(params.endTimestamp).unix();
    const step = value === ONE_DAY ?
      Math.round((endTimestampUnix - startTimestampUnix) / 24) :
      Math.round((endTimestampUnix - startTimestampUnix) / value);
    const maxElements = value === ONE_DAY ? 24 : value;

    const processedData = [{
      name: `${series}ColumnNoPointWidth`,
      data: ApiWrappers.overviewColumns(startTimestampUnix, step, exerciseData, maxElements),
    }];
    const renamedData = [{
      name: `${series}Inline`,
      data: processedData[0].data.map((item) => ({ x: item.x, y: 50, yOrig: item.y || '-' })),
    }];
    return { processedData, renamedData };
  }
}

export default ApiWrappers;
