export const getPatientTablePageIndex = () => {
  const pageIndex = localStorage.getItem('PatientTablePageIndex');
  if (pageIndex !== undefined && pageIndex) {
    return parseInt(pageIndex, 10);
  }
  return 1;
};

export const setPatientTablePageIndex = (pageIndex) => {
  localStorage.setItem('PatientTablePageIndex', pageIndex);
};

function getItemValue(item) {
  return item === 'ajs_user_id' ?
    localStorage.getItem(item) :
    localStorage.getItem('showPhiCopyWarningPopUp');
}

export const getPhiCopyWarningPreference = () => {
  const userWarningPreference = getItemValue('showPhiCopyWarningPopUp');
  if (userWarningPreference === null) return 1;
  if (userWarningPreference) {
    const map = new Map(JSON.parse(userWarningPreference));
    if (map.get(getItemValue('ajs_user_id')) === undefined) {
      return 1;
    }
  }
  return 0;
};

const setPreferenceWithAjsUserId = (preference, keyValue) => {
  const mapValue = keyValue ? JSON.parse(keyValue) : null;
  const map = new Map(mapValue);
  map.set(getItemValue('ajs_user_id'), preference);
  localStorage.setItem('showPhiCopyWarningPopUp', JSON.stringify([...map]));
};

export const setUserPhiCopyWarningPreference = (preference) => {
  const keyValue = getItemValue('showPhiCopyWarningPopUp');
  setPreferenceWithAjsUserId(preference, keyValue);
};

export const setPhiCopyWarningPreference = (preference) => {
  setUserPhiCopyWarningPreference(preference);
};
