export const useBolusGraph = () => ({
  modifySeries,
  updateStrokeStyleForClippedItems,
  multipleYAxis,
});

export interface BolusSeries {
  name: string;
  type: string;
  data: BolusDataPoint[];
  dataLabels: string[];
}

export interface BolusDataPoint {
  y: number;
  className?: string;
}

const CLIPPED_CLASS = 'highcharts-clipped-bottom-stroke';
const CALCULATED_BOLUSES = ['calculatedBolus', 'calculatedBolus12'];
const AUTOMATIC_BOLUSES = ['automaticBolus', 'automaticBolus12'];
const SERIES_WITH_DISABLED_LABELS = [
  'interruptedBolus12',
  'overrideBelowBolus12',
  'overrideAboveBolus12',
  'suggestedBolus12',
];

const modifySeries = (yAxisMax: number, inPdf: boolean) => (series: BolusSeries[], dataLabelsEnabled?: boolean) => {
  const seriesData = inPdf ? series.filter((set) => !CALCULATED_BOLUSES.includes(set.name)) : series;
  if (yAxisMax <= 2) return seriesData;

  return seriesData.flatMap((set) => {
    if (set.type !== 'column') return [{ ...set, yAxis: 2 }];

    const labelsEnabled = !SERIES_WITH_DISABLED_LABELS.includes(set.name) && dataLabelsEnabled;
    const dataBelowLabels = { ...set.dataLabels, enabled: labelsEnabled, y: -20 };
    const dataBelowThreshold: BolusDataPoint[] = [];
    const dataAboveThreshold: BolusDataPoint[] = [];
    const dataAboveThresholdClipped: BolusDataPoint[] = [];
    const isAutomatic = [AUTOMATIC_BOLUSES, CALCULATED_BOLUSES].flat().includes(set.name);

    set.data?.forEach((dataPoint) => {
      if (dataPoint.y <= 0.25) {
        dataBelowThreshold.push(dataPoint);
        return;
      }

      dataAboveThreshold.push(dataPoint);
      dataAboveThresholdClipped.push(isAutomatic ? { ...dataPoint, className: CLIPPED_CLASS } : dataPoint);
    });

    return [
      { ...set, data: dataBelowThreshold, dataLabels: dataBelowLabels, yAxis: 0 },
      { ...set, data: dataAboveThreshold, yAxis: 0, dataLabels: { enabled: false } },
      { ...set, data: dataAboveThresholdClipped, yAxis: 1 },
    ];
  });
};

const updateStrokeStyleForClippedItems = () => {
  document.querySelectorAll(`.${CLIPPED_CLASS}`).forEach((element) => {
    const htmlElement = element as HTMLElement;
    const width = parseFloat(htmlElement.getAttribute('width') as string);
    const height = parseFloat(htmlElement.getAttribute('height') as string);
    htmlElement.style.strokeDasharray = `${width + height} ${width}`;
  });
};

const multipleYAxis = (yAxisMax: number) => ({
  yAxis: [
    {
      id: 'smallBolusAxis',
      min: 0,
      max: 0.25,
      title: false,
      labels: { enabled: false },
      height: '8%',
      top: '90%',
      gridLineWidth: 0,
    },
    {
      min: 0.25,
      max: yAxisMax,
      startOnTick: false,
      title: false,
      labels: { enabled: false },
      height: '95%',
      gridLineWidth: 0,
    },
    {
      max: yAxisMax,
      title: false,
      labels: { enabled: false },
      gridLineWidth: 0,
    },
  ],
});
