import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tooltip';
import images from '~/utils/ImageStore';
import { getTextDirection } from '~/utils/I18n/I18n';
import Style from './GraphsLegend.scss';

const Group = (props) => (
  <div
    className={classNames({
      [Style.group]: true,
      [Style.visible]: props.visible,
    })}
    dir={getTextDirection()}
  >
    <div
      className={classNames({
        [Style.groupTitle]: true,
        [Style.hidden]: !props.titleVisible,
        [Style.noInfoContent]: !props.infoContent,
      })}
    >
      {props.title}
      {props.infoContent &&
        <img
          className={Style.infoIcon}
          alt="icon"
          src={images.legendHelp}
          data-tooltip-id={`GraphsLegendGroup-${props.title}`}
          data-tooltip-variant='light'
          data-tooltip-place='right'
        />}
    </div>
    {props.infoContent &&
      <Tooltip
        className={Style.tooltip}
        classNameArrow={Style.tooltipArrow}
        id={`GraphsLegendGroup-${props.title}`}
        globalEventOff="click"
      >
        {props.infoContent}
      </Tooltip>}
    {props.children}
  </div>
);

Group.propTypes = {
  visible: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ])),
    PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
  ]).isRequired,
  titleVisible: PropTypes.bool.isRequired,
  title: PropTypes.string,
  infoContent: PropTypes.node,
};
Group.defaultProps = {
  visible: true,
  titleVisible: false,
  title: '',
  infoContent: undefined,
};

export default Group;
