import React from 'react';
import {
  UPLOAD, STATUS, CONSENT, SELECT_DEVICE, UPLOADER_DOWNLOAD, CONTACT, LOADING,
} from '~/redux/modules/omnipod/omnipod';
import FileUploadDialog from '../FileUploadDialog/FileUploadDialog';
import FileUploaderPopupUploading from '../../FileUploaderPopup/FileUploaderPopupUploading';
import UploadStatusDialog from '../UploadStatusDialog/UploadStatusDialog';
import ShareDataInsulet from '../ShareDataInsulet/ShareDataInsulet';
import ConnectedOmnipodDashSelectDialog from '../OmnipodDashSelectDialog/OmnipodDashSelectDialog';
import UploaderDownloadDialog from '../UploaderDownloadDialog/UploaderDownloadDialog';
import ContactSupportDialog from '../ContactSupportDialog/ContactSupportDialog';
import { GreyOverlay } from '~/bundles/shared/components/Overlay/Overlay';

const OmnipodModalRenderer = (props) => {
  const { currentModal, hideOmnipodModal, showFileSelect, statusModalProps, switchModal, isMinor, guardianEmail } = props;

  let modal = null;
  let onOverlayClick = hideOmnipodModal;
  switch (currentModal) {
    case UPLOAD:
      modal = (<FileUploadDialog {...props.fileUploadDialogProps} onClose={hideOmnipodModal} />);
      break;
    case STATUS:
      if (statusModalProps.uploadStatus === LOADING) {
        modal = (<FileUploaderPopupUploading />);
        // The popup should not disappear if the user clicks on the overlay while the upload
        // is ongoing or the user will never know if the upload is successful or not.
        onOverlayClick = () => {};
        break;
      }

      modal = (<UploadStatusDialog {...statusModalProps} onClose={hideOmnipodModal} />);
      break;
    case CONSENT:
      modal = (
        <ShareDataInsulet
          fileUpload={showFileSelect}
          onClose={hideOmnipodModal}
          switchModal={switchModal}
          isMinor={isMinor}
          guardianEmail={guardianEmail}
        />
      );
      break;
    case SELECT_DEVICE:
      modal = (
        <ConnectedOmnipodDashSelectDialog onClose={hideOmnipodModal} switchModal={switchModal} />
      );
      break;
    case UPLOADER_DOWNLOAD:
      modal = (<UploaderDownloadDialog onClose={hideOmnipodModal} switchModal={switchModal} />);
      break;
    case CONTACT:
      modal = <ContactSupportDialog onClose={hideOmnipodModal} />;
      break;
    default:
      throw Error(`${currentModal} is not a valid modal for uploading insulet device`);
  }

  return (
    <div>
      {isMinor && currentModal === CONSENT && modal}
      {!(isMinor && currentModal === CONSENT) &&
      <div>
        <GreyOverlay onClick={onOverlayClick} />
        {modal}
      </div>}
    </div>
  );
};

export default OmnipodModalRenderer;
