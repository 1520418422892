import i18next from '~/utils/I18n/I18n';

const t = i18next.getFixedT(null, 'AlarmKeys');

export default (key, _) => {
  switch (key) {
    case 'dexcom_urgent_low_alert':
      return t('dexcomUrgentLowAlert');
    case 'dexcom_high_glucose_alert':
      return t('dexcomHighGlucoseAlert');
    case 'dexcom_low_glucose_alert':
      return t('dexcomLowGlucoseAlert');
    case 'dexcom_rising_fast_alert':
      return t('dexcomRisingFastAlert');
    case 'dexcom_falling_fast_alert':
      return t('dexcomFallingFastAlert');
    case 'dexcom_sensor_failed':
      return t('dexcomSensorFailed');
    case 'dexcom_sensor_expired':
      return t('dexcomSensorExpired');
    case 'dexcom_signal_loss':
      return t('dexcomSignalLoss');
    case 'dexcom_urgent_low_soon':
      return t('dexcomUrgentLowSoon');
    case 'dexcom_brief_sensor_issue':
      return t('dexcomBriefSensorIssue');
    default:
      return null;
  }
};
