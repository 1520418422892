import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, Spinner } from '@glooko/common-ui';
import { translate } from '../WithTranslate/WithTranslate.jsx';
import Style from './FileUploaderPopup.scss';

const FileUploaderPopupUploading = (props) => {
  const { t } = props;

  return (
    <Dialog open={true} size="medium" dataAttributes={{ testid: 'file-uploader-uploading' }}>
      <DialogContent dataAttributes={{ testid: 'file-uploader-uploading' }}>
        <div className={Style.spinner_container}>
          <Spinner
            size="large"
            label={t('uploadUploadingTitle')}
            labelPlacement="bottom"
            dataAttributes={{ testid: 'file-uploader-uploading' }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

FileUploaderPopupUploading.propTypes = {
  t: PropTypes.func.isRequired,
};

FileUploaderPopupUploading.defaultProps = {
  t: /* istanbul ignore next */ () => {},
};

export default translate('FileUploaderPopup')(FileUploaderPopupUploading);
