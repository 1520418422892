import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { map } from 'lodash';
import ReactHighcharts from 'react-highcharts';
import HighchartsMore from 'highcharts-more';
import deepMerge from 'deepmerge';
import Charts from '~/bundles/shared/components/graphs';
import {
  FETCH_STATUS_SUCCESSFUL,
  FETCH_STATUS_FAILED,
  FETCH_STATUS_NOT_CALLED,
  FETCH_STATUSES,
} from '~/bundles/shared/constants/graphs';
import translate from '~/bundles/shared/components/WithTranslate/WithTranslate';

HighchartsMore(ReactHighcharts.Highcharts);

// https://github.com/highcharts/highcharts/issues/1843#issuecomment-326954313
ReactHighcharts.Highcharts.wrap(
  ReactHighcharts.Highcharts.Series.prototype,
  'markerAttribs',
  function (proceed, point, state) {
    const plotX = point.plotX;
    // Temporary change
    point.plotX = Math.round(plotX);

    const attribs = proceed.call(this, point, state);

    point.plotX = plotX;
    return attribs;
  });

const mapStateToProps = (state, ownProps) => ({
  dataLabelsEnabled:
    ownProps.dataLabelsEnabled === undefined ? state.pdf.inPDF : ownProps.dataLabelsEnabled,
  fixSeriesForPDF:
    ownProps.fixSeriesForPDF === undefined ? state.pdf.inPDF : ownProps.fixSeriesForPDF,
  realStartDate: state.page.startDate,
  realEndDate: state.page.endDate,
});

class GraphPresenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
    this.setLoading = this.setLoading.bind(this);

    // chartRef wont be set correctly in specs, have to mock them
    // https://stackoverflow.com/q/46708599
    this.chartRef = {
      chart: {
        showLoading: /* istanbul ignore next */ () => {},
        hideLoading: /* istanbul ignore next */ () => {},
      },
    };
  }

  componentDidMount() {
    this.handleFetchStatuses();
  }

  shouldComponentUpdate(nextProps) {
    return JSON.stringify(nextProps) !== JSON.stringify(this.props);
  }

  componentDidUpdate() {
    this.handleFetchStatuses();
  }

  setLoading(visibility, text = ' ') {
    const chart = this.chartRef.chart;
    if (visibility) {
      chart.showLoading(text); // blank space since we dont want to display message
    } else {
      chart.hideLoading();
    }
  }

  handleFetchStatuses() {
    const { t } = this.props;

    switch (this.props.fetchStatus) {
      case FETCH_STATUS_FAILED: {
        this.setLoading(true, t('errorMessage'));
        break;
      }
      case FETCH_STATUS_SUCCESSFUL: {
        this.setLoading(false);
        break;
      }
      default:
    }
  }

  generateConfig() {
    let series = map(
      this.props.series,
      (s) => Charts.generateSeries(s, this.props.fixSeriesForPDF, this.props.dataLabelsOverride),
    );

    if (this.props.modifySeries) {
      series = this.props.modifySeries(series, this.props.dataLabelsEnabled);
    }

    const config = {
      reactProps: { realStartDate: this.props.realStartDate, realEndDate: this.props.realEndDate },
      chart: {
        width: this.props.windowWidth,
        height: this.props.windowHeight,
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: this.props.dataLabelsEnabled,
          },
        },
      },
      tooltip: {
        positioner: (
          this.props.tooltipPositioner ?
            (...args) => this.props.tooltipPositioner(...args, this.chartRef.chart) :
            null
        ),
        formatter: this.props.tooltipFormatter,
        column: {
          pointPlacement: 'on',
          borderWidth: 0,
        },
        stickOnContact: true,
      },
      xAxis: {
        min: this.props.startTimestamp,
        max: this.props.endTimestamp,
      },
      series: (series.length > 0) ? series : [{ data: [null] }],
    };

    const mergedConfig = deepMerge.all(
      [Charts.initialHighchartsConfig, config, this.props.configOverride],
    );

    const yAxisArray = Array.isArray(mergedConfig.yAxis);

    mergedConfig.yAxis = yAxisArray ? [...mergedConfig.yAxis, Charts.secondAxis] :
      [mergedConfig.yAxis, Charts.secondAxis];
    return mergedConfig;
  }

  render() {
    const cfg = this.generateConfig();

    return (
      <ReactHighcharts
        config={cfg}
        isPureConfig
        ref={(c) => { this.chartRef = c; }}
      />
    );
  }
}

GraphPresenter.propTypes = {
  startTimestamp: PropTypes.number,
  endTimestamp: PropTypes.number,
  series: PropTypes.arrayOf(PropTypes.object).isRequired,
  windowHeight: PropTypes.number,
  windowWidth: PropTypes.number,
  configOverride: PropTypes.shape({}),
  dataLabelsEnabled: PropTypes.bool,
  fixSeriesForPDF: PropTypes.bool,
  tooltipPositioner: PropTypes.func,
  tooltipFormatter: PropTypes.func,
  modifySeries: PropTypes.func,
  fetchStatus: PropTypes.oneOf(FETCH_STATUSES).isRequired,
  dataLabelsOverride: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

GraphPresenter.defaultProps = {
  windowHeight: 0,
  windowWidth: 0,
  startTimestamp: null,
  endTimestamp: null,
  configOverride: {},
  dataLabelsEnabled: undefined,
  modifySeries: undefined,
  fixSeriesForPDF: undefined,
  tooltipPositioner: null,
  tooltipFormatter: null,
  fetchStatus: FETCH_STATUS_NOT_CALLED,
  dataLabelsOverride: false,
  name: '',
  t: /* istanbul ignore next */ () => {},
};

export default connect(mapStateToProps, null)(translate('GraphPresenter')(GraphPresenter));
