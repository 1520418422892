import moment from 'moment';
import { groupBy, range, sortBy, chunk, map, each } from 'lodash';
import { SECONDS_IN_DAY } from '~/bundles/shared/constants/time';

export function daysGroupedByWeek(startDate, endDate) {
  const startDateUnix = moment.utc(startDate).startOf('day').unix();
  const endDateUnix = moment.utc(endDate).endOf('day').unix();

  return sortBy(
    map(
      groupBy(
        range(startDateUnix, endDateUnix, SECONDS_IN_DAY),
        (dateUnix) => moment.unix(dateUnix).utc().startOf('isoWeek').isoWeek(),
      ),
      (days, week) => ({ week, days }),
    ),
    (item) => item.days[0],
  );
}

export const WEEKDAYS = [
  'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday',
];

export function groupedBy2Weeks(groupedByWeeks) {
  return chunk(groupedByWeeks, 2);
}

export function weekdaysCount(startDate, endDate) {
  const numberOfDays = Math.round(
    (moment.utc(endDate).unix() - moment.utc(startDate).unix()) / SECONDS_IN_DAY,
  );
  const startDateWeekDayIndex = moment.utc(startDate).isoWeekday() - 1;
  const minDays = Math.floor(numberOfDays / 7);
  const weekDays = {};
  each(WEEKDAYS, (day) => {
    weekDays[day] = minDays;
  });
  each(range(startDateWeekDayIndex, startDateWeekDayIndex + (numberOfDays % 7)), (i) => {
    weekDays[WEEKDAYS[(i % 7)]] += 1;
  });
  return weekDays;
}

export function timeframeTo30DaysChunks(startDate, endDate) {
  return range(
    moment.utc(startDate).startOf('day').unix(),
    moment.utc(endDate).startOf('day').unix() + 0.1,
    SECONDS_IN_DAY * 30,
  ).map((timestamp) => ({
    startTimestamp: moment.unix(timestamp).utc().toISOString(),
    endTimestamp: moment.min(
      moment.unix(timestamp).utc().add(29, 'days').endOf('day'),
      moment.utc(endDate),
    ).toISOString(),
  }));
}

export function timeframeToSingleDayChunks(startDate, endDate) {
  return range(
    moment.utc(startDate).startOf('day').unix(),
    moment.utc(endDate).startOf('day').unix() + 0.1,
    SECONDS_IN_DAY,
  ).map((timestamp) => ({
    startTimestamp: moment.unix(timestamp).utc().startOf('day').toISOString(),
    endTimestamp: moment.unix(timestamp).utc().endOf('day').toISOString(),
  }));
}

function lineSpaceInteger(a, b, n) {
  // returns n linearly spaced integers from interval [a, b]
  switch (n) {
    case 0: {
      return [];
    }
    case 1: {
      return [a];
    }
    default: {
      const N = n - 1;
      return map(range(N + 1), (i) => parseInt(((i * b) + ((N - i) * a)) / N, 10));
    }
  }
}

export function getIntervalDates(startDate, numDays, dateFormat) {
  const daysUnix = lineSpaceInteger(
    moment.utc(startDate).startOf('day').unix(),
    moment.utc(startDate).add(numDays - 1, 'days').unix(),
    numDays,
  );
  return map(daysUnix, (dayUnix) => moment.unix(dayUnix).utc().format(dateFormat));
}
