import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ImageStore from 'utils/ImageStore';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import styles from './PatientLastSyncColumn.scss';

const PatientLastSyncColumn = ({
  t,
  lastSyncDate,
  lastDeviceSyncedFrom,
  lastDeviceSyncedName,
}) => {
  const countLastSyncDays = () => {
    const diffDays = moment.utc().diff(
      moment.utc(lastSyncDate),
      'days',
    );
    let diffDaysStr;
    if (lastSyncDate === '') {
      diffDaysStr = t('neverSynced');
    } else if (diffDays === 0) {
      diffDaysStr = t('today');
    } else if (diffDays === 1) {
      diffDaysStr = t('yesterday');
    } else if (diffDays > 1 && diffDays <= 180) {
      diffDaysStr = t('daysAgo', { count: diffDays });
    } else {
      diffDaysStr = t('daysAgo', { count: '180+' });
    }
    return diffDaysStr;
  };

  const renderSyncIcon = () => {
    switch (lastDeviceSyncedFrom) {
      case 'home': {
        return <img src={ImageStore.houseUpload} alt="house-upload-icon" />;
      }
      case 'clinic': {
        return <img src={ImageStore.clinicUpload} alt="clinic-upload-icon" />;
      }
      default: {
        return null;
      }
    }
  };

  return (
    <div className={styles.patientLastSyncColumn}>
      <span className={styles.icon}>
        {renderSyncIcon()}
      </span>
      <div className={styles.syncDetails}>
        <span className={styles.syncTime}>
          {countLastSyncDays()}
        </span>
        <span className={styles.deviceName}>{lastDeviceSyncedName}</span>
      </div>
    </div>
  );
};

PatientLastSyncColumn.propTypes = {
    t: PropTypes.func.isRequired,
    lastSyncDate: PropTypes.string.isRequired,
    lastDeviceSyncedFrom: PropTypes.string,
    lastDeviceSyncedName: PropTypes.string,
  };
export default translate('PatientLastSyncColumn')(PatientLastSyncColumn);
