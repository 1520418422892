import { BLANK_STATS_VALUE } from 'bundles/shared/constants/statistics';
import Style from '../../Tooltip.scss';

const ExerciseTooltipData = ({ label, value }: { label: string, value?: number | string }) => {
  if (value === BLANK_STATS_VALUE || value === undefined) {
    return null;
  }

  return (
    <div className={Style.data}>
      <span>{label}</span>
      <span>{value}</span>
    </div>
  );
};

export default ExerciseTooltipData;
