import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Readings from '~/services/Readings';
import ImageStore from '~/utils/ImageStore';
import {
  TYPE_BG,
  TYPE_CGM,
} from '~/bundles/shared/constants/readings';
import { numberFormatterOneDecimal, TIME_FORMATS } from '~/utils/i18nFormats';
import Style from './GlucoseTooltip.scss';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate';

const GlucoseTooltip = (props) => {
  const { point, meterUnits, t } = props;
  const timeDisplay = moment.utc(point.timestamp).format(TIME_FORMATS.MMMM_DO_YYYY_H_MMA);
  const readingsType = point.series.name.match(/cgm/) ? TYPE_CGM : TYPE_BG;
  const mealTagImage =
    (point.mealTag === 'before') ? ImageStore.BeforeMealApple : ImageStore.AfterMealApple;
  const image = readingsType === TYPE_BG ? mealTagImage : null;
  const displayMealTag = (point.mealTag === 'none' || point.mealTag === undefined) ? 'none' : '';
  const readingsTypeText = (point.type === 'manual') ? ` - ${t('pumpBgEntry')}` : '';

  return (
    <div className={Style.GlucoseTooltip}>
      <div className={Style.row}>
        <span className={Style.number} style={{ color: point.color }}>
          {numberFormatterOneDecimal(Readings.displayWithHiLo(point.options.value, meterUnits, readingsType))}
        </span>
        <span style={{ color: point.color }}> {Readings.displayMeterUnits(meterUnits)}</span>
        <img className={Style.mealAppleImg} style={{ display: displayMealTag }} src={image} alt="icon" />
        <span>{readingsTypeText}</span>
      </div>
      <div className={Style.row}>
        <span className={Style.date}>{timeDisplay}</span>
      </div>
    </div>
  );
};

GlucoseTooltip.propTypes = {
  point: PropTypes.shape({
    options: PropTypes.shape({
      y: PropTypes.number,
      yOrig: PropTypes.number,
      value: PropTypes.number,
    }).isRequired,
    mealTag: PropTypes.oneOf(['before', 'after', 'none']),
    type: PropTypes.oneOf(['manual', 'pump', 'meter', 'egv']),
    timestamp: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    series: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
  meterUnits: PropTypes.string.isRequired,
};

export default translate('GlucoseTooltip')(GlucoseTooltip);
