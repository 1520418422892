export default class ProviderGroupSiteHelper {
  static hasSubscriptionFeature(state, featureName) {
    return state.providerGroupSite.subscriptionModel.features.includes(featureName);
  }

  static getPatientTags(state, patientId) {
    const patient = state.providerGroupSite.providerPatients.find((p) => p.id === patientId);
    return (patient) ? patient.tags : [];
  }
}
