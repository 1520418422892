import ExecuteApiCall, { ExecuteUnchangedApiCall } from './apiClient';

export function saveFavorites(params) {
  return ExecuteApiCall('post', 'api/v2/pdf_report/favorite_settings', {
    favoriteSetting: {
      name: params.name,
      color: params.color,
      numberOfDays: params.numberOfDays,
      reportTypes: params.reportTypes,
    },
  });
}

export function fetchFavorites() {
  return ExecuteUnchangedApiCall('get', 'api/v2/pdf_report/favorite_settings');
}

export function removeFavorite(params) {
  return ExecuteUnchangedApiCall('delete', `api/v2/pdf_report/favorite_settings/${params.id}`);
}

export function updateFavorite(params) {
  return ExecuteApiCall('put', `api/v2/pdf_report/favorite_settings/${params.id}`, {
    favoriteSetting: {
      name: params.name,
      color: params.color,
      numberOfDays: params.numberOfDays,
      reportTypes: params.reportTypes,
    },
  });
}
