import React from 'react';
import PropTypes from 'prop-types';
import Style from './GraphsLegend.scss';

const InfoItem = (props) => (
  <div class={Style.InfoItem}>
    <div>
      <span class={Style.groupTitle}>{props.name}</span>
      <img alt="icon" class={Style.infoImage} src={props.imageSrc} />
    </div>
    <div class={Style.infoDescr}>{props.descr}</div>
  </div>
);

InfoItem.propTypes = {
  name: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  descr: PropTypes.string.isRequired,
};

export default InfoItem;
