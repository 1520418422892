import moment from 'moment';
import { isEmpty } from 'lodash';
import UserHelper from '~/redux/modules/users/UserHelper';
import { PAGE_POPTRACKER, PAGE_INITIAL, PAGE_CALENDAR, PAGE_LOGBOOK }
  from '~/bundles/shared/constants/pages';

function graphSelectionsListener(getState) {
  const previousSelections = UserHelper.getLocalGraphSelections();
  const {
    timeFrameIndex,
    availableTimeFrames,
    startDate,
    endDate,
    readingsType,
    id: pageID,
    calendarTimeFrameReturn,
  } = getState().page;

  const previousSyncTimestamp = previousSelections.lastSyncTimestamp;
  const previousSync = previousSyncTimestamp && moment.utc(previousSyncTimestamp);
  const lastSyncTimestamp = UserHelper.lastSyncTimestamp(getState());
  const currentSync = lastSyncTimestamp && moment.utc(lastSyncTimestamp);

  const restoreCalendar =
    calendarTimeFrameReturn && calendarTimeFrameReturn.enabled;

  // erase selections when the provider exits the patient view
  if (pageID === PAGE_POPTRACKER) {
    UserHelper.removeLocalGraphSelections();
    return;
  }

  // erase selections after the first sync
  if (!isEmpty(previousSelections) && !previousSync && currentSync) {
    UserHelper.removeLocalGraphSelections();
    return;
  }

  // erase selections after each sync
  if (previousSync && currentSync && previousSync < currentSync) {
    UserHelper.removeLocalGraphSelections();
    return;
  }

  // don't save the loading defaults
  if (pageID === PAGE_INITIAL) return;

  // don't save when the user selects the day-detail view
  //   on the calendar page
  if (restoreCalendar) return;

  if (pageID !== PAGE_CALENDAR) {
    const options = {
      timeframe: availableTimeFrames[timeFrameIndex].value,
      startDate,
      endDate,
      lastSyncTimestamp,
    };
    if (pageID !== PAGE_LOGBOOK) {
      Object.assign(options, { readingsType });
    }

    UserHelper.setGraphSelectionsLocally(options);
  }
}

export default graphSelectionsListener;
