export const OVERLAY_DAYS_WIDTH_WEB = 804;
export const OVERLAY_DAYS_WIDTH_PDF = 1398;

export const OVERLAY_AXIS_WIDTH_WEB = 120;
export const OVERLAY_AXIS_WIDTH_PDF = 100;

export const OVERLAY_HEIGHT_WEB = 250;
export const OVERLAY_HEIGHT_PDF = 300;

export const WEB_CONFIG = {
  DAYS_WIDTH: 782,
  AXIS_WIDTH: 176,
  SUMMARY_AGP_DAYS_WIDTH: 557,
  SUMMARY_AGP_AXIS_WIDTH: 20,
  HEIGHT: 250,
};

export const PDF_CONFIG = {
  DAYS_WIDTH: 1328,
  AXIS_WIDTH: 170,
  HEIGHT: 300,
  SUMMARY_AGP_DAYS_WIDTH: 1115,
  SUMMARY_AGP_DAYS_HEIGHT: 400,
};
