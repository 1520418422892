import { connect } from 'react-redux';
import images from '~/utils/ImageStore';
import colors from '~/../assets/styles/export/colors';
import UserHelper from '~/redux/modules/users/UserHelper';
import SystemStats from './SystemStats';
import translate from '../../../../shared/components/WithTranslate/WithTranslate';
import { hasBasalIqData } from '../../../../../services/hasDeviceData';

const mapStateToProps = (state, ownProps) => {
  const data = state.statistics.overall;
  const { t } = ownProps;

  const hasData = hasBasalIqData(UserHelper.displayUser(state), data);

  if (!hasData) {
    return {};
  }

  return {
    systemLabel: t('systemNameBiq'),
    activeTime: data.basalIqPumpModeDurationString,
    data: [
      {
        label: t('automaticBiq'),
        image: images.pumpAutomaticMode,
        value: data.basalIqPumpModeAutomaticPercentage,
        activeTime: data.basalIqPumpModePerModeDurationStrings.automatic,
      },
      {
        label: t('manualCiq'),
        image: images.pumpManualMode,
        value: data.basalIqPumpModeManualPercentage,
        activeTime: data.basalIqPumpModePerModeDurationStrings.manual,
      },
    ],
    chartData: [
      {
        color: colors.systemModeGraphLineAuto,
        value: data.basalIqPumpModeAutomaticPercentage,
      },
      {
        color: colors.systemModeGraphLineManual,
        value: data.basalIqPumpModeManualPercentage,
      },
    ],
  };
};

export default translate('SystemStats')(connect(mapStateToProps)(SystemStats));
