import React from 'react';
import PropTypes from 'prop-types';
import ImageStore from '~/utils/ImageStore';
import Style from './PopUpHeader.scss';

const PopUpHeader = ({ title, hidePopUp, showX }) => (
  <div className={Style.PopUpHeader}>
    <p className={Style.title}>{title}</p>
    {showX &&
    <img src={ImageStore.xClose} className={Style.closeX} onClick={hidePopUp} />}
  </div>
);

PopUpHeader.propTypes = {
  showX: PropTypes.bool,
  hidePopUp: PropTypes.func,
  title: PropTypes.string.isRequired,
};

PopUpHeader.defaultProps = {
  showX: true,
  title: '',
  hidePopUp: () => {},
};

export default PopUpHeader;
