// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","file":"gl_classNames.scss"}]);
// Exports
exports.locals = {
	"germanLocaleClassName": "de_locale"
};
module.exports = exports;
