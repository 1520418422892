import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { numberFormatterThreeDecimals, localizedPercentage } from '~/utils/i18nFormats';
import Readings, { AVAILABLE_METER_UNITS } from '~/services/Readings';
import { translate } from '../../../../../WithTranslate/WithTranslate';
import DeviceAndTime from './DeviceAndTime/DeviceAndTime';
import Style from '../BolusTooltip.scss';

const BolusSeriesItem = ({ t, point, meterUnits }) => {
  const source = point.deviceName || t('pumpName');
  const glucoseDisplay = (inputSource) => {
    switch (inputSource) {
      case 'BG':
        return `${t('glucose')} (${t('bg')})`;
      case 'CGM':
        return `${t('glucose')} (${t('cgm')})`;
      case 'BG_MANUAL':
        return `${t('glucose')} (${t('bg_manual')})`;
      default:
        return t('glucose');
    }
  };

  return (
    <div>
      <div className={Style.row}>
        <DeviceAndTime device={source} timestamp={point.timestamp} />
        {point.bloodGlucoseInput && (
          <div className={classNames({ [Style.hidden]: !point.bloodGlucoseInput })}>
            <div className={Style.itemRow}>
              <span>{glucoseDisplay(point.bloodGlucoseInputSource)}</span>
              <span>{`${numberFormatterThreeDecimals(point.bloodGlucoseInput)} ${Readings.displayMeterUnits(meterUnits)}`}</span>
            </div>
          </div>
        )}
        <div className={classNames({ [Style.hidden]: !point.carbsInput })}>
          <div className={Style.itemRow}>
            <span>{t('carbs')}</span>
            <span>{`${numberFormatterThreeDecimals(point.carbsInput)} ${t('grams')}`}</span>
          </div>
        </div>
        <div className={classNames({ [Style.hidden]: !point.insulinRecommendationForCarbs })}>
          <div className={Style.itemRow}>
            <span>{t('meal')}</span>
            <span>{`${numberFormatterThreeDecimals(point.insulinRecommendationForCarbs)} ${t('insulinUnitsAbbreviation')}`}</span>
          </div>
        </div>
        <div className={classNames({ [Style.hidden]: !point.insulinRecommendationForCorrection })}>
          <div className={Style.itemRow}>
            <span>{t('correction')}</span>
            <span>{`${numberFormatterThreeDecimals(point.insulinRecommendationForCorrection)} ${t('insulinUnitsAbbreviation')}`}</span>
          </div>
        </div>
        <div className={classNames({ [Style.hidden]: !point.insulinOnBoard })}>
          <div className={Style.itemRow}>
            <span>{t('iob')}</span>
            <span>{`(${numberFormatterThreeDecimals(point.insulinOnBoard)} ${t('insulinUnitsAbbreviation')})`}</span>
          </div>
        </div>
        <div className={classNames({ [Style.hidden]: !point.insulinProgrammed || point.insulinProgrammed === point.insulinDelivered })}>
          <div className={Style.itemRow}>
            <span>{t('programmed')}</span>
            <span>{`${numberFormatterThreeDecimals(point.insulinProgrammed)} ${t('insulinUnitsAbbreviation')}`}</span>
          </div>
        </div>
        <div className={classNames({ [Style.hidden]: !point.presetVolume })}>
          <div className={classNames(Style.itemRow, Style.weighted)}>
            <span>{t('preset')}</span>
            <span>{`${numberFormatterThreeDecimals(point.presetVolume)} ${t('insulinUnitsAbbreviation')}`}</span>
          </div>
        </div>
        <div className={classNames({ [Style.hidden]: !point.totalInsulinRecommendation || point.isManual })}>
          <div className={classNames(Style.itemRow, Style.weighted)}>
            <span>{t('suggested')}</span>
            <span>{`${numberFormatterThreeDecimals(point.totalInsulinRecommendation)} ${t('insulinUnitsAbbreviation')}`}</span>
          </div>
        </div>
        <div
          className={
            classNames({
              [Style.line]:
                point.totalInsulinRecommendation ||
                (point.insulinProgrammed && point.insulinProgrammed !== point.insulinDelivered) ||
                point.insulinOnBoard ||
                point.insulinRecommendationForCorrection ||
                point.insulinRecommendationForCarbs ||
                point.carbsInput,
            }, {
              [Style.hidden]: point.type === 'suggested_no_delivery',
            })
          }
        />
        <div
          className={
            classNames({
              [Style.hidden]:
                point.initialDelivery === null ||
                point.initialDeliveryPercentage === 100 ||
                point.initialDeliveryPercentage === 0 ||
                point.initialDeliveryPercentage === undefined,
            })
          }
        >
          <div className={Style.itemRow}>
            <span>{t('immediate')}</span>
            <span>{`(${localizedPercentage(point.initialDeliveryPercentage)}) ${point.initialDelivery} ${t('insulinUnitsAbbreviation')}`}</span>
          </div>
        </div>
        <div className={classNames({ [Style.hidden]: !point.extendedDelivery || point.extendedDeliveryPercentage === 0 })}>
          <div className={Style.itemRow}>
            <span>{point.durationString === '' ? t('extended') : t('overXhours', { duration: point.durationString })}</span>
            <span>{`(${localizedPercentage(point.extendedDeliveryPercentage)}) ${point.extendedDelivery} ${t('insulinUnitsAbbreviation')}`}</span>
          </div>
        </div>
        <div className={classNames({ [Style.hidden]: point.insulinDelivered === null || point.type === 'suggested_no_delivery' })}>
          <div className={classNames(Style.itemRow, Style.weighted)}>
            <span>{t('delivered')}</span>
            <span>{`${numberFormatterThreeDecimals(point.insulinDelivered)} ${t('insulinUnitsAbbreviation')}`}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

BolusSeriesItem.propTypes = {
  point: PropTypes.shape({
    timestamp: PropTypes.string.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
  meterUnits: PropTypes.oneOf(AVAILABLE_METER_UNITS).isRequired,
};
export default translate('BolusTooltip')(BolusSeriesItem);
