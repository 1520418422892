import { connect } from 'react-redux';
import images from '~/utils/ImageStore';
import colors from '~/../assets/styles/export/colors';
import UserHelper from '~/redux/modules/users/UserHelper';
import SystemStats from './SystemStats';
import translate from '../../../../shared/components/WithTranslate/WithTranslate';
import { hasOp5Data } from '../../../../../services/hasDeviceData';

const mapStateToProps = (state, ownProps) => {
  const data = state.statistics.overall;
  const { t } = ownProps;

  const hasData = hasOp5Data(UserHelper.displayUser(state), data);

  if (!hasData) {
    return {};
  }

  return {
    systemLabel: t('systemNameOp5'),
    activeTime: data.op5PumpModeDurationString,
    data: [
      {
        label: t('automaticOp5'),
        image: images.pumpAutomaticMode,
        value: data.op5PumpModeAutomaticPercentage,
        activeTime: data.op5PumpModePerModeDurationStrings.automatic,
        subModes: [
          {
            label: t('activityLimited'),
            image: images.pumpOp5LimitedMode,
            value: data.op5PumpModeLimitedPercentage,
            activeTime: data.op5PumpModePerModeDurationStrings.limited,
          },
          {
            label: t('activityActivity'),
            image: images.pumpOp5HypoProtectMode,
            value: data.op5PumpModeHypoProtectPercentage,
            activeTime: data.op5PumpModePerModeDurationStrings.hypoprotect,
          },
        ],
      },
      {
        label: t('manualOp5'),
        image: images.pumpManualMode,
        value: data.op5PumpModeManualPercentage,
        activeTime: data.op5PumpModePerModeDurationStrings.manual,
      },
    ],
    chartData: [
      {
        color: colors.systemModeGraphLineAuto,
        value: data.op5PumpModeAutomaticPercentage,
      },
      {
        color: colors.systemModeGraphLineManual,
        value: data.op5PumpModeManualPercentage,
      },
    ],
  };
};

export default translate('SystemStats')(connect(mapStateToProps)(SystemStats));
