import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { numberFormatterThreeDecimals } from '~/utils/i18nFormats';
import { translate } from '../../../../../WithTranslate/WithTranslate';
import Style from '../BolusTooltip.scss';
import DeviceAndTime from './DeviceAndTime/DeviceAndTime';

const InsulinSeriesItem = ({ t, point }) => {
  let source;

  if (point.delivered) {
    source = point.deviceName || t('smartPenName');
  } else {
    source = t('manual');
  }

  const value = `${numberFormatterThreeDecimals(point.value)} ${t('insulinUnitsAbbreviation')}`;

  return (
    <div>
      <div className={classNames({
        [Style.row]: true,
        [Style.hidden]: !point.value,
      })}
      >
        <DeviceAndTime device={source} timestamp={point.timestamp} />
        <div className={Style.itemRow}>
          <span>{point.name}</span>
          <span>{value}</span>
        </div>
      </div>
    </div>
  );
};

InsulinSeriesItem.propTypes = {
  point: PropTypes.shape({
    timestamp: PropTypes.string.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default translate('BolusTooltip')(InsulinSeriesItem);
