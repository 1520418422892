import React from 'react';
import PropTypes from 'prop-types';

import Styles from './Link.scss';

// eslint-disable-next-line import/prefer-default-export
const Link = (props) => {
  const { href, text, linkClass } = props;
  let classNames = Styles.link;

  if (linkClass) {
    classNames = `${classNames} ${linkClass}`;
  }

  return <a href={href} className={classNames}>{text}</a>;
};

Link.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  linkClass: PropTypes.string,
};

Link.defaultProps = {
  linkClass: null,
};
export default Link;
