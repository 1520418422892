import React from 'react';

export default function WithSkipEmpty(WrappedComponent, checkWith) {
  return (props) => {
    if (checkWith(props)) {
      return <WrappedComponent {...props} />;
    }
    return null;
  };
}
