import React from 'react';
import i18next from '~/utils/I18n/I18n';

export default function WithTranslate(namespace) {
  const t = i18next.getFixedT(null, namespace);

  return (WrappedComponent) => (props) => <WrappedComponent {...props} t={t} />;
}

export const translate = WithTranslate;
