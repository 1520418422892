import deepMerge from 'deepmerge';
import pdfReduxState from '~/redux/modules/pdfReduxState';

export const UPDATE_USER_INFO_ACTIVATION_DATA = 'UPDATE_USER_INFO_ACTIVATION_DATA';
export const UPDATE_RECALLED_DIALOQ_IN_RANGE = 'UPDATE_RECALLED_DIALOQ_IN_RANGE';

export const initialState = deepMerge(
  {
    inPDF: false,
    quickNote: null,
    onePageActivationData: {},
    recalledDialoqInRange: false,
  },
  pdfReduxState().pdf,
);

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_USER_INFO_ACTIVATION_DATA:
      return { ...state, onePageActivationData: action.patientInfo };
    case UPDATE_RECALLED_DIALOQ_IN_RANGE:
      return { ...state, recalledDialoqInRange: action.recalledDialoqInRange };

    default:
      return state;
  }
}

export function updateOnePageActivationData(patientInfo) {
  return { type: UPDATE_USER_INFO_ACTIVATION_DATA, patientInfo };
}

export function updateRecalledDialoqInRange(recalledDialoqInRange) {
  return { type: UPDATE_RECALLED_DIALOQ_IN_RANGE, recalledDialoqInRange };
}
