import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LinkedText } from '@glooko/common-ui';
import ImageStore from '~/utils/ImageStore';
import colors from '~/../assets/styles/export/colors';
import SupportLinks from '~/utils/support';
import { SUCCESS, FAIL } from '~/redux/modules/omnipod/omnipod';
import Style from './../OmnipodUploadContainer.scss';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate';

class UploadStatusDialog extends Component {
  componentWillUnmount() {
    this.props.resetStateAndReduxAfterUpload();
  }

  render() {
    const { t, fileName, incorrectFormat, uploadStatus } = this.props;

    let title;
    let body;
    let headerTitle = t('uploadOmnipodSystemData');
    let statusIcon = <img class={Style.preloaderIcon} src={ImageStore.preloader} />;
    let statusMessage = fileName;
    let buttonDone = { display: 'none' };
    let buttonViewData = { display: 'none' };

    const isSuccess = uploadStatus === SUCCESS;
    const failBody = (
      <div class={Style.omnipodDescription}>
        <div>{t('pleaseUnplug')}</div>
        <div class={Style.paragraphSpacer} />
        {t('stillHavingTrouble')}&nbsp;
        <a
          class={Style.supportLink}
          href={SupportLinks.helpCenter()}
          rel="noopener noreferrer"
          target="_blank"
        >
          {t('contact')}
        </a>&nbsp;
        {t('glookoSupport')}
      </div>
    );

    const commonBody = (
      <div class={Style.omnipodDescription}>
        {t('dataUploaded')}
        <div class={Style.paragraphSpacer} />
        <div>
          {t('youCanAlsoViewData')}
          <span class={Style.textBold}> {t('mobilePhone')}</span>.
        </div>
        <div class={Style.paragraphSpacer}>
          {t('searchForGlooko')}
          <span class={Style.textBold}> {t('googlePlayStore')} </span>
          {t('orThe')}
          <span class={Style.textBold}> {t('appStore')}</span>.
        </div>
        <LinkedText
          template={t('havingProblems')}
          linkContent={[{ title: t('supprtSmall'), url: SupportLinks.helpCenter() }]}
          openInNewTab
        />
      </div>
    );

    if (incorrectFormat) {
      statusIcon = (
        <div class={Style.xIconWhite}>
          <img class={Style.asset} src={ImageStore.xIconWhite} alt="X" />
        </div>
      );
      statusMessage = t('youCanOnlyUploadAFile');
      body = commonBody;
      buttonDone = { display: 'block' };
    }

    if (isSuccess) {
      statusIcon =
        (<div class={Style.checkmark}>
          <img class={Style.asset} src={ImageStore.statusIndicatorSuccess} alt="statusIndicatorSuccess" />
         </div>);
      statusMessage = fileName;
      body = commonBody;
      title = t('success');
      headerTitle = (<div><span class={Style.textBold}>{title}</span></div>);
      buttonDone = { display: 'block' };
      buttonViewData = {
        display: 'block',
        marginInlineEnd: '15px',
        backgroundColor: colors.actionPrimary,
      };
    }

    if (uploadStatus === FAIL) {
      statusIcon = (
        <div class={Style.xIconWhite}>
          <img class={Style.asset} src={ImageStore.xIconWhite} alt="X" />
        </div>
      );
      headerTitle = (<div><span class={Style.textBold}>{t('uploadUnsuccessful')}</span></div>);
      statusMessage = t('uploadUnsuccessful');
      body = failBody;
      buttonDone = { display: 'block' };
    }

    return (
      <div class={Style.omnipodUploaderStatus}>
        <div class={Style.omnipodUploaderHeader}>
          {headerTitle}
          <img src={ImageStore.xClose} class={Style.btnClose} onClick={this.props.onClose} />
        </div>
        <div class={Style.omnipodUploadedContent}>
          <div class={Style.progressWrapper}>
            <div class={Style.filenameWrapper}>{t('file')} {statusMessage} </div>
          </div>
          {statusIcon}
          <img class={Style.omnipodMobileLogo} src={ImageStore.omnipodMobile} alt="Device Image" />
          {body}
          <div class={Style.omnipodButtonContainer}>
            <button
              style={buttonViewData}
              class={Style.omnipodBlueButton}
              onClick={this.props.onViewData}
              disabled={!isSuccess}
            >
              {t('viewData')}
            </button>
            {isSuccess && <button
              style={buttonDone}
              class={Style.cancelButton}
              onClick={this.props.onClose}
            >
              {t('done')}
                          </button>}
          </div>
        </div>
      </div>
    );
  }
}

UploadStatusDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  incorrectFormat: PropTypes.bool,
  fileName: PropTypes.string,
  uploadStatus: PropTypes.oneOf([SUCCESS, FAIL]),
  resetStateAndReduxAfterUpload: PropTypes.func,
};

UploadStatusDialog.defaultProps = {
  resetStateAndReduxAfterUpload: () => {},
};

export default translate('UploadStatusDialog')(UploadStatusDialog);
