// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".GuardianConsentPending_consentText{width:400px;padding:20px 0}.GuardianConsentPending_actionBar{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end}.GuardianConsentPending_actionButton{min-width:93px;margin:0 0 0 10px;text-align:center}", "",{"version":3,"sources":["GuardianConsentPending.scss"],"names":[],"mappings":"AAAA,oCACE,WAAA,CACA,cAAA,CAGF,kCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,oBAAA,CAAA,iBAAA,CAAA,wBAAA,CAGF,qCACE,cAAA,CACA,iBAAA,CACA,iBAAA","file":"GuardianConsentPending.scss","sourcesContent":[".consentText {\n  width: 400px;\n  padding: 20px 0;\n}\n\n.actionBar {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.actionButton {\n  min-width: 93px;\n  margin: 0 0 0 10px;\n  text-align: center;\n}"]}]);
// Exports
exports.locals = {
	"consentText": "GuardianConsentPending_consentText",
	"actionBar": "GuardianConsentPending_actionBar",
	"actionButton": "GuardianConsentPending_actionButton"
};
module.exports = exports;
