import deepMerge from 'deepmerge';
import colors from '~/../assets/styles/export/colors';

export const bgColumnEmpty = {
  color: colors.backgroundDark,
  dataLabels: { format: '0%' },
};

export const bgColumnEmptyVeryLow = deepMerge(
  bgColumnEmpty, { dataLabels: { color: colors.seriousLow } },
);

export const bgColumnEmptyLow = deepMerge(
  bgColumnEmpty, { dataLabels: { color: colors.bgReadingLow } },
);

export const bgColumnEmptyNormal = deepMerge(
  bgColumnEmpty, { dataLabels: { color: colors.bgReadingNormal } },
);

export const bgColumnEmptyHigh = deepMerge(
  bgColumnEmpty, { dataLabels: { color: colors.bgReadingHigh } },
);

export const bgColumnEmptyVeryHigh = deepMerge(
  bgColumnEmpty, { dataLabels: { color: colors.high } },
);

export const bgColumnVeryLow = {
  color: colors.bgReadingVeryLowTir,
  dataLabels: {
    color: colors.bgReadingVeryLowTir,
    formatter: function () {
      return (this.y > 0 ? `${this.y}%` : null);
    },
  },
};
export const bgColumnVeryLowPdf = { ...bgColumnVeryLow, color: colors.bgReadingVeryLowTir };

export const bgColumnLow = {
  color: colors.bgReadingLowTir,
  dataLabels: {
    color: colors.bgReadingLowTir,
    formatter: function () {
      return (this.y > 0 ? `${this.y}%` : null);
    },
  },
};
export const bgColumnLowPdf = { ...bgColumnLow, color: colors.bgReadingLowTir };
export const bgColumnNormal = {
  color: colors.bgReadingNormalTir,
  dataLabels: {
    color: colors.bgReadingNormalTir,
    formatter: function () { return (this.y > 0 ? `${this.y}%` : null); },
  },
};
export const bgColumnNormalPdf = { ...bgColumnNormal, color: colors.bgReadingNormalTir };
export const bgColumnHigh = {
  color: colors.bgReadingHighTir,
  dataLabels: {
    color: colors.bgReadingHighTir,
    formatter: function () { return (this.y > 0 ? `${this.y}%` : null); },
  },
};
export const bgColumnHighPdf = { ...bgColumnHigh, color: colors.bgReadingHighTir };

export const bgColumnVeryHigh = {
  color: colors.bgReadingVeryHighTir,
  dataLabels: {
    color: colors.bgReadingVeryHighTir,
    formatter: function () { return (this.y > 0 ? `${this.y}%` : null); },
  },
};
export const bgColumnVeryHighPdf = { ...bgColumnVeryHigh, color: colors.bgReadingVeryHighTir };
