import { fetchEndDates } from '~/services/endDatesApi';
import { updateEndTimestampMax } from '~/redux/modules/page/page';
import throwIfStatusCodeNotExpected from '~/utils/throwIfStatusCodeNotExpected';

export function fetchEndDatesThunk() {
  return (dispatch) => (
    fetchEndDates()
      .then(throwIfStatusCodeNotExpected(200))
      .then((response) => response.data)
      .then((data) => {
        dispatch(updateEndTimestampMax(data.endDateDashboard, data.endDateReport));
      })
      .catch((e) => {
        console.log(e);
      })
  );
}
