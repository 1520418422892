import React from 'react';
import PropTypes from 'prop-types';

import Styles from './Checkbox.scss';

// eslint-disable-next-line import/prefer-default-export
const Checkbox = (props) => {
  const { labelText, onChangeHandler, checked, checkboxClass } = props;
  let checkboxClasses = Styles.container;

  if (checkboxClass) {
    checkboxClasses = `${checkboxClasses} ${checkboxClass}`;
  }

  return (
    <label className={checkboxClasses} htmlFor={Styles.checkbox}>
      {labelText}
      <input
        id={Styles.checkbox}
        onChange={onChangeHandler}
        type="checkbox"
        className={Styles.checkbox}
        checked={checked}
      />
      <span className={Styles.checkmark} />
    </label>
  );
};

Checkbox.propTypes = {
  labelText: PropTypes.string.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  checkboxClass: PropTypes.string,
};

Checkbox.defaultProps = {
  checked: false,
  checkboxClass: null,
};
export default Checkbox;
