export const SECONDS_IN_MINUTE = 60;
export const SECONDS_IN_HOUR = 60 * SECONDS_IN_MINUTE;
export const SECONDS_IN_DAY = 24 * SECONDS_IN_HOUR;
export const MILLISECONDS_IN_DAY = SECONDS_IN_DAY * 1000;
export const MILLISECONDS_IN_HOUR = SECONDS_IN_HOUR * 1000;

export const TIME_OF_DAY_BUCKETS = ['Morning', 'Afternoon', 'Evening', 'Night'];
export const BI_HOURLY_BUCKETS = Array(12).fill(0).map((_, i) => (i + 1) * 2);

export const CUSTOM_RANGE = 10000;
export const ONE_DAY = 1;
export const ONE_WEEK = 7;
export const TWO_WEEKS = 14;
export const THIRTY_DAYS = 30;
export const NINETY_DAYS = 90;
export const NINETY_DAYS_BREADCRUMBS = 100001;

export const ALL_TIMEFRAMES = [
  {
    value: ONE_DAY,
    nDisplayDays: 1,
    nColumns: 8,
    statName: '1DAY',
  }, {
    value: ONE_WEEK,
    nDisplayDays: 7,
    nColumns: 7,
    pointWidth: 24,
    percentilesWidth: 110,
    statName: '1W',
  }, {
    value: TWO_WEEKS,
    nDisplayDays: 14,
    nColumns: 7,
    pointWidth: 16,
    percentilesWidth: 55,
    statName: '2W',
  }, {
    value: THIRTY_DAYS,
    nDisplayDays: 5,
    nColumns: 4,
    pointWidth: 8,
    percentilesWidth: 24,
    statName: '1M',
  }, {
    value: NINETY_DAYS,
    nDisplayDays: 13,
    nColumns: 13,
    pointWidth: 4,
    percentilesWidth: 4,
    statName: '90DAYS',
  }, {
    value: CUSTOM_RANGE,
    statName: 'CUSTOM',
  },
];

export const TIMEFRAME_VALUES = new Set(ALL_TIMEFRAMES.map((timeframe) => (timeframe.value)));
export const PDF_TIMEFRAMES = ALL_TIMEFRAMES.filter((timeframe) => (timeframe.value !== ONE_DAY));
export const PDF_TIMEFRAME_VALUES = new Set(PDF_TIMEFRAMES.map((timeframe) => (timeframe.value)));
