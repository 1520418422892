import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { graphsFetchStatisticsDataThunk } from '~/redux/thunks/graphs';
import GraphsHelper from '~/redux/modules/graphs/GraphsHelper';
import PageHelper from '~/redux/modules/page/PageHelper';
import UserHelper from '~/redux/modules/users/UserHelper';
import {
  cleanUp,
  keyFromParams,
} from '~/redux/modules/graphs/graphs';
import { WEEKDAYS } from '~/utils/syncTimestamps';
import Readings from '~/services/Readings';
import {
  FETCH_STATUSES,
  FETCH_STATUS_NOT_CALLED,
  FETCH_STATUS_IN_PROGRESS,
  FETCH_STATUS_FAILED,
  FETCH_STATUS_SUCCESSFUL } from '~/bundles/shared/constants/graphs';
import { NINETY_DAYS } from '~/bundles/shared/constants/time';
import I18n from '~/utils/I18n/I18n';
import { TIME_FORMATS } from '~/utils/i18nFormats';
import OverviewTooltipPresenter from '../OverviewTooltipPresenter/OverviewTooltipPresenter';

export const COMPONENT_ID = 'OverviewTooltipContainer';

const mapStateToProps = (state, ownProps) => {
  const tooltipKey = keyFromParams({
    startTimestamp: ownProps.startTimestamp,
    endTimestamp: ownProps.endTimestamp,
    id: COMPONENT_ID,
  });
  const { status, series } = GraphsHelper.retrieveFetchStatusAndSeries(state, tooltipKey, {});
  const isNinetyDays = PageHelper.currentTimeFrameLength(state) === NINETY_DAYS;
  const hasCarbs = GraphsHelper.showCarbsGraphOnOverview(state);
  const hasExercise = GraphsHelper.showExerciseGraphOnOverview(state);
  const hasSteps = GraphsHelper.showStepsGraphOnOverview(state);
  const hasInsulinPump = GraphsHelper.showInsulinPumpGraphOnOverview(state);
  const hasInsulinManual = GraphsHelper.showInsulinManualGraphOnOverview(state);

  const { beforeMealNormalGlucoseMax, afterMealNormalGlucoseMax, normalGlucoseMin, meterUnits } =
    UserHelper.displayUser(state).preference;

  return {
    overall: series,
    fetchStatus: status,
    readingsType: state.page.readingsType,
    insulinOnly: !UserHelper.hasPump(state),
    meterUnits: UserHelper.displayUser(state).preference.meterUnits,
    isCheckedPercentiles: state.graphs.percentiles,
    beforeMealMax: Readings.displayValue(
      Readings.denormalizedValue(beforeMealNormalGlucoseMax),
      meterUnits,
    ),
    afterMealMax: Readings.displayValue(
      Readings.denormalizedValue(afterMealNormalGlucoseMax),
      meterUnits,
    ),
    normalMin: Readings.displayValue(
      Readings.denormalizedValue(normalGlucoseMin),
      meterUnits,
    ),
    isNinetyDays,
    hasCarbs,
    hasExercise,
    hasSteps,
    hasInsulinPump,
    hasInsulinManual,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    graphsFetchStatisticsDataThunk,
    cleanUp,
  }, dispatch),
});

export class OverviewTooltipContainer extends React.Component {
  componentDidMount() {
    if (this.props.fetchStatus === FETCH_STATUS_NOT_CALLED) {
      this.fetchData(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const currentKey = keyFromParams({
      startTimestamp: this.props.startTimestamp,
      endTimestamp: this.props.endTimestamp,
      id: COMPONENT_ID,
    });

    const newKey = keyFromParams({
      startTimestamp: nextProps.startTimestamp,
      endTimestamp: nextProps.endTimestamp,
      id: COMPONENT_ID,
    });

    if (currentKey !== newKey) {
      this.cleanup(this.props);
      this.fetchData(nextProps);
    }
  }

  componentWillUnmount() {
    this.cleanup(this.props);
  }

  cleanup(props) {
    props.actions.cleanUp({
      startTimestamp: props.startTimestamp,
      endTimestamp: props.endTimestamp,
      id: COMPONENT_ID,
    });
  }

  fetchData(props) {
    props.actions.graphsFetchStatisticsDataThunk({
      startTimestamp: props.startTimestamp,
      endTimestamp: props.endTimestamp,
      readingsType: props.readingsType,
      normalized: false,
      includeInsulin: true,
      includeExercise: true,
      excludeManual: false,
      dow: WEEKDAYS,
      id: COMPONENT_ID,
    });
  }

  generateDateTitle() {
    const { oneDay } = this.props;
    if (oneDay) {
      return moment.utc(this.props.startTimestamp).format(TIME_FORMATS.DDDD_MMM_DO_YYYY_DASH);
    }
    const startDate = moment.utc(this.props.startTimestamp);
    const endDate = moment.utc(this.props.endTimestamp);
    const dateFormatStart = this.getStartDateTimeFormat(startDate, endDate);
    const dateFormatEnd = this.getEndDateTimeFormat(startDate, endDate);
    return `${startDate.format(dateFormatStart)} - ${endDate.format(dateFormatEnd)}`;
  }

  getStartDateTimeFormat(startDate, endDate) {
    if (startDate.isSame(endDate, 'year')) {
      if (startDate.isSame(endDate, 'months')) {
        return TIME_FORMATS.RANGE_SAME_MONTH_START;
      } else {
        return TIME_FORMATS.RANGE_CROSS_MONTH_START;
      }
    } else {
      return TIME_FORMATS.MMM_DO_YYYY;
    }
  }

  getEndDateTimeFormat(startDate, endDate) {
    if (endDate.isSame(startDate, 'year')) {
      if (endDate.isSame(startDate, 'months')) {
        return TIME_FORMATS.RANGE_SAME_MONTH_END;
      } else {
        return TIME_FORMATS.RANGE_CROSS_MONTH_END;
      }
    } else {
      return TIME_FORMATS.MMM_DO_YYYY;
    }
  }

  generateLoadingMessage() {
    const t = I18n.getFixedT(null, 'OverviewTooltip');

    switch (this.props.fetchStatus) {
      case FETCH_STATUS_NOT_CALLED:
      case FETCH_STATUS_IN_PROGRESS:
        return t('retrieving');
      case FETCH_STATUS_FAILED:
        return t('errorRetrieving');
      case FETCH_STATUS_SUCCESSFUL:
      default:
        return null;
    }
  }

  render() {
    const loadingMessage = this.generateLoadingMessage();
    return (
      <OverviewTooltipPresenter
        {...this.props}
        date={this.generateDateTitle()}
        loadingMessage={loadingMessage}
        showMessageRow={!!loadingMessage}
        isPercentilesOn={this.props.isCheckedPercentiles}
      />
    );
  }
}

OverviewTooltipContainer.propTypes = {
  startTimestamp: PropTypes.string.isRequired,
  endTimestamp: PropTypes.string.isRequired,
  meterUnits: PropTypes.string.isRequired,
  oneDay: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    graphsFetchStatisticsDataThunk: PropTypes.func.isRequired,
    cleanUp: PropTypes.func.isRequired,
  }),
  fetchStatus: PropTypes.oneOf(FETCH_STATUSES).isRequired,
  overall: PropTypes.shape({}).isRequired,
  hasInsulinManual: PropTypes.bool.isRequired,
  hasInsulinPump: PropTypes.bool.isRequired,
  hasCarbs: PropTypes.bool.isRequired,
  hasExercise: PropTypes.bool.isRequired,
  hasSteps: PropTypes.bool.isRequired,
  isCheckedPercentiles: PropTypes.bool.isRequired,
};

OverviewTooltipContainer.defaultProps = {
  fetchStatus: FETCH_STATUS_NOT_CALLED,
  meterUnits: Readings.MGDL,
  actions: {
    graphsFetchStatisticsDataThunk: () => {},
    cleanUp: () => {},
  },
  overall: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(OverviewTooltipContainer);
