const detectBrowser = {
  getUserAgent: () => navigator.userAgent,
  isIE: () => {
    // this is just for IE11
    const userAgent = detectBrowser.getUserAgent();
    return /Trident/.test(userAgent) && /rv:11\.0/.test(userAgent);
  },
};

export default detectBrowser;
