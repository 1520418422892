import React from 'react';
import PropTypes from 'prop-types';
import { Tile } from '@glooko/common-ui';
import classnames from 'classnames';
import Style from './TileList.scss';

const TileList = (props) => {
  const { tiles } = props;

  return (
    <div className={Style.tileList}>
      {tiles.map((tile) => (
        <Tile
          key={tile.id}
          id={tile.id}
          className={classnames(tile.className, Style.tileListItem)}
          onClick={tile.onClick}
          dataAttributes={{ testid: tile.id }}
        >
          {tile.content}
        </Tile>
      ))}
    </div>
  );
};

export default TileList;

TileList.propTypes = {
  tiles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    content: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.node),
    ]).isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func,
  })).isRequired,
};
