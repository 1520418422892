import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SingleColumnChart from '~/bundles/shared/components/graphs/SingleColumnChart/SingleColumnChart.jsx';
import Style from './SystemModeChart.scss';
import { translate } from '../../WithTranslate/WithTranslate';

const SystemModeChart = (props) => {
  const { data, chartData, styleOverride, isRtl, t } = props;

  const displayString = (string) => ((string === '') ? '' : `(${string})`);

  const modeItem = (mode, level = 0) => (
    <div className={classNames({ [Style.subMode]: level })} key={mode.label}>
      <div className={classNames(Style.mode)}>
        {mode.image && (
          <div className={Style.image}>
            <img alt={mode.label} src={mode.image} />
          </div>
        )}
        <div className={Style.label}>
          {mode.label}
        </div>
        <div className={Style.line} />
        <div className={Style.percentage}>
          {t('percentageValue', { value: mode.value })}{' '}
          <span className={Style.durationString}>
          {displayString(mode.activeTime)}
          </span>
        </div>
      </div>

      {mode.subModes && mode.subModes.map((subMode) => modeItem(subMode, level + 1))}
    </div>
  );

  const configOverride = {
    chart: {
      type: 'bar',
      height: 30,
      width: styleOverride ? parseInt(styleOverride.width, 10) : 292,
      style: {
        direction: 'ltr', // we will override by reversing data if rtl
      },
    },
    plotOptions: {
      series: {
        pointWidth: 30,
      },
    },
  };

  return (
    <div>
      <div className={Style.chartWrapper}>
        <SingleColumnChart
          chartName="systemModeChart"
          data={isRtl ? chartData.reverse() : chartData}
          configOverride={configOverride}
        />
      </div>
      {data.map((mode) => modeItem(mode))}
    </div>
  );
};

SystemModeChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    image: PropTypes.string,
    value: PropTypes.number.isRequired,
    activeTime: PropTypes.string.isRequired,
    subModes: PropTypes.any,
  })).isRequired,
  chartData: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string,
    value: PropTypes.number,
  })).isRequired,
  styleOverride: PropTypes.object,
  isRtl: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

SystemModeChart.defaultProps = {
  data: [],
  chartData: [],
  styleOverride: null,
  isRtl: false,
  t: () => {},
};

export default translate('SystemModeChart')(SystemModeChart);
