import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '@glooko/common-ui';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import Style from './FindPatientFooterPresenter.scss';

const Footer = (props) => {
  const { createFormNewPatient, emptyStateAndCloseModal, handleContinueButton,
    hasPatients, isContinueActive, goBack, t, patientView, isUpdate } = props;
  const newPatient = createFormNewPatient;
  let buttonLabel = t('continue');
  if (patientView) {
    buttonLabel = t('create');
  }
  if (isUpdate) {
    buttonLabel = t('update');
  }
  return (
    <div class={Style.footer}>
      <div
        class={classNames({
          [Style.btnContainer]: true,
        })}
      >
        <div
          role="presentation"
          class={Style.btnBack}
          hidden={(!hasPatients && !newPatient) || patientView}
          onClick={goBack}
        >{t('back')}
        </div>
        <Button
          class={classNames({
            [Style.Button]: true,
            [Style.primary]: true,
            [Style.buttonMinWidth]: true,
            [Style.primaryColor]: true,
          })}
          disabled={!isContinueActive}
          onClick={handleContinueButton}
          dataAttributes={{ testid: 'find-patient-footer-continue' }}
        >
          {buttonLabel}
        </Button>
        <Button
          class={classNames({
            [Style.Button]: true,
            [Style.primary]: true,
            [Style.buttonMinWidth]: true,
          })}
          onClick={emptyStateAndCloseModal}
          dataAttributes={{ testid: 'find-patient-footer-cancel' }}
        >
          {t('cancel')}
        </Button>
      </div>
    </div>
  );
};

Footer.propTypes = {
  createFormNewPatient: PropTypes.bool.isRequired,
  emptyStateAndCloseModal: PropTypes.func.isRequired,
  handleContinueButton: PropTypes.func.isRequired,
  hasPatients: PropTypes.bool.isRequired,
  isContinueActive: PropTypes.bool.isRequired,
  goBack: PropTypes.func.isRequired,
  fixOverflow: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  patientView: PropTypes.bool,
  isUpdate: PropTypes.bool,
};

Footer.defaultProps = {
  isContinueActive: false,
  patientView: false,
  isUpdate: false,
};

export default translate('FindPatientModal')(Footer);
