import React from 'react';
import PropTypes from 'prop-types';
import ImageStore from '~/utils/ImageStore';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import classNames from 'classnames';
import Style from './FindPatientHeaderPresenter.scss';

const Header = (props) => {
  const {
    createNewPatientModalVisible,
    updatePatientDataView,
    createFormNewPatient,
    hasPatients,
    emptyStateAndCloseModal,
    t,
    patientView,
    error,
  } = props;

  function getTitle() {
    if (createNewPatientModalVisible) return t('createTitle');
    if (updatePatientDataView) return t('updatePatientAccount');
    return t('findTitle');
  }

  function getDescription() {
    if (updatePatientDataView) return t('updatePatientAccountDescription');
    if (hasPatients) return t('headerDescription');
    return '';
  }

  const newPatient = createFormNewPatient;
  return (
    <div class={Style.header}>
      <div class={Style.headerTitle}>
        <div class={Style.findTitle}>{getTitle()}</div>
        <div
          onClick={emptyStateAndCloseModal}
          role="presentation"
          class={Style.btnClose}
        >
          <img src={ImageStore.dobModalClose} alt="X" />
        </div>
      </div>
      {error && <div class={Style.requestFail}>{error}</div>}
      <div class={classNames({
        [Style.headerDescription]: true,
        [Style.isHidden]: !createNewPatientModalVisible,
      })}
      >
          <div class={Style.labelValue} hidden={!newPatient || patientView}>
            {newPatient ? t('createDescription') : ''}
          </div>
      </div>
      <div class={classNames({
        [Style.headerDescription]: true,
        [Style.isHidden]: createNewPatientModalVisible,
      })}
      >{getDescription()}
      </div>
      <div class={Style.indicatesRequiredField}>
        {t('indicatesRequiredField')}
      </div>
    </div>
  );
};

Header.propTypes = {
  createNewPatientModalVisible: PropTypes.bool.isRequired,
  updatePatientDataView: PropTypes.bool.isRequired,
  createFormNewPatient: PropTypes.bool.isRequired,
  emptyStateAndCloseModal: PropTypes.func.isRequired,
  hasPatients: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  patientView: PropTypes.bool,
  error: PropTypes.string.isRequired,
};

Header.defaultProps = {
  patientView: false,
};

export default translate('FindPatientModal')(Header);
