import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Style from './Overlay.scss';

const Overlay = ({ onClick, extraStyleClass }) => (<div className={classNames(Style.Overlay, extraStyleClass)} onClick={onClick} />);
const GreyOverlay = (props) => (<Overlay extraStyleClass={Style.GreyOverlay} {...props} />);
const WhiteOverlay = (props) => (<Overlay extraStyleClass={Style.WhiteOverlay} {...props} />);

Overlay.propTypes = {
  onClick: PropTypes.func,
  extraStyleClass: PropTypes.string,
};

Overlay.defaultProps = {
  onClick: () => {},
  extraStyleClass: null,
};

export {
  GreyOverlay,
  WhiteOverlay,
};
