import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogHeader, Tile } from '@glooko/common-ui';
import PropTypes from 'prop-types';
import moment from 'moment';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import style from './SimilarPatientList.scss';

export class SimilarPatientList extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    selectedPatient: PropTypes.string.isRequired,
    renderPatientSummary: PropTypes.func.isRequired,
    cancelSimilarModal: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedPatient: props.selectedPatient,
    };
  }

  selectPatient(patientId) {
    this.setState({ selectedPatient: patientId });
  }

  patientHtml(patient, index, t) {
    return (
      <div key={index} className={style.clinic_modal_patient_row}>
        <input
          name="select_patient"
          type="radio"
          id={patient.id}
          value={patient.id}
          checked={patient.id === this.state.selectedPatient}
          onChange={() => this.selectPatient(patient.id)}
        />
        <div className={style.name}>
          {patient.fullName}<br /> {t('dob')} {moment(patient.dateOfBirth).format('L')}
        </div>
        { patient.lastSyncedDeviceTimestamp !== null ?
          <div className={style.last_sync}>
            <div>{t('lastSync')}</div>
            <div>{moment.utc(patient.lastSyncedDeviceTimestamp).format('ll')}</div>
          </div> :
          null}
      </div>
    );
  }

  clinicModalPatientList() {
    const html = [];
    const { data, t } = this.props;
    for (let i = 0; i < data.length; i += 1) {
      html.push(this.patientHtml(data[i], i, t));
    }
    return (<div> {html} </div>);
  }

  render() {
    const { selectedPatient } = this.state;
    const { t, cancelSimilarModal, renderPatientSummary } = this.props;
    return (
          <Dialog size="small" open={true} onClose={() => cancelSimilarModal()} dataAttributes={{ testid: 'similar-patient-list' }}>
          <DialogHeader dataAttributes={{ testid: 'similar-patient-list' }}><h2>{t('selectPatient')}</h2></DialogHeader>
          <DialogContent dataAttributes={{ testid: 'similar-patient-list' }}>
          <div>
            <p> {t('patientDescription')} </p>
          </div>
          <div className={style.body}>
            <Tile className={style.tile} id="similarPatient" dataAttributes={{ testid: 'similarPatient' }}>
              {this.clinicModalPatientList()}
            </Tile>
          </div>
          </DialogContent>
          <DialogActions dataAttributes={{ testid: 'similar-patient-list' }} className={style.bottomActions}>
              <Button
                className={style.button}
                onClick={() => { renderPatientSummary(selectedPatient); }}
                dataAttributes={{ testid: 'similar-patient-list-view-data' }}
              >
                {t('viewData')}
              </Button>
              <Button
                className={style.button}
                variation='secondary'
                onClick={() => cancelSimilarModal()}
                dataAttributes={{ testid: 'similar-patient-list-cancel' }}
              >
                {t('cancel')}
              </Button>
          </DialogActions>
          </Dialog>
    );
  }
}

export default translate('SimilarPatientList')(SimilarPatientList);
