class MathUtils {
  static cantorPair(x, y) { return (((x + y) * (x + y + 1)) / 2) + y; }

  static ceilTo2HighestDigits(number) {
    if (number < 100) {
      return number;
    }
    const power = Math.floor(Math.log10(number)) - 1;
    return Math.ceil(number / (10 ** power)) * (10 ** power);
  }

  static floorFloatToPrecision(number, precision) {
    return Math.floor(number * (10 ** precision)) / (10 ** precision);
  }

  static numberWithCommas(number) {
    const parts = number.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }
}

export default MathUtils;
