import React from 'react';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { keyFromParams, cleanUp } from '~/redux/modules/graphs/graphs';
import {
  graphsFetchOverviewDayDataThunk,
  graphsFetchExerciseHoursOverviewThunk,
  graphsUpdateSeriesThunk,
  graphsUpdateSeriesFromCacheThunk,
  graphsUpdateOverviewHoursLegendConfigThunk,
} from '~/redux/thunks/graphs';
import UserHelper from '~/redux/modules/users/UserHelper';
import GraphsHelper from '~/redux/modules/graphs/GraphsHelper';
import { TYPE_CGM } from '~/bundles/shared/constants/readings';
import {
  FETCH_STATUS_NOT_CALLED,
  FETCH_STATUS_SUCCESSFUL,
  FETCH_STATUS_FAILED,
} from '~/bundles/shared/constants/graphs';
import {
  bgGraphSeriesName,
  basalSeriesName,
  bolusSeriesName,
  carbsSeriesName,
  insulinSeriesName,
  pumpModeSeriesNameProcessed,
  pumpModeOp5SeriesName,
  pumpModeGenericSeriesNameProcessed,
  pumpModeBasaliqSeriesNameProcessed,
  basalBarSeriesName,
  pumpModeCamApsSeriesName,
} from '~/bundles/shared/constants/pages/overview';
import Readings from '~/services/Readings';
import { GRAPH_NAME_HOURS_EXERCISE } from 'bundles/shared/constants/exercise';
import { GRAPH_NAME } from '../OverviewGraphHoursWithAxis/OverviewGraphHoursWithAxis';
import { groupedSeries, getAxisMaxFromRedux } from './HoursGraphHelpers';
import hoursLegendConfig from './hoursLegendConfig';
import OverviewGraphHoursPresenter from '../OverviewGraphHoursPresenter/OverviewGraphHoursPresenter';

const mapStateToProps = (state, ownProps) => {
  const meterUnits = UserHelper.displayUser(state).preference.meterUnits;
  const seriesKey = keyFromParams({
    startTimestamp: ownProps.startTimestamp,
    endTimestamp: ownProps.endTimestamp,
    id: ownProps.graphTypeName || GRAPH_NAME,
  });
  const { series, status: dataFetched } = GraphsHelper.retrieveFetchStatusAndSeries(state, seriesKey);

  const timeFrameConfig = state.page.availableTimeFrames[state.page.timeFrameIndex];
  const groupedDataSeries = groupedSeries([...series, ...ownProps.exerciseSeries]);
  const legendConfigured = state.graphs.overviewGraphHoursLegendConfig.configured;
  const legendConfig = state.graphs.overviewGraphHoursLegendConfig.data || hoursLegendConfig(groupedDataSeries);

  return {
    meterUnits,
    timeFrameConfig,
    ...groupedDataSeries,
    ...getAxisMaxFromRedux(state, seriesKey),
    bgGraphAxisEndIndex: Math.round(
      Readings.displayValue(Readings.LIMITS[TYPE_CGM].hi, meterUnits) * 1.08,
    ),
    dataFetched,
    readingsType: state.page.readingsType,
    graphsCache: ownProps.cacheGraphs && state.graphs,
    legendConfigured: legendConfigured,
    legendConfig,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    graphsFetchOverviewDayDataThunk,
    graphsUpdateOverviewHoursLegendConfigThunk,
    graphsFetchExerciseHoursOverviewThunk,
    graphsUpdateSeriesThunk,
    graphsUpdateSeriesFromCacheThunk,
    cleanUp,
  }, dispatch),
});

@connect(mapStateToProps, mapDispatchToProps)
export default class OverviewGraphHoursContainer extends React.Component {
  constructor(props) {
    super(props);
    this.fetchSeries = this.fetchSeries.bind(this);
    this.fetchSeriesExercise = this.fetchSeriesExercise.bind(this);
    this.updateSeries = this.updateSeries.bind(this);
  }

  componentDidMount() {
    if (this.props.dataFetched === FETCH_STATUS_NOT_CALLED) {
      this.fetchSeries();
      this.fetchStartingCache();
    }
    if (this.props.exerciseFetchStatus === FETCH_STATUS_NOT_CALLED) {
      this.fetchSeriesExercise(this.props);
    }
  }

  componentDidUpdate(prevProps) {
    const prevKey = keyFromParams({
      startTimestamp: prevProps.startTimestamp,
      endTimestamp: prevProps.endTimestamp,
      id: GRAPH_NAME,
    });

    const nextKey = keyFromParams({
      startTimestamp: this.props.startTimestamp,
      endTimestamp: this.props.endTimestamp,
      id: GRAPH_NAME,
    });

    const seriesFinishedFetching = [FETCH_STATUS_SUCCESSFUL, FETCH_STATUS_FAILED];
    if (
      seriesFinishedFetching.includes(this.props.dataFetched) &&
      seriesFinishedFetching.includes(this.props.exerciseFetchStatus) &&
      !this.props.legendConfigured
    ) {
      this.props.actions.graphsUpdateOverviewHoursLegendConfigThunk(this.props.legendConfig);
    }

    if (prevKey !== nextKey) {
      const cachedSeries = this.props.graphsCache[nextKey];

      if (!this.props.cacheGraphs) this.cleanup(prevProps);

      if (cachedSeries) {
        graphsUpdateSeriesFromCacheThunk(nextKey, cachedSeries);
      } else {
        this.fetchSeries();
      }

      if (this.props.cacheGraphs) {
        const previousTimestamp = prevProps.startTimestamp;

        this.cacheNextSeries(
          previousTimestamp, this.forwardDirection(previousTimestamp, this.props.startTimestamp),
        );
      }
    }

    const prevKeyExercise = keyFromParams({
      startTimestamp: prevProps.startTimestamp,
      endTimestamp: prevProps.endTimestamp,
      id: GRAPH_NAME_HOURS_EXERCISE,
    });

    const nextKeyExercise = keyFromParams({
      startTimestamp: this.props.startTimestamp,
      endTimestamp: this.props.endTimestamp,
      id: GRAPH_NAME_HOURS_EXERCISE,
    });

    if (prevKeyExercise !== nextKeyExercise) {
      this.cleanupExercise(prevProps);
      this.fetchSeriesExercise(this.props);
    }
  }

  componentWillUnmount() {
    this.cleanup(this.props);
    this.cleanupExercise(this.props);
  }

  fetchStartingCache = () => {
    const [
      prevStartTimestamp, prevEndTimestamp, nextStartTimestamp, nextEndTimestamp,
    ] = this.calculateTimestamps(this.props.startTimestamp, 'add', 'subtract');

    this.fetchSeries(prevStartTimestamp, prevEndTimestamp);
    this.fetchSeries(nextStartTimestamp, nextEndTimestamp);
  }

  cacheNextSeries = (previousTimestamp, forwardDirection) => {
    const fetchTimeMethod = forwardDirection ? 'add' : 'subtract';
    const cleanupTimeMethod = forwardDirection ? 'subtract' : 'add';

    const [
      fetchStart, fetchEnd, cleanupStart, cleanupEnd,
    ] = this.calculateTimestamps(previousTimestamp, fetchTimeMethod, cleanupTimeMethod);

    this.fetchSeries(fetchStart, fetchEnd);
    this.cleanUpDay(cleanupStart, cleanupEnd);
  }

  calculateTimestamps = (secondTimestamp, firstTimeMethod, secondTimeMethod) => {
    const firstStart = moment(this.props.startTimestamp).utc()[firstTimeMethod](1, 'days')
      .startOf('day')
      .toISOString();
    const secondStart = moment(secondTimestamp).utc()[secondTimeMethod](1, 'days')
      .startOf('day')
      .toISOString();

    return [
      firstStart, moment(firstStart).utc().endOf('day').toISOString(),
      secondStart, moment(secondStart).utc().endOf('day').toISOString(),
    ];
  }

  forwardDirection = (prevTimestamp, nextTimestamp) => {
    if (moment(nextTimestamp).utc().unix() < moment(prevTimestamp).utc().unix()) return false;

    return true;
  }

  cleanUpDay = (startTimestamp, endTimestamp) => {
    this.props.actions.cleanUp({
      id: GRAPH_NAME,
      startTimestamp,
      endTimestamp,
    });
  }

  cleanup(props) {
    this.props.actions.cleanUp({
      startTimestamp: props.startTimestamp,
      endTimestamp: props.endTimestamp,
      id: GRAPH_NAME,
    });
  }

  cleanupExercise(props) {
    this.props.actions.cleanUp({
      startTimestamp: props.startTimestamp,
      endTimestamp: props.endTimestamp,
      id: GRAPH_NAME_HOURS_EXERCISE,
    });
  }

  fetchSeries(start = null, end = null) {
    this.props.actions.graphsFetchOverviewDayDataThunk({
      startTimestamp: start || this.props.startTimestamp,
      endTimestamp: end || this.props.endTimestamp,
      series: sortBy([
        ...bgGraphSeriesName,
        ...carbsSeriesName,
        ...insulinSeriesName,
        ...basalSeriesName,
        ...pumpModeSeriesNameProcessed,
        ...pumpModeOp5SeriesName,
        ...pumpModeGenericSeriesNameProcessed,
        ...pumpModeBasaliqSeriesNameProcessed,
        ...bolusSeriesName,
        ...basalBarSeriesName,
        ...pumpModeCamApsSeriesName,
      ]),
      id: GRAPH_NAME,
      meterUnits: this.props.meterUnits,
      insulinTooltips: true,
      filterBgReadings: true,
    });
  }

  fetchSeriesExercise() {
    this.props.actions.graphsFetchExerciseHoursOverviewThunk({
      startTimestamp: this.props.startTimestamp,
      endTimestamp: this.props.endTimestamp,
      timeFrameConfig: this.props.timeFrameConfig,
      id: GRAPH_NAME_HOURS_EXERCISE,
      meterUnits: this.props.meterUnits,
    });
  }

  updateSeries(seriesLookup, series) {
    const start = this.props.startTimestamp;
    const end = this.props.endTimestamp;
    const plotLookup = `${GRAPH_NAME}_${start}_${end}`;
    this.props.actions.graphsUpdateSeriesThunk(plotLookup, seriesLookup, series);
  }

  render() {
    const { startTimestamp, endTimestamp, ...otherProps } = this.props;

    return (
      <OverviewGraphHoursPresenter
        startTimestamp={moment.utc(startTimestamp).unix()}
        endTimestamp={moment.utc(endTimestamp).unix()}
        updateSeries={this.updateSeries}
        {...otherProps}
      />
    );
  }
}

OverviewGraphHoursContainer.propTypes = {
  startTimestamp: PropTypes.string.isRequired,
  endTimestamp: PropTypes.string.isRequired,
  meterUnits: PropTypes.string.isRequired,
  graphsWindowWidth: PropTypes.number.isRequired,
  timeFrameConfig: PropTypes.shape({}).isRequired,
  bgGraphSeries: PropTypes.arrayOf(PropTypes.object).isRequired,
  bolusSeries: PropTypes.arrayOf(PropTypes.object).isRequired,
  exerciseSeries: PropTypes.arrayOf(PropTypes.object).isRequired,
  basalSeries: PropTypes.arrayOf(PropTypes.object).isRequired,
  pumpModeSeries: PropTypes.arrayOf(PropTypes.object).isRequired,
  carbsSeries: PropTypes.arrayOf(PropTypes.object).isRequired,
  bgGraphAxisEndIndex: PropTypes.number.isRequired,
  bolusAxisEndIndex: PropTypes.number.isRequired,
  exerciseAxisEnd: PropTypes.number,
  dataFetched: PropTypes.string.isRequired,
  exerciseFetchStatus: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    graphsFetchExerciseHoursOverviewThunk: PropTypes.func,
    graphsFetchOverviewDayDataThunk: PropTypes.func,
    graphsUpdateSeriesThunk: PropTypes.func.isRequired,
    cleanUp: PropTypes.func,
  }).isRequired,
  graphKey: PropTypes.string,
  cacheGraphs: PropTypes.bool,
  graphsCache: PropTypes.object,
  legendConfiguered: PropTypes.bool.isRequired,
  legendConfig: PropTypes.object,
  hasExercise: PropTypes.bool.isRequired,
  weekView: PropTypes.bool,
  graphTypeName: PropTypes.string,
};

OverviewGraphHoursContainer.defaultProps = {
  actions: {
    graphsFetchExerciseHoursOverviewThunk: () => (null),
    graphsFetchOverviewDayDataThunk: () => (null),
    graphsUpdateSeriesThunk: () => { },
    cleanUp: () => (null),
  },
  meterUnits: Readings.MGDL,
  timeFrameConfig: {},
  bgGraphSeries: [],
  basalSeries: [],
  pumpModeSeries: [],
  exerciseSeries: [],
  bolusSeries: [],
  carbsSeries: [],
  dataFetched: FETCH_STATUS_NOT_CALLED,
  exerciseFetchStatus: FETCH_STATUS_NOT_CALLED,
  bolusAxisEndIndex: 10,
  bgGraphAxisEndIndex: 400,
  graphKey: null,
  cacheGraphs: false,
  legendConfiguered: false,
  graphsCache: {},
};
