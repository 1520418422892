import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { numberFormatterThreeDecimals } from '~/utils/i18nFormats';
import { translate } from '../../../../../WithTranslate/WithTranslate';
import DeviceAndTime from './DeviceAndTime/DeviceAndTime';
import Style from '../BolusTooltip.scss';

const AutomaticBolusSeriesItem = ({ t, point }) => {
  const value = `${numberFormatterThreeDecimals(point.insulinDelivered)} ${t('insulinUnitsAbbreviation')}`;
  const source = point.deviceName || t('pumpName');

  return (
    <div>
      <div className={classNames({
        [Style.row]: true,
        [Style.hidden]: point.insulinDelivered === null,
      })}
      >
        <DeviceAndTime device={source} timestamp={point.timestamp} />
        <div className={Style.itemRow}>
          <span>{t('automaticallyDelivered')}</span>
          <span>{value}</span>
        </div>
      </div>
    </div>
  );
};

AutomaticBolusSeriesItem.propTypes = {
  point: PropTypes.shape({
    timestamp: PropTypes.string.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default translate('BolusTooltip')(AutomaticBolusSeriesItem);
