import React from 'react';
import PropTypes from 'prop-types';
import deepMerge from 'deepmerge';
import { connect } from 'react-redux';
import { ONE_DAY } from '~/bundles/shared/constants/time';
import PageHelper from '~/redux/modules/page/PageHelper';
import Tooltips from '~/bundles/shared/components/graphs/tooltips.jsx';
import GraphPresenter from '~/bundles/shared/components/graphs/GraphPresenter/GraphPresenter.jsx';
import Style from './ExerciseGraph.scss';

const mapStateToProps = (state) => ({
  pointWidth: PageHelper.currentTimeframe(state).pointWidth,
  hours: PageHelper.currentTimeFrameLength(state) === ONE_DAY,
});

@connect(mapStateToProps, null)
export default class ExerciseGraph extends React.Component {
  render() {
    const { yAxisMax, pointWidth, configOverride, hours, ...otherProps } = this.props;
    const tooltipFormatter = hours ? Tooltips.exerciseTooltip : null;
    return (
      <div class={Style.ExerciseGraph}>
        <GraphPresenter
          {...otherProps}
          tooltipPositioner={Tooltips.tooltipHoursPositioner}
          tooltipFormatter={tooltipFormatter}
          configOverride={deepMerge({
            yAxis: {
              max: yAxisMax,
            },
            plotOptions: {
              series: {
                pointWidth,
                dataLabels: {
                  verticalAlign: 'middle',
                },
              },
              column: {
                pointWidth,
              },
            },
          }, configOverride)}
        />
      </div>
    );
  }
}

ExerciseGraph.propTypes = {
  startTimestamp: PropTypes.number.isRequired,
  endTimestamp: PropTypes.number.isRequired,
  series: PropTypes.arrayOf(PropTypes.object).isRequired,
  windowHeight: PropTypes.number.isRequired,
  windowWidth: PropTypes.number.isRequired,
  yAxisMax: PropTypes.number.isRequired,
  pointWidth: PropTypes.number.isRequired,
  configOverride: PropTypes.shape({}).isRequired,
  hours: PropTypes.bool.isRequired,
};

ExerciseGraph.defaultProps = {
  yAxisMax: 100,
  pointWidth: 0,
  configOverride: {},
  hours: false,
};
