import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@glooko/common-ui';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import { PATH_POP_INSIGHTS } from '~/bundles/shared/constants/navigation';
import styles from './PatientFlag.scss';

class PatientFlag extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    flag: PropTypes.any,
  }

  static defaultProps = {
    flag: {},
  }

  constructor(props) {
    super(props);
    this.renderHelpcenterLink = this.renderHelpcenterLink.bind(this);
  }

  renderHelpcenterLink(ownedByGlooko, helpcenterLink) {
    const { t } = this.props;
    if (ownedByGlooko) {
      return (
        <div className={styles.glookoCohortFooterRight}>
          <Button
            onClick={() => window.open(helpcenterLink, '_blank')}
            variation='link'
            dataAttributes={{ testid: 'patient-flag-read-more' }}
          >
            {t('readMore')}
          </Button>
        </div>
      );
    }
    return null;
  }

  render() {
    const { flag, t } = this.props;
    const { description, ownedByGlooko, helpcenterLink } = flag;
    return (
      <div className={styles.glookoCohort}>
        <p className={styles.glookoCohortDescription}>
          { description }
        </p>
        <div className={styles.glookoCohortFooter}>
          <div className={styles.glookoCohortFooterLeft}>
            <Button
              onClick={() => window.location.assign(PATH_POP_INSIGHTS)}
              variation='link'
              dataAttributes={{ testid: 'patient-flag-view-flags' }}
            >
              {t('viewFlags')}
            </Button>
          </div>
          {this.renderHelpcenterLink(ownedByGlooko, helpcenterLink)}
        </div>
      </div>
    );
  }
}

export default translate('PatientFlag')(PatientFlag);
