import {
  favoritesFetchSuccess,
  favoritesInProgress,
  favoritesHasErrored,
} from '~/redux/modules/pdfFavorites/pdfFavorites';
import {
  fetchFavorites,
  saveFavorites,
  removeFavorite,
  updateFavorite,
} from '~/services/favoritesApi';
import ApiWrappers from '~/services/apiWrappers';
import throwIfStatusCodeNotExpected from '~/utils/throwIfStatusCodeNotExpected';

export function wizardFetchFavorites() {
  return (dispatch) => {
    dispatch(favoritesInProgress());
    return fetchFavorites()
      .then(throwIfStatusCodeNotExpected(200))
      .then((response) => ApiWrappers.favoriteWrapper(response.data.favorite_settings))
      .then((response) => dispatch(favoritesFetchSuccess(response)))
      .catch(() => dispatch(favoritesHasErrored()));
  };
}

export function wizardSaveFavorite(params) {
  return (dispatch) => saveFavorites(params)
    .then(throwIfStatusCodeNotExpected(201))
    .then(() => dispatch(wizardFetchFavorites()))
    .catch(() => dispatch(favoritesHasErrored()));
}

export function wizardUpdateFavorite(params) {
  return (dispatch) => updateFavorite(params)
    .then(throwIfStatusCodeNotExpected(204))
    .then(() => dispatch(wizardFetchFavorites()))
    .catch(() => dispatch(favoritesHasErrored()));
}

export function removeFavoriteThunk(params) {
  return (dispatch) => removeFavorite(params)
    .then(throwIfStatusCodeNotExpected(204))
    .then(() => dispatch(wizardFetchFavorites()))
    .catch(() => dispatch(favoritesHasErrored()));
}
