import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '../WithTranslate/WithTranslate';
import Style from './PrimeWarningDialog.scss';
import InfoDialog from '../InfoDialog/InfoDialog';

const PrimeWarningDialog = ({ t, isOpen, onClose }) => {
  if (!isOpen) return null;

  const dialogContent = (
    <div>
        <p className={Style.content}>{t('content')}</p>
        <p className={Style.content}>
          {t('title_solosmart')}<br />
          {t('content_solosmart')}
        </p>
    </div>
  );

  return (
    <InfoDialog
      closeDialog={onClose}
      content={dialogContent}
      isOpen={true}
      title={t('title')}
    />
  );
};

PrimeWarningDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default translate('PrimeWarningDialog')(PrimeWarningDialog);
