import {
  fetchCurrentUsers,
  fetchPatientMrn,
  updatePatient as updatePatientApiCall,
  sendActivationEmail as sendActivationEmailApiCall,
  sendGuardianEmail as sendGuardianEmailApiCall,
  updatePatientInvite as updatePatientInviteApiCall,
  updatePoptrackerVersion,
  getUserEncryptedData,
  updatePregnancyDueDate,
} from '~/services/usersApi';
import {
  getPendingUserConsents,
  updateUserConsentChoice,
  reviewParentalConsent,
  withdrawParentalConsent,
  updateUserConsentAlertChoice,
}
  from '~/services/partnersConsentApi';
import {
  fetchPatientsFlags,
} from '~/services/providerGroupSiteApi';
import { insuletConsentResponse } from '~/services/omnipodApi';
import {
  usersIsLoading,
  usersFetchSuccess,
  usersHasErrored,
  updateAdvisorConsentState,
  usersUpdatePatient,
  updatePoptrackerVersionState,
  updatePatientMrnState,
  updateUserEncryptedData,
  updatePatientFlags,
  userPendingConsentsFetchReceived,
  userPendingConsentChoiceMade,
  userAllConsentsFetchReceived,
  userConsentsFetchLoading,
  updateSendGuardianEmailStatus,
  updateInsuletConsent,
  updateResendParentalConsentEmail,
  userAlertChoiceMade,
} from '~/redux/modules/users/users';
import throwIfStatusCodeNotExpected from '~/utils/throwIfStatusCodeNotExpected';
import { PAGE_SETTINGS } from '~/bundles/shared/constants/pages';
import { trackUpdatedDeviceDataSharing } from '../../services/eventLogging';

export function fetchCurrentUsersThunk() {
  return (dispatch) => {
    dispatch(usersIsLoading(true));

    return fetchCurrentUsers()
      .then(throwIfStatusCodeNotExpected(200))
      .then((response) => response.data)
      .then((users) => {
        dispatch(usersIsLoading(false));
        dispatch(usersFetchSuccess(users.currentUser, users.currentPatient));
        dispatch(updateAdvisorConsentState(users.currentUser.advisorConsent));
      })
      .catch(() => {
        dispatch(usersHasErrored(true));
        dispatch(usersIsLoading(false));
      });
  };
}

export function updatePatientEmailForInviteThunk(params) {
  return () => updatePatientInviteApiCall(params)
    .catch((error) => {
      if (error.response.status !== 200) {
        throw new Error(error.response.data.error_key);
      }
    });
}

export function updatePatient(patient, patientParams, additionalStateUpdates, onFailure = undefined, closeWindow = undefined) {
  return (dispatch) => updatePatientApiCall(patient, patientParams)
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      dispatch(usersIsLoading(false));
      return response;
    })
    .then(() => {
      dispatch(usersUpdatePatient({ ...additionalStateUpdates, ...patientParams }));
      closeWindow();
    })
    .catch((err) => {
      if ((err.response.status === 400 || err.response.status === 422) && onFailure !== undefined) {
        onFailure();
      }
      dispatch(usersHasErrored(true));
    });
}
export function sendActivationEmail(patient, additionalStateUpdates) {
  return (dispatch) => sendActivationEmailApiCall(patient)
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response;
    })
    .then(() => {
      dispatch(usersUpdatePatient(additionalStateUpdates));
    })
    .catch(() => {
      dispatch(usersHasErrored(true));
    });
}

export function sendGuardianEmail() {
  return (dispatch) => {
    dispatch(updateSendGuardianEmailStatus('initiated'));

    return sendGuardianEmailApiCall()
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(response.statusText);
        }
        return response;
      })
      .then(() => {
        dispatch(updateSendGuardianEmailStatus('sent'));
      })
      .catch(() => {
        dispatch(updateSendGuardianEmailStatus(null));
      });
  };
}

export function updatePoptrackerVersionThunk(payload) {
  return (dispatch) => {
    window.PopulationTracker.prototype.toggleLoader(true);

    return updatePoptrackerVersion(payload)
      .then(throwIfStatusCodeNotExpected(200))
      .then(() => {
        dispatch(updatePoptrackerVersionState(payload.poptrackerVersion));
        window.location.assign('/patients');
      })
      .catch((response) => {
        dispatch(updatePoptrackerVersionState(null));
        return response;
      });
  };
}

export function updatePatientMrnThunk() {
  return (dispatch) => fetchPatientMrn()
    .then(throwIfStatusCodeNotExpected(200))
    .then((response) => {
      dispatch(updatePatientMrnState(response.data));
    })
    .catch((e) => {
      console.log(e);
    });
}
export function fetchPatientFlagsThunk(patientId) {
  const payload = {
    // eslint-disable-next-line camelcase
    user_id: [patientId],
  };
  return (dispatch) => fetchPatientsFlags(payload)
    .then(throwIfStatusCodeNotExpected(200))
    .then(({ data }) => {
      const flags = data[patientId] || [];
      dispatch(updatePatientFlags(flags));
    })
    .catch((e) => {
      console.log(e);
    });
}

export function fetchUserEncryptedData(professional) {
  return (dispatch) => getUserEncryptedData(professional)
    .then(throwIfStatusCodeNotExpected(200))
    .then((response) => response.data)
    .then((encryptedDataObject) => {
      dispatch(updateUserEncryptedData(encryptedDataObject));
    })
    .catch((e) => {
      console.log(e);
    });
}

export function fetchPendingConsentsThunk(isMinor) {
  const choice = isMinor ? 'pending_parental' : 'pending';
  return (dispatch) => getPendingUserConsents(choice)
    .then(throwIfStatusCodeNotExpected(200))
    .then((response) => response.data)
    .then((pendingConsents) => {
      dispatch(userPendingConsentsFetchReceived(pendingConsents));
    })
    .catch((e) => {
      console.log(e);
    });
}

export function fetchAllConsentsThunk() {
  return (dispatch) => {
    dispatch(userConsentsFetchLoading(true));
    return getPendingUserConsents('all')
      .then(throwIfStatusCodeNotExpected(200))
      .then((response) => response.data)
      .then((consents) => {
        dispatch(userAllConsentsFetchReceived(consents));
        dispatch(userConsentsFetchLoading(false));
      })
      .catch((e) => {
        console.log(e);
      });
  };
}

export function updatePatientConsentThunk(partner, version, choice) {
  return (dispatch) => {
    dispatch(userConsentsFetchLoading(true));
    return updateUserConsentChoice(partner, version, choice)
      .then(throwIfStatusCodeNotExpected(200))
      .then((res) => {
        if (res.status === 200) {
          trackUpdatedDeviceDataSharing(partner, choice);
          dispatch(fetchAllConsentsThunk());
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
}

export function updatePatientConsentChoiceThunk(partner, version, choice) {
  return (dispatch, getState) => {
    dispatch(userPendingConsentChoiceMade(partner));
    return updateUserConsentChoice(partner, version, choice)
      .then(throwIfStatusCodeNotExpected(200))
      .then(() => {
        const page = getState().page;
        if (page && page.id === PAGE_SETTINGS) {
          dispatch(fetchAllConsentsThunk());
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
}

export function updateConsentAlertFlagThunk(partner, alertMinor) {
  return (dispatch) => {
    dispatch(userAlertChoiceMade(partner, alertMinor));
    return updateUserConsentAlertChoice(partner, alertMinor)
      .then(throwIfStatusCodeNotExpected(200))
      .then(() => dispatch(fetchPendingConsentsThunk(true)))
      .catch((e) => {
        console.log(e);
      });
  };
}

export function updatePregnancyDueDateThunk(patient, dueDate) {
  return () => updatePregnancyDueDate(patient, dueDate)
    .then(throwIfStatusCodeNotExpected(200))
    .catch((e) => {
      console.log(e);
    });
}

// Supporting 'agree'/'disagree' from settings
export function submitInsuletConsentChoiceThunk(params, onFailure) {
  return (dispatch) => insuletConsentResponse(params)
    .then(() => {
      dispatch(updateInsuletConsent(params));
    })
    .catch(() => {
      onFailure();
    });
}

export function resendParentalReviewConsentThunk(partner) {
  return (dispatch) => reviewParentalConsent(partner)
    .then(throwIfStatusCodeNotExpected(200))
    .then(() => {
      dispatch(updateResendParentalConsentEmail(true));
    })
    .catch((e) => {
      console.log(e);
    });
}

export function withdrawParentalConsentThunk(partner) {
  return (dispatch) => withdrawParentalConsent(partner)
    .then(throwIfStatusCodeNotExpected(200))
    .then(() => {
      dispatch(updateResendParentalConsentEmail(true));
    })
    .catch((e) => {
      console.log(e);
    });
}
