import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@glooko/common-ui';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import Styles from './FilterPatientsActions.scss';

const FilterPatientsActions = (props) => {
  const { t, onCancelClick, onApplyClick, isApplyDisabled } = props;

  return (
    <div className={Styles.actions}>
      <Button
        className={Styles.actions__cancel}
        variation="secondary"
        onClick={onCancelClick}
        dataAttributes={{ testid: 'filter-patients-actions-cancel' }}
      >
         {t('cancel')}
      </Button>
      <Button
        className={Styles.actions__apply}
        disabled={isApplyDisabled}
        onClick={onApplyClick}
        dataAttributes={{ testid: 'filter-patients-actions-apply' }}
      >
        {t('apply')}
      </Button>
    </div>
  );
};

FilterPatientsActions.propTypes = {
  t: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onApplyClick: PropTypes.func.isRequired,
  isApplyDisabled: PropTypes.bool,
};

FilterPatientsActions.defaultProps = {
  isApplyDisabled: false,
};

export default translate('FilterPatientsActions')(FilterPatientsActions);
