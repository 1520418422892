import i18next from '~/utils/I18n/I18n';

const t = i18next.getFixedT(null, 'AlarmKeys');

export default (key, opts) => {
  const { errorCode, deviceModel } = opts;
  const errorCodePopUpSufix = errorCode ? ` [${errorCode}]` : '';
  const popUpOpts = { errorCode: errorCodePopUpSufix, deviceModel };

  switch (key) {
    case 'biocorp_time_management':
      return t('biocorpTimeManagement');
    case 'biocorp_time_management_popup':
      return t('biocorpTimeManagementPopup', popUpOpts);
    case 'biocorp_pairing':
      return t('biocorpPairing');
    case 'biocorp_pairing_popup':
      return t('biocorpPairingPopup', popUpOpts);
    case 'biocorp_generic':
      if (!errorCode) return t('error');

      return t('biocorpGeneric', { errorCode });
    case 'biocorp_generic_popup':
      return t('biocorpGenericPopup', popUpOpts);
    case 'biocorp_invalid_dose':
      return t('biocorpInvalidDose');
    case 'biocorp_invalid_dose_popup':
      return t('biocorpInvalidDosePopup');
    case 'biocorp_invalid_pen':
      return t('biocorpInvalidPen');
    case 'biocorp_invalid_pen_popup':
      return t('biocorpInvalidPenPopup', { deviceModel });
    default:
      return null;
  }
};
