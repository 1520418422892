import i18next from '~/utils/I18n/I18n';

const t = i18next.getFixedT(null, 'AlarmKeys');

export default (key, _) => {
  switch (key) {
    case 'novodialoq_crc_corrupted':
      return t('novodialoqCrcCorrupted');
    case 'novodialoq_crc_corrupted_popup':
      return t('novodialoqCrcCorruptedPopup');
    case 'novodialoq_dose_amount_err':
      return t('novodialoqDoseAmountErr');
    case 'novodialoq_dose_amount_err_popup':
      return t('novodialoqDoseAmountErrPopup');
    case 'novodialoq_drug_type_err':
      return t('novodialoqDrugTypeErr');
    case 'novodialoq_drug_type_err_popup':
      return t('novodialoqDrugTypeErrPopup');
    case 'novodialoq_drug_type_read_err':
      return t('novodialoqDrugTypeReadErr');
    case 'novodialoq_drug_type_read_err_popup':
      return t('novodialoqDrugTypeReadErrPopup');
    case 'novodialoq_expired_eol':
      return t('novodialoqExpiredEol');
    case 'novodialoq_expired_eol_popup':
      return t('novodialoqExpiredEolPopup');
    case 'novodialoq_interrupted_dose':
      return t('novodialoqInterruptedDose');
    case 'novodialoq_interrupted_dose_popup':
      return t('novodialoqInterruptedDosePopup');
    case 'novodialoq_pulsed_injection':
      return t('novodialoqPulsedInjection');
    case 'novodialoq_pulsed_injection_popup':
      return t('novodialoqPulsedInjectionPopup');
    case 'novodialoq_recoverable_err':
      return t('novodialoqRecoverableErr');
    case 'novodialoq_recoverable_err_popup':
      return t('novodialoqRecoverableErrPopup');
    case 'novodialoq_timeout_dose':
      return t('novodialoqTimeoutDose');
    case 'novodialoq_timeout_dose_popup':
      return t('novodialoqTimeoutDosePopup');
    case 'novodialoq_warning_eol':
      return t('novodialoqWarningEol');
    case 'novodialoq_warning_eol_popup':
      return t('novodialoqWarningEolPopup');
    default:
      return null;
  }
};
