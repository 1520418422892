import ExecuteApiCall, { ExecuteUnchangedApiCall } from './apiClient';

export const fetchPartnerIntegrations = () => ExecuteApiCall(
  'get',
  'api/v3/cloud_connections?clientType=web',
);

export const connectToIntegrationWithoutCredentials = (integration, redirectUrl) => ExecuteUnchangedApiCall(
  'get',
  `api/v3/cloud_connections/connect/${integration}?redirectUrl=${redirectUrl}`,
);

export const connectToIntegrationWithCredentials = (integration, credentials) => ExecuteUnchangedApiCall(
  'post',
  `api/v3/cloud_connections/connect_with_credentials/${integration}`,
  { credentials },
);

export const disconnectFromIntegration = (integration) => ExecuteUnchangedApiCall(
  'get',
  `api/v3/cloud_connections/disconnect/${integration}`,
);

export const triggerIntegrationSync = (integration) => ExecuteApiCall(
  'get',
  `api/v3/cloud_connections/sync/${integration}`,
);
