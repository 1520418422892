import { connect } from 'react-redux';
import images from '~/utils/ImageStore';
import colors from '~/../assets/styles/export/colors';
import UserHelper from '~/redux/modules/users/UserHelper';
import SystemStats from './SystemStats';
import translate from '../../../../shared/components/WithTranslate/WithTranslate';
import { hasControlIqData } from '../../../../../services/hasDeviceData';

const mapStateToProps = (state, ownProps) => {
  const data = state.statistics.overall;
  const { t } = ownProps;

  const hasData = hasControlIqData(UserHelper.displayUser(state), data);

  if (!hasData) {
    return {};
  }

  return {
    systemLabel: t('systemNameCiq'),
    activeTime: data.controlIqPumpModeDurationString,
    data: [
      {
        label: t('automaticCiq'),
        image: images.pumpAutomaticMode,
        value: data.controlIqPumpModeAutomaticPercentage,
        activeTime: data.controlIqPumpModePerModeDurationStrings.automatic,
        subModes: [
          {
            label: t('activitySleep'),
            image: images.pumpSleepMode,
            value: data.controlIqPumpModeSleepPercentage,
            activeTime: data.controlIqPumpModePerModeDurationStrings.sleep,
          },
          {
            label: t('activityExercise'),
            image: images.pumpExerciseMode,
            value: data.controlIqPumpModeExercisePercentage,
            activeTime: data.controlIqPumpModePerModeDurationStrings.exercise,
          },
        ],
      },
      {
        label: t('manualCiq'),
        image: images.pumpManualMode,
        value: data.controlIqPumpModeManualPercentage,
        activeTime: data.controlIqPumpModePerModeDurationStrings.manual,
      },
    ],
    chartData: [
      {
        color: colors.systemModeGraphLineAuto,
        value: data.controlIqPumpModeAutomaticPercentage,
      },
      {
        color: colors.systemModeGraphLineManual,
        value: data.controlIqPumpModeManualPercentage,
      },
    ],
  };
};

export default translate('SystemStats')(connect(mapStateToProps)(SystemStats));
