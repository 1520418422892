import axios from 'axios';
import isEqual from 'lodash/isEqual';
import { TYPE_BG, TYPE_CGM } from '~/bundles/shared/constants/readings';
import {
  OVERALL, MEAL, TIME_OF_DAY, DAY_OF_WEEK, BI_HOURLY,
} from '~/bundles/shared/constants/statistics';
import { FETCH_STATUS_FAILED, FETCH_STATUS_IN_PROGRESS } from '~/bundles/shared/constants/graphs';
import { updateBGStatistics, updateBGStatisticsStatus, updateBGStatisticsOfTimeOfDay } from
  '~/redux/modules/statistics/readings/bg/bg';
import { updateCGMStatistics, updateCGMStatisticsStatus, updateCGMStatisticsOfTimeOfDay } from
  '~/redux/modules/statistics/readings/cgm/cgm';
import fetchReadingStatistics from '~/services/readingsApi';

export function fetchReadingStatisticsThunk(params) {
  return (dispatch, getState) => {
    const state = getState();
    const readingType = params.type;
    const statusActionCreator =
      (readingType === TYPE_BG ? updateBGStatisticsStatus : updateCGMStatisticsStatus);
    if (isEqual(params, state.statistics.readings[readingType].params)) {
      return;
    }

    dispatch(statusActionCreator(FETCH_STATUS_IN_PROGRESS));

    if (readingType === TYPE_BG) {
      return axios.all([
        fetchReadingStatistics(OVERALL, params),
        fetchReadingStatistics(MEAL, params),
        fetchReadingStatistics(TIME_OF_DAY, params),
        fetchReadingStatistics(DAY_OF_WEEK, params),
        fetchReadingStatistics(BI_HOURLY, params),
      ]).then(axios.spread((overallStats, mealStats, todStats, dowStats, biHourlyStats) => {
        dispatch(
          updateBGStatistics(
            overallStats.data.overall,
            mealStats.data.meal,
            todStats.data.tod,
            dowStats.data.dow,
            biHourlyStats.data.hourly,
            params,
          ),
        );
      }))
        .catch(() => {
          dispatch(statusActionCreator(FETCH_STATUS_FAILED));
        });
    } else if (readingType === TYPE_CGM) {
      return axios.all([
        fetchReadingStatistics(OVERALL, params),
        fetchReadingStatistics(TIME_OF_DAY, params),
        fetchReadingStatistics(DAY_OF_WEEK, params),
        fetchReadingStatistics(BI_HOURLY, params),
      ]).then(axios.spread((overallStats, todStats, dowStats, biHourlyStats) => {
        dispatch(
          updateCGMStatistics(
            overallStats.data.overall,
            todStats.data.tod,
            dowStats.data.dow,
            biHourlyStats.data.hourly,
            params,
          ),
        );
      }))
        .catch(() => {
          dispatch(statusActionCreator(FETCH_STATUS_FAILED));
        });
    }
  };
}

export function fetchTimeOfDayStatisticsThunk(params) {
  return (dispatch, getState) => {
    const state = getState();
    const readingType = params.type;
    const statusActionCreator =
      (readingType === TYPE_BG ? updateBGStatisticsStatus : updateCGMStatisticsStatus);
    if (isEqual(params, state.statistics.readings[readingType].params)) {
      return;
    }

    dispatch(statusActionCreator(FETCH_STATUS_IN_PROGRESS));

    if (readingType === TYPE_BG) {
      return axios.all([
        fetchReadingStatistics(TIME_OF_DAY, params),
      ]).then(axios.spread((todStats) => {
        dispatch(
          updateBGStatisticsOfTimeOfDay(
            todStats.data.tod,
            params,
          ),
        );
      }))
        .catch(() => {
          dispatch(statusActionCreator(FETCH_STATUS_FAILED));
        });
    } else if (readingType === TYPE_CGM) {
      return axios.all([
        fetchReadingStatistics(TIME_OF_DAY, params),
      ]).then(axios.spread((todStats) => {
        dispatch(
          updateCGMStatisticsOfTimeOfDay(
            todStats.data.tod,
            params,
          ),
        );
      }))
        .catch(() => {
          dispatch(statusActionCreator(FETCH_STATUS_FAILED));
        });
    }
  };
}
