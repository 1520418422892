import {
  FETCH_STATUS_FAILED, FETCH_STATUS_IN_PROGRESS,
  FETCH_STATUS_SUCCESSFUL, FETCH_STATUS_NOT_CALLED,
} from '~/bundles/shared/constants/graphs';
import { fetchCurrentUserSyncOptions } from '~/services/syncOptions';

// ACTIONS
export const SYNC_OPTIONS_REQUESTED = 'SYNC_OPTIONS_REQUESTED';
export const SYNC_OPTIONS_RECEIVED = 'SYNC_OPTIONS_RECEIVED';

const initialState = {
  status: FETCH_STATUS_NOT_CALLED,
  syncOptions: [],
};

// REDUCER
export default function reducer(state = initialState, action = []) {
  switch (action.type) {
    case SYNC_OPTIONS_REQUESTED:
      return {
        ...state,
        status: FETCH_STATUS_IN_PROGRESS,
      };

    case SYNC_OPTIONS_RECEIVED:
      return {
        ...state,
        status: action.status,
        syncOptions: action.syncOptions,
      };

    default:
      return state;
  }
}

// ACTION CREATORS
export function syncOptionsRequested() {
  return { type: SYNC_OPTIONS_REQUESTED };
}

export function syncOptionsReceived(status, syncOptions) {
  return { type: SYNC_OPTIONS_RECEIVED, status, syncOptions };
}

// THUNK ACTION CREATORS
export function fetchSyncOptions() {
  return (dispatch) => {
    dispatch(syncOptionsRequested());
    fetchCurrentUserSyncOptions()
      .then((response) => {
        dispatch(syncOptionsReceived(FETCH_STATUS_SUCCESSFUL, response.data.sync_options));
      })
      .catch(() => {
        dispatch(syncOptionsReceived(FETCH_STATUS_FAILED));
      });
  };
}
