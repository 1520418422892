import { ExecuteFileUploadApiCall, OpenPageGenericWithLocale } from './apiClient';
import { PATH_ROOT } from '../bundles/shared/constants/navigation';

export function sendData(data, extractorName) {
  const headers = { 'Content-Type': 'application/octet-stream' };

  return ExecuteFileUploadApiCall(
    'post',
    `api/v3/device/file/upload?extractorName=${extractorName}`,
    { data, headers },
  );
}

export function openSummaryPage() {
  return OpenPageGenericWithLocale(PATH_ROOT, {}, '_self');
}
