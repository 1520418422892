import deepMerge from 'deepmerge';
import colors from '~/../assets/styles/export/colors';
import images from '~/utils/ImageStore';
import { WEEKDAYS } from '~/utils/syncTimestamps';
import {
  WEB_CONFIG as OverlayWebConstants,
  PDF_CONFIG as OverlayPdfConstants,
} from '~/bundles/shared/constants/pages/overlay';
import Labels from '../labels';
import Events from '../events';
import {
  bgColumnEmptyVeryLow,
  bgColumnEmptyLow,
  bgColumnEmptyNormal,
  bgColumnEmptyHigh,
  bgColumnEmptyVeryHigh,
  bgColumnVeryLow,
  bgColumnVeryLowPdf,
  bgColumnLow,
  bgColumnLowPdf,
  bgColumnNormal,
  bgColumnNormalPdf,
  bgColumnHigh,
  bgColumnHighPdf,
  bgColumnVeryHigh,
  bgColumnVeryHighPdf,
} from './glucoseColumnSeries';

const ALL_EXPORTS = {};

const exportProperties = (argExport, argOptions = {}) => {
  const forExport = argExport;
  const options = Object.assign({ generateName: true }, argOptions);

  if (options.generateName) {
    Object.keys(forExport).forEach((key) => {
      forExport[key].name = key;
    });
  }

  Object.assign(ALL_EXPORTS, forExport);
};

const readings = {
  type: 'scatter',
  lineWidth: 0,
  states: {
    hover: {
      enabled: false,
    },
  },
  stickyTracking: false,
};

const bgSeriesMarker = {
  symbol: 'circle',
  radius: 5,
};
exportProperties({ bgSeriesMarker }, { generateName: false });

const bgSeries = {
  ...readings,
  dataGrouping: {
    enabled: false,
  },
  marker: bgSeriesMarker,
  dataLabels: {
    format: null,
    formatter: Labels.scatter,
  },
  stickyTracking: true,
  zIndex: 2,
};

const bgSeriesManual = {
  marker: {
    ...bgSeriesMarker,
    lineColor: colors.lineGrey,
    lineWidth: 1.5,
    radius: 4,
  },
  tooltip: {
    distance: 50,
  },
};

exportProperties({ bgSeriesManual });

const bgVeryLow = {
  ...bgSeries,
  color: colors.seriousLow,
};

const bgLow = {
  ...bgSeries,
  color: colors.bgReadingLow,
};

const bgNormal = {
  ...bgSeries,
  color: colors.bgReadingNormal,
};

const bgHigh = {
  ...bgSeries,
  color: colors.bgReadingHigh,
};

const bgVeryHigh = {
  ...bgSeries,
  color: colors.high,
};

exportProperties({ bgVeryLow, bgLow, bgNormal, bgHigh, bgVeryHigh });

const bgDowScatter = deepMerge(
  bgSeries,
  {
    states: {
      hover: {
        enabled: true,
        halo: {
          size: 0,
        },
      },
    },
    marker: {
      states: {
        hover: {
          radiusPlus: 2,
        },
      },
    },
    events: {
      click: Events.showHistoryItemsTooltip,
    },
  },
);

exportProperties(
  WEEKDAYS.reduce((accumulator, weekday) => ({
    ...accumulator,
    [`bgDowScatter${weekday}`]: { ...bgDowScatter, name: `bgDowScatter${weekday}` },
  }), {}),
);

exportProperties({
  bgColumnEmptyVeryLow,
  bgColumnEmptyLow,
  bgColumnEmptyNormal,
  bgColumnEmptyHigh,
  bgColumnEmptyVeryHigh,
  bgColumnVeryLow,
  bgColumnVeryLowPdf,
  bgColumnLow,
  bgColumnLowPdf,
  bgColumnNormal,
  bgColumnNormalPdf,
  bgColumnHigh,
  bgColumnHighPdf,
  bgColumnVeryHigh,
  bgColumnVeryHighPdf,
});

export const bgDowConnected = deepMerge(bgSeries, {
  lineWidth: 5,
  events: {
    click: Events.setWeekdaySelected,
  },
  states: {
    hover: {
      enabled: true,
      halo: {
        size: 0,
      },
    },
  },
  marker: {
    states: {
      hover: {
        radiusPlus: 3,
      },
    },
  },
  glookoCustomOptions: {
    state: {
      selected: {
        lineWidth: 7,
        marker: {
          radius: 7,
        },
      },
    },
  },
});

exportProperties({ bgDowConnected });

const cgmDowConnected = deepMerge(bgSeries, {
  lineWidth: 0.001,
  states: {
    hover: {
      enabled: true,
      lineWidth: 7,
      halo: {
        size: 0,
      },
    },
  },
  marker: {
    radius: 2,
    states: {
      hover: {
        radiusPlus: 8,
        lineColor: 'white',
        lineWidth: 3,
      },
    },
  },
  events: {
    click: Events.setWeekdaySelected,
  },
  glookoCustomOptions: {
    state: {
      selected: {
        lineWidth: 7,
      },
    },
  },
});

const generateDowConnectedSeries = (type, day) => (
  deepMerge({
    ...((type === 'bg') ? bgDowConnected : cgmDowConnected),
    name: `${type}DowConnected${day}`,
    color: colors[`dow${day}`],
    lineColor: colors[`dow${day}Transparent5`],
  }, {
    glookoCustomOptions: {
      state: {
        otherSelected: {
          color: colors[`dow${day}Transparent3`],
          lineColor: colors[`dow${day}Transparent3`],
        },
      },
    },
    marker: {
      states: {
        hover: {
          fillColor: colors[`dow${day}`],
        },
      },
    },
  })
);

exportProperties(
  WEEKDAYS.reduce((accumulator, weekday) => ({
    ...accumulator,
    [`cgmDowConnected${weekday}`]: generateDowConnectedSeries('cgm', weekday),
    [`bgDowConnected${weekday}`]: generateDowConnectedSeries('bg', weekday),
  }), {}),
);

const bgLowManual = {
  ...bgLow,
  ...bgSeriesManual,
};

const bgNormalManual = {
  ...bgNormal,
  ...bgSeriesManual,
};

const bgHighManual = {
  ...bgHigh,
  ...bgSeriesManual,
};

const bgAbove400 = {
  ...bgSeries,
  ...bgHigh,
  marker: {
    symbol: `url(${images.GlucoseMarkerHighNormal})`,
    width: 18,
    height: 18,
    radius: 5,
  },
};
const bgAbove400PdfFixed = deepMerge(bgAbove400, {
  marker: {
    width: 35,
  },
});

const bgAbove400Manual = {
  ...bgAbove400,
  marker: {
    ...bgAbove400.marker,
    symbol: `url(${images.GlucoseMarkerHighManual})`,
  },
};

const bgAbove400ManualPdfFixed = deepMerge(bgAbove400Manual, {
  marker: {
    width: 35,
  },
});

const bgTargets = {
  type: 'spline',
  marker: {
    enabled: false,
  },
  zIndex: 15,
  enableMouseTracking: false,
  color: colors.bgTargetLine,
};

const bgTargets12 = {
  ...bgTargets,
  dataLabels: {
    formatter: Labels.bgTarget,
    useHTML: false,
    overflow: 'none',
    crop: false,
    enabled: true,
    zIndex: 5,
    x: 30,
    style: {
      fontFamily: 'Lato, Helvetica, Arial, Lucida Grande, sans-serif',
      fontWeight: 500,
      color: colors.bgTargetLine,
      position: 'right',
    },
  },
};

exportProperties({
  bgLowManual,
  bgNormalManual,
  bgHighManual,
  bgAbove400,
  bgAbove400Manual,
  bgAbove400PdfFixed,
  bgAbove400ManualPdfFixed,
  bgTargets,
  bgTargets12,
});

const cgmSeries = {
  ...readings,
  dataGrouping: {
    enabled: true,
  },
  marker: {
    symbol: 'circle',
    radius: 2,
  },
  dataLabels: {
    enabled: false,
  },
  stickyTracking: true,
  zIndex: 1,
};

const cgmLow = {
  ...cgmSeries,
  color: colors.bgReadingLow,
};
const cgmLow5 = {
  ...cgmSeries,
  color: colors.bgReadingLow5,
};

const cgmNormal = {
  ...cgmSeries,
  color: colors.bgReadingNormal,
};

const cgmNormal5 = {
  ...cgmSeries,
  color: colors.bgReadingNormal5,
};

const cgmHigh = {
  ...cgmSeries,
  color: colors.bgReadingHigh,
};

const cgmHigh5 = {
  ...cgmSeries,
  color: colors.bgReadingHigh5,
};

const cgmCalibration = {
  marker: {
    symbol: 'diamond',
    radius: 5,
  },
};

let cgmCalculated = {
  color: colors.bgCgmCalculated,
};

// If it's in black and white mode.
if (colors.bgCgmCalculated === '#949393') {
  cgmCalculated = {
    color: colors.bgCgmCalculated,
    marker: {
      symbol: 'circle',
      radius: 1,
    },
  };
}

exportProperties({ cgmLow, cgmNormal, cgmHigh, cgmCalculated });
exportProperties({ cgmLow5, cgmNormal5, cgmHigh5 });

const cgmCalibrationLow = deepMerge(cgmLow, cgmCalibration);
const cgmCalibrationNormal = deepMerge(cgmNormal, cgmCalibration);
const cgmCalibrationHigh = deepMerge(cgmHigh, cgmCalibration);

const cgmCalibrationLow5 = deepMerge(cgmLow5, cgmCalibration);
const cgmCalibrationNormal5 = deepMerge(cgmNormal5, cgmCalibration);
const cgmCalibrationHigh5 = deepMerge(cgmHigh5, cgmCalibration);

exportProperties({ cgmCalibrationLow, cgmCalibrationNormal, cgmCalibrationHigh });
exportProperties({ cgmCalibrationLow5, cgmCalibrationNormal5, cgmCalibrationHigh5 });

const basal = {
  type: 'area',
  step: 'left',
  dataGrouping: {
    enabled: false,
  },
  fillOpacity: 0.07,
  fillColor: 'transparent',
  lineWidth: 1,
  trackByArea: true,
  stickyTracking: false,
  states: {
    hover: {
      enabled: false,
      halo: {
        size: 0,
      },
    },
  },
  marker: {
    symbol: 'circle',
    radius: 0,
  },
  dataLabels: {
    enabled: false,
  },
};

const scheduledBasal = {
  ...basal,
  color: colors.basalScheduledSeries,
};

const unusedScheduledBasal = {
  ...basal,
  color: colors.unusedScheduledBasal,
  fillOpacity: 0,
  zIndex: 2,
  tooltip: {
    pointFormat: '',
  },
};

const temporaryBasal = {
  ...basal,
  name: 'Basal Temporary',
  color: colors.basalScheduledSeries,
  dashStyle: 'shortdot',
  zIndex: 1,
};

const suspendBasal = {
  step: true,
  id: 'basal_interrupt_series',
  dataGrouping: {
    enabled: false,
  },
  lineWidth: 0,
  color: colors.basalSuspendedSeries,
  marker: {
    symbol: 'circle',
    radius: 3,
    fillColor: colors.white,
    lineWidth: 2,
    lineColor: colors.basalSuspendedSeries, // inherit from series
    enabled: true,
  },
  states: {
    hover: {
      enabled: false,
      halo: {
        size: 0,
      },
    },
  },
  enableMouseTracking: true,
  stickyTracking: false,
  dataLabels: {
    enabled: false,
  },
};

const lgsPlgs = {
  type: 'area',
  step: 'left',
  dataGrouping: {
    enabled: false,
  },
  dataLabels: {
    enabled: false,
  },
  lineWidth: 0,
  zIndex: 9,
  color: colors.basalLgsPlgs,
  fillOpacity: 1,
  trackByArea: true,
  stickyTracking: false,
  states: {
    hover: {
      enabled: false,
      halo: {
        size: 0,
      },
    },
  },
};

const scheduledBasal12 = {
  ...scheduledBasal,
  lineWidth: 2,
  fillColor: colors.basalScheduledSeries1,
};

const unusedScheduledBasal12 = {
  ...unusedScheduledBasal,
  lineWidth: 2,
};

const basalLabels = {
  type: 'spline',
  marker: {
    enabled: false,
  },
  zIndex: 15,
  enableMouseTracking: false,
  lineWidth: 0,
};

const basalLabels12 = {
  ...basalLabels,
  dataLabels: {
    formatter: Labels.basalLabels,
    useHTML: false,
    allowOverlap: true,
    overflow: 'none',
    align: 'left',
    crop: false,
    enabled: true,
    zIndex: 10,
    style: {
      fontFamily: 'Lato, Helvetica, Arial, Lucida Grande, sans-serif',
      fontWeight: 500,
      color: 'black',
    },
  },
};

const basalModulation = {
  type: 'area',
  step: 'left',
  zIndex: -3,
  color: colors.basalModulationSeries,
  lineColor: colors.basalModulationSeriesBorder,
  dataGrouping: {
    enabled: false,
  },
  lineWidth: 0.5,
  trackByArea: true,
  stickyTracking: false,
  states: {
    hover: {
      enabled: false,
      halo: {
        size: 0,
      },
    },
  },
  marker: {
    symbol: 'circle',
    radius: 0,
  },
  dataLabels: {
    enabled: false,
  },
};

const temporaryBasal12 = {
  ...temporaryBasal,
  lineWidth: 2,
  fillColor: colors.basalScheduledSeries1,
};

exportProperties({
  scheduledBasal,
  scheduledBasal12,
  unusedScheduledBasal,
  unusedScheduledBasal12,
  basalModulation,
  temporaryBasal,
  temporaryBasal12,
  suspendBasal,
  lgsPlgs,
  basalLabels,
  basalLabels12,
});

const pumpMode = {
  type: 'area',
  step: 'left',
  dataGrouping: {
    enabled: false,
  },
  fillOpacity: 0.07,
  lineWidth: 2,
  trackByArea: true,
  stickyTracking: false,
  states: {
    hover: {
      enabled: false,
      halo: {
        size: 0,
      },
    },
  },
  marker: {
    symbol: 'circle',
    radius: 0,
  },
  dataLabels: {
    enabled: false,
  },
};

const pumpControliqAutomaticMode = {
  ...pumpMode,
  color: colors.pumpAutomaticMode,
  zIndex: 2,
  dataLabels: {
    formatter: Labels.pumpAutomaticMode,
    enabled: true,
    useHTML: true,
    allowOverlap: true,
    verticalAlign: 'top',
    align: 'left',
    x: -2,
    y: -2,
  },
};

const pumpControliqSleepMode = {
  ...pumpMode,
  color: colors.pumpSleepMode,
  zIndex: 3,
  dataLabels: {
    formatter: Labels.pumpSleepMode,
    enabled: true,
    useHTML: true,
    allowOverlap: true,
    verticalAlign: 'top',
    align: 'left',
    x: -2,
    y: 20,
  },
};

const pumpControliqExerciseMode = {
  ...pumpMode,
  color: colors.pumpExerciseMode,
  zIndex: 4,
  dataLabels: {
    formatter: Labels.pumpExerciseMode,
    enabled: true,
    useHTML: true,
    allowOverlap: true,
    verticalAlign: 'top',
    align: 'left',
    x: -2,
    y: 22,
  },
};

const pumpControliqManualMode = {
  ...pumpMode,
  color: colors.pumpManualMode,
  zIndex: 5,
  dataLabels: {
    formatter: Labels.pumpManualMode,
    enabled: true,
    useHTML: true,
    verticalAlign: 'top',
    align: 'left',
  },
};

const pumpOp5ManualMode = {
  ...pumpMode,
  color: colors.pumpManualMode,
  zIndex: 2,
  dataLabels: {
    formatter: Labels.pumpManualMode,
    enabled: true,
    useHTML: true,
    verticalAlign: 'top',
    align: 'left',
  },
};

const pumpOp5AutomaticMode = {
  ...pumpMode,
  color: colors.pumpAutomaticMode,
  zIndex: 3,
  dataLabels: {
    formatter: Labels.pumpAutomaticMode,
    enabled: true,
    useHTML: true,
    allowOverlap: true,
    verticalAlign: 'top',
    align: 'left',
    x: -2,
    y: -2,
  },
};
const pumpOp5HypoprotectMode = {
  ...pumpMode,
  color: colors.pumpOp5HypoProtectMode,
  zIndex: 4,
  dataLabels: {
    formatter: Labels.pumpOp5HypoProtectMode,
    enabled: true,
    useHTML: true,
    allowOverlap: true,
    verticalAlign: 'top',
    align: 'left',
    x: -2,
    y: 20,
  },
};
const pumpOp5LimitedMode = {
  ...pumpMode,
  color: colors.pumpOp5LimitedMode,
  fillOpacity: 0.3,
  zIndex: 5,
  dataLabels: {
    formatter: Labels.pumpOp5LimitedMode,
    enabled: true,
    useHTML: true,
    allowOverlap: true,
    verticalAlign: 'top',
    align: 'left',
    x: -2,
    y: 22,
  },
};

const pumpGenericAutomaticMode = {
  ...pumpMode,
  color: colors.pumpAutomaticMode,
  zIndex: 2,
  dataLabels: {
    formatter: Labels.pumpAutomaticMode,
    enabled: true,
    useHTML: true,
    allowOverlap: true,
    verticalAlign: 'top',
    align: 'left',
    x: -2,
    y: -2,
  },
};

const pumpGenericManualMode = {
  ...pumpMode,
  color: colors.pumpManualMode,
  zIndex: 3,
  dataLabels: {
    formatter: Labels.pumpManualMode,
    enabled: true,
    useHTML: true,
    allowOverlap: true,
    verticalAlign: 'top',
    align: 'left',
    x: -2,
    y: -2,
  },
};

const pumpBasaliqAutomaticMode = {
  ...pumpMode,
  color: colors.pumpAutomaticMode,
  zIndex: 2,
  dataLabels: {
    formatter: Labels.pumpAutomaticMode,
    enabled: true,
    useHTML: true,
    allowOverlap: true,
    verticalAlign: 'top',
    align: 'left',
    x: -2,
    y: -2,
  },
};

const pumpBasaliqManualMode = {
  ...pumpMode,
  color: colors.pumpManualMode,
  zIndex: 3,
  dataLabels: {
    formatter: Labels.pumpManualMode,
    enabled: true,
    useHTML: true,
    allowOverlap: true,
    verticalAlign: 'top',
    align: 'left',
    x: -2,
    y: -2,
  },
};

const pumpCamapsAutomaticMode = {
  ...pumpMode,
  color: colors.pumpAutomaticMode,
  zIndex: 2,
  dataLabels: {
    formatter: Labels.pumpAutomaticMode,
    enabled: true,
    useHTML: true,
    allowOverlap: true,
    verticalAlign: 'top',
    align: 'left',
    x: -2,
    y: -2,
  },
};

const pumpCamapsManualMode = {
  ...pumpMode,
  color: colors.pumpManualMode,
  zIndex: 2,
  dataLabels: {
    formatter: Labels.pumpManualMode,
    enabled: true,
    useHTML: true,
    verticalAlign: 'top',
    align: 'left',
  },
};

const pumpCamapsBoostMode = {
  ...pumpMode,
  color: colors.pumpBoostMode,
  zIndex: 3,
  dataLabels: {
    formatter: Labels.pumpCamapsBoostMode,
    enabled: true,
    useHTML: true,
    allowOverlap: true,
    verticalAlign: 'top',
    align: 'left',
    x: -2,
    y: -2,
  },
};

const pumpCamapsEaseOffMode = {
  ...pumpMode,
  color: colors.pumpEaseOffMode,
  zIndex: 3,
  dataLabels: {
    formatter: Labels.pumpCamapsEaseOffMode,
    enabled: true,
    useHTML: true,
    allowOverlap: true,
    verticalAlign: 'top',
    align: 'left',
    x: -2,
    y: -2,
  },
};

const pumpCamapsAttempting = {
  ...pumpMode,
  dashStyle: 'ShortDash',
  lineColor: colors.pumpAttemptingModeLine,
  fillColor: colors.pumpAttemptingMode,
  lineWidth: 1,
};

const pumpCamapsNoCgmMode = pumpCamapsAttempting;
const pumpCamapsNoPumpConnectivityMode = pumpCamapsAttempting;
const pumpCamapsPumpDeliverySuspendedMode = pumpCamapsAttempting;
const pumpCamapsExtendedBolusNotAllowedMode = pumpCamapsAttempting;
const pumpCamapsDailyTotalInsulinExceededMode = pumpCamapsAttempting;
const pumpCamapsBluetoothTurnedOffMode = pumpCamapsAttempting;
const pumpCamapsUnableToProceedMode = pumpCamapsAttempting;
const pumpCamapsDepoweredMode = pumpCamapsAttempting;

exportProperties({
  pumpControliqAutomaticMode,
  pumpControliqSleepMode,
  pumpControliqExerciseMode,
  pumpControliqManualMode,
  pumpOp5ManualMode,
  pumpOp5AutomaticMode,
  pumpOp5LimitedMode,
  pumpOp5HypoprotectMode,
  pumpGenericAutomaticMode,
  pumpGenericManualMode,
  pumpBasaliqAutomaticMode,
  pumpBasaliqManualMode,
  pumpCamapsAutomaticMode,
  pumpCamapsManualMode,
  pumpCamapsBoostMode,
  pumpCamapsEaseOffMode,
  pumpCamapsNoCgmMode,
  pumpCamapsNoPumpConnectivityMode,
  pumpCamapsPumpDeliverySuspendedMode,
  pumpCamapsExtendedBolusNotAllowedMode,
  pumpCamapsDailyTotalInsulinExceededMode,
  pumpCamapsBluetoothTurnedOffMode,
  pumpCamapsUnableToProceedMode,
  pumpCamapsDepoweredMode,
});

const basalBar = {
  type: 'area',
  step: 'left',
  dataGrouping: {
    enabled: false,
  },
  fillOpacity: 1,
  trackByArea: true,
  stickyTracking: false,
  states: {
    hover: {
      enabled: false,
      halo: {
        size: 0,
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
};

const basalBarAutomated = {
  ...basalBar,
  color: colors.basalBarAutomated,
  zIndex: 10,
};

const basalBarAutomatedMax = {
  ...basalBar,
  lineWidth: 0,
  fillColor: {
    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
    stops: [
      [0, colors.basalBarAutomatedMax],
      [0.3, colors.basalBarAutomatedMax],
      [0.3, colors.basalBarAutomatedMaxGradient],
      [0.7, colors.basalBarAutomatedMaxGradient],
      [0.7, colors.basalBarAutomatedMax],
      [1, colors.basalBarAutomatedMax],
    ],
  },
  zIndex: 9,
};

const basalBarAutomatedSuspend = {
  ...basalBar,
  lineWidth: 0,
  fillColor: {
    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
    stops: [
      [0, colors.basalBarAutomatedPause],
      [0.3, colors.basalBarAutomatedPause],
      [0.3, colors.basalBarAutomatedPauseGradient],
      [0.7, colors.basalBarAutomatedPauseGradient],
      [0.7, colors.basalBarAutomatedPause],
      [1, colors.basalBarAutomatedPause],
    ],
  },
  zIndex: 8,
};

exportProperties({
  basalBarAutomated,
  basalBarAutomatedMax,
  basalBarAutomatedSuspend,
});

const events = {
  type: 'scatter',
  zIndex: 10,
  dataGrouping: {
    enabled: false,
  },
  dataLabels: {
    enabled: false,
  },
  lineWidth: 0,
  states: {
    hover: {
      enabled: false,
      lineWidth: 0,
      lineWidthPlus: 0,
      halo: {
        size: 0,
      },
    },
  },
  stickyTracking: false,
  yAxis: 1,
};

const pieChart = {
  type: 'pie',
  plotBackgroundColor: null,
  plotBorderWidth: null,
  plotShadow: false,
  dataLabels: {
    enabled: false,
  },
};

const columnChart = {
  type: 'column',
  plotBackgroundColor: null,
  plotBorderWidth: null,
  plotShadow: false,
};

exportProperties({
  pieChart,
  insulinPieChart: pieChart,
  bgPieChart: pieChart,
  systemModeChart: columnChart,
});

const setSiteChange = {
  ...events,
  marker: {
    width: 16 * 0.8, // original size * scale
    height: 14 * 0.8, // original size * scale
    symbol: `url(${images.SetChange})`,
  },
  stickyTracking: false,
};

const pumpAlarm = {
  ...events,
  marker: {
    width: 18 * 0.6, // original size * scale
    height: 16 * 0.6, // original size * scale
    symbol: `url(${images.pumpAlarm})`,
  },
};

const pumpAdvisoryAlert = {
  ...events,
  marker: {
    width: 18 * 0.6, // original size * scale
    height: 16 * 0.6, // original size * scale
    symbol: `url(${images.pumpAdvisoryAlert})`,
  },
};

const reservoirChange = {
  ...events,
  marker: {
    width: 15 * 0.8, // original size * scale
    height: 9 * 0.8, // original size * scale
    symbol: `url(${images.canulaRefil})`,
  },
};

exportProperties({ setSiteChange, pumpAlarm, pumpAdvisoryAlert, reservoirChange });

const bolus = {
  type: 'column',
  zIndex: 5,
  pointWidth: 4,
  dataGrouping: {
    enabled: false,
  },
  dataLabels: {
    formatter: Labels.bolus,
    allowOverlap: true,
  },
  enableMouseTracking: true,
  stickyTracking: false,
};

const overviewColumn = {
  type: 'column',
  dataGrouping: {
    enabled: false,
  },
  enableMouseTracking: true,
  stickyTracking: true,
  stacking: 'normal',
};

const gkInsulin = {
  ...bolus,
  color: colors.bolusInjection,
};

const gkInsulinOther = {
  ...bolus,
  color: colors.bolusInjection,
};

const gkInsulinBolus = {
  ...bolus,
  color: colors.bolus,
};

const gkInsulinBasal = {
  ...bolus,
  color: colors.basal,
};

const gkInsulinPremixed = {
  ...bolus,
  color: colors.insulinPremixed,
};

const deliveredBolus = {
  ...bolus,
  color: colors.bolus,
  zIndex: 10,
};

const injectionBolus = {
  ...bolus,
  color: colors.bolusInjection,
};

const interruptedBolus = {
  ...bolus,
  type: 'scatter',
  zIndex: 12,
  marker: {
    symbol: `url(${images.interruptedMarker})`,
    width: 4,
    height: 2,
  },
};

const overrideBelowBolus = {
  ...bolus,
  type: 'scatter',
  marker: {
    symbol: `url(${images.overrideBelowBolusMarker})`,
    width: 4,
    height: 2,
  },
  zIndex: 12,
};

const overrideAboveBolus = {
  ...overrideBelowBolus,
  type: 'scatter',
  marker: {
    symbol: `url(${images.overrideAboveBolusMarker})`,
    width: 4,
    height: 2,
  },
  zIndex: 12,
};

const suggestedBolus = {
  ...bolus,
  color: colors.bolusSuggested,
  zIndex: 9,
  pointWidth: 4,
};

const calculatedBolus = {
  ...bolus,
  color: '#E2E4E7',
  borderWidth: 1,
  borderColor: '#16262E9E',
  zIndex: bolus.zIndex - 1,
};

const automaticBolus = {
  ...deliveredBolus,
  pointWidth: 4,
  borderWidth: 1,
  borderColor: 'black',
};

const extendedBolusStep = {
  type: 'area',
  step: 'left',
  dataGrouping: {
    enabled: false,
  },
  dataLabels: {
    formatter: Labels.bolus,
    allowOverlap: false,
  },
  lineWidth: 1,
  color: colors.bolus,
  fillOpacity: 0.14,
  trackByArea: true,
  stickyTracking: false,
  states: {
    hover: {
      enabled: false,
      halo: {
        size: 0,
      },
    },
  },
};

const gkInsulin12 = {
  ...gkInsulin,
  pointWidth: 12,
};

const gkInsulinBolus12 = {
  ...gkInsulinBolus,
  pointWidth: 12,
};

const gkInsulinBasal12 = {
  ...gkInsulinBasal,
  pointWidth: 12,
};

const gkInsulinPremixed12 = {
  ...gkInsulinPremixed,
  pointWidth: 12,
};

const gkInsulinOther12 = {
  ...gkInsulinOther,
  pointWidth: 12,
};

const deliveredBolus12 = {
  ...deliveredBolus,
  pointWidth: 12,
};

const automaticBolus12 = {
  ...deliveredBolus,
  pointWidth: 12,
  borderWidth: 1,
  borderColor: 'black',
};

const injectionBolus12 = {
  ...injectionBolus,
  pointWidth: 12,
};

const interruptedBolus12 = {
  ...interruptedBolus,
  marker: {
    symbol: `url(${images.interruptedMarker})`,
    width: 12,
    height: 2,
  },
  dataLabels: {
    enabled: false,
  },
};

const overrideBelowBolus12 = {
  ...overrideBelowBolus,
  marker: {
    symbol: `url(${images.overrideBelowBolusMarker})`,
    width: 12,
    height: 2,
  },
  dataLabels: {
    enabled: false,
  },
};

const overrideAboveBolus12 = {
  ...overrideAboveBolus,
  marker: {
    symbol: `url(${images.overrideAboveBolusMarker})`,
    width: 12,
    height: 2,
  },
  dataLabels: {
    enabled: false,
  },
};

const suggestedBolus12 = {
  ...suggestedBolus,
  pointWidth: 12,
  dataLabels: {
    enabled: false,
  },
};

const calculatedBolus12 = {
  ...calculatedBolus,
  pointWidth: 12,
};

const extendedBolusStep12 = {
  ...extendedBolusStep,
  pointWidth: 12,
  lineWidth: 1.5,
};

exportProperties({
  gkInsulin,
  deliveredBolus,
  injectionBolus,
  interruptedBolus,
  overrideBelowBolus,
  overrideAboveBolus,
  suggestedBolus,
  automaticBolus,
  extendedBolusStep,
  gkInsulin12,
  deliveredBolus12,
  automaticBolus12,
  injectionBolus12,
  interruptedBolus12,
  overrideBelowBolus12,
  overrideAboveBolus12,
  suggestedBolus12,
  extendedBolusStep12,
  gkInsulinBolus,
  gkInsulinBasal,
  gkInsulinPremixed,
  gkInsulinOther,
  gkInsulinBasal12,
  gkInsulinBolus12,
  gkInsulinPremixed12,
  gkInsulinOther12,
  calculatedBolus,
  calculatedBolus12,
});

const carbAll = {
  ...readings,
  dataGrouping: {
    enabled: false,
  },
  name: 'carbAll',
  color: colors.carbsSeriesTransparent7,
  dataLabels: {
    formatter: Labels.carbAllDot,
    verticalAlign: 'bottom',
  },
};

const inline = deepMerge(carbAll,
  {
    dataLabels: {
      useHTML: true,
      formatter: Labels.overviewInlineDot,
    },
    marker: {
      enabled: false,
    },
  });

const carbAllInline = inline;
const insulinAllInlinePump = inline;
const insulinAllInlineManual = inline;
const exerciseInline = deepMerge(carbAll, {
  dataLabels: {
    useHTML: true,
    formatter: Labels.exerciseInline,
  },
  marker: {
    enabled: false,
  },
});
const stepsInline = exerciseInline;

const carbAll12 = deepMerge(carbAll, {
  dataLabels: {
    formatter: Labels.carbAll12,
    useHTML: true,
    overflow: 'none',
    crop: false,
    enabled: true,
  },
  marker: {
    radius: 1,
  },
  name: 'carbAll12',
  color: colors.white,
});

exportProperties({
  carbAll,
  carbAll12,
}, { generateName: false });

exportProperties({
  carbAllInline,
  insulinAllInlinePump,
  insulinAllInlineManual,
  exerciseInline,
  stepsInline,
});

[
  {
    name: 'carbAllColumn',
    props: { color: colors.carbsSeries },
  }, {
    name: 'gkInsulinColumn',
    props: { color: colors.bolusInjection },
  }, {
    name: 'gkInsulinBolusColumn',
    props: { color: colors.bolus },
  }, {
    name: 'gkInsulinBasalColumn',
    props: { color: colors.basal },
  }, {
    name: 'gkInsulinPremixedColumn',
    props: { color: colors.insulinPremixed },
  }, {
    name: 'gkInsulinOtherColumn',
    props: {
      color: colors.bolusInjection,
      dataLabels: {
        enabled: false,
      },
    },
  }, {
    name: 'insulinBolusColumn',
    props: { color: colors.bolus },
  }, {
    name: 'insulinBasalColumn',
    props: { color: colors.basal },
  }, {
    name: 'insulinPremixedColumn',
    props: { color: colors.insulinPremixed },
  }, {
    name: 'stepsColumn',
    props: { color: colors.exerciseSeries },
  }, {
    name: 'exerciseColumn',
    props: { color: colors.exerciseSeries },
  },
].forEach((graphType) => {
  exportProperties({
    [`${graphType.name}NoPointWidth`]: {
      ...overviewColumn,
      ...graphType.props,
    },
  });
});

const exerciseColumnHours = {
  ...overviewColumn,
  color: colors.exerciseSeries,
  pointWidth: 12,
};

const emptySeries = {
  ...readings,
};

exportProperties({ emptySeries, exerciseColumnHours });

const agp = {
  states: {
    hover: {
      enabled: false,
    },
  },
};

const boxPlotColumns = {
  type: 'columnrange',
  stacking: 'normal',
  pointWidth: OverlayWebConstants.DAYS_WIDTH / 24,
  minPointLength: 1,
};

const agpBoxPlotTooltips = {
  ...boxPlotColumns,
  color: colors.transparent,
  borderColor: colors.transparent,
  states: {
    hover: {
      color: colors.whiteFifty,
    },
  },
  tooltip: {
    shape: 'square',
  },
  zIndex: 16,
};

const overviewTooltips = deepMerge(agpBoxPlotTooltips, {
  states: {
    hover: {
      borderColor: 'blue',
      borderWidth: 2,
    },
  },
});
exportProperties({ agpBoxPlotTooltips, overviewTooltips });

const bgAverage = {
  type: 'spline',
  states: {
    hover: {
      enabled: false,
    },
  },
  marker: {
    enabled: false,
  },
  zIndex: 15,
  color: colors.linkBlueDarker,
  enableMouseTracking: false,
};

const bgAveragePdfFixed = {
  ...bgAverage,
  color: colors.black,
};

exportProperties({ bgAverage, bgAveragePdfFixed });

const dailyAverage = {
  type: 'spline',
  states: {
    hover: {
      enabled: false,
    },
  },
  marker: {
    enabled: false,
  },
  zIndex: 15,
  lineWidth: 1,
  color: colors.bgBeforeMaxPDF,
};

exportProperties({
  bgDailyAverage: dailyAverage,
  cgmDailyAverage: dailyAverage,
});

exportProperties(
  WEEKDAYS.reduce((accumulator, weekday) => ({
    ...accumulator,
    [`bgAverage${weekday}`]: bgAverage,
  }), {}),
);

const agpMedian = {
  ...agp,
  type: 'spline',
  marker: {
    enabled: false,
  },
  zIndex: 15,
  enableMouseTracking: false,
  color: colors.graphsMedian,
};

['bg', 'cgm'].forEach((type) => {
  exportProperties(
    [...WEEKDAYS, ''].reduce((accumulator, weekday) => ({
      ...accumulator,
      [`${type}AgpMedian${weekday}`]: agpMedian,
    }), {}),
  );
});

['bg', 'cgm'].forEach((type) => {
  [
    { name: '25To75', props: { color: colors.fontRegularTransparent5 } },
    { name: '10To90', props: { color: colors.backgroundGreyTransparent35 } },
  ].forEach((option) => {
    exportProperties({
      [`${type}DailyPercentile${option.name}`]: {
        type: 'columnrange',
        stacking: 'normal',
        minPointLength: 1,
        ...option.props,
      },
    });
  });
});

const bgAgpPercentilesTenthToNinetieth = {
  ...agp,
  ...boxPlotColumns,
  zIndex: 7,
  color: colors.agp1090,
};

const bgAgpPercentilesTenthToNinetiethWeekday = {
  ...bgAgpPercentilesTenthToNinetieth,
  pointWidth: bgAgpPercentilesTenthToNinetieth.pointWidth / 2,
};

const cgmAgpPercentilesTenthToNinetieth = {
  ...agp,
  type: 'areasplinerange',
  zIndex: 7,
  color: colors.agp1090,
  marker: {
    enabled: false,
  },
};

const bgAgpPercentilesTwentyFifthToSeventyFifth = {
  ...agp,
  ...boxPlotColumns,
  zIndex: 8,
  color: colors.agp2575,
};

const bgAgpPercentilesTwentyFifthToSeventyFifthWeekday = {
  ...bgAgpPercentilesTwentyFifthToSeventyFifth,
  pointWidth: bgAgpPercentilesTwentyFifthToSeventyFifth.pointWidth / 2,
};

const cgmAgpPercentilesTwentyFifthToSeventyFifth = {
  ...agp,
  type: 'areasplinerange',
  zIndex: 8,
  color: colors.agp2575,
  marker: {
    enabled: false,
  },
};

exportProperties({
  bgAgpPercentilesTenthToNinetieth,
  cgmAgpPercentilesTenthToNinetieth,
  bgAgpPercentilesTwentyFifthToSeventyFifth,
  cgmAgpPercentilesTwentyFifthToSeventyFifth,
});

const bgMinMax = {
  ...agp,
  color: colors.graphsMedian,
  borderColor: colors.graphsMedian,
  type: 'line',
  dashStyle: 'Dash',
  lineWidth: 1,
  zIndex: 8,
  marker: {
    radius: 0,
  },
};

const cgmMinMax = {
  ...agp,
  type: 'spline',
  zIndex: 8,
  color: colors.graphsMedian,
  marker: { enabled: false },
  dashStyle: 'Dash',
  lineWidth: 1,
};

exportProperties({
  bgAgpMaximum: bgMinMax,
  bgAgpMinimum: bgMinMax,
  cgmAgpMaximum: cgmMinMax,
  cgmAgpMinimum: cgmMinMax,
});

exportProperties(
  WEEKDAYS.reduce((accumulator, weekday) => ({
    ...accumulator,
    [`bgAgpPercentilesTenthToNinetieth${weekday}`]: bgAgpPercentilesTenthToNinetiethWeekday,
    [`cgmAgpPercentilesTenthToNinetieth${weekday}`]: cgmAgpPercentilesTenthToNinetieth,
    [`bgAgpPercentilesTwentyFifthToSeventyFifth${weekday}`]: bgAgpPercentilesTwentyFifthToSeventyFifthWeekday,
    [`cgmAgpPercentilesTwentyFifthToSeventyFifth${weekday}`]: cgmAgpPercentilesTwentyFifthToSeventyFifth,
    [`bgAgpMaximum${weekday}`]: bgMinMax,
    [`bgAgpMinimum${weekday}`]: bgMinMax,
    [`cgmAgpMaximum${weekday}`]: cgmMinMax,
    [`cgmAgpMinimum${weekday}`]: cgmMinMax,
  }), {}),
);

exportProperties(Object.entries({
  agpBoxPlotTooltips,
  bgAgpPercentilesTenthToNinetieth,
  bgAgpPercentilesTwentyFifthToSeventyFifth,
  bgMinMax }).reduce((accumulator, option) => ({
  ...accumulator,
  [`${option[0]}PdfFixed`]: {
    ...option[1],
    pointWidth: OverlayPdfConstants.DAYS_WIDTH / 24,
  },
}), {}));

exportProperties({ insulinPieChart: pieChart });

export default ALL_EXPORTS;
