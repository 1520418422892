export const IGLUCOSE_DEVICES_FETCH_SUCCESS = 'IGLUCOSE_DEVICES_FETCH_SUCCESS';
export const IGLUCOSE_DEVICES_HAS_ERRORED = 'IGLUCOSE_DEVICES_HAS_ERRORED';
export const IGLUCOSE_DEVICES_IS_LOADING = 'IGLUCOSE_DEVICES_IS_LOADING';
export const REMOVE_IGLUCOSE_DEVICE_SUCCESS = 'REMOVE_IGLUCOSE_DEVICE';
export const REMOVE_IGLUCOSE_DEVICE_HAS_ERRORED = 'REMOVE_IGLUCOSE_DEVICE_HAS_ERRORED';
export const CLOSE_REMOVE_DEVICE_ERROR = 'CLOSE_REMOVE_DEVICE_ERROR';

export function iglucoseDevicesHasErrored(bool) {
  return { type: IGLUCOSE_DEVICES_HAS_ERRORED, hasErrored: bool };
}

export function iglucoseDevicesIsLoading(bool) {
  return { type: IGLUCOSE_DEVICES_IS_LOADING, isLoading: bool };
}

export function iglucoseDevicesFetchSuccess(iglucoseDevices) {
  return { type: IGLUCOSE_DEVICES_FETCH_SUCCESS, iglucoseDevices };
}

export function removeIglucoseDeviceSuccess(serialNumber) {
  return { type: REMOVE_IGLUCOSE_DEVICE_SUCCESS, serialNumber };
}

export function removeIglucoseDeviceHasErrored() {
  return { type: REMOVE_IGLUCOSE_DEVICE_HAS_ERRORED };
}

export function closeRemoveDeviceError() {
  return { type: CLOSE_REMOVE_DEVICE_ERROR };
}

const initialState = {
  connectedDevices: [],
  isLoading: false,
  hasErrored: false,
  isErrorModalOpen: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case IGLUCOSE_DEVICES_FETCH_SUCCESS:
      return Object.assign(
        {},
        state,
        { connectedDevices: action.iglucoseDevices },
      );
    case IGLUCOSE_DEVICES_IS_LOADING:
      return Object.assign(
        {}, state, { isLoading: action.isLoading },
      );
    case IGLUCOSE_DEVICES_HAS_ERRORED:
      return Object.assign(
        {}, state, { hasErrored: action.hasErrored },
      );
    case REMOVE_IGLUCOSE_DEVICE_SUCCESS: {
      const tempDevices = [];
      for (let i = 0; i < state.connectedDevices.length; i += 1) {
        const device = state.connectedDevices[i];
        if (device.serialNumber !== action.serialNumber) {
          tempDevices.push(state.connectedDevices[i]);
        }
      }
      return Object.assign(
        {}, state, { connectedDevices: tempDevices },
      );
    }
    case REMOVE_IGLUCOSE_DEVICE_HAS_ERRORED: {
      return Object.assign(
        {}, state, { isErrorModalOpen: true },
      );
    }
    case CLOSE_REMOVE_DEVICE_ERROR: {
      return Object.assign(
        {}, state, { isErrorModalOpen: false },
      );
    }

    default:
      return state;
  }
}
