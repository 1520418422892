import ExecuteApiCallTerminals from './terminalSyncsApiClient';
import { ExecuteUnchangedApiCall } from './apiClient';

export function fetchTerminalSyncs(params) {
  return ExecuteApiCallTerminals(
    'get',
    'api/v2/provider_group_site/terminal_syncs/view/terminal_syncs',
    params,
  );
}

export function validatePatientDob(params) {
  return ExecuteUnchangedApiCall(
    'get',
    'api/v2/provider_group_site/users/validate_dob',
    params,
  );
}

export function assignToPatient(params) {
  return ExecuteUnchangedApiCall(
    'post',
    'api/v2/provider_group_site/terminal_syncs/assign/update',
    params,
  );
}

export function unassignTerminalSync(params) {
  return ExecuteUnchangedApiCall(
    'delete',
    'api/v2/provider_group_site/terminal_syncs/unassign',
    params,
  );
}
