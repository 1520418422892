import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AxisPresenter from '../../AxisPresenter/AxisPresenter';
import Style from '../../CalendarTabContainer/CalendarGraphRow/CalendarGraphRow.scss';

export default class OverviewGraphRow extends React.Component {
  render() {
    const {
      axisStartIndex, axisEndIndex,
      axisTickStep, axisTitle, axisSubtitle,
      windowHeight, axisWidth, axisHide,
      additionalPaddingTop,
    } = this.props;

    return (
      <div className={Style.CalendarGraphRow}>
        <AxisPresenter
          startIndex={axisStartIndex}
          endIndex={axisEndIndex}
          tickStep={axisTickStep}
          windowHeight={windowHeight}
          title={axisTitle}
          subtitle={axisSubtitle}
          windowWidth={axisWidth}
          hideAxis={axisHide}
          additionalPaddingTop={additionalPaddingTop}
        />
        <div class={classNames({
          [Style.CalendarGraphRow_inline]: true,
          [Style.borderLeft]: true,
        })}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

OverviewGraphRow.propTypes = {
  axisStartIndex: PropTypes.number.isRequired,
  axisEndIndex: PropTypes.number.isRequired,
  axisTickStep: PropTypes.number.isRequired,
  axisTitle: PropTypes.string.isRequired,
  axisWidth: PropTypes.number.isRequired,
  axisSubtitle: PropTypes.string.isRequired,
  axisHide: PropTypes.bool.isRequired,
  windowHeight: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ])),
    PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
  ]),
  additionalPaddingTop: PropTypes.number.isRequired,
};

OverviewGraphRow.defaultProps = {
  axisSubtitle: '',
  axisHide: false,
  widthLarger: false,
  children: {},
  inPdf: false,
  additionalPaddingTop: 0,
};
