import { combineReducers } from 'redux';
import audit from './audit/audit';
import cloudIntegrations from './cloudIntegrations/cloudIntegrations';
import countries from './countries/countries';
import favorites from './pdfFavorites/pdfFavorites';
import graphs from './graphs/graphs';
import messageDialog from './messageDialog';
import mids from './mids/mids';
import omnipod from './omnipod/omnipod';
import op5Confirm from './op5Confirm/op5Confirm';
import page from './page/page';
import pdf from './pdf/pdf';
import providerGroupSite from './providerGroupSite/providerGroupSite';
import statistics from './statistics/statistics';
import users from './users/users';
import history from './history/history';
import syncOptions from './syncOptions/syncOptions';
import searchParams from './searchParams/searchParams';
import thirdPartyAppsAccess from './thirdPartyApps/thirdPartyApps';
import devices from './deviceSettings/devices';
import devicesAndSettings from './devicesAndSettings/devicesAndSettings';
import pumpSettings from './deviceSettings/pumpSettings';
import partnerIntegrations from './partnerIntegrations/partnerIntegrations';
import categories from './bloodPressure/categories';

const combinedReducers = combineReducers({
  categories,
  audit,
  cloudIntegrations,
  countries,
  favorites,
  graphs,
  messageDialog,
  mids,
  omnipod,
  op5Confirm,
  page,
  pdf,
  providerGroupSite,
  statistics,
  users,
  history,
  syncOptions,
  searchParams,
  thirdPartyAppsAccess,
  devices,
  pumpSettings,
  devicesAndSettings,
  partnerIntegrations,
});

export default combinedReducers;
