import { FETCH_STATUS_NOT_CALLED, FETCH_STATUS_SUCCESSFUL } from
  '~/bundles/shared/constants/graphs';

export const UPDATE_PATTERNS = 'UPDATE_PATTERNS';
export const UPDATE_PATTERNS_STATUS = 'UPDATE_PATTERNS_STATUS';

export function updatePatterns(stats, params) {
  return { type: UPDATE_PATTERNS, stats, params };
}

export function updatePatternsStatus(status) {
  return { type: UPDATE_PATTERNS_STATUS, status };
}

const initialState = {
  status: FETCH_STATUS_NOT_CALLED,
  summaryStatements: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_PATTERNS:
      return {
        ...state,
        ...action.stats,
        status: FETCH_STATUS_SUCCESSFUL,
        params: action.params,
      };
    case UPDATE_PATTERNS_STATUS:
      return { ...state, status: action.status };
    default:
      return state;
  }
}
