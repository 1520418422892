import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import combinedReducers from '../modules/combinedReducers';
import graphSelectionsListener from '../listeners/graphSelectionsListener';

let middleware = [thunk];

// eslint-disable-next-line no-process-env
if (process.env.NODE_ENV === 'development') {
  middleware = [...middleware, createLogger(), reduxImmutableStateInvariant()];
}

if (!window.glookoStore) {
  window.glookoStore =
    composeWithDevTools(applyMiddleware(...middleware))(createStore)(combinedReducers);
  window.glookoStore.subscribe(() => graphSelectionsListener(window.glookoStore.getState.bind(window.glookoStore)));
}

export default window.glookoStore;
