import { groupDataByTimeInterval } from '~/bundles/shared/helpers/series';
import { base, readingTypesBaseSwitch } from '../../utils/baseLegendConfig';

export const multipleDaysMergedConfig = (daysData) => {
  const mergedConfig = {};

  const mergeConfig = (config) => {
    Object.keys(config).forEach((groupKey) => {
      if (!mergedConfig[groupKey]) mergedConfig[groupKey] = config[groupKey];
      else {
        Object.keys(config[groupKey]).forEach((itemKey) => {
          mergedConfig[groupKey][itemKey] = mergedConfig[groupKey][itemKey] ||
            config[groupKey][itemKey];
        });
      }
    });
  };

  daysData.forEach((data) => {
    mergeConfig(hoursLegendConfig(data));
  });

  return mergedConfig;
};

const controlIqSystemModes = (series) => base(series, 'pumpModeSeries', (mode, dataExists, config) => {
  switch (mode.name) {
    case 'pumpControliqAutomaticMode':
      config.auto = dataExists;
      break;
    case 'pumpControliqExerciseMode':
      config.exercise = dataExists;
      break;
    case 'pumpControliqSleepMode':
      config.sleep = dataExists;
      break;
    case 'pumpControliqManualMode':
      config.manual = dataExists;
      break;
  }

  return config;
});

const basalIqSystemModes = (series) => base(series, 'pumpModeBasaliqSeries', (mode, dataExists, config) => {
  switch (mode.name) {
    case 'pumpBasaliqAutomaticMode':
      config.auto = dataExists;
      break;
    case 'pumpBasaliqManualMode':
      config.manual = dataExists;
      break;
  }
  return config;
});

const genericSystemModes = (series) => base(series, 'pumpModeGenericSeries', (mode, dataExists, config) => {
  switch (mode.name) {
    case 'pumpGenericAutomaticMode':
      config.auto = dataExists;
      break;
    case 'pumpGenericManualMode':
      config.manual = dataExists;
      break;
  }

  return config;
});

const op5SystemModes = (series) => base(series, 'pumpModeOp5Series', (mode, dataExists, config) => {
  switch (mode.name) {
    case 'pumpOp5AutomaticMode':
      config.auto = dataExists;
      break;
    case 'pumpOp5ManualMode':
      config.manual = dataExists;
      break;
    case 'pumpOp5LimitedMode':
      config.limited = dataExists;
      break;
    case 'pumpOp5HypoprotectMode':
      config.activity = dataExists;
      break;
  }

  return config;
});

const camApsSystemModes = (series) => base(series, 'pumpModeCamApsSeries', (mode, dataExists, config) => {
  switch (mode.name) {
    case 'pumpCamapsAutomaticMode':
      config.auto = dataExists;
      break;
    case 'pumpCamapsManualMode':
      config.manual = dataExists;
      break;
    case 'pumpCamapsBoostMode':
      config.boost = dataExists;
      break;
    case 'pumpCamapsEaseOffMode':
      config.easeOff = dataExists;
      break;
    case 'pumpCamapsNoCgmMode':
    case 'pumpCamapsNoPumpConnectivityMode':
    case 'pumpCamapsPumpDeliverySuspendedMode':
    case 'pumpCamapsExtendedBolusNotAllowedMode':
    case 'pumpCamapsDailyTotalInsulinExceededMode':
    case 'pumpCamapsBluetoothTurnedOffMode':
    case 'pumpCamapsUnableToProceedMode':
    case 'pumpCamapsDepoweredMode':
      config.attempting = config.attempting || dataExists;
      break;
  }

  return config;
});

const glucose = (series, forceDisplay = true) => base(series, 'bgGraphSeries', (data, dataExists, config) => {
  switch (data.name) {
    case 'bgHigh':
    case 'bgHighManual':
    case 'bgAbove400':
    case 'bgAbove400Manual':
    case 'cgmHigh':
    case 'cgmCalibrationHigh':
      config.above = config.above || dataExists;
      break;
    case 'bgNormal':
    case 'bgNormalManual':
    case 'cgmNormal':
    case 'cgmCalibrationNormal':
      config.normal = config.normal || dataExists;
      break;
    case 'bgLow':
    case 'bgLowManual':
    case 'cgmLow':
    case 'cgmCalibrationLow':
      config.low = config.low || dataExists;
      break;
  }

  return config;
}, forceDisplay);

const carbs = (series) => base(series, 'carbsSeries', (data, dataExists, config) => {
  config.carbs = false;
  config.carbsMultiple = false;
  if (data.name !== 'carbAll12' || !dataExists) return config;

  const grouped = groupDataByTimeInterval(data.data, 30, 'minutes');
  Object.values(grouped).forEach((group) => {
    const key = group.length > 1 ? 'carbsMultiple' : 'carbs';
    config[key] = true;
  });

  return config;
});

const exercise = (series) => {
  const config = { display: false };

  if (!series) return config;
  config.exercise = series['exerciseSeries'].length > 0;
  config.display = config.exercise;

  return config;
};

const bgCgmReading = (series) => base(
  series,
  'bgGraphSeries',
  (data, dataExists, config) => readingTypesBaseSwitch(data, dataExists, config),
);

const readingTypes = (series) => {
  const glucoseConfig = glucose(series, false);
  const bgCgmReadingConfig = bgCgmReading(series);
  const carbsConfig = carbs(series);
  const exerciseConfig = exercise(series);

  const display = !!(
    glucoseConfig.display ||
    bgCgmReadingConfig.display ||
    carbsConfig.display ||
    exerciseConfig.display
  );

  return {
    ...glucoseConfig,
    ...bgCgmReadingConfig,
    ...carbsConfig,
    ...exerciseConfig,
    display,
  };
};

const insulinDose = (series) => base(series, 'insulinManualAndPumpSeries', (data, dataExists, config) => {
  switch (data.name) {
    case 'deliveredBolus12':
      data.data.every((e) => {
        if (config.deliveredBolus && config.undeliveredBolus) return false;

        const undelivered = e.totalInsulinRecommendation > 0 && e.insulinDelivered === 0;

        if (undelivered) config.undeliveredBolus = true;
        else config.deliveredBolus = true;

        return true;
      });
      config.hasPump = config.hasPump || dataExists;
      break;
    case 'gkInsulinBolus12':
      config.deliveredBolus = config.deliveredBolus || dataExists;
      break;
    case 'extendedBolusStep12':
      data.data.every((e) => {
        if (config.extendedBolus && config.deliveredBolus) return false;

        config.extendedBolus = config.extendedBolus || !!e.extendedBolusDuration;
        config.deliveredBolus = config.deliveredBolus || !!e.initialDelivery;

        return true;
      });
      config.hasPump = config.hasPump || dataExists;
      break;
    case 'injectionBolus12':
      config.custom = config.custom || dataExists;
      config.hasPump = config.hasPump || dataExists;
      break;
    case 'gkInsulinOther12':
      config.custom = config.custom || dataExists;
      break;
    case 'interruptedBolus12':
      config.suspended = dataExists;
      config.hasPump = config.hasPump || config.suspended;
      break;
    case 'automaticBolus12':
      config.automaticBolus = dataExists;
      config.hasPump = config.hasPump || config.automaticBolus;
      break;
    case 'overrideAboveBolus12':
    case 'overrideBelowBolus12':
      config.overrideBolus = config.overrideBolus || dataExists;
      config.hasPump = config.hasPump || config.overrideBolus;
      break;
    case 'gkInsulinPremixed12':
      config.premixed = dataExists;
      break;
    case 'gkInsulinBasal12':
      config.basal = dataExists;
      break;
    case 'calculatedBolus12':
      config.calculatedBolus = dataExists;
      break;
  }

  return config;
});

const basal = (series) => base(series, 'basalSeries', (data, dataExists, config) => {
  switch (data.name) {
    case 'suspendBasal':
      config.suspend = dataExists;
      break;
    case 'lgsPlgs':
      config.lgsPlgs = dataExists;
      break;
    case 'basalModulation':
      config.basalModulation = dataExists;
      break;
    case 'reservoirChange':
      config.reservoirChange = dataExists;
      break;
    case 'setSiteChange':
      config.setSiteChange = dataExists;
      break;
    case 'pumpAlarm':
      config.pumpAlarm = dataExists;
      break;
    case 'pumpAdvisoryAlert':
      config.pumpAdvisoryAlert = dataExists;
      break;
    case 'temporaryBasal12':
      config.tempBasalRate = dataExists;
      break;
    case 'scheduledBasal12':
      config.scheduledBasal = dataExists;
      break;
    case 'unusedScheduledBasal12':
      config.unusedScheduledBasal = dataExists;
      break;
  }

  return config;
});

const basalOp5 = (series) => base(series, 'basalBarSeries', (data, dataExists, config) => {
  switch (data.name) {
    case 'basalBarAutomated':
      config.auto = dataExists;
      break;
    case 'basalBarAutomatedMax':
      config.autoMax = dataExists;
      break;
    case 'basalBarAutomatedSuspend':
      config.autoPause = dataExists;
      break;
  }

  return config;
});

const insulin = (insulinDoseConfig, series) => {
  if (insulinDoseConfig.display) return { display: false };

  return base(series, 'insulinManualSeries', (data, dataExists, config) => {
    switch (data.name) {
      case 'gkInsulinBolus12':
        config.bolus = dataExists;
        break;
      case 'gkInsulinBasal12':
        config.basal = dataExists;
        break;
      case 'gkInsulinPremixed12':
        config.premixed = dataExists;
        break;
      case 'gkInsulinOther12':
        config.custom = dataExists;
        break;
      case 'calculatedBolus12':
        config.calculatedBolus = dataExists;
        break;
    }

    return config;
  });
};

const hoursLegendConfig = (series) => {
  const insulinDoseConfig = insulinDose(series);
  insulinDoseConfig.display = !!insulinDoseConfig.hasPump;
  delete insulinDoseConfig.hasPump;

  return {
    controlIqSystemModes: controlIqSystemModes(series),
    basalIqSystemModes: basalIqSystemModes(series),
    genericSystemModes: genericSystemModes(series),
    op5SystemModes: op5SystemModes(series),
    camApsSystemModes: camApsSystemModes(series),
    glucose: glucose(series),
    readingTypes: readingTypes(series),
    insulinDose: insulinDoseConfig,
    basal: basal(series),
    basalOp5: basalOp5(series),
    insulin: insulin(insulinDoseConfig, series),
  };
};

export default hoursLegendConfig;
