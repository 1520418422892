import React, { useState } from 'react';
import { connect } from 'react-redux';
import UserHelper from '~/redux/modules/users/UserHelper';
import { useReferenceDeviceList } from '~/bundles/shared/hooks/useReferenceDeviceList';
import SupportLinks from '~/utils/support';
import Images from '~/utils/ImageStore';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate';
import FileUploaderPopup from '~/bundles/shared/components/FileUploaderPopup/FileUploaderPopup';

import ButtonStyle from '../IntegrationButton/IntegrationButton.scss';
import { trackSelectedAppsDevices } from '../../../../../services/eventLogging';

const mapStateToProps = (state) => {
  const user = UserHelper.displayUser(state);
  const countryOfResidence = user.countryOfResidence;
  return { countryOfResidence };
};

const MedtronicCsvButton = (props) => {
  const { t, countryOfResidence } = props;
  const [showPopup, setShowPopup] = useState(false);
  const deviceName = 'Medtronic';

  const { data, error } = useReferenceDeviceList();

  const startStep = (
    <ol>
      <li>{t('startStepLine11Medtronic')}{' '}
        <a
          href={SupportLinks.article('27404421584147')}
          target="_blank"
        >{t('startStepLine12')}
        </a>
      </li>
      <li>
        {t('startStepLine21')}{' '}
        <strong>{t('startStepLine22')}</strong>{' '}
        {t('startStepLine23')}
      </li>
      <li>{t('startStepLine3')}</li>
      <li>
        {t('startStepLine41')}{' '}
        <strong>{t('startStepLine42')}</strong>{' '}
        {t('startStepLine43')}{' '}
        <strong>{t('startStepLine44')}</strong>{' '}
        {t('startStepLine45')}
      </li>
    </ol>
  );

  const openPopup = () => setShowPopup(true);
  const closePopup = () => setShowPopup(false);
  const onClickIntegration = () => {
    trackSelectedAppsDevices(deviceName);
    openPopup();
  };

  const device = data?.devices.find((device) => device.id === 'MEDTRONIC_CSV');
  const showButton = device?.supportedCountries.includes(countryOfResidence);

  if (!showButton || error) {
    return null;
  }

  return (
    <>
      {showPopup && (
        <FileUploaderPopup
          closePopup={closePopup}
          acceptedExtensions={['.csv']}
          extractorName="MedtronicCSV"
          startTitle={t('uploadMedtronicDataTitle')}
          startStep={startStep}
          showConfirm={true}
        />
      )}
      <div
        className={ButtonStyle.IntegrationButton}
        role="button"
        tabIndex={0}
        onClick={onClickIntegration}
      >
        <div className={ButtonStyle.content}>
          <img className={ButtonStyle.image} src={Images.integrationLogoMedtronic} alt="integration" />
          <div className={ButtonStyle.name}>
            <span>{t('uploadMedtronicDataButton')}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps, null)(translate('AbbottCSVButton')(MedtronicCsvButton));
