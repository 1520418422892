export const displayOrderOfCreateAccountFields = [
  'firstName',
  'lastName',
  'dateOfBirth',
  'typeOfDiabetes',
  'zipCode',
];

export const displayOrderOfMedicalInfoFields = [
  'medicalRecordNumber',
  'participantId',
  'gender',
  'careProgram',
  't1dExchangeIdentifier',
];

export const displayOrderOfInviteFields = [
  'email',
  'optOutEmailInfo',
  'reasonForEmailInfo',
  'phoneNumber',
  'optOutPhoneInfo',
  'reasonForPhoneInfo',
];

export const keysNameInParamsAndICPA = {
  mrn: 'medicalRecordNumber',
  phone: 'phoneNumber',
  diabetesType: 'typeOfDiabetes',
  address: 'zipCode',
};

export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export function isKeySetToNotDisplayed(icpaConfig, key) {
  return icpaConfig[key] === 'not_displayed';
}

export function isEmailFake(email) {
  return email.includes('@kiosk.fake');
}

function userDetailsHasRequiredICPAFieldValue(field, userDetails) {
  if (field === 'gender' && userDetails[field] === null) {
    return true;
  }
  if (field === 'email' && isEmailFake(userDetails[field])) {
    return false;
  } else if (
    userDetails[field] === undefined &&
    userDetails[getKeyByValue(keysNameInParamsAndICPA, field)]
  ) {
    if (getKeyByValue(keysNameInParamsAndICPA, field) === 'address') {
      return !!userDetails[getKeyByValue(keysNameInParamsAndICPA, field)].zipcode;
    }
    return true;
  } else if (userDetails[field]) return true;
  return false;
}
export function patientHasICPARequiredData(icpaConfig, userDetails) {
  const requiredFields = Object.keys(icpaConfig).filter((elem) => icpaConfig[elem] === 'required' && elem !== 'reasonForChange');
  const patientHasAllRequiredICPAData = requiredFields.every((field) => userDetailsHasRequiredICPAFieldValue(field, userDetails),
  );
  return patientHasAllRequiredICPAData;
}

export function returnNotDisplayedKeyFromParams(icpaConfig, paramKey) {
  const icpaKey = keysNameInParamsAndICPA[paramKey];
  const keyInRequestParams = getKeyByValue(keysNameInParamsAndICPA, icpaKey);
  if (paramKey === 'email') return null;
  if (
    icpaConfig[paramKey] === undefined &&
    keyInRequestParams &&
    isKeySetToNotDisplayed(icpaConfig, icpaKey)
  ) {
    return keyInRequestParams;
  } else if (isKeySetToNotDisplayed(icpaConfig, paramKey)) {
    return paramKey;
  }
  return null;
}
