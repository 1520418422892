import moment from 'moment';

const frenchOriginalWeekDaysMin = moment.localeData('fr-ca').weekdaysMin();

moment.updateLocale('fr-ca', {
  weekdaysMin: frenchOriginalWeekDaysMin.map((weekday) => weekday.toLowerCase()),
});

moment.localeData('es-US');
moment.updateLocale('es', {
  meridiem: function (hour, minute, isLowercase) {
    if (hour < 12) {
      return 'a.m.';
    } else {
      return 'p.m.';
    }
  },
});

/* Currently there is no way to get moment to not use arabic characters for dates,
   so overriding here
*/
export const useWesterArabicNumerals = () => {
  const symbolMap = {
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    '6': '6',
    '7': '7',
    '8': '8',
    '9': '9',
    '0': '0',
  };
  const numberMap = {
    '١': '1',
    '٢': '2',
    '٣': '3',
    '٤': '4',
    '٥': '5',
    '٦': '6',
    '٧': '7',
    '٨': '8',
    '٩': '9',
    '٠': '0',
  };
  moment.updateLocale('ar', {
    preparse: function (string) {
      return string.replace(/\u200f/g, '').replace(/[١٢٣٤٥٦٧٨٩٠]/g, (match) => numberMap[match]).replace(/،/g, ',');
    },
    postformat: function (string) {
      return string.replace(/\d/g, (match) => symbolMap[match]).replace(/,/g, '،');
    },
  });
};
useWesterArabicNumerals();
