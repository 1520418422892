// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".InfoDialog_title{font-weight:600}", "",{"version":3,"sources":["InfoDialog.scss"],"names":[],"mappings":"AAAA,kBACE,eAAA","file":"InfoDialog.scss","sourcesContent":[".title {\n  font-weight: 600;\n}\n"]}]);
// Exports
exports.locals = {
	"title": "InfoDialog_title"
};
module.exports = exports;
