import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import Select from 'react-select';
import PropTypes from 'prop-types';
import 'react-select/dist/react-select.css';
import Style from './DropdownDatepicker.scss';

class DropdownDatepicker extends React.Component {
  static propTypes = {
    dobUpdateCallback: PropTypes.func.isRequired,
    initialDateProps: PropTypes.shape({
      year: PropTypes.string.isRequired,
      month: PropTypes.string.isRequired,
      monthNum: PropTypes.string.isRequired,
      day: PropTypes.string.isRequired,
      dobFail: PropTypes.bool.isRequired,
    }),
    numericMonth: PropTypes.bool,
    validateOnEmptySelection: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    initialDateProps: {
      year: 'Year',
      month: 'Month',
      monthNum: '',
      day: 'Day',
      dobFail: false,
    },
    numericMonth: false,
    validateOnEmptySelection: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      year: this.props.initialDateProps.year,
      month: this.props.initialDateProps.month,
      monthNum: this.props.initialDateProps.monthNum,
      day: this.props.initialDateProps.day,
      yearSelected: false,
      daySelected: false,
      monthSelected: false,
      emptySelection: {
        day: false,
        month: false,
        year: false,
      },
    };
  }

  getValidDays = () => {
    const monthNum = this.state.monthNum;
    const year = this.state.year;
    if (monthNum !== '' && monthNum !== 'Invalid date') {
      const comparator = year !== 'Year' ?
        moment.utc(`${year}-${monthNum}`, 'YYYY-MM') :
        moment.utc(monthNum, 'MM');
      return this.extractDaysFromMonth(comparator);
    }
    return this.extractDaysFromMonth(moment.utc(1, 'MM'));
  }

  populateYearsDropdown = () => {
    const currentYear = moment().year();
    let startYear = 1899;
    const years = [];

    while (startYear < currentYear) {
      years.push({
        value: startYear += 1,
        label: startYear,
      });
    }
    return years;
  }

  populateMonthsDropdown = () => {
    const monthNames = moment.months();
    const months = [];
    let monthNumber = 0;
    monthNames.map((month) => {
      monthNumber += 1;
      const generatedValue = monthNumber.toString().padStart(2, '0');
      return months.push({
        value: generatedValue,
        label: this.props.numericMonth ? generatedValue : month,
      });
    });
    return months;
  }

  extractDaysFromMonth = (month) => {
    const days = Array.from(Array(month.daysInMonth() + 1).keys()).slice(1);
    const daysParsed = [];
    days.map((day) => daysParsed.push({
      value: day.toString().padStart(2, '0'),
      label: day.toString().padStart(2, '0'),
    }));
    return daysParsed;
  }

  updateDaySelection = () => {
    const daysValid = this.getValidDays();
    const daysValidLast = daysValid.slice(-1)[0].value;
    let currentDay = this.state.day;

    if (currentDay !== 'Day') {
      if (daysValidLast < currentDay) {
        currentDay = daysValidLast;
      }
    }
    this.updateDay(currentDay);
  }

  updateDayAndDispatchDate = () => {
    this.updateDaySelection();
    this.dispatchDate();
  }

  dispatchDate = () => {
    const day = this.state.day;
    const monthNum = this.state.monthNum;
    const year = this.state.year;
    const date = `${year}-${monthNum}-${day}`;
    this.props.dobUpdateCallback(date);
  }

  selectYear = (option) => {
    this.setState({
      ...this.state,
      year: option.value,
      yearSelected: true,
      emptySelection: { ...this.state.emptySelection, year: false },
    }, () => this.updateDayAndDispatchDate());
  }

  selectMonth = (option) => {
    this.setState({
      ...this.state,
      month: option.label,
      monthNum: option.value,
      monthSelected: true,
      emptySelection: { ...this.state.emptySelection, month: false },
    }, () => this.updateDayAndDispatchDate());
  }

  selectDay = (option) => {
    this.setState({
      ...this.state,
      day: option.value,
      daySelected: true,
      emptySelection: { ...this.state.emptySelection, day: false },
    }, () => this.dispatchDate());
  }

  updateDay = (day) => {
    this.setState({
      ...this.state,
      day,
    }, () => this.dispatchDate());
  }

  validateOnEmptySelection = (refSelect) => {
    const { day, month, year } = this.props.initialDateProps;
    const { placeholder } = refSelect.props;
    const setEmptySelectionInState = (input) => {
      this.setState((prevState) => ({
        emptySelection: { ...prevState.emptySelection, [input]: true },
      }));
    };
    if (placeholder === day) { setEmptySelectionInState('day'); }
    if (placeholder === month) { setEmptySelectionInState('month'); }
    if (placeholder === year) { setEmptySelectionInState('year'); }
  }

  render() {
    const { dobFail } = this.props.initialDateProps;
    const { validateOnEmptySelection, numericMonth } = this.props;
    const { daySelected, monthSelected, yearSelected, emptySelection } = this.state;
    return (
      <div>
        <Select
          class={classNames({
            [Style.selectDay]: true,
            [Style.dateFailed]: (emptySelection.day || dobFail) && !daySelected })}
          onChange={this.selectDay}
          options={this.getValidDays()}
          placeholder={this.state.day}
          ref={(select) => { this.dayRef = select; }}
          onBlur={
            validateOnEmptySelection ?
              () => this.validateOnEmptySelection(this.dayRef) :
              undefined
          }
        />
        <Select
          class={classNames({
            [Style.selectMonth]: true,
            [Style.dateFailed]: (emptySelection.month || dobFail) && !monthSelected,
          })}
          onChange={this.selectMonth}
          options={this.populateMonthsDropdown()}
          placeholder={this.state.month}
          ref={(select) => { this.monthRef = select; }}
          onBlur={
            validateOnEmptySelection ?
              () => this.validateOnEmptySelection(this.monthRef) :
              undefined
          }
        />
        <Select
          class={classNames({
            [Style.selectYear]: true,
            [Style.dateFailed]: (emptySelection.year || dobFail) && !yearSelected })}
          onChange={this.selectYear}
          options={this.populateYearsDropdown()}
          placeholder={this.state.year}
          ref={(select) => { this.yearRef = select; }}
          onBlur={
            validateOnEmptySelection ?
              () => this.validateOnEmptySelection(this.yearRef) :
              undefined
          }
        />
      </div>
    );
  }
}

export default DropdownDatepicker;
