import ExerciseGraph from 'bundles/graphs/components/Graphs/ExerciseGraph/ExerciseGraph';
import classNames from 'classnames';
import OverviewGraphRow from '../../OverviewGraphRow/OverviewGraphRow';
import Style from '../OverviewGraphPresenter.scss';

interface OverviewGraphExerciseRowProps {
  show: boolean;
  axisEndIndex: number;
  axisWidth: number;
  oneDayWidth: number;
  startTimestamp: string | number;
  endTimestamp: string | number;
  axisTitle: string;
  axisSubtitle: string;
  inPdf: boolean;
  configOverride: unknown;
  fetchStatus: string;
  series: unknown[];
  graphsWindowWidth: number;
}

const OverviewGraphExerciseRow = (props: OverviewGraphExerciseRowProps) => {
  const {
    show,
    axisEndIndex,
    axisWidth,
    oneDayWidth,
    startTimestamp,
    endTimestamp,
    axisTitle,
    axisSubtitle,
    inPdf,
    configOverride,
    fetchStatus,
    series,
    graphsWindowWidth,
  } = props;
  if (!show) {
    return null;
  }

  return (
    <OverviewGraphRow
      axisEndIndex={inPdf ? 100 : axisEndIndex}
      axisHide={inPdf}
      axisStartIndex={0}
      axisSubtitle={axisSubtitle}
      axisTickStep={axisEndIndex / 2}
      axisTitle={axisTitle}
      axisWidth={axisWidth}
      endTimestamp={endTimestamp}
      inPdf={inPdf}
      oneDayWidth={oneDayWidth}
      startTimestamp={startTimestamp}
      windowHeight={inPdf ? 48 : 72}
    >
      <div className={classNames(Style.borderBottom, { [Style.borderRight]: inPdf })}>
        <ExerciseGraph
          configOverride={configOverride}
          endTimestamp={endTimestamp}
          fetchStatus={fetchStatus}
          series={series}
          startTimestamp={startTimestamp}
          windowHeight={inPdf ? 48 : 72}
          windowWidth={graphsWindowWidth}
          yAxisMax={inPdf ? 100 : axisEndIndex}
        />
      </div>
    </OverviewGraphRow>
  );
};

export default OverviewGraphExerciseRow;
