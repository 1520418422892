const RailsToClientConstants = window.RailsToClientConstants || {};

export const defaultLocale = window.I18n_default_locale;
export const locale = window.I18n_locale;
export const availableLocales = window.I18n_available_locales;
export const emailPath = RailsToClientConstants.email_path;
export const editUserRegistrationPath = RailsToClientConstants.edit_user_registration_path;
export const languagePath = RailsToClientConstants.language_path;
export const signedInByToken = RailsToClientConstants.signed_in_by_token === 'true';
export const createPdfTarget = RailsToClientConstants.create_pdf_target;
