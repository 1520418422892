import moment from 'moment';

class PregnancyUtils {
  static calculatePregnancyWeeksAndDays = (dueDate, now) => {
    const nowDate = moment(now).utc();
    const conceptionDate = moment(dueDate).utc().subtract(40, 'weeks').add(1, 'days');
    const maxDeliveryDate = moment(conceptionDate).utc().add(43, 'weeks');
    let weeks;
    let days;
    let activePregnancy = false;

    if (!nowDate.isBefore(conceptionDate, 'day') && !nowDate.isAfter(maxDeliveryDate, 'day')) {
      activePregnancy = true;
      weeks = nowDate.diff(conceptionDate, 'week');
      days = nowDate.diff(conceptionDate, 'day') - (weeks * 7);
    }

    return { weeks, days, activePregnancy };
  }

  static getRangeString = (dueDate, startDate, endDate) => {
    const start = PregnancyUtils.calculatePregnancyWeeksAndDays(dueDate, startDate);
    const end = PregnancyUtils.calculatePregnancyWeeksAndDays(dueDate, endDate);

    if (!start.activePregnancy && !end.activePregnancy) {
      return null;
    }

    const startWeeks = start.weeks !== undefined ? start.weeks : 0;
    const startDays = start.days !== undefined ? start.days : 0;
    const endWeeks = end.weeks !== undefined ? end.weeks : 43;
    const endDays = end.days !== undefined ? end.days : 0;

    if (moment(startDate).utc().isSame(moment(endDate).utc(), 'day')) {
      return `${startWeeks}+${startDays}`;
    }

    return `${startWeeks}+${startDays} - ${endWeeks}+${endDays}`;
  };
}

export default PregnancyUtils;
