import { map, isEmpty } from 'lodash';
import {
  fetchPatients,
  fetchProviderGroupSite,
  fetchPoptrackerFilters,
  updateProviderGroupSiteSetting,
  fetchSuggestedResults,
  fetchSearchResults,
  fetchProviderPatients,
  fetchProfessionalTags,
  fetchPatientTags,
  saveProviderTags,
  saveCustomTags,
  deleteProviderTags,
  deleteCustomTags,
  fetchPatientCarePrograms,
  fetchCurrentPatientCarePrograms,
  fetchPatientsFlags,
  createPatient,
} from '~/services/providerGroupSiteApi';
import {
  patientSearchResults,
  providerGroupSiteFetchSuccess,
  providerGroupSiteSettingUpdateSuccess,
  updateSuggestedResults,
  updateSearchResults,
  updateSearchStatus,
  filterListFetched,
  providerPatientsFetched,
  providerPatientsHasErrored,
  providerPatientsInProgress,
  professionalTagsFetched,
  professionalTagsHasErrored,
  professionalTagsInProgress,
  fetchPatientTagsInProgress,
  fetchPatientTagsSuccess,
  fetchPatientTagsHasErrored,
  updateTagsStatusSaved,
  updateTagsStatusInProgress,
  updateTagsStatusHasErrored,
  updateFiltersList,
  updatePatientCareProgramsState,
  updatePatientCareProgramsInProgress,
  updateCreatedPopupVisibility,
  createPatientSuccess,
  createPatientError,
} from '~/redux/modules/providerGroupSite/providerGroupSite';
import {
  POP_INSIGHTS_FEATURE,
} from '~/bundles/shared/constants/providerGroupSite';
import { searchParamsUpdateSuccess } from '~/redux/modules/searchParams/searchParams';
import throwIfStatusCodeNotExpected from '~/utils/throwIfStatusCodeNotExpected';

export const SEARCH_PATIENT_SEARCHING = 'SEARCH_PATIENT_SEARCHING';
export const SEARCH_PATIENT_SUCCESS = 'SEARCH_PATIENT_SUCCESS';
export const SEARCH_PATIENT_NO_RESULTS = 'SEARCH_PATIENT_NO_RESULTS';
export const SEARCH_PATIENT_FAIL = 'SEARCH_PATIENT_FAIL';

export const CREATE_PATIENT_SUCCESS = 'CREATE_PATIENT_SUCCESS';
export const CREATE_PATIENT_FAIL = 'CREATE_PATIENT_FAIL';

export function fetchProviderGroupSiteThunk() {
  return (dispatch) => fetchProviderGroupSite()
    .then(throwIfStatusCodeNotExpected(200))
    .then((response) => response.data)
    .then((providerGroupSite) => {
      dispatch(providerGroupSiteFetchSuccess(providerGroupSite.primarySite));
    })
    .catch((response) => response);
}

export function fetchFiltersThunk() {
  return (dispatch) => (
    fetchPoptrackerFilters()
      .then(throwIfStatusCodeNotExpected(200))
      .then((response) => response.data)
      .then((data) => dispatch(filterListFetched(data.search_filters)))
      .catch((response) => response)
  );
}

export function updateProviderGroupSiteSettingThunk(payload) {
  return (dispatch) => updateProviderGroupSiteSetting(payload)
    .then(throwIfStatusCodeNotExpected(200))
    .then(() => {
      dispatch(providerGroupSiteSettingUpdateSuccess(payload));
    })
    .catch((response) => response);
}

export function fetchPatientsThunk(params) {
  return (dispatch) => {
    dispatch(patientSearchResults({}, SEARCH_PATIENT_SEARCHING));
    return fetchPatients(params)
      .then(throwIfStatusCodeNotExpected(200))
      .then((response) => response.data)
      .then((patients) => {
        if (patients.users.length > 0) {
          dispatch(patientSearchResults(patients, SEARCH_PATIENT_SUCCESS));
        } else {
          dispatch(patientSearchResults({}, SEARCH_PATIENT_NO_RESULTS));
        }
      })
      .catch((response) => {
        dispatch(patientSearchResults({}, SEARCH_PATIENT_FAIL));
        return response;
      });
  };
}

export function fetchSuggestedResultsThunk() {
  return (dispatch) => fetchSuggestedResults()
    .then(throwIfStatusCodeNotExpected(200))
    .then((response) => response.data)
    .then((results) => {
      dispatch(updateSuggestedResults(results));
    })
    .catch((response) => {
      dispatch(updateSuggestedResults({}));
      return response;
    });
}

export function fetchSearchResultsThunk(params) {
  return (dispatch) => {
    dispatch(updateSearchStatus());
    return fetchSearchResults(params)
      .then(throwIfStatusCodeNotExpected(200))
      .then((response) => response.data)
      .then((results) => {
        dispatch(updateSearchResults(results));
      })
      .catch((response) => {
        dispatch(updateSearchResults({ results: [] }));
        return response;
      });
  };
}

export function fetchProfessionalTagsThunk(params) {
  return (dispatch) => {
    dispatch(professionalTagsInProgress(true));

    return fetchProfessionalTags(params)
      .then(throwIfStatusCodeNotExpected(200))
      .then((response) => response.data)
      .then((data) => {
        dispatch(professionalTagsFetched(data));
      })
      .catch(() => {
        dispatch(professionalTagsHasErrored(true));
      });
  };
}

export function fetchPatientTagsThunk(patientId) {
  return (dispatch) => {
    dispatch(fetchPatientTagsInProgress(patientId));
    return fetchPatientTags(patientId)
      .then(({ data }) => {
        dispatch(fetchPatientTagsSuccess({ tags: data.tags, patientId }));
      })
      .catch(() => {
        dispatch(fetchPatientTagsHasErrored());
      });
  };
}

export function providerTagsSaveThunk(params) {
  return (dispatch) => {
    dispatch(updateTagsStatusInProgress(true));
    return saveProviderTags(params)
      .then(throwIfStatusCodeNotExpected(200))
      .then((response) => response.data)
      .then((data) => {
        dispatch(updateTagsStatusSaved(data));
      })
      .catch(() => {
        dispatch(updateTagsStatusHasErrored(true));
      });
  };
}

export function customTagsSaveThunk(params) {
  return (dispatch) => {
    dispatch(updateTagsStatusInProgress(true));
    return saveCustomTags(params)
      .then(throwIfStatusCodeNotExpected(200))
      .then((response) => response.data)
      .then((data) => {
        dispatch(updateTagsStatusSaved(data));
      })
      .then(() => {
        dispatch(fetchFiltersThunk());
        dispatch(fetchProfessionalTagsThunk());
      })
      .catch(() => {
        dispatch(updateTagsStatusHasErrored(true));
      });
  };
}

export function customTagsDeleteThunk(id, params) {
  return (dispatch) => {
    dispatch(updateTagsStatusInProgress(true));
    return deleteCustomTags(id, params)
      .then(throwIfStatusCodeNotExpected(200))
      .then((response) => response.data)
      .then((data) => {
        dispatch(updateTagsStatusSaved(data));
      })
      .then(() => {
        dispatch(fetchFiltersThunk());
        dispatch(fetchProfessionalTagsThunk());
      })
      .catch(() => {
        dispatch(updateTagsStatusHasErrored(true));
      });
  };
}

export function providerTagsDeleteThunk(id, params) {
  return (dispatch) => {
    dispatch(updateTagsStatusInProgress(true));
    return deleteProviderTags(id, params)
      .then(throwIfStatusCodeNotExpected(200))
      .then((response) => response.data)
      .then((data) => {
        dispatch(updateTagsStatusSaved(data));
      })
      .catch(() => {
        dispatch(updateTagsStatusHasErrored(true));
      });
  };
}

export function fetchFlagsThunk(data) {
  const patientIds = [];
  const patientData = {
    ...data,
  };
  map(patientData.patients, (item) => patientIds.push(item.id));
  const payload = {
    // eslint-disable-next-line camelcase
    user_id: patientIds,
  };
  return (dispatch) => fetchPatientsFlags(payload)
    .then(throwIfStatusCodeNotExpected(200))
    .then((response) => response.data)
    .then((res) => {
      const patients = [];
      map(patientData.patients, (item) => {
        let patientFlagIds = [];
        if (!isEmpty(res)) {
          const flagsIds = res[item.id];
          if (flagsIds && flagsIds.length > 0) {
            patientFlagIds = flagsIds;
          }
        }
        patients.push({
          ...item,
          flagsIds: patientFlagIds,
        });
      });

      dispatch(providerPatientsInProgress(false));
      dispatch(providerPatientsFetched({
        ...patientData,
        patients,
      }));
    })
    .catch((e) => {
      dispatch(providerPatientsHasErrored(true));
      dispatch(providerPatientsInProgress(false));
      dispatch(providerPatientsFetched(data));
    });
}

export function fetchFilterProviderPatientsThunk(
  searchParams,
  currentFilters,
  filterParams,
) {
  return (dispatch, getState) => {
    dispatch(providerPatientsInProgress(true));
    dispatch(updateFiltersList(currentFilters));
    dispatch(searchParamsUpdateSuccess(searchParams));
    return fetchProviderPatients({ ...searchParams, ...filterParams })
      .then(throwIfStatusCodeNotExpected(200))
      .then((response) => response.data)
      .then((data) => {
        const state = getState();
        const features = state.providerGroupSite && state.providerGroupSite.subscriptionModel &&
        state.providerGroupSite.subscriptionModel.features;
        if (features && features.includes(POP_INSIGHTS_FEATURE) &&
          data.patients.length > 0) {
          dispatch(fetchFlagsThunk(data));
        } else {
          dispatch(providerPatientsInProgress(false));
          dispatch(providerPatientsFetched(data));
        }
      })
      .catch((e) => {
        dispatch(providerPatientsHasErrored(true));
        dispatch(providerPatientsInProgress(false));
      });
  };
}

export function fetchPatientCareProgramsThunk(patientId) {
  return (dispatch) => {
    dispatch(updatePatientCareProgramsInProgress());
    return fetchPatientCarePrograms(patientId)
      .then(throwIfStatusCodeNotExpected(200))
      .then((response) => {
        if (response.status === 200) {
          dispatch(updatePatientCareProgramsState(response.data.care_programs));
        }
      })
      .catch(() => {
        dispatch(providerPatientsHasErrored(true));
      });
  };
}

export function fetchCurrentPatientCareProgramsThunk(patientId) {
  return (dispatch) => {
    dispatch(updatePatientCareProgramsInProgress());
    return fetchCurrentPatientCarePrograms(patientId)
      .then(throwIfStatusCodeNotExpected(200))
      .then((response) => {
        if (response.status === 200) {
          dispatch(updatePatientCareProgramsState(response.data.carePrograms));
        }
      })
      .catch(() => {
        dispatch(providerPatientsHasErrored(true));
      });
  };
}

export function createPatientThunk(payload, onFailure = undefined) {
  return (dispatch) => createPatient(payload)
    .then(throwIfStatusCodeNotExpected(201))
    .then((response) => {
      if (response.status === 201) {
        dispatch(createPatientSuccess(response, CREATE_PATIENT_SUCCESS));
        dispatch(updateCreatedPopupVisibility(true));
      }
    })
    .catch((error) => {
      dispatch(createPatientError(error, CREATE_PATIENT_FAIL));
      if (error.response.data && error.response.data.error_key) {
        onFailure(error.response.data.error_key);
      }
    });
}
