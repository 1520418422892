import { numberFormatterOneDecimal } from 'utils/i18nFormats';
import { BLANK_STATS_VALUE } from 'bundles/shared/constants/statistics';
import Style from '../OverviewTooltipPresenter.scss';
import ExerciseDurationRow from './ExerciseDurationRow';

interface ExerciseTooltipRowProps {
  show: boolean;
  value: string | number | GraphExercise[];
  title: string;
  subtitle: string;
  valueTitle: string;
}

const ExerciseTooltipRow = (props: ExerciseTooltipRowProps) => {
  const { show, value, title, subtitle, valueTitle } = props;

  if (!show) { return null; }
  if (value === BLANK_STATS_VALUE || (Array.isArray(value) && value.length === 0)) { return null; }

  const rowValue = () => {
    if (Array.isArray(value)) {
      return (
        <div className={Style.exercisesContainer}>
          {value.map((exercise) => <ExerciseDurationRow key={exercise.duration} exercise={exercise} />)}
        </div>
      );
    }

    return (
      <>
        <div className={Style.value}>{numberFormatterOneDecimal(value)}</div>
        <div className={Style.subtitle}>{valueTitle}</div>
      </>
    );
  };

  return (
    <>
      <div className={Style.titleWithSubtitle}>
        <span className={Style.title}>{title}</span>
        <span className={Style.subtitle}>{subtitle}</span>
      </div>

      <div className={Style.TooltipContainer}>
        <div className={Style.horizontalElement}>
          {rowValue()}
        </div>
      </div>
    </>
  );
};

export default ExerciseTooltipRow;
