import { useQuery } from '@tanstack/react-query';
import { ExecuteUnchangedApiCall } from '~/services/apiClient';

export const useReferenceDeviceList = (select) => useQuery({
  queryKey: ['reference_device_list'],
  queryFn: () => ExecuteUnchangedApiCall('get', 'api/v2/reference/devices').then((res) => res.data),
  staleTime: Infinity,
  select: select || undefined,
  refetchOnWindowFocus: false,
  retryOnMount: false,
  retry: false,
});
