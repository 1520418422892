import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogHeader } from '@glooko/common-ui';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import styles from './ReasonForChange.scss';

class ReasonForChange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reasonForUpdate: '',
    };
  }

  handleReasonChange = (event) => {
    const { handleReasonChange } = this.props;
    this.setState({ reasonForUpdate: event.target.value });
    handleReasonChange(event);
  }

  render() {
    const {
      t,
      isReasonModalOpen,
      updatePatientEmailWithReason,
      reasonForChangeRequired,
      handleCloseReasonModal,
    } = this.props;
    const { reasonForUpdate } = this.state;
    const reasonForChangeLabel = reasonForChangeRequired ? t('reasonForChangeRequired') : t('reasonForChange');

    return (
      <div>
        <Dialog
          size="small"
          open={isReasonModalOpen}
          onClose={handleCloseReasonModal}
          className={styles.reasonForUpdateModalOverlay}
          dataAttributes={{ testid: 'reason-for-change' }}
        >
          <DialogHeader dataAttributes={{ testid: 'reason-for-change' }}><h2>{t('reasonModalTitle')}</h2></DialogHeader>
          <DialogContent dataAttributes={{ testid: 'reason-for-change' }}>
            <p className={styles.reasonForUpdateModalContext}>
              {t('reasonModalDescription')}
            </p>
            <ul className={styles.changeItems}>
              <li>{t('email')}</li>
            </ul>
            <div>
              <div className={styles.reasonForChangeTextLabel}>{reasonForChangeLabel}</div>
              <textarea
                placeholder={t('reasonForChangePlaceholder')}
                onChange={this.handleReasonChange}
                className={styles.reasonForChangeTextControl}
              />
            </div>
          </DialogContent>
          <DialogActions dataAttributes={{ testid: 'reason-for-change' }}>
            <div className={styles.footerButtons}>
              <Button
                className={styles.footerButton}
                variation='secondary'
                onClick={handleCloseReasonModal}
                dataAttributes={{ testid: 'reason-for-change-cancel' }}
              >
                {t('cancel')}
              </Button>
              <Button
                className={styles.footerButton}
                disabled={reasonForChangeRequired && !reasonForUpdate}
                onClick={updatePatientEmailWithReason}
                dataAttributes={{ testid: 'reason-for-change-save' }}
              >
                {t('save')}
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ReasonForChange.propTypes = {
  isReasonModalOpen: PropTypes.bool,
  t: PropTypes.func.isRequired,
  updatePatientEmailWithReason: PropTypes.func.isRequired,
  reasonForChangeRequired: PropTypes.bool,
  handleCloseReasonModal: PropTypes.func.isRequired,
  handleReasonChange: PropTypes.func.isRequired,
};

ReasonForChange.defaultProps = {
  isReasonModalOpen: false,
  reasonForChangeRequired: false,
};

const translatedReasonForChange = translate('ReasonForChange')(ReasonForChange);

export default (translatedReasonForChange);
