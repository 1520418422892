// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".InsulinCalculationsInfoDialog_content{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;gap:17px;margin-bottom:30px}.InsulinCalculationsInfoDialog_itemTitle{font-weight:800}", "",{"version":3,"sources":["InsulinCalculationsInfoDialog.scss"],"names":[],"mappings":"AAAA,uCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,QAAA,CACA,kBAAA,CAGF,yCACE,eAAA","file":"InsulinCalculationsInfoDialog.scss","sourcesContent":[".content {\n  display: flex;\n  flex-direction: column;\n  gap: 17px;\n  margin-bottom: 30px;\n}\n\n.itemTitle {\n  font-weight: 800;\n}\n"]}]);
// Exports
exports.locals = {
	"content": "InsulinCalculationsInfoDialog_content",
	"itemTitle": "InsulinCalculationsInfoDialog_itemTitle"
};
module.exports = exports;
