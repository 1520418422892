export const PATIENT_ADJUSTMENTS_FETCH_SUCCESS = 'PATIENT_ADJUSTMENTS_FETCH_SUCCESS';
export const PATIENT_ADJUSTMENTS_HAS_ERRORED = 'PATIENT_ADJUSTMENTS_HAS_ERRORED';
export const PATIENT_ADJUSTMENTS_IS_LOADING = 'PATIENT_ADJUSTMENTS_IS_LOADING';

export function patientAdjustmentsHasErrored(bool) {
  return { type: PATIENT_ADJUSTMENTS_HAS_ERRORED, hasErrored: bool };
}

export function patientAdjustmentsIsLoading(bool) {
  return { type: PATIENT_ADJUSTMENTS_IS_LOADING, isLoading: bool };
}

export function patientAdjustmentsFetchSuccess(patientAdjustments) {
  return { type: PATIENT_ADJUSTMENTS_FETCH_SUCCESS, patientAdjustments };
}

const initialState = {
  patientAdjustments: [],
  isLoading: false,
  hasErrored: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case PATIENT_ADJUSTMENTS_FETCH_SUCCESS:
      return Object.assign(
        {},
        state,
        { patientAdjustments: action.patientAdjustments.patientAdjustments },
      );
    case PATIENT_ADJUSTMENTS_IS_LOADING:
      return Object.assign(
        {}, state, { isLoading: action.isLoading },
      );
    case PATIENT_ADJUSTMENTS_HAS_ERRORED:
      return Object.assign(
        {}, state, { hasErrored: action.hasErrored },
      );
    default:
      return state;
  }
}
