import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { translate } from 'bundles/shared/components/WithTranslate/WithTranslate';
import { TYPE_BG, TYPE_CGM } from '~/bundles/shared/constants/readings';
import { numberFormatterOneDecimal } from '~/utils/i18nFormats';
import Readings from '~/services/Readings';
import Colors from '~/../assets/styles/export/colors';
import Style from './OverviewTooltipPresenter.scss';
import ExerciseTooltipRow from './ExerciseTooltipRow/ExerciseTooltipRow';

const OverviewTooltipPresenter = (props) => {
  const { overall, t } = props;
  t('calories');

  const renderDeviceInsulinTooltipSection = () => {
    const { hasPump, hasPen } = overall;
    let subtitle;

    if (hasPump && hasPen) subtitle = t('fromPumpAndPen');
    else if (hasPump) subtitle = t('fromPump');
    else subtitle = t('fromPen');

    return (
      <div>
        <div className={Style.titleWithSubtitle}>
          <span className={Style.title}>{t('insulinDevice')}</span>
          <span className={Style.subtitle}>{subtitle}</span>
        </div>
        <div className={Style.TooltipContainer}>
          <div class={Style.horizontalElement}>
            <div class={Style.value}>
              {numberFormatterOneDecimal(overall.totalPumpInsulinPerDay)}
              <div class={Style.unit}>{t('unit', { count: 2 })}</div>
            </div>
            <div class={Style.subtitle}>{t('insulinPerDay')}</div>
          </div>
          <div class={Style.tableContainer}>
            <table class={Style.insulinTable}>
              <tbody>
                {overall.bolusUnitsPerDay > 0 &&
                  <tr class={Style.tableElementsRow}>
                    <td class={Style.insulinTableTitle}>{t('bolusPerDay')}</td>
                    <td class={Style.insulinTablePercentage} style={{ color: Colors.bolus }}>
                      {t('percentageValue', { percentage: overall.bolusPercentage })}
                    </td>
                    <td class={Style.insulinTableUnits} style={{ color: Colors.bolus }}>
                      ({numberFormatterOneDecimal(overall.bolusUnitsPerDay)} {t('unit', { count: overall.bolusUnitsPerDay })})
                    </td>
                  </tr>}
                {overall.basalUnitsPerDay > 0 &&
                  <tr>
                    <td class={Style.insulinTableTitle}>{t('basalPerDay')}</td>
                    <td class={Style.insulinTablePercentage} style={{ color: Colors.basal }}>
                      {t('percentageValue', { percentage: overall.basalPercentage })}
                    </td>
                    <td class={Style.insulinTableUnits} style={{ color: Colors.basal }}>
                      ({numberFormatterOneDecimal(overall.basalUnitsPerDay)} {t('unit', { count: overall.basalUnitsPerDay })})
                    </td>
                  </tr>}
                {overall.premixedUnitsPerDay > 0 &&
                  <tr>
                    <td class={Style.insulinTableTitle}>{t('premixedPerDay')}</td>
                    <td class={Style.insulinTablePercentage} style={{ color: Colors.premixed }}>
                      {t('percentageValue', { percentage: overall.premixedPercentage })}
                    </td>
                    <td class={Style.insulinTableUnits} style={{ color: Colors.premixed }}>
                      ({numberFormatterOneDecimal(overall.premixedUnitsPerDay)} {t('unit', { count: overall.premixedUnitsPerDay })})
                    </td>
                  </tr>}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  const renderManualInsulinTooltipSection = () => (
    <div>
      <div className={Style.titleWithSubtitle}>
        <span className={Style.title}>{t('insulinManual')}</span>
        <span className={Style.subtitle}>{t('fromManual')}</span>
      </div>
      <div className={Style.TooltipContainer}>
        <div class={Style.horizontalElement}>
          <div class={Style.value}>
            {numberFormatterOneDecimal(overall.totalOtherInsulinPerDay)}
            <div class={Style.unit}>{t('unit', { count: isNaN(overall.totalOtherInsulinPerDay) ? 0 : overall.totalOtherInsulinPerDay })}</div>
          </div>
          <div class={Style.subtitle}>{t('insulinPerDay')}</div>
        </div>
        <div class={Style.tableContainer}>
          <table class={Style.insulinTable}>
            <tbody>
              {overall.otherBolusUnitsPerDay > 0 &&
                <tr class={Style.tableElementsRow}>
                  <td class={Style.insulinTableTitle}>{t('bolusPerDay')}</td>
                  <td class={Style.insulinTablePercentage} style={{ color: Colors.bolus }}>
                    {t('percentageValue', { percentage: overall.otherBolusPercentage })}
                  </td>
                  <td class={Style.insulinTableUnits} style={{ color: Colors.bolus }}>
                    ({numberFormatterOneDecimal(overall.otherBolusUnitsPerDay)} {t('unit', { count: overall.otherBolusUnitsPerDay })})
                  </td>
                </tr>}
              {overall.otherBasalUnitsPerDay > 0 &&
                <tr>
                  <td class={Style.insulinTableTitle}>{t('basalPerDay')}</td>
                  <td class={Style.insulinTablePercentage} style={{ color: Colors.basal }}>
                    {t('percentageValue', { percentage: overall.otherBasalPercentage })}
                  </td>
                  <td class={Style.insulinTableUnits} style={{ color: Colors.basal }}>
                    ({numberFormatterOneDecimal(overall.otherBasalUnitsPerDay)} {t('unit', { count: overall.otherBasalUnitsPerDay })})
                  </td>
                </tr>}
              {overall.otherPremixedUnitsPerDay > 0 &&
                <tr>
                  <td class={Style.insulinTableTitle}>{t('premixedPerDay')}</td>
                  <td class={Style.insulinTablePercentage} style={{ color: Colors.premixed }}>
                    {t('percentageValue', { percentage: overall.otherPremixedPercentage })}
                  </td>
                  <td class={Style.insulinTableUnits} style={{ color: Colors.premixed }}>
                    ({numberFormatterOneDecimal(overall.otherPremixedUnitsPerDay)} {t('unit', { count: overall.otherPremixedUnitsPerDay })})
                  </td>
                </tr>}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

  const renderEmptyInsulinTooltipSection = () => (
    <div>
      <div className={Style.TooltipTitle}>
        {t('insulin')}
      </div>
      <div className={Style.TooltipContainer}>
        <div class={Style.horizontalElement}>
          <div class={Style.value}>
            -
            <div class={Style.unit}>{t('unit_plural')}</div>
          </div>
          <div class={Style.subtitle}>{t('insulinPerDay')}</div>
        </div>
      </div>
    </div>
  );

  const renderInsulinTooltipSection = () => {
    const { hasInsulinPump, hasInsulinManual } = props;

    return (
      <div>
        {hasInsulinPump && renderDeviceInsulinTooltipSection()}
        {hasInsulinManual && renderManualInsulinTooltipSection()}
        {!hasInsulinPump && !hasInsulinManual && renderEmptyInsulinTooltipSection()}
      </div>
    );
  };

  const renderTooltip = () => {
    const { isNinetyDays, readingsType, beforeMealMax, afterMealMax, normalMin } = props;

    const stepSources = overall.stepSources ? overall.stepSources.join(', ') : null;
    const exerciseSources = overall.exerciseSources ? overall.exerciseSources.join(', ') : null;

    const displayColorFromValue =
      (value) => Readings.displayColorFromValue(value, beforeMealMax, afterMealMax, normalMin);

    return (
      <div>
        <div class={classNames({ [Style.TooltipTitle]: true, [Style.noLine]: true })}>
          {readingsType === TYPE_BG ? t('glucoseBg') : t('glucoseCgm')}
        </div>
        <div class={Style.TooltipContainer}>
          <div class={Style.horizontalElement}>
            <div class={Style.value}>
              {numberFormatterOneDecimal(overall.median)}
              <div class={Style.unit}>{`${Readings.displayMeterUnits(props.meterUnits)}`}</div>
            </div>
            <div class={Style.subtitle}>{t('median')}</div>
          </div>
          {props.isPercentilesOn ?
            <div style={{ display: 'inline-block' }}>
              <div class={Style.verticalElements}>
                <div class={Style.subtitle}>{t('tenthPercentile')}</div>
                <div class={Style.subtitle}>{t('tewentyFifthPercentile')}</div>
              </div>
              <div class={classNames({
                [Style.verticalElements]: true,
                [Style.percentileVerticalElements]: true,
              })}
              >
                <div
                  class={Style.percentilesPadding}
                  style={{ color: displayColorFromValue(overall.tenthPercentile) }}
                >
                  {overall.tenthPercentile}
                </div>
                <div
                  class={Style.valueSubtitle}
                  style={{ color: displayColorFromValue(overall.tewentyFifthPercentile) }}
                >
                  {overall.tewentyFifthPercentile}
                </div>
              </div>
              <div class={classNames({ [Style.verticalElements]: true, [Style.percentileVerticalElements]: true })}>
                <div class={Style.percentilesPadding}>
                  -
                </div>
                <div class={Style.valueSubtitle}>
                  -
                </div>
              </div>
              <div class={classNames({
                [Style.verticalElements]: true,
                [Style.percentileVerticalElements]: true,
                [Style.lastPercentileElement]: true,
              })}
              >
                <div
                  class={Style.percentilesPadding}
                  style={{ color: displayColorFromValue(overall.ninetiethPercentile) }}
                >
                  {overall.ninetiethPercentile}
                </div>
                <div
                  class={Style.valueSubtitle}
                  style={{ color: displayColorFromValue(overall.seventyFiftnPercentile) }}
                >
                  {overall.seventyFiftnPercentile}
                </div>
              </div>
            </div> :
            <div style={{ display: 'inline-block' }}>
              <div class={Style.verticalElements}>
                <div class={Style.subtitle}>{t('aboveRange')}</div>
                <div class={Style.subtitle}>{t('inTargetRange')}</div>
                <div class={Style.subtitle}>{t('belowRange')}</div>
              </div>
              <div class={Style.valueElement}>
                <div class={Style.valueSubtitle} style={{ color: Colors.high }}>
                  {t('percentageValue', { percentage: overall.highPercentage })}
                </div>
                <div class={Style.valueSubtitle} style={{ color: Colors.bgReadingNormal }}>
                  {t('percentageValue', { percentage: overall.inRangePercentage })}
                </div>
                <div class={Style.valueSubtitle} style={{ color: Colors.bgReadingLow }}>
                  {t('percentageValue', { percentage: overall.lowPercentage })}
                </div>
              </div>
            </div>}
        </div>
        <div class={classNames({ [Style.TooltipTitle]: true, [Style.hidden]: !props.hasCarbs })}>{t('carbs')}</div>
        <div class={classNames({ [Style.TooltipContainer]: true, [Style.hidden]: !props.hasCarbs })}>
          <div class={Style.horizontalElement}>
            <div class={Style.value}>
              {numberFormatterOneDecimal(overall.carbsPerDay)}
              <div class={Style.unit}>{t('grams')}</div>
            </div>
            <div class={Style.subtitle}>{isNinetyDays ? t('carbsPerDay') : t('totalCarbs')}</div>
          </div>
        </div>

        {renderInsulinTooltipSection()}

        <ExerciseTooltipRow
          show={props.hasSteps}
          value={overall.averageSteps}
          title={t('steps')}
          subtitle={t('sources', { sources: stepSources })}
          valueTitle={t('steps')}
        />

        <ExerciseTooltipRow
          show={props.hasExercise}
          value={overall.exercises}
          title={t('exercise')}
          subtitle={t('sources', { sources: exerciseSources })}
          valueTitle={t('duration')}
        />
      </div>
    );
  };

  const renderMessageRow = () => (
    <div class={Style.messageRow}>
      {props.loadingMessage}
    </div>
  );

  const tooltip = props.showMessageRow ? renderMessageRow() : renderTooltip();
  return (
    <div>
      <div class={Style.TooltipDate}>
        {props.date}
      </div>
      {tooltip}
    </div>
  );
};

OverviewTooltipPresenter.propTypes = {
  date: PropTypes.string.isRequired,
  meterUnits: PropTypes.string.isRequired,
  readingsType: PropTypes.oneOf([TYPE_BG, TYPE_CGM]).isRequired,
  t: PropTypes.func.isRequired,
  overall: PropTypes.shape({}).isRequired,
  showMessageRow: PropTypes.bool.isRequired,
  loadingMessage: PropTypes.string,
  isNinetyDays: PropTypes.bool.isRequired,
  hasInsulinPump: PropTypes.bool.isRequired,
  hasInsulinManual: PropTypes.bool.isRequired,
  hasCarbs: PropTypes.bool.isRequired,
  hasExercise: PropTypes.bool.isRequired,
  hasSteps: PropTypes.bool.isRequired,
  isPercentilesOn: PropTypes.bool.isRequired,
  normalMin: PropTypes.number.isRequired,
  beforeMealMax: PropTypes.number.isRequired,
  afterMealMax: PropTypes.number.isRequired,
};
OverviewTooltipPresenter.defaultProps = {
  t: () => { },
  loadingMessage: null,
  isNinetyDays: false,
};

export default translate('OverviewTooltip')(OverviewTooltipPresenter);
