export const SET_MESSAGE = 'SET_MESSAGE';

export function setMessage(newState) {
  return {
    type: SET_MESSAGE, payload: { newState },
  };
}

const initialState = {
  title: null,
  message: null,
  show: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_MESSAGE: {
      return Object.assign({}, state, action.payload.newState);
    }
    default:
      return state;
  }
}
