import detectOs from '~/utils/detectOs';
import { insuletConsentResponse } from '~/services/omnipodApi';
import { sendData } from '~/services/fileuploadApi';
import { updateInsuletConsent } from '../users/users';

// MODAL CONSTANTS
export const CONSENT = 'CONSENT';
export const UPLOAD = 'UPLOAD';
export const STATUS = 'STATUS';
export const SELECT_DEVICE = 'SELECT_DEVICE';
export const UPLOADER_DOWNLOAD = 'UPLOADER_DOWNLOAD';
export const CONTACT = 'CONTACT';
// DEVICE CONSTANTS
export const DASH = 'dash';
export const OMNIPOD = 'omnipod';
// FILE UPLOAD CONSTANTS
export const FILE_UPLOAD_STATUS = 'FILE_UPLOAD_STATUS';
export const SUCCESS = 'SUCCESS';
export const FAIL = 'FAIL';
export const LOADING = 'LOADING';
export const UPDATE_SELECTED_DEVICE = 'UPDATE_SELECTED_DEVICE'; // DEVICE SELECTION (OMNIPOD V DASH)
export const UPDATE_DIALOG_VISIBILITY = 'UPDATE_DIALOG_VISIBILITY';

export function fileUploadStatus(status) {
  return { type: FILE_UPLOAD_STATUS, status };
}

export function updateSelectedDevice(selectedDevice = null) {
  return { type: UPDATE_SELECTED_DEVICE, selectedDevice };
}

export function updateDialogVisibility(visible) {
  return { type: UPDATE_DIALOG_VISIBILITY, visible };
}

export function uploadOmnipodFile(file) {
  return (dispatch) => {
    dispatch(fileUploadStatus(LOADING));
    return sendData(file, 'InsuletOmnipod')
      .then(() => {
        dispatch(fileUploadStatus(SUCCESS));
      })
      .catch(() => {
        dispatch(fileUploadStatus(FAIL));
      });
  };
}

export function shareDataWithInsulet(consent) {
  return (dispatch) => insuletConsentResponse(consent)
    .then(() => {
      dispatch(updateInsuletConsent(consent));
    });
}

const initialState = {
  selectedDevice: null,
  dialogVisible: false,
  isMacOS: detectOs.isMac(),
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FILE_UPLOAD_STATUS:
      return Object.assign({}, state, { uploadStatus: action.status });
    case UPDATE_DIALOG_VISIBILITY:
      return Object.assign({}, state, { dialogVisible: action.visible });
    case UPDATE_SELECTED_DEVICE:
      return Object.assign({}, state, { selectedDevice: action.selectedDevice });
    default:
      return state;
  }
}
