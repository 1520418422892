import novodialoqTranslationMapper from './novodialoqTranslationMapper';
import novopenTranslationMapper from './novopenTranslationMapper';
import biocorpTranslationMapper from './biocorpTranslationMapper';
import lillyTranslationMapper from './lillyTranslationMapper';
import tandemTranslationMapper from './tandemTranslationMapper';
import genericTranslationMapper from './genericTranslationMapper';
import mallyaTranslationMapper from './mallyaTranslationMapper';
import dexcomTranslationMapper from './dexcomTranslationMapper';

const groupToHandlerMap = {
  novodialoq: novodialoqTranslationMapper,
  novopen: novopenTranslationMapper,
  biocorp: biocorpTranslationMapper,
  lilly: lillyTranslationMapper,
  tandem: tandemTranslationMapper,
  generic: genericTranslationMapper,
  mallya: mallyaTranslationMapper,
  dexcom: dexcomTranslationMapper,
};

const translationMapper = (key, opts = {}) => {
  const result = { shortText: null, popUpText: null };
  const handler = groupToHandlerMap[key.split('_')[0]];
  if (!handler) return result;

  result.shortText = handler(key, opts);
  result.popUpText = handler(`${key}_popup`, opts);

  return result;
};

export default translationMapper;
