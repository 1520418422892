import {
  TYPE_BG, TYPE_CGM,
  READINGS_LOW, READINGS_NORMAL, READINGS_HIGH,
} from '~/bundles/shared/constants/readings';
import i18next, { getTextDirection } from '~/utils/I18n/I18n';
import { numberFormatterOneDecimal } from '~/utils/i18nFormats';
import Colors from '~/../assets/styles/export/colors';
import UserHelper from '~/redux/modules/users/UserHelper';

const t = i18next.getFixedT(null, 'Readings');

const NORMAL_MIN = 70;
const BEFORE_MAX = 130;
const AFTER_MAX = 180;
export const MGDL = 'mgdl';
export const MMOLL = 'mmoll';
export const METER_UNITS_NOT_SET = 'not_set';
const MMOL_DIVISOR = 18.016;
const NORMALIZED_FACTOR = 100;
const MIDNIGHT_SNACK_BEGIN = 7000;
const BREAKFAST_BEGIN = 18000;
const LUNCH_BEGIN = 36000;
const DINNER_BEGIN = 54000;
const BG_MAX = 200100;
const BG_MIN = 100;

const LIMITS = {
  [TYPE_BG]: {
    hi: 550,
    lo: 20,
  },
  [TYPE_CGM]: {
    hi: 401,
    lo: 39,
  },
};

const detailedTagString = (tag) => {
  // Switch instead of "return t(tag);" because of testing unused translations
  switch (tag) {
    case '3am':
      return t('3am');
    case 'before_breakfast':
      return t('before_breakfast');
    case 'after_breakfast':
      return t('after_breakfast');
    case 'before_lunch':
      return t('before_lunch');
    case 'after_lunch':
      return t('after_lunch');
    case 'before_dinner':
      return t('before_dinner');
    case 'after_dinner':
      return t('after_dinner');
    case 'at_bedtime':
      return t('at_bedtime');
    case 'other':
      return t('other');
    default:
      return t(tag);
  }
};

export const AVAILABLE_METER_UNITS = [MGDL, MMOLL, METER_UNITS_NOT_SET];

class Readings {
  static get NORMAL_MIN() {
    return NORMAL_MIN;
  }

  static get BEFORE_MAX() {
    return BEFORE_MAX;
  }

  static get AFTER_MAX() {
    return AFTER_MAX;
  }

  static get MGDL() {
    return MGDL;
  }

  static get MMOLL() {
    return MMOLL;
  }

  static get METER_UNITS_NOT_SET() {
    return METER_UNITS_NOT_SET;
  }

  static get AVAILABLE_METER_UNITS() {
    return AVAILABLE_METER_UNITS;
  }

  static get LIMITS() {
    return LIMITS;
  }

  static get MIDNIGHT_SNACK_BEGIN() {
    return MIDNIGHT_SNACK_BEGIN;
  }

  static get BREAKFAST_BEGIN() {
    return BREAKFAST_BEGIN;
  }

  static get LUNCH_BEGIN() {
    return LUNCH_BEGIN;
  }

  static get DINNER_BEGIN() {
    return DINNER_BEGIN;
  }

  static displayMeterUnits(meterUnits) {
    return meterUnits === MMOLL ? t('mmoll') : t('mgdl');
  }

  static displayDetailedMealTag(tag, showDetailedBGTags = false) {
    if (!Object.is(tag, null)) {
      return detailedTagString(tag);
    } else {
      return showDetailedBGTags ? t('no_meal_tag') : '';
    }
  }

  static gmiIfccUnits() {
    return t('mmol_mol');
  }

  static displayOnOff(value) {
    return value === true ? t('on') : t('off');
  }

  static displayValue(value, meterUnits, type = TYPE_BG, hiLo = false) {
    if (hiLo && value === LIMITS[type].hi) {
      return 'HI';
    }
    if (hiLo && value === LIMITS[type].lo) {
      return 'LO';
    }
    return Readings.#convertToMeterUnits(value, meterUnits);
  }

  static setExclusiveValue(condition, value, maxVal, meterUnits) {
    let adjustVal = 0;
    if (condition) {
      adjustVal = meterUnits === MMOLL ? 0.1 : 1.0;
      adjustVal = (value === maxVal) ? 0 : adjustVal;
    }
    return adjustVal;
  }

  static displayRangeValue({ lowVal, highVal, meterUnits, exclusiveLow,
    exclusiveHigh, isRtl = (getTextDirection() === 'rtl'),
    convert = true }) {
    const meterUnitText = Readings.displayMeterUnits(meterUnits);
    const maxHighVal = (meterUnits === MMOLL ? 13.9 : 250);
    const minLowVal = (meterUnits === MMOLL ? 3.0 : 54);
    const convertHighVal = convert ? Readings.displayValue(highVal, meterUnits) : highVal;
    const convertLowVal = convert ? Readings.displayValue(lowVal, meterUnits) : lowVal;
    let rangeText = '';
    const adjustLowVal = Readings.setExclusiveValue(exclusiveLow, lowVal, maxHighVal, meterUnits);
    const adjustHighVal = Readings.setExclusiveValue(exclusiveHigh, highVal, minLowVal, meterUnits);

    if (lowVal === undefined) {
      const formattedVal = numberFormatterOneDecimal(convertHighVal);
      rangeText = `< ${formattedVal}`;
    } else if (highVal === undefined) {
      const formattedVal = numberFormatterOneDecimal(convertLowVal);
      rangeText = `> ${formattedVal}`;
    } else {
      const formattedLowVal = numberFormatterOneDecimal(convertLowVal + adjustLowVal);
      const formattedHighVal = numberFormatterOneDecimal(convertHighVal - adjustHighVal);
      rangeText = isRtl ? `${formattedHighVal}-${formattedLowVal}` : `${formattedLowVal}-${formattedHighVal}`;
    }

    return `${rangeText} ${meterUnitText}`;
  }

  static formatInHiLo(value, meterUnits, type = TYPE_BG) {
    if (value === Readings.displayValue(LIMITS[type].hi, meterUnits, type)) {
      return 'HI';
    }
    if (value === Readings.displayValue(LIMITS[type].lo, meterUnits, type)) {
      return 'LO';
    }
    return value;
  }

  static displayWithHiLo(value, meterUnits, type = TYPE_BG) {
    let val = Readings.denormalizedValue(value, meterUnits, type);
    if (val >= LIMITS[type].hi) return 'HI';
    if (val <= LIMITS[type].lo) return 'LO';

    val = Readings.#convertToMeterUnits(val, meterUnits);

    return numberFormatterOneDecimal(val);
  }

  static displayDenormalizedWithUnit(value, meterUnits, type = TYPE_BG) {
    const denormalizedValue = Readings.denormalizedValue(value, meterUnits, type);
    const displayValue = Readings.#convertToMeterUnits(denormalizedValue, meterUnits);
    const formattedValue = numberFormatterOneDecimal(displayValue);

    return `${formattedValue} ${Readings.displayMeterUnits(meterUnits)}`;
  }

  static displayColorFromBucketName(bucketName) {
    switch (bucketName) {
      case READINGS_LOW:
        return Colors.bgReadingLowLabel;
      case READINGS_NORMAL:
        return Colors.bgReadingNormalLabel;
      default:
        return Colors.bgReadingHighLabel;
    }
  }

  static bucketName(value, beforeMax, afterMax, normalMin) {
    if (value < normalMin) {
      return READINGS_LOW;
    } else if (value > beforeMax && value > afterMax) {
      return READINGS_HIGH;
    }

    return READINGS_NORMAL;
  }

  static displayStatisticsColorFromBucketName(bucketName) {
    switch (bucketName) {
      case 'high':
        return Colors.bgReadingHigh;
      case 'low':
        return Colors.bgReadingLow;
      case 'normal':
        return Colors.bgReadingNormal;
      default:
        return Colors.fontLight;
    }
  }

  static displayColorFromValue(value, beforeMax, afterMax, normalMin) {
    return isNaN(value) ?
      Colors.fontLight :
      Readings.displayColorFromBucketName(
        Readings.bucketName(value, beforeMax, afterMax, normalMin),
      );
  }

  static normalizeValue(value, meterUnits = MGDL, type = TYPE_BG) {
    const mgdlValue =
      (meterUnits === MGDL) ? value : Math.round(value * MMOL_DIVISOR);

    if (mgdlValue === LIMITS[type].hi) {
      return BG_MAX;
    }
    if (mgdlValue === LIMITS[type].lo) {
      return BG_MIN;
    }

    return mgdlValue * NORMALIZED_FACTOR;
  }

  static denormalizedValue(value, meterUnits = MGDL, type = TYPE_BG) {
    if (value === BG_MAX) {
      return LIMITS[type].hi;
    }
    if (value === BG_MIN) {
      return LIMITS[type].lo;
    }

    return Math.round(value / NORMALIZED_FACTOR);
  }

  static displayReadingsType(hasBGandEGV, userHasEGV, noToggleBg) {
    if (hasBGandEGV && !noToggleBg) {
      return `${UserHelper.retrieveLabel('bg')}${' '}
        ${UserHelper.retrieveLabel('and')}${' '}
        ${UserHelper.retrieveLabel('cgm')}`;
    } else if (userHasEGV && !noToggleBg) {
      return UserHelper.retrieveLabel('cgm');
    } return UserHelper.retrieveLabel('bg');
  }

  static #convertToMeterUnits(value, meterUnits) {
    return meterUnits === MMOLL ? Math.round((value / MMOL_DIVISOR) * 10) / 10 : value;
  }
}

export default Readings;
