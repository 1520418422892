import moment from 'moment';
import { PAGES } from '~/bundles/shared/constants/pages';
import { CUSTOM_RANGE, TIMEFRAME_VALUES, PDF_TIMEFRAME_VALUES, TWO_WEEKS }
  from '~/bundles/shared/constants/time';

export const PAGE_CHANGED = 'PAGE_CHANGED';
export const READINGS_TYPE_CHANGED = 'READINGS_TYPE_CHANGED';
export const UPDATE_EXERCISE_UNITS = 'UPDATE_EXERCISE_UNITS';
export const UPDATE_EXERCISE_DATA_SOURCES = 'UPDATE_EXERCISE_DATA_SOURCE';
export const UPDATE_TIMEFRAME = 'UPDATE_TIMEFRAME';
export const UPDATE_TIMEFRAME_PDF_WIZARD = 'UPDATE_TIMEFRAME_PDF_WIZARD';
export const UPDATE_TIMESTAMPS = 'UPDATE_TIMESTAMPS';
export const UPDATE_TIMESTAMPS_PDF_WIZARD = 'UPDATE_TIMESTAMPS_PDF_WIZARD';
export const UPDATE_END_TIMESTAMP_MAX = 'UPDATE_END_TIMESTAMP_MAX';
export const UPDATE_PDF_WIZARD_VISIBILITY = 'UPDATE_PDF_WIZARD_VISIBILITY';
export const UPDATE_SYNC_DRAWER_VISIBILITY = 'UPDATE_SYNC_DRAWER_VISIBILITY';
export const UPDATE_SELECTED_OVERRIDE_GUID = 'UPDATE_SELECTED_OVERRIDE_GUID';
export const UPDATE_MEDICATION_OVERRIDE_LIST = 'UPDATE_MEDICATION_OVERRIDE_LIST';
export const UPDATE_INSULIN_SELECTOR_VISIBILITY = 'UPDATE_INSULIN_SELECTOR_VISIBILITY';
export const UPDATE_PAYLOAD_FOR_MEDICATION_SELECTOR = 'UPDATE_PAYLOAD_FOR_MEDICATION_SELECTOR';
export const UPDATE_CONFIRM_DOB_MODAL_VISIBILITY = 'UPDATE_CONFIRM_DOB_MODAL_VISIBILITY';
export const UPDATE_FIND_PATIENT_MODAL_VISIBILITY = 'UPDATE_FIND_PATIENT_MODAL_VISIBILITY';
export const UPDATE_CREATE_NEW_PATIENT_MODAL_VISIBILITY = 'UPDATE_CREATE_NEW_PATIENT_MODAL_VISIBILITY';
export const UPDATE_PATIENT_ICPA_DETAILS_MODAL_VISIBILITY = 'UPDATE_PATIENT_ICPA_DETAILS_MODAL_VISIBILITY';
export const UPDATE_DEVICE_TYPES_FILTER_SELECTION = 'UPDATE_DEVICE_TYPES_FILTER_SELECTION';
export const UPDATE_TERMINALS_FILTER_SELECTION = 'UPDATE_TERMINALS_FILTER_SELECTION';
export const DEVICE_ASSIGN_STATUS = 'DEVICE_ASSIGN_STATUS';
export const UPDATE_ROW_SYNC_DATA = 'UPDATE_ROW_SYNC_DATA';
export const UPDATE_EXPORT_TO_CSV_MODAL_VISIBILITY = 'UPDATE_EXPORT_TO_CSV_MODAL_VISIBILITY';
// DOWNLOAD CSV
export const DOWNLOAD_CSV_IN_PROGRESS = 'DOWNLOAD_CSV_IN_PROGRESS';
export const DOWNLOAD_CSV_SUCCESS = 'DOWNLOAD_CSV_SUCCESS';
export const DOWNLOAD_CSV_FAILED = 'DOWNLOAD_CSV_FAILED';

export const initialState = (() => {
  const selectedPageIndex = 0;
  const {
    id,
    availableTimeFrames,
    timeFrameIndex,
    readingsTypeEnabled,
    readingsTypeSelectorEnabled,
    profileFeaturesEnabled,
    exerciseTypeSelectorEnabled,
    noToggleBg,
    customRangeDaysLimit,
  } = PAGES[selectedPageIndex];
  const timeFrame = availableTimeFrames[timeFrameIndex].value;
  const timeFramePdfWizard = TWO_WEEKS;

  return {
    id,
    showControllerBar: false,
    showNavBar: false,
    selectedPageIndex, // Overview
    timeFrameIndex, // 1
    timeFrameIndexPdfWizard: 2,
    timeFramePdfWizard,
    availableTimeFrames,
    startDate: null,
    startDatePdfWizard: null,
    endDate: null,
    endDatePdfWizard: null,
    endDateCommon: null, // max date allowed for web app
    endDatePDF: null, // max date allowed for PDF
    pdfWizardDisplayed: false,
    readingsTypeEnabled,
    readingsTypeSelectorEnabled,
    profileFeaturesEnabled,
    exerciseTypeSelectorEnabled,
    noToggleBg,
    customRangeDaysLimit,
    exerciseDataSources: {},
    calendarTimeFrameReturn: {
      startDate: null,
      endDate: null,
      enabled: false,
      timeFrameIndex: null,
    },
    exportToCSVModalVisible: false,
    CSVDownloadStatus: '',
    datepickerEnabled: timeFrame === CUSTOM_RANGE,
    pdfWizardDatepickerEnabled: timeFramePdfWizard === CUSTOM_RANGE,
    terminals: [],
    deviceTypes: [],
    assignStatus: {},
    insulinSelectorDisplayed: false,
    medicationOverrideList: null,
  };
})();

export function updatePdfWizardVisibility(pdfWizardDisplayed) {
  return { type: UPDATE_PDF_WIZARD_VISIBILITY, pdfWizardDisplayed };
}

export function updateInsulinSelectorVisibility(insulinSelectorDisplayed) {
  return { type: UPDATE_INSULIN_SELECTOR_VISIBILITY, insulinSelectorDisplayed };
}

export function updateMedicationOverrideList(medicationOverrideList) {
  return { type: UPDATE_MEDICATION_OVERRIDE_LIST, medicationOverrideList };
}

export function updateSelectedOverrideGuid(selectedOverrideGuid) {
  return { type: UPDATE_SELECTED_OVERRIDE_GUID, selectedOverrideGuid };
}

export function updatePayloadForMedicationSelector(payload, rowId) {
  return { type: UPDATE_PAYLOAD_FOR_MEDICATION_SELECTOR, payload, rowId };
}

export function updateConfirmDobModalVisibility(confirmDobModalDisplayed) {
  return { type: UPDATE_CONFIRM_DOB_MODAL_VISIBILITY, confirmDobModalDisplayed };
}

export function updateFindPatientModalVisibility(findPatientModalDisplayed) {
  return { type: UPDATE_FIND_PATIENT_MODAL_VISIBILITY, findPatientModalDisplayed };
}

export function updateCreateNewPatientModalVisibility(createNewPatientModalDisplayed) {
  return { type: UPDATE_CREATE_NEW_PATIENT_MODAL_VISIBILITY, createNewPatientModalDisplayed };
}

export function updatePatientICPADetailsModalVisibility(patientICPADataUpdateFormVisible) {
  return {
    type: UPDATE_PATIENT_ICPA_DETAILS_MODAL_VISIBILITY,
    patientICPADataUpdateFormVisible,
  };
}

export function updateDeviceTypesFilterSelection(deviceTypes) {
  return { type: UPDATE_DEVICE_TYPES_FILTER_SELECTION, deviceTypes };
}

export function updateTerminalsFilterSelection(terminals) {
  return { type: UPDATE_TERMINALS_FILTER_SELECTION, terminals };
}

export function deviceAssignStatus(status) {
  return { type: DEVICE_ASSIGN_STATUS, status };
}

export function updateRowSyncData(rowData) {
  return { type: UPDATE_ROW_SYNC_DATA, rowData };
}

export function updateEndTimestampMax(endDateCommon, endDatePDF) {
  return { type: UPDATE_END_TIMESTAMP_MAX, endDateCommon, endDatePDF };
}

export function updateTimeframe(timeFrameParams) {
  return { type: UPDATE_TIMEFRAME, timeFrameParams };
}

export function updateTimeframePdfWizard(timeFrameParams) {
  return { type: UPDATE_TIMEFRAME_PDF_WIZARD, timeFrameParams };
}

export function updateTimestamps(startDate, endDate) {
  return { type: UPDATE_TIMESTAMPS, startDate, endDate };
}

export function updateTimestampsPdfWizard(startDatePdfWizard, endDatePdfWizard) {
  return { type: UPDATE_TIMESTAMPS_PDF_WIZARD, startDatePdfWizard, endDatePdfWizard };
}

export function pageChange(pageParams) {
  return { type: PAGE_CHANGED, pageParams };
}

export function readingsTypeChanged(readingsType, endDate, startDate) {
  return { type: READINGS_TYPE_CHANGED, readingsType, startDate, endDate };
}

export function updateExerciseDataSources(exerciseDataSources) {
  return { type: UPDATE_EXERCISE_DATA_SOURCES, exerciseDataSources };
}

export function stateOnTimeFrameChange(timeFrame, endDateParam) {
  const datepickerEnabled = timeFrame === CUSTOM_RANGE;

  if (datepickerEnabled) {
    return { datepickerEnabled };
  }
  const startDate =
    moment.utc(endDateParam)
      .subtract({ days: timeFrame - 1 })
      .startOf('day')
      .toISOString();
  const endDate =
    moment.utc(endDateParam)
      .endOf('day')
      .toISOString();

  return { startDate, endDate, datepickerEnabled };
}

export function updateExportCSVModalVisibility(visible) {
  return { type: UPDATE_EXPORT_TO_CSV_MODAL_VISIBILITY, visible };
}

export function downloadCSVInProgress() {
  return { type: DOWNLOAD_CSV_IN_PROGRESS };
}

export function downloadCSVSuccessful() {
  return { type: DOWNLOAD_CSV_SUCCESS };
}

export function downloadCSVFailed() {
  return { type: DOWNLOAD_CSV_FAILED };
}

export default function reducer(state = initialState, action = {}) {
  let startDate;
  let startDatePdfWizard;
  let endDate;
  let endDatePdfWizard;
  const calendarTimeFrameReturn = {
    startDate: null,
    endDate: null,
    enabled: false,
    timeFrameIndex: null,
  };

  switch (action.type) {
    case UPDATE_TIMEFRAME:
      if (action.timeFrameParams.timeFrameIndex === -1 ||
          !TIMEFRAME_VALUES.has(action.timeFrameParams.timeframe)) {
        return { ...state };
      }

      return { ...state, ...action.timeFrameParams, calendarTimeFrameReturn };
    case UPDATE_TIMEFRAME_PDF_WIZARD:
      if (action.timeFrameParams.timeFrameIndexPdfWizard === -1 ||
          !PDF_TIMEFRAME_VALUES.has(action.timeFrameParams.timeFramePdfWizard)) {
        return { ...state };
      }

      return { ...state, ...action.timeFrameParams, calendarTimeFrameReturn };
    case UPDATE_END_TIMESTAMP_MAX:
      return { ...state, endDateCommon: action.endDateCommon, endDatePDF: action.endDatePDF };
    case UPDATE_PDF_WIZARD_VISIBILITY:
      return { ...state, pdfWizardDisplayed: action.pdfWizardDisplayed };
    case UPDATE_INSULIN_SELECTOR_VISIBILITY:
      return { ...state, insulinSelectorDisplayed: action.insulinSelectorDisplayed };
    case UPDATE_MEDICATION_OVERRIDE_LIST:
      return { ...state, medicationOverrideList: action.medicationOverrideList };
    case UPDATE_SELECTED_OVERRIDE_GUID:
      return { ...state, selectedOverrideGuid: action.selectedOverrideGuid };
    case UPDATE_PAYLOAD_FOR_MEDICATION_SELECTOR:
      return {
        ...state,
        payloadForMedicationSelector: action.payload,
        rowIdForMedicationSelector: action.rowId,
      };
    case UPDATE_CONFIRM_DOB_MODAL_VISIBILITY:
      return { ...state, confirmDobModalDisplayed: action.confirmDobModalDisplayed };
    case UPDATE_FIND_PATIENT_MODAL_VISIBILITY:
      return { ...state, findPatientModalDisplayed: action.findPatientModalDisplayed };
    case UPDATE_CREATE_NEW_PATIENT_MODAL_VISIBILITY:
      return { ...state, createNewPatientModalDisplayed: action.createNewPatientModalDisplayed };
    case UPDATE_PATIENT_ICPA_DETAILS_MODAL_VISIBILITY:
      return {
        ...state,
        patientICPADataUpdateFormVisible:
          action.patientICPADataUpdateFormVisible,
      };
    case UPDATE_DEVICE_TYPES_FILTER_SELECTION:
      return { ...state, deviceTypes: action.deviceTypes };
    case UPDATE_TERMINALS_FILTER_SELECTION:
      return { ...state, terminals: action.terminals };
    case DEVICE_ASSIGN_STATUS:
      return { ...state, assignStatus: action.status };
    case UPDATE_ROW_SYNC_DATA:
      return { ...state, rowData: action.rowData };

    case PAGE_CHANGED:
      return {
        ...state,
        ...action.pageParams,
        calendarTimeFrameReturn: (action.pageParams.calendarTimeFrameReturn ||
          calendarTimeFrameReturn),
      };
    case READINGS_TYPE_CHANGED:
      return {
        ...state,
        readingsType: action.readingsType,
        endDate: (action.endDate || state.endDate),
        startDate: (action.startDate || state.startDate),
      };
    case UPDATE_EXERCISE_DATA_SOURCES:
      return { ...state, exerciseDataSources: action.exerciseDataSources };
    case UPDATE_TIMESTAMPS:
      startDate = action.startDate || state.startDate;
      endDate = action.endDate || state.endDate;

      return { ...state, startDate, endDate };
    case UPDATE_TIMESTAMPS_PDF_WIZARD:
      startDatePdfWizard = action.startDatePdfWizard || state.startDatePdfWizard;
      endDatePdfWizard = action.endDatePdfWizard || state.endDatePdfWizard;

      return { ...state, startDatePdfWizard, endDatePdfWizard };
    case UPDATE_EXPORT_TO_CSV_MODAL_VISIBILITY:
      return Object.assign({
        ...state,
        exportToCSVModalVisible: action.visible,
      });
    case DOWNLOAD_CSV_IN_PROGRESS:
      return Object.assign({
        ...state,
        CSVDownloadStatus: DOWNLOAD_CSV_IN_PROGRESS,
      });
    case DOWNLOAD_CSV_SUCCESS:
      return Object.assign({
        ...state,
        CSVDownloadStatus: DOWNLOAD_CSV_SUCCESS,
      });
    case DOWNLOAD_CSV_FAILED:
      return Object.assign({
        ...state,
        CSVDownloadStatus: DOWNLOAD_CSV_FAILED,
      });
    default:
      return state;
  }
}
