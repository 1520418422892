import React from 'react';
import PropTypes from 'prop-types';
import SystemModeChart from
  '~/bundles/shared/components/graphs/SystemModeChart/SystemModeChart.jsx';
import Style from './SystemStats.scss';
import { getTextDirection } from '../../../../../utils/I18n/I18n';

const SystemStats = (props) => {
  const {
    data,
    chartData,
    systemLabel,
    activeTime,
    styleOverride,
  } = props;

  if (!data || !data.length) {
    return null;
  }

  return (
    <div className={Style.systemContainer} style={styleOverride}>
      <div className={Style.titleContainer}>
        <div className={Style.title}>{systemLabel}</div>
        <div className={Style.activeTime}>({activeTime})</div>
      </div>
      <div className={Style.Omnipod5Stats}>
        <SystemModeChart
          data={data}
          chartData={chartData}
          styleOverride={styleOverride}
          isRtl={getTextDirection() === 'rtl'}
        />
      </div>
    </div>
  );
};

SystemStats.propTypes = {
  systemLabel: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    image: PropTypes.string,
    value: PropTypes.number.isRequired,
    activeTime: PropTypes.string.isRequired,
    subModes: PropTypes.any,
  })),
  chartData: PropTypes.array,
  activeTime: PropTypes.string,
  styleOverride: PropTypes.object,
};

SystemStats.defaultProps = {
  activeTime: '-',
  styleOverride: null,
};

export default SystemStats;
