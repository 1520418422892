import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import ImageStore from '~/utils/ImageStore';
import SupportLinks from '~/utils/support';
import Style from './IpgRestrictedPresenter.scss';

const contactGlookoUrl = 'https://get.glooko.com/l/801123/2022-04-26/3rv1vw';
const gssContactGlookoUrl = 'https://glooko.com/contact/';

export class IpgRestrictedPresenter extends Component {
  static propTypes = {
    ipgNoInsuletPump: PropTypes.bool,
    gssNoDevice: PropTypes.bool,
    omnipodWebUploadEnabled: PropTypes.bool,
    isShowingOmnipodUpload: PropTypes.bool,
    actions: PropTypes.shape({
      updateDialogVisibility: PropTypes.func,
    }).isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    ipgNoInsuletPump: false,
    gssNoDevice: false,
    omnipodWebUploadEnabled: false,
    isShowingOmnipodUpload: false,
    isProvider: false,
  };

  openUrlInNewTab = (url) => window.open(url, '_blank');

  handleBack = () => {
    window.open('/', '_self');
  }

  handleContactGlooko = () => {
    const { ipgNoInsuletPump } = this.props;
    if (ipgNoInsuletPump) {
      this.openUrlInNewTab(contactGlookoUrl);
    } else {
      this.openUrlInNewTab(gssContactGlookoUrl);
    }
  }

  handleMoreInfo = () => {
    this.openUrlInNewTab(SupportLinks.article('5630196226579'));
  }

  handleUploadOmnipod = () => {
    this.props.actions.updateDialogVisibility(true);
  }

  render() {
    const { ipgNoInsuletPump, gssNoDevice, omnipodWebUploadEnabled, isShowingOmnipodUpload, t } = this.props;
    const patientRestricted = ipgNoInsuletPump || gssNoDevice;

    if (!patientRestricted || isShowingOmnipodUpload) {
      return null;
    }

    return (
      <div className={Style.IpgRestrictedPresenter}>
        <div className={Style.outerFrame}>
          <div className={Style.headerSection}>
            <button className={Style.backLink} onClick={this.handleBack}>
              <img className={Style.backLinkArrow} src={ImageStore.ipgBackArrow} alt="back" />
              <span>{t('back')}</span>
            </button>
          </div>
          <div className={Style.mainSection}>
            <div className={Style.imageSection}>
              <img className={Style.image} alt="ipg" src={ImageStore.ipgPerson} />
            </div>
            <div className={Style.contentSection}>
              {ipgNoInsuletPump && <div className={Style.title}>{t('title')}</div>}
              <div className={Style.info}>
              {ipgNoInsuletPump ? t('infoText1') : t('gssInfoText')}
              </div>
              {ipgNoInsuletPump &&
              <div className={Style.info}>
                {t('infoText2')}
              </div>}
              <div className={classNames({
                [Style.info]: true,
                [Style.note]: true })}
              >
                {ipgNoInsuletPump ? t('note') : t('gssNote')}
              </div>
              <div className={Style.actionBar}>
                <button
                  className={classNames({
                    [Style.actionButton]: true,
                    [Style.primary]: true })}
                  onClick={this.handleContactGlooko}
                >
                  {t('contactGlooko')}
                </button>
                { ipgNoInsuletPump &&
                  <>
                  <button
                    className={classNames({
                      [Style.actionButton]: true,
                      [Style.secondary]: true })}
                    onClick={this.handleMoreInfo}
                  >
                    {t('moreInformation')}
                  </button>
                  {omnipodWebUploadEnabled &&
                    <button
                      className={classNames({
                        [Style.actionButton]: true,
                        [Style.secondary2]: true })}
                      onClick={this.handleUploadOmnipod}
                    >
                      {t('syncOmnipod')}
                    </button>}
                  </>}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default translate('IpgRestricted')(IpgRestrictedPresenter);
