import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogHeader } from '@glooko/common-ui';
import { translate } from '../WithTranslate/WithTranslate';
import { openSummaryPage, sendData } from '../../../../services/fileuploadApi';
import FileUploaderPopupFailed from './FileUploaderPopupFailed';
import FileUploaderPopupUploading from './FileUploaderPopupUploading';
import FileUploaderPopupSuccess from './FileUploaderPopupSuccess';
import SupportLinks from '../../../../utils/support';
import FileUploaderPopupConfirm from './FileUploaderPopupConfirm';

export const FILE_UPLOAD_POPUP_START = 'FILE_UPLOAD_POPUP_START';
export const FILE_UPLOAD_POPUP_UPLOADING = 'FILE_UPLOAD_POPUP_UPLOADING';
export const FILE_UPLOAD_POPUP_FAILED = 'FILE_UPLOAD_POPUP_FAILED';
export const FILE_UPLOAD_POPUP_SUCCESS = 'FILE_UPLOAD_POPUP_SUCCESS';
export const FILE_UPLOAD_POPUP_CONFIRM = 'FILE_UPLOAD_POPUP_CONFIRM';

const FileUploaderPopup = (props) => {
  const {
    t,
    closePopup,
    startTitle,
    startStep,
    extractorName,
    acceptedExtensions,
    showConfirm,
  } = props;

  const [file, setFile] = useState();
  const [status, setStatus] = useState(FILE_UPLOAD_POPUP_START);

  const fileInput = useRef();

  const uploadFile = async () => {
    await sendData(file, extractorName)
      .then(() => setStatus(FILE_UPLOAD_POPUP_SUCCESS))
      .catch(() => setStatus(FILE_UPLOAD_POPUP_FAILED));
  };

  const onFileChange = (event) => {
    setFile(event.target.files[0]);
    setStatus(showConfirm ? FILE_UPLOAD_POPUP_CONFIRM : FILE_UPLOAD_POPUP_UPLOADING);
  };

  const tryAgain = () => setStatus(FILE_UPLOAD_POPUP_START);
  const confirm = () => setStatus(FILE_UPLOAD_POPUP_UPLOADING);

  useEffect(() => {
    if (status === FILE_UPLOAD_POPUP_UPLOADING) {
      uploadFile();
    }
  }, [status, uploadFile]);

  return (
    <>
      {status === FILE_UPLOAD_POPUP_CONFIRM && (
        <FileUploaderPopupConfirm
          onConfirm={confirm}
          onDecline={tryAgain}
          file={file}
        />
      )}
      {status === FILE_UPLOAD_POPUP_UPLOADING && (
        <FileUploaderPopupUploading />
      )}
      {status === FILE_UPLOAD_POPUP_FAILED && (
        <FileUploaderPopupFailed
          acceptedExtensions={acceptedExtensions}
          onClosePopup={closePopup}
          onTryAgain={tryAgain}
        />
      )}
      {status === FILE_UPLOAD_POPUP_SUCCESS && (
        <FileUploaderPopupSuccess
          onDone={closePopup}
          onViewData={openSummaryPage}
          fileName={file.name}
        />
      )}
      {status === FILE_UPLOAD_POPUP_START && (
        <Dialog open={true} size="medium" onClose={closePopup} dataAttributes={{ testid: 'file-uploader' }}>
          <DialogHeader dataAttributes={{ testid: 'file-uploader' }}>
            <h2>{startTitle}</h2>
          </DialogHeader>
          <DialogContent dataAttributes={{ testid: 'file-uploader' }}>
            {startStep}
            <p>
              {t('needHelp1')}{' '}
              <a
                href={SupportLinks.fileTicket()}
                target="_blank"
              >{t('needHelp2')}
              </a>.
            </p>
            <input
              role="button"
              aria-label="File Upload"
              type="file"
              accept={acceptedExtensions.join(',')}
              style={{ display: 'none' }}
              ref={fileInput}
              onChange={onFileChange}
            />
          </DialogContent>
          <DialogActions dataAttributes={{ testid: 'file-uploader' }}>
            <Button variation="secondary" onClick={closePopup} dataAttributes={{ testid: 'file-uploader-cancel' }}>{t('cancelButton')}</Button>
            <Button onClick={() => fileInput.current.click()} dataAttributes={{ testid: 'file-uploader-upload' }}>{t('uploadButton')}</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

FileUploaderPopup.propTypes = {
  t: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  acceptedExtensions: PropTypes.array.isRequired,
  extractorName: PropTypes.string.isRequired,
  startTitle: PropTypes.string.isRequired,
  startStep: PropTypes.node.isRequired,
  showConfirm: PropTypes.bool,
};

FileUploaderPopup.defaultProps = {
  t: /* istanbul ignore next */ () => { },
  closePopup: /* istanbul ignore next */ () => { },
  acceptedExtensions: [],
  extractorName: '',
  startTitle: '',
  startStep: '',
  showConfirm: false,
};

export default translate('FileUploaderPopup')(FileUploaderPopup);
