import { FETCH_STATUS_NOT_CALLED, FETCH_STATUS_SUCCESSFUL } from '~/bundles/shared/constants/graphs';

export const UPDATE_DEVICES_AND_SETTINGS = 'UPDATE_DEVICES_AND_SETTINGS';
export const UPDATE_DEVICES_AND_SETTINGS_STATUS = 'UPDATE_DEVICES_AND_SETTINGS_STATUS';

export function updateDevicesAndSettings(devicesAndSettings) {
  return { type: UPDATE_DEVICES_AND_SETTINGS, devicesAndSettings };
}

export function updateDevicesAndSettingsStatus(status) {
  return { type: UPDATE_DEVICES_AND_SETTINGS_STATUS, status };
}

const initialState = {
  status: FETCH_STATUS_NOT_CALLED,
  devices: [],
  deviceSettings: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_DEVICES_AND_SETTINGS:
      return {
        ...state,
        ...action.devicesAndSettings,
        status: FETCH_STATUS_SUCCESSFUL,
      };
    case UPDATE_DEVICES_AND_SETTINGS_STATUS:
      return { ...state, status: action.status };
    default:
      return state;
  }
}
