import React from 'react';
import PropTypes from 'prop-types';
import Style from './ButtonAssign.scss';

class ButtonAssign extends React.Component {
  static propTypes = {
    disabled: PropTypes.bool.isRequired,
    float: PropTypes.string,
    onClickCallback: PropTypes.func.isRequired,
    padding: PropTypes.string,
    params: PropTypes.shape({}),
    title: PropTypes.string.isRequired,
  }

  static defaultProps = {
    float: '',
    padding: '',
    params: {},
  }

  constructor(props) {
    super(props);
    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  handleButtonClick() {
    const { params } = this.props;
    this.props.onClickCallback(params);
  }

  render() {
    const { title, disabled, float, padding } = this.props;
    const style = { float, padding };
    return (
      <button
        class={Style.buttonAssign}
        disabled={disabled}
        onClick={this.handleButtonClick}
        type="button"
        style={style}
      >
        {title}
      </button>
    );
  }
}

export default ButtonAssign;
