import React from 'react';
import PropTypes from 'prop-types';
import Style from './SettingsGroup.scss';

const SettingsGroup = (props) => (
  <div className={Style.SettingsGroup}>
    {props.children}
  </div>
);

SettingsGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

SettingsGroup.defaultProps = {
  children: [],
};

export default SettingsGroup;
