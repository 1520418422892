import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import FaAngleDown from 'react-icons/lib/fa/angle-down';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate';
import Style from '../PdfWizard.scss';

const MANAGE_FAVOURITES_LINK = 'settings#manage-favourite-settings';

const SelectFavoriteReport = (props) => {
  const {
    t,
    placeholder,
    selectFavoriteReport,
    favorites,
    selectedFavorite,
    signedInByToken,
  } = props;

  const arrowRenderer = () => (
    <span className={Style.arrowDown}>
      <p><FaAngleDown /></p>
    </span>
  );

  return (
    <div className={Style.Select}>
      <div className={Style.reportTitle}>
        {t('preferredSetting')}
      </div>
      <Select
        placeholder={placeholder}
        arrowRenderer={arrowRenderer}
        className={Style.select}
        multi={false}
        disabled={favorites.length === 0}
        clearable={false}
        onChange={selectFavoriteReport}
        options={favorites}
        searchable={false}
        value={selectedFavorite}
      />
      {!signedInByToken && (
        <div className={Style.manageButtonContainer}>
          <a className={Style.manageButton} href={MANAGE_FAVOURITES_LINK} target="_self">{t('manageFavorites')}</a>
        </div>
      )}
    </div>
  );
};

SelectFavoriteReport.propTypes = {
  placeholder: PropTypes.string.isRequired,
  selectFavoriteReport: PropTypes.func.isRequired,
  favorites: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedFavorite: PropTypes.string.isRequired,
  signedInByToken: PropTypes.bool,
};

export default translate('SelectFavoriteReport')(SelectFavoriteReport);
