import React from 'react';
import PropTypes from 'prop-types';
import deepMerge from 'deepmerge';
import GraphPresenter from '~/bundles/shared/components/graphs/GraphPresenter/GraphPresenter';

export default class PieChart extends React.Component {
  render() {
    const baseConfig = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      plotOptions: {
        pie: {
          size: 50,
          colors: this.props.colors,
          borderWidth: 0,
          allowPointSelect: false,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
        },
      },
    };
    const series = [{
      name: this.props.pieChartName,
      data: this.props.data,
    }];
    return (
      <GraphPresenter
        windowWidth={56}
        windowHeight={56}
        configOverride={deepMerge(baseConfig, this.props.configOverride)}
        series={series}
      />
    );
  }
}

PieChart.propTypes = {
  pieChartName: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])).isRequired,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  configOverride: PropTypes.shape({}),
};

PieChart.defaultProps = {
  data: [],
  colors: [],
  configOverride: {},
  pieChartName: '',
};
