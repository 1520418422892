import classNames from 'classnames';
import Style from './InfoPanelSection.scss';

export interface InfoPanelSectionProps {
  children?: React.ReactNode | React.ReactNode[];
  style?: object;
  title?: string;
  subtitle?: React.ReactNode;
  titleStyle?: object;
  inPdf?: boolean;
  inSummaryPdfCgm?: boolean;
}

const InfoPanelSection = ({ title, subtitle, titleStyle, children, style, inPdf, inSummaryPdfCgm }: InfoPanelSectionProps) => (
  <section className={Style.InfoPanelSection} style={style}>
    {title ? (
      <header
        className={classNames(Style.header, {
          [Style.inPdf]: inPdf,
          [Style.inSummaryPdfCgm]: inSummaryPdfCgm,
        })}
        style={titleStyle}
      >
        <h3 className={Style.title}>{title}&lrm;</h3>
        {subtitle ? (<span className={Style.subtitle}> {subtitle}</span>) : null}
      </header>
    ) : null}

    {children}
  </section>
);

export default InfoPanelSection;
