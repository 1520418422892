import deepMerge from 'deepmerge';
import Readings from '~/services/Readings';
import { USER_TYPE_PATIENT } from '~/bundles/shared/constants/users';

export const USERS_HAS_ERRORED = 'USERS_HAS_ERRORED';
export const USERS_IS_LOADING = 'USERS_IS_LOADING';
export const USERS_FETCH_SUCCESS = 'USERS_FETCH_SUCCESS';
export const UPDATE_ADVISOR_CONSENT_STATE = 'UPDATE_ADVISOR_CONSENT_STATE';
export const UPDATE_INSULET_CONSENT_STATE = 'UPDATE_INSULET_CONSENT_STATE';
export const REPLACE_PATIENT = 'REPLACE_PATIENT';
export const UPDATE_MIDS_TAG_STATE = 'UPDATE_MIDS_TAG_STATE';
export const UPDATE_PATIENT = 'UPDATE_PATIENT';
export const UPDATE_POPTRACKER_VERSION = 'UPDATE_POPTRACKER_VERSION';
export const UPDATE_PATIENT_MRN = 'UPDATE_PATIENT_MRN';
export const UPDATE_PATIENT_FLAGS = 'UPDATE_PATIENT_FLAGS';
export const UPDATE_USER_ENCRYPTED_DATA = 'UPDATE_USER_ENCRYPTED_DATA';
export const USERS_CONSENTS_FETCH_SUCCESS = 'USERS_CONSENTS_FETCH_SUCCESS';
export const USERS_CONSENT_CHOICE_MADE = 'USERS_CONSENT_CHOICE_MADE';
export const USERS_ALERT_CHOICE_MADE = 'USERS_ALERT_CHOICE_MADE';
export const USERS_ALL_CONSENTS_FETCH_SUCCESS = 'USERS_ALL_CONSENTS_FETCH_SUCCESS';
export const USERS_CONSENTS_IS_LOADING = 'USERS_CONSENTS_IS_LOADING';
export const USERS_SEND_GUARDIAN_EMAIL_STATUS = 'USERS_SEND_GUARDIAN_EMAIL_STATUS';
export const UPDATE_PARENTAL_CONSENT_EMAIL_FLAG = 'UPDATE_PARENTAL_CONSENT_EMAIL_FLAG';

export function usersHasErrored(bool) {
  return { type: USERS_HAS_ERRORED, hasErrored: bool };
}

export function usersIsLoading(bool) {
  return { type: USERS_IS_LOADING, isLoading: bool };
}

export function usersReplacePatient(currentPatient) {
  return { type: REPLACE_PATIENT, currentPatient };
}

export function usersUpdatePatient(patientParams) {
  return { type: UPDATE_PATIENT, patientParams };
}

export function usersFetchSuccess(currentUser, currentPatient) {
  return {
    type: USERS_FETCH_SUCCESS,
    currentUsers: { currentUser, currentPatient },
  };
}

export function updateAdvisorConsentState(newState) {
  return { type: UPDATE_ADVISOR_CONSENT_STATE, advisorConsent: newState };
}

export function updateMidsTagState(newState) {
  return { type: UPDATE_MIDS_TAG_STATE, midsTag: newState };
}

export function updatePatientTagsState(tags) {
  return { type: UPDATE_PATIENT_TAGS_STATE, tags };
}

export function updateInsuletConsent(newState) {
  return { type: UPDATE_INSULET_CONSENT_STATE, insuletConsent: newState };
}

export const updatePoptrackerVersionState = (poptrackerVersion) => ({
  type: UPDATE_POPTRACKER_VERSION, payload: { poptrackerVersion },
});

export function updatePatientMrnState(newState) {
  return { type: UPDATE_PATIENT_MRN, patientMrn: newState };
}

export function updateResendParentalConsentEmail(newState) {
  return { type: UPDATE_PARENTAL_CONSENT_EMAIL_FLAG, isParentalEmailSent: newState };
}

export function updatePatientFlags(flags) {
  return { type: UPDATE_PATIENT_FLAGS, flags: flags };
}

export function updateUserEncryptedData(encryptedDetails) {
  return {
    type: UPDATE_USER_ENCRYPTED_DATA,
    encryptedDetails,
  };
}

export function userPendingConsentsFetchReceived(pendingConsents) {
  return {
    type: USERS_CONSENTS_FETCH_SUCCESS,
    pendingConsents,
  };
}

export function userAllConsentsFetchReceived(consents) {
  return {
    type: USERS_ALL_CONSENTS_FETCH_SUCCESS,
    consents,
  };
}

export function userPendingConsentChoiceMade(partner) {
  return {
    type: USERS_CONSENT_CHOICE_MADE,
    partner,
  };
}

export function userAlertChoiceMade(partner, alertMinor) {
  return {
    type: USERS_ALERT_CHOICE_MADE,
    partner,
    alertMinor,
  };
}

export function userConsentsFetchLoading(bool) {
  return { type: USERS_CONSENTS_IS_LOADING, isLoading: bool };
}

export function updateSendGuardianEmailStatus(status) {
  return {
    type: USERS_SEND_GUARDIAN_EMAIL_STATUS,
    status,
  };
}

const initialUserState = {
  id: '',
  firstName: '',
  lastName: '',
  userType: USER_TYPE_PATIENT,
  admin: false,
  insulinData: false,
  lastSyncTimestamps: {
    lastSyncTimestamp: null,
    meter: null,
    cgmDevice: null,
    pump: null,
    kiosk: null,
    insulinPen: null,
  },
  preference: {
    afterMealNormalGlucoseMax: Readings.normalizeValue(Readings.AFTER_MAX),
    beforeMealNormalGlucoseMax: Readings.normalizeValue(Readings.BEFORE_MAX),
    normalGlucoseMin: Readings.normalizeValue(Readings.NORMAL_MIN),
    breakfastBegin: Readings.BREAKFAST_BEGIN,
    dinnerBegin: Readings.DINNER_BEGIN,
    lunchBegin: Readings.LUNCH_BEGIN,
    meterUnits: Readings.MGDL,
    midnightSnackBegin: Readings.MIDNIGHT_SNACK_BEGIN,
  },
  syncedExerciseApps: [],
  twoFactorEnabled: false,
  pendingConsents: [],
  consents: [],
};

const userEncryptedData = {
  message: '',
  nonce: '',
};

export const initialState = {
  currentUsers: {
    currentUser: { ...initialUserState, userEncryptedData },
    currentPatient: { ...initialUserState },
  },
  isLoading: false,
  hasErrored: false,
  isConsentsLoading: false,
  isParentalEmailSent: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case USERS_FETCH_SUCCESS:
      return Object.assign({}, state,
        {
          currentUsers: {
            ...state.currentUsers,
            currentUser: {
              ...state.currentUsers.currentUser,
              ...action.currentUsers.currentUser,
            },
            currentPatient: action.currentUsers.currentPatient,
          },
        },
      );
    case USERS_IS_LOADING:
      return Object.assign(
        {}, state, { isLoading: action.isLoading },
      );
    case USERS_HAS_ERRORED:
      return Object.assign(
        {}, state, { hasErrored: action.hasErrored },
      );
    case UPDATE_ADVISOR_CONSENT_STATE:
      return Object.assign(
        {}, state,
        {
          currentUsers: {
            ...state.currentUsers,
            currentUser: {
              ...state.currentUsers.currentUser,
              advisorConsent: action.advisorConsent,
            },
          },
        },
      );
    case UPDATE_INSULET_CONSENT_STATE:
      return Object.assign(
        {}, state,
        {
          currentUsers: {
            ...state.currentUsers,
            currentPatient: {
              ...state.currentUsers.currentPatient,
              insuletConsent: action.insuletConsent,
            },
          },
        },
      );
    case REPLACE_PATIENT: {
      return Object.assign(
        {}, state,
        {
          currentUsers: { ...state.currentUsers, currentPatient: action.currentPatient },
        },
      );
    }
    case UPDATE_PATIENT: {
      return deepMerge(Object.assign({}, state), {
        currentUsers: {
          currentPatient: action.patientParams,
        },
      });
    }
    case UPDATE_MIDS_TAG_STATE:
      return Object.assign(
        {}, state,
        {
          currentUsers: {
            ...state.currentUsers,
            currentPatient: {
              ...state.currentUsers.currentPatient,
              midsTag: action.midsTag,
            },
          },
        },
      );
    case UPDATE_POPTRACKER_VERSION:
      return Object.assign(
        {}, state,
        {
          currentUsers: {
            ...state.currentUsers,
            currentUser: {
              ...state.currentUsers.currentUser,
              poptrackerVersion: action.payload.poptrackerVersion,
            },
          },
        },
      );
    case UPDATE_PATIENT_MRN:
      return deepMerge(Object.assign({}, state), {
        currentUsers: {
          currentPatient: action.patientMrn,
        },
      });
    case UPDATE_PATIENT_FLAGS:
      return Object.assign(
        {}, state,
        {
          currentUsers: {
            ...state.currentUsers,
            currentPatient: {
              ...state.currentUsers.currentPatient,
              flags: action.flags,
            },
          },
        },
      );
    case UPDATE_USER_ENCRYPTED_DATA:
      return Object.assign(
        {}, state,
        {
          currentUsers: {
            ...state.currentUsers,
            currentUser: {
              ...state.currentUsers.currentUser,
              userEncryptedData: action.encryptedDetails,
            },
          },
        },
      );
    case USERS_CONSENTS_FETCH_SUCCESS:
      return Object.assign(
        {}, state,
        {
          currentUsers: {
            ...state.currentUsers,
            currentUser: {
              ...state.currentUsers.currentUser,
              pendingConsents: action.pendingConsents,
            },
          },
        },
      );
    case USERS_ALL_CONSENTS_FETCH_SUCCESS:
      return Object.assign(
        {}, state,
        {
          currentUsers: {
            ...state.currentUsers,
            currentUser: {
              ...state.currentUsers.currentUser,
              consents: action.consents,
            },
          },
        },
      );
    case USERS_CONSENT_CHOICE_MADE:
      const { pendingConsents } = state.currentUsers.currentUser;
      const consentIndex = pendingConsents.findIndex((consent) => consent.partner === action.partner);
      const adjustedConsents = pendingConsents.slice();
      if (consentIndex >= 0) {
        adjustedConsents.splice(consentIndex, 1);
      }

      return Object.assign(
        {}, state,
        {
          currentUsers: {
            ...state.currentUsers,
            currentUser: {
              ...state.currentUsers.currentUser,
              pendingConsents: adjustedConsents,
            },
          },
        },
      );
    case USERS_ALERT_CHOICE_MADE:
      const pendingConsentList = state.currentUsers.currentUser.pendingConsents;
      const index = pendingConsentList.findIndex((consent) => consent.partner === action.partner);
      pendingConsentList[index].alertMinor = action.alertMinor;

      return Object.assign(
        {}, state,
        {
          currentUsers: {
            ...state.currentUsers,
            currentUser: {
              ...state.currentUsers.currentUser,
              pendingConsents: pendingConsentList,
            },
          },
        },
      );
    case USERS_CONSENTS_IS_LOADING:
      return Object.assign(
        {}, state, { isConsentsLoading: action.isLoading },
      );
    case USERS_SEND_GUARDIAN_EMAIL_STATUS:
      return Object.assign(
        {}, state, { sendGuardianEmailStatus: action.status },
      );
    case UPDATE_PARENTAL_CONSENT_EMAIL_FLAG:
      return Object.assign(
        {}, state, { isParentalEmailSent: action.isParentalEmailSent },
      );
    default:
      return state;
  }
}
