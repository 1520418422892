import React from 'react';
import moment from 'moment';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import { TIME_FORMATS, localizedPercentage } from '~/utils/i18nFormats';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tooltip';
import Readings from '~/services/Readings';
import styles from './PatientTirColumn.scss';

class PatientTirColumn extends React.Component {
  static propTypes = {
    t: PropTypes.func,
    patientId: PropTypes.string.isRequired,
    meterUnits: PropTypes.string,
    cgmTirPercentage: PropTypes.number,
    cgmTirStartDate: PropTypes.string,
    cgmTirEndDate: PropTypes.string,
    cgmPercentTimeActive: PropTypes.number,
    cgmTargetRangeHigh: PropTypes.number,
    cgmTargetRangeLow: PropTypes.number,
    meterAverageReadingsPerDay: PropTypes.number,
    meterTargetRangeHigh: PropTypes.number,
    meterTargetRangeLow: PropTypes.number,
    meterTirStartDate: PropTypes.string,
    meterTirEndDate: PropTypes.string,
    meterTirPercentage: PropTypes.number,
  }

  hasColumnData() {
    const { cgmTirPercentage, meterTirPercentage } = this.props;
    return (cgmTirPercentage !== null || meterTirPercentage !== null);
  }

  tirPercentageString(cgmTirPercentage, meterTirPercentage) {
    const tirPercentage = (cgmTirPercentage !== null) ? cgmTirPercentage : meterTirPercentage;
    return localizedPercentage(Math.round(tirPercentage));
  }

  renderDateRangeString(startDate, endDate) {
    return `${moment.utc(startDate).format(TIME_FORMATS.MM_DD_YYYY)} - ${moment.utc(endDate).format(TIME_FORMATS.MM_DD_YYYY)}`;
  }

  renderTargetRangeString(rangeLow, rangeHigh) {
    const { meterUnits } = this.props;
    const denormalizedLow = Readings.denormalizedValue(rangeLow, meterUnits);
    const denormalizedHigh = Readings.denormalizedValue(rangeHigh, meterUnits);
    return Readings.displayRangeValue({ lowVal: denormalizedLow, highVal: denormalizedHigh, meterUnits: meterUnits });
  }

  renderMeterTooltip() {
    const {
      t,
      meterTirStartDate,
      meterTirEndDate,
      meterAverageReadingsPerDay,
      meterTargetRangeLow,
      meterTargetRangeHigh,
    } = this.props;
    return (
      <div className={styles.tooltip}>
        <span className={styles.tooltipHeader}>{t('timeInRange')}</span>
        <div className={styles.tooltipGroup}>
          <span className={styles.tooltipSubheader}>{t('dateRange')}</span>
          <span>{this.renderDateRangeString(meterTirStartDate, meterTirEndDate)}</span>
        </div>
        <div className={styles.tooltipGroup}>
          <span className={styles.tooltipSubheader}>{t('avgReadingsDaily')}</span>
          <span>{meterAverageReadingsPerDay}</span>
        </div>
        <div className={styles.tooltipGroup}>
          <span className={styles.tooltipSubheader}>{t('targetRange')}</span>
          <span>{this.renderTargetRangeString(meterTargetRangeLow, meterTargetRangeHigh)}</span>
        </div>
      </div>
    );
  }

  renderCgmTooltip() {
    const {
      t,
      cgmTirStartDate,
      cgmTirEndDate,
      cgmPercentTimeActive,
      cgmTargetRangeLow,
      cgmTargetRangeHigh,
    } = this.props;
    return (
      <div className={styles.tooltip}>
        <span className={styles.tooltipHeader}>{t('timeInRange')}</span>
        <div className={styles.tooltipGroup}>
          <span className={styles.tooltipSubheader}>{t('dateRange')}</span>
          <span>{this.renderDateRangeString(cgmTirStartDate, cgmTirEndDate)}</span>
        </div>
        <div className={styles.tooltipGroup}>
          <span className={styles.tooltipSubheader}>{t('percentageCgmActive')}</span>
          <span>{localizedPercentage(Math.round(cgmPercentTimeActive))}</span>
        </div>
        <div className={styles.tooltipGroup}>
          <span className={styles.tooltipSubheader}>{t('targetRange')}</span>
          <span>{this.renderTargetRangeString(cgmTargetRangeLow, cgmTargetRangeHigh)}</span>
        </div>
      </div>
    );
  }

  render() {
    const { cgmTirPercentage, meterTirPercentage, patientId } = this.props;
    const tooltipId = `tir-${patientId}`;
    return (
      <>
        <span
          className={styles.patientTirColumn}
          data-tooltip-variant='light'
          data-tooltip-id={tooltipId}
          data-tooltip-place='top'
          data-tooltip-float={true}
        >
          {(this.hasColumnData()) && this.tirPercentageString(cgmTirPercentage, meterTirPercentage)}
        </span>
        {
          this.hasColumnData() &&
          <Tooltip id={tooltipId} className={styles.tooltip}>
            {(cgmTirPercentage !== null) ? this.renderCgmTooltip() : this.renderMeterTooltip()}
          </Tooltip>
        }
      </>
    );
  }
}

export default translate('PatientTirColumn')(PatientTirColumn);
