import moment from 'moment';
import { TIME_FORMATS } from 'utils/i18nFormats';
import classNames from 'classnames';
import Style from './DateRangeTooltip.scss';
import TooltipStyle from '../Tooltip.scss';

const DateRangeTooltip = ({ startTimestamp, endTimestamp }: { startTimestamp: string, endTimestamp?: string }) => {
  const timeFormat = () => {
    const startTime = moment.utc(startTimestamp).format(TIME_FORMATS.MMMM_DO_YYYY_H_MMA as unknown as string);
    const endTime = moment.utc(endTimestamp).format(TIME_FORMATS.H_MM_A as unknown as string);

    return endTimestamp === undefined ? startTime : `${startTime} - ${endTime}`;
  };

  return (
    <section className={classNames(Style.DateRangeTooltip, TooltipStyle.row)}>
      <span className={Style.date}>
        {timeFormat()}
      </span>
    </section>
  );
};

export default DateRangeTooltip;
