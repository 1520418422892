import React from 'react';
import PropTypes from 'prop-types';
import { singleGlucoseColumnConfig, singleGlucoseColumnSeries } from './singleGlucoseColumnConfig';
import GraphPresenter from '../../GraphPresenter/GraphPresenter';
import Style from './SingleGlucoseColumn.scss';

export const SingleGlucoseColumn = ({ statistics, configOverride, inPdf }) => {
  const series = singleGlucoseColumnSeries(statistics, inPdf);
  const config = singleGlucoseColumnConfig(statistics, configOverride);
  return (
    <div className={Style.SingleGlucoseColumn}>
      <GraphPresenter configOverride={config} series={series} dataLabelsEnabled={false} />
    </div>
  );
};

SingleGlucoseColumn.propTypes = {
  configOverride: PropTypes.shape({}),
  statistics: PropTypes.shape({
    count: PropTypes.number,
    lowPercentage: PropTypes.number.isRequired,
    normalPercentage: PropTypes.number.isRequired,
    highPercentage: PropTypes.number.isRequired,
  }).isRequired,
  inPdf: PropTypes.bool.isRequired,
};

SingleGlucoseColumn.defaultProps = {
  configOverride: {},
  statistics: {
    count: 0,
    lowPercentage: 33.3,
    normalPercentage: 33.3,
    highPercentage: 33.3,
  },
  inPdf: false,
};

export default SingleGlucoseColumn;
