import moment from 'moment';
import i18next from 'utils/I18n/I18n';
import PregnancyUtils from '~/utils/pregnancyUtils';
import TimeUtils from '~/utils/TimeUtils/TimeUtils';
import Images from '~/utils/ImageStore';

const t = i18next.getFixedT(null, 'PatientListTable');

export const findLastSync = (timestamps) => {
  if (timestamps.length === 0) {
    return '';
  }

  // eslint-disable-next-line no-confusing-arrow
  const lastDate = timestamps.reduce((maxTimestamp, currentTimestamp) => (currentTimestamp > maxTimestamp) ?
    currentTimestamp : maxTimestamp, timestamps[0]);
  return moment.utc(lastDate).format('YYYY-MM-DD');
};

export const addHediaTags = (patients) => patients.map((patient) => {
  const { tags, hediaEnabled } = patient;

  if (hediaEnabled) {
    const hediaTag = {
      category: 'hedia',
      tags: [{ id: 'hedia-tag', label: 'Hedia', class: 'hedia' }],
    };
    return {
      ...patient,
      tags: [hediaTag, ...tags],
    };
  }

  return patient;
});

export const addPregnancyTags = (patients, weeksLabelPrefix) => patients.map((patient) => {
  const { tags, dueDate } = patient;

  if (dueDate) {
    const weeksAndDaysActive = PregnancyUtils.calculatePregnancyWeeksAndDays(
      dueDate,
      TimeUtils.generateUtcTimestampNoTZ(),
    );

    if (weeksAndDaysActive.activePregnancy) {
      const weeksLabel = `${weeksLabelPrefix} ${weeksAndDaysActive.weeks}+${weeksAndDaysActive.days}`;
      const pregnancyTag = {
        category: 'pregnancy',
        tags: [
          {
            id: 'pregnancy-tag',
            label: weeksLabel,
            class: 'pregnancy',
            image: Images.pregnancyIcon,
          },
        ],
      };
      return {
        ...patient,
        tags: [pregnancyTag, ...tags],
      };
    }
  }

  return patient;
});

export const mapFlagsAndPatients = (patients, searchFilterFlags, flagTranslations) => patients.map((patient) => {
  const { flagsIds = [], tags } = patient;
  const flags = [];

  if (flagsIds.length > 0 && searchFilterFlags.length > 0) {
    flagsIds.forEach((flagId) => {
      const flag = searchFilterFlags.find((x) => x.originalId === flagId);

      if (flag && flagTranslations[flag.originalId] && flag.ownedByGlooko) {
        const translatedFlag = {
          ...flag,
          label: flagTranslations[flag.originalId].label,
          description: flagTranslations[flag.originalId].description,
        };
        flags.push(translatedFlag);
      } else if (flag) {
        flags.push(flag);
      }
    });
  }

  const patientTags = [
    ...tags,
    {
      category: 'flags',
      tags: flags,
    },
  ];

  return { ...patient, tags: patientTags };
});

export const getFlagTranslations = (isMGDL) => {
  const units = isMGDL ? t('mgdl') : t('mmoll');
  return {
    dropout_risk: {
      label: t('dropoutRiskLabel'),
      description: t('dropoutRiskDescription'),
    },
    hyper_rate: {
      label: t('hyperRateLabel'),
      description: t('hyperRateDescription', { defaultValue: isMGDL ? 180 : 10, units }),
    },
    hypo_rate: {
      label: t('hypoRateLabel'),
      description: t('hypoRateDescription', { defaultValue: isMGDL ? 70 : 3.9, units }),
    },
    marked_high: {
      label: t('markedHighLabel'),
      description: t('markedHighDescription', { defaultValue: isMGDL ? 250 : 13.9, units }),
    },
    marked_low: {
      label: t('markedLowLabel'),
      description: t('markedLowDescription', { defaultValue: isMGDL ? 54 : 3, units }),
    },
  };
};
