import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { range } from 'lodash';
import { TYPE_BG, TYPE_CGM } from '~/bundles/shared/constants/readings';
import { FETCH_STATUS_NOT_CALLED, FETCH_STATUSES, FETCH_STATUS_SUCCESSFUL, FETCH_STATUS_FAILED } from '~/bundles/shared/constants/graphs';
import Readings from '~/services/Readings';
import Colors from '~/../assets/styles/export/colors';
import { SECONDS_IN_DAY } from '~/bundles/shared/constants/time';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate';
import OverviewGraphRow from '../OverviewGraphRow/OverviewGraphRow';
import BGGraph from '../../Graphs/BGGraph/BGGraph';
import InsulinGraph from '../../Graphs/InsulinGraph/InsulinGraph';
import WithLoadingState from '~/bundles/shared/components/WithLoadingState/WithLoadingState';
import Spinner from '~/bundles/shared/components/Spinner/Spinner';
import CarbsGraph from '../../Graphs/CarbsGraph/CarbsGraph';
import InsulinInlineGraph from '../../Graphs/InsulinInlineGraph/InsulinInlineGraph';
import Style from './OverviewGraphPresenter.scss';
import OverviewGraphExerciseRow from './OverviewGraphExerciseRow/OverviewGraphExerciseRow';

const OverviewGraphPresenter = (props) => {
  const {
    carbsSeries,
    insulinPumpSeries,
    insulinManualSeries,
    exerciseSeries,
    stepsSeries,
    insulinInlinePumpSeries,
    insulinInlineManualSeries,
  } = props;
  const { hasCarbs, hasExercise, hasSteps, hasInsulinPump, hasInsulinManual } = props;
  const { t, bgGraphAxisEndIndex, inPdf, startTimestamp, endTimestamp } = props;
  const days = Math.round((endTimestamp - startTimestamp) / SECONDS_IN_DAY);
  const step = days > 30 && !props.inPdf ? 7 : 1;
  const firstStepOffset = days > 30 && !props.inPdf ? days % 7 : 0;
  const configOverride = {
    xAxis: {
      plotLines: range(
        (Math.floor(props.startTimestamp / SECONDS_IN_DAY) * SECONDS_IN_DAY) +
        (firstStepOffset * SECONDS_IN_DAY),
        (Math.ceil(props.endTimestamp / SECONDS_IN_DAY) * SECONDS_IN_DAY) - 0.1,
        SECONDS_IN_DAY * step,
      ).map((elem) => ({
        color: Colors.borderGrey,
        width: 1,
        dashStyle: 'solid',
        value: elem,
        zIndex: 2,
      })),
    },
  };
  const bgGraphPdf = [...props.bgGraphSeries, ...props.cgmGraphSeries];
  const bgGraphWeb = props.readingsType === TYPE_BG ? props.bgGraphSeries : props.cgmGraphSeries;
  const bgGraphSeries = props.inPdf ? bgGraphPdf : bgGraphWeb;

  return (
    <div class={Style.OverviewGraphPresenter}>
      <OverviewGraphRow
        startTimestamp={props.startTimestamp}
        endTimestamp={props.endTimestamp}
        windowHeight={props.inPdf ? 300 : 250}
        axisStartIndex={0}
        axisEndIndex={bgGraphAxisEndIndex}
        axisTickStep={props.meterUnits === Readings.MMOLL ? 6 : 100}
        axisTitle={t('glucoseTitle')}
        axisSubtitle={`(${Readings.displayMeterUnits(props.meterUnits)})`}
        axisWidth={props.axisWidth}
        oneDayWidth={props.oneDayWidth}
        inPdf={inPdf}
      >
        <div class={classNames(Style.borderTop, Style.borderBottom, { [Style.borderRight]: inPdf })}>
          <BGGraph
            startTimestamp={props.startTimestamp}
            endTimestamp={props.endTimestamp}
            series={bgGraphSeries}
            windowHeight={props.inPdf ? 300 : 250}
            windowWidth={props.graphsWindowWidth}
            yAxisMax={bgGraphAxisEndIndex}
            fetchStatus={props.fetchStatus}
            configOverride={configOverride}
          />
        </div>
      </OverviewGraphRow>
      {hasCarbs ? (
        <OverviewGraphRow
          startTimestamp={props.startTimestamp}
          endTimestamp={props.endTimestamp}
          windowHeight={inPdf ? 48 : 72}
          axisStartIndex={0}
          axisEndIndex={inPdf ? 100 : props.carbsAxisEndIndex}
          axisTickStep={Math.floor(props.carbsAxisEndIndex / 2)}
          axisTitle={t('carbsTitle')}
          axisSubtitle={`(${t('grams')})`}
          axisWidth={props.axisWidth}
          oneDayWidth={props.oneDayWidth}
          inPdf={inPdf}
          axisHide={inPdf}
        >
          <div class={classNames(Style.borderBottom, { [Style.borderRight]: inPdf })}>
            <CarbsGraph
              startTimestamp={props.startTimestamp}
              endTimestamp={props.endTimestamp}
              series={carbsSeries}
              windowHeight={props.inPdf ? 48 : 72}
              windowWidth={props.graphsWindowWidth}
              yAxisMax={inPdf ? 100 : props.carbsAxisEndIndex}
              fetchStatus={props.fetchStatus}
              configOverride={configOverride}
            />
          </div>
        </OverviewGraphRow>
      ) : null}
      {hasInsulinPump && inPdf ? (
        <OverviewGraphRow
          startTimestamp={props.startTimestamp}
          endTimestamp={props.endTimestamp}
          windowHeight={48}
          axisStartIndex={0}
          axisEndIndex={0}
          axisTickStep={0}
          axisTitle={hasInsulinManual ? t('insulinDeviceTotal') : t('insulinTotal')}
          axisHide
          axisSubtitle={`(${t('units')})`}
          axisWidth={props.axisWidth}
          oneDayWidth={props.oneDayWidth}
          inPdf={inPdf}
        >
          <div class={classNames(Style.borderBottom, { [Style.borderRight]: inPdf })}>
            <InsulinInlineGraph
              startTimestamp={props.startTimestamp}
              endTimestamp={props.endTimestamp}
              series={insulinInlinePumpSeries}
              windowHeight={48}
              windowWidth={props.graphsWindowWidth}
              yAxisMax={100}
              fetchStatus={props.fetchStatus}
              configOverride={configOverride}
            />
          </div>
        </OverviewGraphRow>
      ) : null}
      {hasInsulinPump && inPdf ? (
        <OverviewGraphRow
          startTimestamp={props.startTimestamp}
          endTimestamp={props.endTimestamp}
          windowHeight={props.inPdf ? 100 : 72}
          axisStartIndex={0}
          axisEndIndex={props.insulinPumpAxisEndIndex}
          axisTickStep={Math.floor(props.insulinPumpAxisEndIndex / 2)}
          axisTitle={hasInsulinManual ? t('insulinDevice') : t('insulin')}
          axisSubtitle={`(${t('units')})`}
          axisWidth={props.axisWidth}
          oneDayWidth={props.oneDayWidth}
          inPdf={inPdf}
        >
          <div class={classNames(Style.borderBottom, { [Style.borderRight]: inPdf })}>
            <InsulinGraph
              startTimestamp={props.startTimestamp}
              endTimestamp={props.endTimestamp}
              series={insulinPumpSeries}
              windowHeight={inPdf ? 100 : 72}
              windowWidth={props.graphsWindowWidth}
              yAxisMax={props.insulinPumpAxisEndIndex}
              fetchStatus={props.fetchStatus}
              configOverride={configOverride}
            />
          </div>
        </OverviewGraphRow>
      ) : null}
      {hasInsulinManual && inPdf ? (
        <OverviewGraphRow
          startTimestamp={props.startTimestamp}
          endTimestamp={props.endTimestamp}
          windowHeight={48}
          axisStartIndex={0}
          axisEndIndex={0}
          axisTickStep={0}
          axisTitle={hasInsulinPump ? t('insulinManualTotal') : t('insulinTotal')}
          axisHide
          axisSubtitle={`(${t('units')})`}
          axisWidth={props.axisWidth}
          oneDayWidth={props.oneDayWidth}
          inPdf={inPdf}
        >
          <div class={classNames(Style.borderBottom, { [Style.borderRight]: inPdf })}>
            <InsulinInlineGraph
              startTimestamp={props.startTimestamp}
              endTimestamp={props.endTimestamp}
              series={insulinInlineManualSeries}
              windowHeight={48}
              windowWidth={props.graphsWindowWidth}
              yAxisMax={100}
              fetchStatus={props.fetchStatus}
              configOverride={configOverride}
            />
          </div>
        </OverviewGraphRow>
      ) : null}
      {hasInsulinManual && inPdf ? (
        <OverviewGraphRow
          startTimestamp={props.startTimestamp}
          endTimestamp={props.endTimestamp}
          windowHeight={props.inPdf ? 100 : 72}
          axisStartIndex={0}
          axisEndIndex={props.insulinManualAxisEndIndex}
          axisTickStep={Math.floor(props.insulinManualAxisEndIndex / 2)}
          axisTitle={hasInsulinPump ? t('insulinManual') : t('insulin')}
          axisSubtitle={`(${t('units')})`}
          axisWidth={props.axisWidth}
          oneDayWidth={props.oneDayWidth}
          inPdf={inPdf}
        >
          <div class={classNames(Style.borderBottom, { [Style.borderRight]: inPdf })}>
            <InsulinGraph
              startTimestamp={props.startTimestamp}
              endTimestamp={props.endTimestamp}
              series={insulinManualSeries}
              windowHeight={inPdf ? 100 : 72}
              windowWidth={props.graphsWindowWidth}
              yAxisMax={props.insulinManualAxisEndIndex}
              fetchStatus={props.fetchStatus}
              configOverride={configOverride}
            />
          </div>
        </OverviewGraphRow>
      ) : null}
      {hasInsulinPump && !inPdf &&
        <OverviewGraphRow
          startTimestamp={props.startTimestamp}
          endTimestamp={props.endTimestamp}
          windowHeight={props.inPdf ? 100 : 72}
          axisStartIndex={0}
          axisEndIndex={props.insulinPumpAxisEndIndex}
          axisTickStep={Math.floor(props.insulinPumpAxisEndIndex / 2)}
          axisTitle={t('insulinDevice')}
          axisSubtitle={`(${t('units')})`}
          axisWidth={props.axisWidth}
          oneDayWidth={props.oneDayWidth}
          inPdf={inPdf}
        >
          <div class={classNames(Style.borderBottom, { [Style.borderRight]: inPdf })}>
            <InsulinGraph
              startTimestamp={props.startTimestamp}
              endTimestamp={props.endTimestamp}
              series={insulinPumpSeries}
              windowHeight={inPdf ? 100 : 72}
              windowWidth={props.graphsWindowWidth}
              yAxisMax={props.insulinPumpAxisEndIndex}
              fetchStatus={props.fetchStatus}
              configOverride={configOverride}
            />
          </div>
        </OverviewGraphRow>}
      {hasInsulinManual && !inPdf &&
        <OverviewGraphRow
          startTimestamp={props.startTimestamp}
          endTimestamp={props.endTimestamp}
          windowHeight={props.inPdf ? 100 : 72}
          axisStartIndex={0}
          axisEndIndex={props.insulinManualAxisEndIndex}
          axisTickStep={Math.floor(props.insulinManualAxisEndIndex / 2)}
          axisTitle={t('insulinManual')}
          axisSubtitle={`(${t('units')})`}
          axisWidth={props.axisWidth}
          oneDayWidth={props.oneDayWidth}
          inPdf={inPdf}
        >
          <div class={classNames(Style.borderBottom, { [Style.borderRight]: inPdf })}>
            <InsulinGraph
              startTimestamp={props.startTimestamp}
              endTimestamp={props.endTimestamp}
              series={insulinManualSeries}
              windowHeight={inPdf ? 100 : 72}
              windowWidth={props.graphsWindowWidth}
              yAxisMax={props.insulinManualAxisEndIndex}
              fetchStatus={props.fetchStatus}
              configOverride={configOverride}
            />
          </div>
        </OverviewGraphRow>}
      {!hasInsulinPump && !hasInsulinManual && !inPdf &&
        <OverviewGraphRow
          startTimestamp={props.startTimestamp}
          endTimestamp={props.endTimestamp}
          windowHeight={72}
          axisStartIndex={0}
          axisEndIndex={10}
          axisTickStep={5}
          axisTitle={t('insulin')}
          axisSubtitle={`(${t('units')})`}
          axisWidth={props.axisWidth}
          oneDayWidth={props.oneDayWidth}
          inPdf={inPdf}
        >
          <div class={classNames(Style.borderBottom, { [Style.borderRight]: inPdf })}>
            <InsulinGraph
              startTimestamp={props.startTimestamp}
              endTimestamp={props.endTimestamp}
              series={[]}
              windowHeight={72}
              windowWidth={props.graphsWindowWidth}
              yAxisMax={10}
              fetchStatus={props.fetchStatus}
              configOverride={configOverride}
            />
          </div>
        </OverviewGraphRow>}

      <OverviewGraphExerciseRow
        show={hasSteps}
        startTimestamp={props.startTimestamp}
        endTimestamp={props.endTimestamp}
        axisEndIndex={props.stepsAxisEndIndex}
        axisTitle={t('stepsTitle')}
        axisSubtitle={t('stepsSubtitle')}
        axisWidth={props.axisWidth}
        oneDayWidth={props.oneDayWidth}
        inPdf={inPdf}
        configOverride={configOverride}
        fetchStatus={props.fetchStatusExercise}
        graphsWindowWidth={props.graphsWindowWidth}
        series={stepsSeries}
      />

      <OverviewGraphExerciseRow
        show={hasExercise}
        startTimestamp={props.startTimestamp}
        endTimestamp={props.endTimestamp}
        axisEndIndex={props.exerciseAxisEndIndex}
        axisTitle={t('exerciseTitle')}
        axisSubtitle={t('exerciseSubtitle')}
        axisWidth={props.axisWidth}
        oneDayWidth={props.oneDayWidth}
        inPdf={inPdf}
        configOverride={configOverride}
        fetchStatus={props.fetchStatusExercise}
        graphsWindowWidth={props.graphsWindowWidth}
        series={exerciseSeries}
      />
    </div>
  );
};

OverviewGraphPresenter.propTypes = {
  startTimestamp: PropTypes.number.isRequired,
  endTimestamp: PropTypes.number.isRequired,
  meterUnits: PropTypes.string.isRequired,
  graphsWindowWidth: PropTypes.number.isRequired,
  axisWidth: PropTypes.number.isRequired,
  bgGraphSeries: PropTypes.arrayOf(PropTypes.object).isRequired,
  cgmGraphSeries: PropTypes.arrayOf(PropTypes.object).isRequired,
  carbsSeries: PropTypes.arrayOf(PropTypes.object).isRequired,
  insulinPumpSeries: PropTypes.arrayOf(PropTypes.object).isRequired,
  insulinManualSeries: PropTypes.arrayOf(PropTypes.object).isRequired,
  insulinInlinePumpSeries: PropTypes.arrayOf(PropTypes.object).isRequired,
  exerciseSeries: PropTypes.arrayOf(PropTypes.object).isRequired,
  stepsSeries: PropTypes.arrayOf(PropTypes.object).isRequired,
  bgGraphAxisEndIndex: PropTypes.number.isRequired,
  fetchStatus: PropTypes.oneOf(FETCH_STATUSES).isRequired,
  fetchStatusExercise: PropTypes.oneOf(FETCH_STATUSES).isRequired,
  hasCarbs: PropTypes.bool.isRequired,
  hasExercise: PropTypes.bool.isRequired,
  hasSteps: PropTypes.bool.isRequired,
  hasInsulinManual: PropTypes.bool.isRequired,
  hasInsulinPump: PropTypes.bool.isRequired,
  readingsType: PropTypes.oneOf([TYPE_BG, TYPE_CGM]).isRequired,
  oneDayWidth: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  carbsAxisEndIndex: PropTypes.number.isRequired,
  insulinManualAxisEndIndex: PropTypes.number.isRequired,
  insulinPumpAxisEndIndex: PropTypes.number.isRequired,
  insulinManualAndPumpAxisEndIndex: PropTypes.number.isRequired,
  exerciseAxisEndIndex: PropTypes.number.isRequired,
  stepsAxisEndIndex: PropTypes.number.isRequired,
  inPdf: PropTypes.bool.isRequired,
};

OverviewGraphPresenter.defaultProps = {
  meterUnits: Readings.MGDL,
  fetchStatus: FETCH_STATUS_NOT_CALLED,
  fetchStatusExercise: FETCH_STATUS_NOT_CALLED,
  hasCarbs: false,
  readingsType: TYPE_BG,
  insulinManualAxisEndIndex: 0,
  insulinPumpAxisEndIndex: 0,
  insulinManualAndPumpAxisEndIndex: 0,
  inPdf: false,
  t: (k) => `i18n.${k}`,
};

export const TranslatedOverviewGraphPresenter = translate('OverviewGraphPresenter')(OverviewGraphPresenter);
export default WithLoadingState(
  TranslatedOverviewGraphPresenter,
  (props) => (
    !(
      (props.fetchStatus === FETCH_STATUS_SUCCESSFUL ||
        props.fetchStatus === FETCH_STATUS_FAILED) &&
      (props.fetchStatusExercise === FETCH_STATUS_SUCCESSFUL ||
        props.fetchStatusExercise === FETCH_STATUS_FAILED)
    )
  ),
  {
    emptyComponent: () => (
      <div style={{
        left: 122,
        width: 'calc(100% - 122px)',
        height: '100%',
        position: 'absolute',
      }}
      >
        <Spinner />
      </div>),
  },
);
