import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { TIME_FORMATS } from '~/utils/i18nFormats';
import { getTextDirection } from '~/utils/I18n/I18n';
import { AVAILABLE_METER_UNITS } from '~/services/Readings';
import InsulinSeriesItem from './BolusTooltipItems/InsulinSeriesItem';
import BolusSeriesItem from './BolusTooltipItems/BolusSeriesItem';
import AutomaticBolusSeriesItem from './BolusTooltipItems/AutomaticBolusSeriesItem';
import Style from './BolusTooltip.scss';

export default class BolusTooltip extends React.Component {
  processTooltipData = (meterUnits) => this.props.point.tooltipData.map((point, index) => {
    const key = `${point.group}-${index}`;
    const props = { key, point, meterUnits };

    switch (point.group) {
      case 'gkInsulin':
        return <InsulinSeriesItem {...props} />;
      case 'automaticBolus':
        return <AutomaticBolusSeriesItem {...props} />;
      default:
        return <BolusSeriesItem {...props} />;
    }
  });

  render() {
    const { point, meterUnits } = this.props;
    const date = moment.utc(point.timestamp).format(TIME_FORMATS.MMM_DD_YYYY);
    const isRtl = getTextDirection() === 'rtl';

    return (
      <div className={isRtl ? Style.rtl : ''}>
        <div className={Style.header}>
          <span className={Style.date}>{date}</span>
        </div>
        <div className={Style.rows}>
          {this.processTooltipData(meterUnits)}
        </div>
      </div>
    );
  }
}

BolusTooltip.propTypes = {
  point: PropTypes.shape({
    tooltipData: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  meterUnits: PropTypes.oneOf(AVAILABLE_METER_UNITS).isRequired,
};
