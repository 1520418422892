import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImageStore from '~/utils/ImageStore';
import SupportLinks from '~/utils/support';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate';
import OmnipodUploadStyle from './../OmnipodUploadContainer.scss';
import Style from './FileUploadDialog.scss';

class FileUploadDialog extends Component {
  render() {
    const t = this.props.t;

    return (
      <div className={OmnipodUploadStyle.omnipodUploader}>
        <div className={OmnipodUploadStyle.omnipodUploaderHeader}>
          {t('uploadOmnipodSystemData')}
          <img
            src={ImageStore.xClose}
            className={OmnipodUploadStyle.btnClose}
            onClick={this.props.onClose}
          />
        </div>
        <div>
          <img
            src={ImageStore.omnipodLogoNew}
            className={OmnipodUploadStyle.omnipodLogo}
            alt="Omnipod Logo"
          />
          <div className={Style.infoBody}>
            <ol>
              <li className={Style.info}>{t('plugOmnipod')}</li>
              <li className={Style.info}>
                {t('click')} <span className={Style.bold}>{t('upload')}</span>
              </li>
              <li className={Style.info}>
                {t('fileBrowserInfo')}
                <span className={Style.bold}> {t('noName')}</span>,
                <span className={Style.bold}> {t('removableDisk')}</span>,
                <span className={Style.bold}> {t('usbDrive')} </span>
                {t('or')}
                <span className={Style.bold}> {t('android')}</span>.&nbsp;
                {t('selectWhichever')}
              </li>
              <li className={Style.info}>
                {t('selectIbf')} <span className={Style.bold}>{t('open')}</span>.
              </li>
              <li className={Style.info}>
                {t('needHelpContact')}&nbsp;
                <a
                  href={SupportLinks.fileTicket()}
                  className={OmnipodUploadStyle.supportLink}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {t('support')}
                </a>.
              </li>
            </ol>
          </div>
          <div className={OmnipodUploadStyle.buttonContainer}>
            <input
              style={{ display: 'none' }}
              type="file"
              onChange={this.props.onChange}
              ref={(fileInput) => this.fileInput = fileInput}
            />
            <button
              className={OmnipodUploadStyle.omnipodBlueButton}
              onClick={() => this.fileInput.click()}
            >
              {t('upload')}
            </button>
            <button className={OmnipodUploadStyle.cancelButton} onClick={this.props.onClose}>
              {t('cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

FileUploadDialog.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default translate('FileUploadDialog')(FileUploadDialog);
