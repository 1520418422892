import React from 'react';
import PropTypes from 'prop-types';
import deepMerge from 'deepmerge';
import { connect } from 'react-redux';
import { ONE_DAY } from '~/bundles/shared/constants/time';
import PageHelper from '~/redux/modules/page/PageHelper';
import GraphPresenter from '~/bundles/shared/components/graphs/GraphPresenter/GraphPresenter.jsx';
import Style from './PumpModesGraph.scss';

const mapStateToProps = (state) => ({
  hours: PageHelper.currentTimeFrameLength(state) === ONE_DAY,
});

@connect(mapStateToProps, null)
export default class PumpModesGraph extends React.Component {
  render() {
    const { yAxisMax, configOverride, hours, ...otherProps } = this.props;

    return (
      <div class={Style.PumpModesGraph}>
        <GraphPresenter
          {...otherProps}
          configOverride={deepMerge({
            tooltip: {
              enabled: false,
            },
            yAxis: {
              max: yAxisMax,
              min: 0,
              marginBottom: 0,
            },
          }, configOverride)}
        />
      </div>
    );
  }
}

PumpModesGraph.propTypes = {
  startTimestamp: PropTypes.number.isRequired,
  endTimestamp: PropTypes.number.isRequired,
  series: PropTypes.arrayOf(PropTypes.object).isRequired,
  yAxisMax: PropTypes.number.isRequired,
  windowHeight: PropTypes.number.isRequired,
  windowWidth: PropTypes.number.isRequired,
  configOverride: PropTypes.shape({}).isRequired,
  hours: PropTypes.bool.isRequired,
  updateSeries: PropTypes.func.isRequired,
};

PumpModesGraph.defaultProps = {
  yAxisMax: 2.2,
  configOverride: {},
  hours: false,
  updateSeries: () => null,
};
