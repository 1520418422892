import deepMerge from 'deepmerge';
import SearchBarUtils from '~/utils/SearchBarUtils/SearchBarUtils';

export const UPDATE_SEARCH_PARAMS = 'UPDATE_SEARCH_PARAMS';

export function searchParamsUpdateSuccess(searchParams) {
  return { type: UPDATE_SEARCH_PARAMS, searchParams };
}

const storage = SearchBarUtils.getSearchQueryFromStorage();
const query = storage === null ? '' : storage.query;
const savedSelectedOption = query === '' ? 'pgs' : storage.option.value;

const initialState = {
  // eslint-disable-next-line no-unneeded-ternary
  includeDeactivated: window.sessionStorage.include_deactivated === 'true' ? true : false,
  page: 1,
  searchBy: savedSelectedOption,
  poptrackerSearchQuery: query,
  sortBy: 'last_sync_timestamp',
  direction: 'descending',
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_SEARCH_PARAMS:
      return deepMerge(state, action.searchParams);
    default:
      return state;
  }
}
