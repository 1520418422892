import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import deepMerge from 'deepmerge';
import { connect } from 'react-redux';
import PageHelper from '~/redux/modules/page/PageHelper';
import GraphPresenter from '~/bundles/shared/components/graphs/GraphPresenter/GraphPresenter.jsx';
import Labels from '~/bundles/shared/components/graphs/labels';
import Style from './InsulinGraph.scss';

const mapStateToProps = (state, ownProps) => {
  const options = [
    state,
    moment.unix(ownProps.startTimestamp).utc().toISOString(),
    moment.unix(ownProps.endTimestamp).utc().toISOString(),
  ];
  return {
    pointWidth: PageHelper.currentTimeframe(...options).pointWidth,
    datalabelsEnabled: state.pdf.inPDF && PageHelper.currentTimeFrameLength(...options) < 15,
  };
};

@connect(mapStateToProps, null)
export default class InsulinGraph extends React.Component {
  render() {
    const { yAxisMax, pointWidth, configOverride, datalabelsEnabled, ...otherProps } = this.props;
    return (
      <div class={Style.InsulinGraph}>
        <GraphPresenter
          {...otherProps}
          configOverride={deepMerge({
            yAxis: {
              max: yAxisMax,
            },
            plotOptions: {
              series: {
                pointWidth,
                dataLabels: {
                  enabled: datalabelsEnabled,
                  style: {
                    color: 'white',
                    fontWeight: 'bold',
                  },
                  formatter: Labels.insulinColumn,
                },
              },
            },
          }, configOverride)}
        />
      </div>
    );
  }
}

InsulinGraph.propTypes = {
  startTimestamp: PropTypes.number.isRequired,
  endTimestamp: PropTypes.number.isRequired,
  series: PropTypes.arrayOf(PropTypes.object).isRequired,
  windowHeight: PropTypes.number.isRequired,
  windowWidth: PropTypes.number.isRequired,
  yAxisMax: PropTypes.number.isRequired,
  pointWidth: PropTypes.number.isRequired,
  configOverride: PropTypes.shape({}).isRequired,
  datalabelsEnabled: PropTypes.bool.isRequired,
};
InsulinGraph.defaultProps = {
  yAxisMax: 10,
  pointWidth: 0,
  configOverride: {},
  datalabelsEnabled: false,
};
