import {
  OVERLAY_AGP,
  OVERLAY_BOX_PLOT,
  OVERLAY_SCATTER,
  OVERLAY_SPAGHETTI,
  OVERVIEW,
  OVERVIEW_TAB_INDEX,
  OVERLAY_TAB_INDEX,
  WEEK_VIEW_TAB_INDEX,
  CALENDAR_TAB_INDEX,
} from '~/redux/modules/graphs/graphs';

import {
  trackGraphsOverlayAgpClicked,
  trackGraphsOverlaySpaghettiClicked,
  trackGraphsOverlayBoxPlotClicked,
  trackGraphsOverlayScatterClicked,
  trackGraphsOverviewPercentilesClicked,
  trackGraphsOverviewAverageClicked,
  trackGraphsOverlayScatterConnectDaysClicked,
  trackGraphsOverlayScatterAverageClicked,
  trackGraphsOverlayLowestHighestClicked,
  trackGraphsOverlayMedianClicked,
  trackGraphsOverviewClicked,
  trackGraphsOverlayClicked,
  trackGraphsWeekViewClicked,
  trackGraphsCalendarClicked,
} from '~/services/eventLogging';

export const callGraphsChangeTypeTracker = (typeId) => {
  switch (typeId) {
    case (OVERLAY_AGP):
      trackGraphsOverlayAgpClicked(); break;
    case (OVERLAY_SPAGHETTI):
      trackGraphsOverlaySpaghettiClicked(); break;
    case (OVERLAY_BOX_PLOT):
      trackGraphsOverlayBoxPlotClicked(); break;
    case (OVERLAY_SCATTER):
      trackGraphsOverlayScatterClicked(); break;
    default:
  }
};

export const callGraphsUpdateOptionsTracker = (tabType, options) => {
  switch (tabType) {
    case OVERVIEW:
      if ('percentiles' in options) {
        trackGraphsOverviewPercentilesClicked(options['percentiles']);
      } else if ('average' in options) {
        trackGraphsOverviewAverageClicked(options['average']);
      }
      break;
    case OVERLAY_SCATTER:
      if ('connectDays' in options) {
        trackGraphsOverlayScatterConnectDaysClicked(options['connectDays']);
      } else if ('average' in options) {
        trackGraphsOverlayScatterAverageClicked(options['average']);
      }
      break;
    case OVERLAY_SPAGHETTI:
      break;
    default:
      if ('hiLo' in options) {
        trackGraphsOverlayLowestHighestClicked(options['hiLo'], tabType);
      } else if ('median' in options) {
        trackGraphsOverlayMedianClicked(options['median'], tabType);
      }
  }
};

export const callGraphTabTracker = (tabIndex) => {
  switch (tabIndex) {
    case OVERVIEW_TAB_INDEX:
      trackGraphsOverviewClicked();
      break;
    case OVERLAY_TAB_INDEX:
      trackGraphsOverlayClicked();
      break;
    case WEEK_VIEW_TAB_INDEX:
      trackGraphsWeekViewClicked();
      break;
    case CALENDAR_TAB_INDEX:
      trackGraphsCalendarClicked();
      break;
    default:
  }
};
