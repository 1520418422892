export const FETCH_STATUS_NOT_CALLED = 'FETCH_STATUS_NOT_CALLED';
export const FETCH_STATUS_IN_PROGRESS = 'FETCH_STATUS_IN_PROGRESS';
export const FETCH_STATUS_SUCCESSFUL = 'FETCH_STATUS_SUCCESSFUL';
export const FETCH_STATUS_FAILED = 'FETCH_STATUS_FAILED';
export const FETCH_STATUSES = [
  FETCH_STATUS_NOT_CALLED,
  FETCH_STATUS_IN_PROGRESS,
  FETCH_STATUS_SUCCESSFUL,
  FETCH_STATUS_FAILED,
];
export const FETCH_STATUSES_BUSY = new Set([FETCH_STATUS_IN_PROGRESS, FETCH_STATUS_FAILED]);
