import React from 'react';
import PropTypes from 'prop-types';
import { PATH_SUMMARY } from '~/bundles/shared/constants/navigation';
import NewPatientModal from '~/bundles/newPatientModal/components/NewPatientModal.jsx';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import { getSimilarPatientList } from '~/services/usersApi';
import SimilarPatientList from '../SimilarPatientList/SimilarPatientList';
import styles from './PatientColumn.scss';

class PatientColumn extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    patient: PropTypes.object.isRequired,
    sortByName: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isInviteModalOpen: false,
      newEmailIsValid: true,
      newEmailInUse: false,
      newEmail: props.patient.email,
      similarPatients: [],
      selectedPatient: '',
    };
    this.showSimilarPatient = this.showSimilarPatient.bind(this);
    this.cancelSimilarModal = this.cancelSimilarModal.bind(this);
  }

  removeFakeEmail(email) {
    if (email.indexOf('kiosk.fake') === -1) {
      return email;
    }
    return '';
  }

  updateColumn(email) {
    this.setState({
      newEmailIsValid: true,
      newEmailInUse: false,
      newEmail: email,
    });
    this.openInviteModal(false);
  }

  openInviteModal(option) {
    this.setState({ isInviteModalOpen: option });
  }

  patientStatus(patient, t, patientObj) {
    if (patient.archived) {
      return <span className={styles.patient_status}> {t('archived')} </span>;
    } else if (patient.exited) {
      return <span className={styles.patient_status}> {t('exited')} </span>;
    } else if (patient.isActivated) {
      return <span className={styles.patient_status}> {t('activated')} </span>;
    }
    return (
      <span role="button" tabIndex="-1" id={patient.id} className={styles.invite} onClick={() => this.openInviteModal(true)}>
        {patientObj.patientEmail ? t('resendInvite') : t('invite')}
      </span>
    );
  }

  showSimilarPatient(patient) {
    const firstName = patient.firstName;
    const lastName = patient.lastName;

    if (firstName.trim() === '' || lastName.trim() === '') {
      this.renderPatientSummary(patient.id);
      return;
    }
    const self = this;
    window.PopulationTracker.prototype.toggleLoader(true);
    getSimilarPatientList(firstName, lastName)
      .then((response) => {
        window.PopulationTracker.prototype.toggleLoader(false);
        if (response.data.similarNames.length < 2) {
          this.renderPatientSummary(patient.id);
          return;
        }
        self.setState({
          similarPatients: response.data.similarNames,
          selectedPatient: patient.id,
        });
      })
      .catch((error) => {
        window.PopulationTracker.prototype.toggleLoader(false);
        if (error.response.status !== 200) {
          throw new Error(error.response.data.error_key);
        }
      });
  }

  cancelSimilarModal() {
    this.setState({ similarPatients: [] });
  }

  renderPatientSummary(patientId) {
    window.location.assign(`${PATH_SUMMARY}?patient=${patientId}`);
    window.PopulationTracker.prototype.toggleLoader(true);
  }

  render() {
    const { t, patient, sortByName } = this.props;
    const patientObj = {
      patientId: patient.id,
      patientEmail: this.removeFakeEmail(this.state.newEmail),
    };
    const { similarPatients, selectedPatient } = this.state;
    return (
      <div className={styles.patientColumn}>
        <span
          role="button"
          tabIndex="-1"
          className={styles.patient_name}
          onClick={() => this.showSimilarPatient(patient)}
        >
          {sortByName ?
            <span className={styles.patient_sort}>{patient.firstName}
              &nbsp; <b className={styles.last_name}>{patient.lastName}</b>
            </span> :
            <span>{patient.firstName}
              &nbsp;{patient.lastName}
            </span>}
        </span>
        <div>
          <small>
            <span className={styles.patient_dob}>{patient.dob} | </span>
            { this.patientStatus(patient, t, patientObj) }
            {this.state.isInviteModalOpen &&
            <NewPatientModal
              isInviteModalOpen={this.state.isInviteModalOpen}
              patient={patientObj}
              onCancelClick={() => this.openInviteModal(false)}
              newEmailIsValid={this.state.newEmailIsValid}
              newEmailInUse={this.state.newEmailInUse}
              onInviteClick={(email) => this.updateColumn(email)}
            />}
          </small>
        </div>
        { similarPatients.length > 1 ?
          <SimilarPatientList
            data={similarPatients}
            selectedPatient={selectedPatient}
            renderPatientSummary={this.renderPatientSummary}
            cancelSimilarModal={this.cancelSimilarModal}
          /> :
          null}
      </div>
    );
  }
}

export default translate('PatientColumn')(PatientColumn);
