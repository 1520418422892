import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Tooltip } from 'react-tooltip';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Tag } from '@glooko/common-ui';
import Style from './TagList.scss';

const TagList = ({
    className,
    maxWidth,
    tags,
    id,
    onClickTag,
    tooltipPosition,
    tooltipClass,
    tagClassName,
    tagContentClassName,
  }) => {
  const normalizedTags = useMemo(() => tags.reduce((acc, tagGroup) => {
      acc.push(...tagGroup.tags);
      return acc;
    }, []), [tags]);

  const initialState = {
    renderedTags: [],
    overflowTags: normalizedTags,
    // dont trigger render cycle if no length
    tagsLoaded: normalizedTags.length === 0,
    width: 0,
  };
  const [state, setState] = useState(initialState);
  const containerRef = useRef(null);

  useEffect(() => {
    if (!state.tagsLoaded) {
      setNextTag();
    }
  });

  const setNextTag = () => {
    const width = Math.floor(containerRef.current.getBoundingClientRect().width);
    let renderedTags = state.renderedTags;
    let overflowTags = state.overflowTags;
    let tagsLoaded = state.tagsLoaded;
    // if larger than allowed size
    // Pull last rendered tag back into overflow and mark done
    if (width > maxWidth) {
      renderedTags = normalizedTags.slice(0, state.renderedTags.length - 1);
      overflowTags = normalizedTags.slice(state.renderedTags.length - 1);
      tagsLoaded = true;
    // if less than allowed, add to renderedList
    } else if (state.overflowTags.length) {
      renderedTags = normalizedTags.slice(0, state.renderedTags.length + 1);
      overflowTags = normalizedTags.slice(state.renderedTags.length + 1);
      tagsLoaded = false;
    } else if (state.overflowTags.length === 0) {
      tagsLoaded = true;
    }
    setState({
      renderedTags, overflowTags, tagsLoaded, width,
    });
  };

  const renderOverflowTag = () => {
    const { renderedTags, overflowTags } = state;
    if (overflowTags.length > 0) {
      return (
        <div
          data-tooltip-id={`overflow-tags-tooltip-${id}`}
          key={renderedTags.length + 1}
        >
          <Tag
            id={`${renderedTags.length + 1}`}
            onClick={onClickTag}
            className={Style.tag}
          >
            <span>{`+ ${overflowTags.length}`}</span>
          </Tag>
          <Tooltip
            id={`overflow-tags-tooltip-${id}`}
            delayHide={50}
            delayShow={50}
            variant="light"
            place={tooltipPosition}
            className={Style.tooltip}
          >
            <div>{overflowTags.map((overflowTag) => (
              <div key={overflowTag.id}>
                {overflowTag.label}
              </div>))}
            </div>
          </Tooltip>
        </div>
      );
    }
  };

  const renderVisibleTags = () => state.renderedTags.map((tag, tagKey) => (
      <div
        data-tooltip-id={`tags-tooltip-${id}-${tag.id}`}
        key={tagKey}
      >
        <Tag
          key={tag.id}
          id={`${tag.id}`}
          variation={tag.tagAlert ? 'alert' : 'info'}
          image={tag.image}
          onClick={onClickTag}
          className={classnames(`${(tag.class) ? tag.class : Style.tag}`, tagClassName)}
        >
          <span className={tagContentClassName}>
              {tag.label}
          </span>
        </Tag>
        {tag.hover || tag.label.length > 19 ?
          <Tooltip
            id={`tags-tooltip-${id}-${tag.id}`}
            variant="light"
            delayHide={50}
            delayShow={50}
            place={tooltipPosition}
            className={Style.tooltip}
          >
            <div className={tooltipClass}>
              {tag.hover ? tag.hover : tag.label}
            </div>
          </Tooltip> : null}
      </div>));

  return (
    <div className={className} width={maxWidth} data-testid="tag-list-container">
      <div ref={containerRef} className={Style.tagContainer}>
        {renderVisibleTags()}
        {renderOverflowTag()}
      </div>
    </div>
  );
};

TagList.propTypes = {
  id: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape({
    category: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      tagAlert: PropTypes.bool,
      class: PropTypes.string,
      image: PropTypes.string,
    })),
  })).isRequired,
  className: PropTypes.string,
  tagClassName: PropTypes.string,
  tagContentClassName: PropTypes.string,
  onClickTag: PropTypes.func,
  tooltipPosition: PropTypes.string,
  maxWidth: PropTypes.number,
  tooltipClass: PropTypes.string,
};

TagList.defaultProps = {
  className: 'TagList',
  tagClassName: undefined,
  tagContentClassName: undefined,
  onClickTag: () => {},
  tooltipPosition: 'bottom',
  maxWidth: 308,
  tooltipClass: '',
};

export default TagList;
