import React from 'react';
import PropTypes from 'prop-types';
import Style from './PopUp.scss';
import { GreyOverlay } from '~/bundles/shared/components/Overlay/Overlay';
import PopUpHeader from '../PopUpHeader/PopUpHeader';

const PopUp = (props) => {
  const { showPopUp, hidePopUp, showOverlay } = props;

  if (!showPopUp) {
    return null;
  }

  return (
    <div>
      {showOverlay && <GreyOverlay onClick={hidePopUp} />}
      <div className={Style.PopUpContainer} style={props.style}>
        <PopUpHeader
          title={props.title}
          showX={props.showX}
          hidePopUp={hidePopUp}
        />
        <div>{props.body}</div>
        <div>{props.footer}</div>
      </div>
    </div>
  );
};

PopUp.propTypes = {
  body: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  footer: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  showOverlay: PropTypes.bool,
  showPopUp: PropTypes.bool.isRequired,
  showX: PropTypes.bool.isRequired,
  hidePopUp: PropTypes.func,
  style: PropTypes.shape({}),
  title: PropTypes.string.isRequired,
};

PopUp.defaultProps = {
  showPopUp: false,
  showOverlay: true,
  showX: true,
  footer: null,
  style: undefined,
  title: '',
  hidePopUp: () => {},
};

export default PopUp;
