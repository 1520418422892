import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { TIME_FORMATS } from '~/utils/i18nFormats';
import Style from './DeviceAndTime.scss';

const DeviceAndTime = ({ device, timestamp }) => {
  const time = moment.utc(timestamp).format(TIME_FORMATS.H_MM__A);

  return (
    <div className={Style.DeviceAndTime}>
      <span className={Style.device}>{device}</span>
      <span>{time}</span>
    </div>
  );
};

DeviceAndTime.propTypes = {
  device: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
};

export default DeviceAndTime;
