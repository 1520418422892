export const FETCH_THIRD_PARTY_ACCESS = 'FETCH_THIRD_PARTY_ACCESS';
export const FETCH_THIRD_PARTY_ACCESS_SUCCESS = 'FETCH_THIRD_PARTY_ACCESS_SUCCESS';
export const FETCH_THIRD_PARTY_ACCESS_FAILED = 'FETCH_THIRD_PARTY_ACCESS_FAILED';

export function updateThirdPartiesAccess(haveAccess) {
  return {
    type: FETCH_THIRD_PARTY_ACCESS_SUCCESS,
    payload: [...haveAccess],
  };
}

export function fetchingAccess() {
  return {
    type: FETCH_THIRD_PARTY_ACCESS,
  };
}

export function fetchThirdpartyAccessFailed() {
  return {
    type: FETCH_THIRD_PARTY_ACCESS_FAILED,
  };
}
const initalState = {
  enrollments: [],
};

export default function reducer(state = initalState, action = {}) {
  switch (action.type) {
    case FETCH_THIRD_PARTY_ACCESS_SUCCESS:
      return { enrollments: [...state.enrollments, ...action.payload] };
    case FETCH_THIRD_PARTY_ACCESS:
    case FETCH_THIRD_PARTY_ACCESS_FAILED:
    default:
      return state;
  }
}
