import { useDialogShow } from '@glooko/common-ui';
import InfoButton from 'bundles/shared/components/InfoButton/InfoButton';
import InfoDialog from 'bundles/shared/components/InfoDialog/InfoDialog';
import WithTranslate from 'bundles/shared/components/WithTranslate/WithTranslate';

const RoutineStepAverageInfo = (props: WithTranslationFunction) => {
  const { t } = props;
  const { isOpen, openDialog, closeDialog } = useDialogShow();

  return (
    <>
      <InfoButton aria-label="steps-info" onClick={openDialog} />

      <InfoDialog
        closeDialog={closeDialog}
        content={<p>{t('stepsInfoMessage')}</p>}
        isOpen={isOpen}
        title={t('steps')}
      />
    </>
  );
};

export default WithTranslate('RoutineStepAverageInfo')(RoutineStepAverageInfo);
