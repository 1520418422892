import React from 'react';
import PropTypes from 'prop-types';
import { range, map } from 'lodash';
import classNames from 'classnames';
import AxisPresenter from '../../AxisPresenter/AxisPresenter';
import Style from './CalendarGraphRow.scss';

export default class CalendarGraphRow extends React.Component {
  constructor(props) {
    super(props);
    this.generateEmpty = this.generateEmpty.bind(this);
  }

  generateEmpty(id, index) {
    const withLeftBorder = !(index === 0 && id !== 0);
    const withRightBorder = id === 6;

    return (
      <div
        key={id}
        class={classNames({
          [Style.CalendarGraphRow_empty]: true,
          [Style.borderLeft]: withLeftBorder,
          [Style.borderRight]: withRightBorder,
          [Style.borderBottom]: true,
          [Style.borderTop]: this.props.firstRow,
        })}
        style={{
          height: this.props.windowHeight,
          width: this.props.oneDayWidth - ((withLeftBorder && 1) || 0) -
                  ((withRightBorder && 1) || 0),
        }}
      />
    );
  }

  render() {
    const {
      axisStartIndex, axisEndIndex,
      axisTickStep, axisTitle, axisSubtitle,
      windowHeight, axisHide, axisWidth,
    } = this.props;

    return (
      <div class={Style.CalendarGraphRow}>
        <AxisPresenter
          startIndex={axisStartIndex}
          endIndex={axisEndIndex}
          tickStep={axisTickStep}
          windowHeight={windowHeight}
          title={axisTitle}
          subtitle={axisSubtitle}
          hideAxis={axisHide}
          windowWidth={axisWidth}
        />
        {map(range(0, this.props.emptyBefore), this.generateEmpty)}
        <div class={Style.CalendarGraphRow_inline}>
          {this.props.children}
        </div>
        {map(range(7 - this.props.emptyAfter, 7), this.generateEmpty)}
      </div>
    );
  }
}

CalendarGraphRow.propTypes = {
  emptyBefore: PropTypes.number.isRequired,
  emptyAfter: PropTypes.number.isRequired,
  axisStartIndex: PropTypes.number.isRequired,
  axisEndIndex: PropTypes.number.isRequired,
  axisTickStep: PropTypes.number.isRequired,
  axisTitle: PropTypes.string.isRequired,
  axisWidth: PropTypes.number.isRequired,
  axisSubtitle: PropTypes.string.isRequired,
  windowHeight: PropTypes.number.isRequired,
  axisHide: PropTypes.bool,
  children: PropTypes.element.isRequired,
  oneDayWidth: PropTypes.number.isRequired,
  firstRow: PropTypes.bool,
};

CalendarGraphRow.defaultProps = {
  axisHide: false,
  axisSubtitle: '',
  oneDayWidth: 100,
  firstRow: false,
};
