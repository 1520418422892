import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Readings from '~/services/Readings';
import ImageStore from '~/utils/ImageStore';
import { numberFormatterOneDecimal, TIME_FORMATS } from '~/utils/i18nFormats';
import {
  TYPE_BG,
  TYPE_CGM,
  READINGS_LOW,
  READINGS_NORMAL,
  READINGS_HIGH,
  READINGS_ABOVE_400,
} from '~/bundles/shared/constants/readings';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate';
import Style from './ScatterTooltip.scss';

const ScatterTooltip = (props) => {
  const { point, meterUnits, t } = props;
  const timeDisplay = moment.utc(point.timestamp).format(TIME_FORMATS.MMMM_DO_YYYY_H_MMA);
  const readingsType = point.type === 'egv' ? TYPE_CGM : TYPE_BG;
  const mealTagImage =
    (point.mealTag === 'before') ? ImageStore.BeforeMealApple : ImageStore.AfterMealApple;
  const displayMealTag = (point.mealTag === 'none') ? 'none' : '';
  const readingsTypeText = (point.type === 'manual') ? ` - ${t('pumpBgEntry')}` : '';
  const color = Readings.displayColorFromBucketName(point.bucket);

  return (
    <div class={Style.ScatterTooltip}>
      <div class={Style.row}>
        <span class={Style.number} style={{ color }}>
          {numberFormatterOneDecimal(Readings.formatInHiLo(point.yOrig, meterUnits, readingsType))}
        </span>
        <span style={{ color }}> {Readings.displayMeterUnits(meterUnits)}</span>
        <img class={Style.mealAppleImg} style={{ display: displayMealTag }} src={mealTagImage} alt="icon" />
        <span>{readingsTypeText}</span>
      </div>
      <div class={Style.row}>
        <span class={Style.date}>{timeDisplay}</span>
      </div>
    </div>
  );
};

ScatterTooltip.propTypes = {
  point: PropTypes.shape({
    yOrig: PropTypes.number.isRequired,
    mealTag: PropTypes.oneOf(['before', 'after', 'none']),
    type: PropTypes.oneOf(['manual', 'pump', 'meter', 'egv']).isRequired,
    timestamp: PropTypes.string.isRequired,
    bucket: PropTypes.oneOf([
      READINGS_LOW,
      READINGS_NORMAL,
      READINGS_HIGH,
      READINGS_ABOVE_400,
    ]).isRequired,
  }).isRequired,
  meterUnits: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default translate('ScatterTooltip')(ScatterTooltip);
