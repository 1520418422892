import React from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UserHelper from '~/redux/modules/users/UserHelper';
import ProviderGroupSiteHelper from '~/redux/modules/providerGroupSite/ProviderGroupSiteHelper';
import { updateSelectedDevice, updateDialogVisibility } from '~/redux/modules/omnipod/omnipod';
import { OMNIPOD_WEB_UPLOAD_FEATURE } from '~/bundles/shared/constants/providerGroupSite';
import ImageStore from '~/utils/ImageStore';
import OmnipodUploadContainer from './OmnipodUploadContainer';
import Style from './OmnipodUpload.scss';

const mapStateToProps = (state) => {
  const currentPatient = UserHelper.displayUser(state);
  const currentUserIsPatient = UserHelper.currentUserIsPatient(state);
  const omnipodWebUploadEnabled = ProviderGroupSiteHelper.hasSubscriptionFeature(
    state, OMNIPOD_WEB_UPLOAD_FEATURE,
  );

  // show omnipod button when...
  // if patient is logged in and has synced and omnipod
  // if professional is logged in and viewing patient page
  const show = (currentUserIsPatient && currentPatient.haveOmnipod) ||
                 (!currentUserIsPatient && omnipodWebUploadEnabled);

  return {
    currentPatient,
    show,
    selectedDevice: state.omnipod.selectedDevice,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ updateSelectedDevice, updateDialogVisibility }, dispatch),
});

const OmnipodUpload = (props) => {
  const { t, actions, currentPatient, selectedDevice, show } = props;

  if (!show) {
    return null;
  }

  const setVisibility = () => {
    if (selectedDevice) {
      actions.updateSelectedDevice();
    }
    actions.updateDialogVisibility(true);
  };

  const patientLoaded = currentPatient.insuletConsent !== undefined;

  return (
    <div>
      <div
        role="presentation"
        onClick={() => setVisibility()}
        className={Style.btnOmnipodUploader}
      >
        {t('syncOmnipod')}
        <img src={ImageStore.uploadIcon} className={Style.omnipodSyncIcon} alt="icon" />
      </div>
      {patientLoaded && <OmnipodUploadContainer />}
    </div>
  );
};

OmnipodUpload.propTypes = {
  t: PropTypes.func.isRequired,
  currentPatient: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  selectedDevice: PropTypes.object,
};

OmnipodUpload.defaultProps = {
  selectedDevice: null,
};

export default translate('OmnipodUpload')(connect(mapStateToProps, mapDispatchToProps)(OmnipodUpload));
