export const base = (series, name, callback, forceDisplay = false) => {
  const defaultConfig = { display: forceDisplay };
  if (!series) return defaultConfig;

  const group = series[name];
  if (!group || group.length === 0) return defaultConfig;

  let config = {};

  group.forEach((d) => {
    // Handle null values withoud modifying state
    let data = d;
    if (!data.data) data = { ...data, data: [] };

    config = callback(data, data.data.length > 0, config);
  });

  config.display = forceDisplay || Object.values(config).some(Boolean);

  return config;
};

export const readingTypesBaseSwitch = (data, dataExists, config) => {
  switch (data.name) {
    case 'bgHigh':
    case 'bgNormal':
    case 'bgLow':
      config.bg = config.bg || dataExists;
      break;
    case 'bgAbove400':
      config.above400 = dataExists;
      config.bg = config.bg || config.above400;
      break;
    case 'bgHighManual':
    case 'bgNormalManual':
    case 'bgLowManual':
      config.pumpBg = config.pumpBg || dataExists;
      break;
    case 'bgAbove400Manual':
      config.above400Manual = dataExists;
      config.pumpBg = config.pumpBg || config.above400Manual;
      break;
    case 'cgmCalibrationHigh':
    case 'cgmCalibrationNormal':
    case 'cgmCalibrationLow':
      config.calibrations = config.calibrations || dataExists;
      break;
    case 'cgmHigh':
    case 'cgmNormal':
    case 'cgmLow':
      config.cgm = config.cgm || data.data.some((e) => !e.calculated);
      config.calculatedCgm = config.calculatedCgm || data.data.some((e) => e.calculated);
      break;
  }

  return config;
};
