// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "@media print{.PageBreak_PageBreak{display:block;clear:both;page-break-after:always}}", "",{"version":3,"sources":["PageBreak.scss"],"names":[],"mappings":"AAGA,aACE,qBACE,aAAA,CACA,UAAA,CACA,uBAAA,CAAA","file":"PageBreak.scss","sourcesContent":["@import \"gl_sizes\";\n@import \"gl_colors\";\n\n@media print {\n  .PageBreak {\n    display: block;\n    clear: both;\n    page-break-after: always;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"PageBreak": "PageBreak_PageBreak"
};
module.exports = exports;
