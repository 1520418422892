import GlobalizeRaw from 'globalize';
import i18next from '~/utils/I18n/I18n';

GlobalizeRaw.load(
  require('cldr-data/supplemental/likelySubtags.json'),
  require('cldr-data/main/en/numbers.json'),
  require('cldr-data/main/fr/numbers.json'),
  require('cldr-data/main/fr-CA/numbers.json'),
  require('cldr-data/main/de/numbers.json'),
  require('cldr-data/main/el/numbers.json'),
  require('cldr-data/main/es-US/numbers.json'),
  require('cldr-data/main/tr/numbers.json'),
  require('cldr-data/main/hr/numbers.json'),
  require('cldr-data/main/en-GB/numbers.json'),
  require('cldr-data/main/es/numbers.json'),
  require('cldr-data/main/it/numbers.json'),
  require('cldr-data/main/fi/numbers.json'),
  require('cldr-data/main/sk/numbers.json'),
  require('cldr-data/main/pl/numbers.json'),
  require('cldr-data/main/da/numbers.json'),
  require('cldr-data/main/nl/numbers.json'),
  require('cldr-data/main/sv/numbers.json'),
  require('cldr-data/main/nb/numbers.json'),
  require('cldr-data/main/cs/numbers.json'),
  require('cldr-data/main/pt/numbers.json'),
  require('cldr-data/main/ar/numbers.json'),
  require('cldr-data/supplemental/numberingSystems.json'),
  require('cldr-data/supplemental/plurals.json'),
  require('cldr-data/supplemental/ordinals.json'),
  require('cldr-data/main/en/units.json'),
  require('cldr-data/main/fr/units.json'),
  require('cldr-data/main/fr-CA/units.json'),
  require('cldr-data/main/de/units.json'),
  require('cldr-data/main/el/units.json'),
  require('cldr-data/main/es-US/units.json'),
  require('cldr-data/main/tr/units.json'),
  require('cldr-data/main/hr/units.json'),
  require('cldr-data/main/en-GB/units.json'),
  require('cldr-data/main/es/units.json'),
  require('cldr-data/main/it/units.json'),
  require('cldr-data/main/fi/units.json'),
  require('cldr-data/main/sk/units.json'),
  require('cldr-data/main/pl/units.json'),
  require('cldr-data/main/da/units.json'),
  require('cldr-data/main/nl/units.json'),
  require('cldr-data/main/sv/units.json'),
  require('cldr-data/main/nb/units.json'),
  require('cldr-data/main/cs/units.json'),
  require('cldr-data/main/pt-PT/units.json'),
  require('cldr-data/main/ar/units.json'),
);

const GlobalizeCache = {};

const GlobalizeExceptions = {
  'nb-NO': 'nb',
};

export function globalizeLocale(languageOverride = null) {
  const language = languageOverride || i18next.language;

  if (GlobalizeExceptions[language]) {
    return GlobalizeExceptions[language];
  }

  return (language || 'en');
}

export const GlobalizeFn = (languageOverride = null) => {
  if (typeof Proxy === 'undefined') {
    return new GlobalizeRaw(languageOverride || i18next.language);
  }
  return new Proxy({}, {
    get(_, prop) {
      const language = globalizeLocale(languageOverride);
      GlobalizeCache[language] = GlobalizeCache[language] || (new GlobalizeRaw(language));
      return GlobalizeCache[language][prop];
    },
  });
};

export const Globalize = GlobalizeFn();
