import { fetchAllCategories } from '~/services/bloodPressureApi';

// ACTIONS
export const CATEGORIES_REQUESTED =
  'bloodPressure/CATEGORIES_REQUESTED';
export const CATEGORIES_RECEIVED =
  'bloodPressure/CATEGORIES_RECEIVED';

const initialState = {
  allCategories: [],
};

// REDUCER
export default (state = initialState, action = []) => {
  switch (action.type) {
    case CATEGORIES_REQUESTED:
      return {
        ...state,
      };

    case CATEGORIES_RECEIVED:
      return {
        ...state,
        allCategories: [].concat(action.allCategories),
      };

    default:
      return state;
  }
};

// ACTION CREATORS

// thunk action creator
export const fetchCategories = () => (dispatch) => {
  dispatch({ type: CATEGORIES_REQUESTED });

  return fetchAllCategories()
    .then((res) => {
      dispatch({
        type: CATEGORIES_RECEIVED,
        status: 'success',
        allCategories: res.data.categories,
      });
    })
    .catch(() => {
      dispatch({
        type: CATEGORIES_RECEIVED,
        status: 'failure',
        allCategories: [],
      });
    });
};
