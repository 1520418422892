import React, { useState } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import classNames from 'classnames';
import { Checkbox } from '@glooko/common-ui';

import FilterSectionHeader from './FilterSectionHeader/FilterSectionHeader';
import Styles from './FilterSection.scss';

const FilterSection = (props) => {
  const [expanded, setExpanded] = useState(false);

  const { filters, onFilterClick, title } = props;

  const selectedFilters = () => {
    const checkedFilters = moreSelectedFilters();

    return filters.slice(0, 5).concat(checkedFilters);
  };

  const moreSelectedFilters = () => filters.filter((e, i) => i > 4 && e.checked);

  const renderFilters = () => {
    const data = expanded ? filters : selectedFilters();

    return data.map((element, index) => {
      const tagClassNames = classNames(
        Styles['filter-section__filter'],
        { [Styles['filter-section__filter--first']]: index === 0 },
      );

      return (
        <div className={tagClassNames} key={shortid.generate()}>
          <Checkbox
            label={element.label}
            onChange={onFilterClick}
            id={`${element.id}`}
            checked={element.checked}
            dataAttributes={{ testid: 'filter-section' }}
          />
        </div>
      );
    });
  };

  const filterSectionClasses = classNames(
    Styles['filter-section'],
    { [Styles['filter-section--hide']]: filters.length === 0 },
  );

  return (
    <div className={filterSectionClasses}>
      <FilterSectionHeader
        title={title}
        expanded={expanded}
        dataCount={filters.length}
        onExpanderClick={() => setExpanded((prev) => !prev)}
        checkedFilters={moreSelectedFilters().length}
      />
      {renderFilters()}
    </div>
  );
};

FilterSection.propTypes = {
  title: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
  })).isRequired,
  onFilterClick: PropTypes.func.isRequired,
};

export default FilterSection;
