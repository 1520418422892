import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate';

import Style from './GraphsLegend.scss';

const GraphsLegendPresenter = (props) => {
  const {
    t,
    inSummaryPage,
    weekView,
    weekViewWeb,
    agpLegend,
    hiddenTitle,
    hiddenLink,
    onButtonClick,
    linkLabel,
    children,
  } = props;

  return (
    <div className={classNames(Style.GraphsLegend, {
      [Style.inSummaryPage]: inSummaryPage,
      [Style.weekView]: weekView,
      [Style.weekViewWeb]: weekViewWeb,
    })}
    >
      {!(agpLegend || weekView) && (
        <div>
          <span className={classNames(Style.title, { [Style.hiddenTitle]: hiddenTitle })}>
            {t('legend')}
          </span>
          <span
            className={classNames(Style.button, { [Style.hiddenLink]: hiddenTitle || hiddenLink })}
            onClick={onButtonClick}
          >
            {linkLabel}
          </span>
        </div>
      )}
      <div className={Style.grid}>{children}</div>
    </div>
  );
};

GraphsLegendPresenter.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ])),
    PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
  ]).isRequired,
  t: PropTypes.func.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  hiddenLink: PropTypes.bool,
  linkLabel: PropTypes.string,
  hiddenTitle: PropTypes.bool.isRequired,
  inSummaryPage: PropTypes.bool,
  agpLegend: PropTypes.bool,
  weekView: PropTypes.bool,
  weekViewWeb: PropTypes.bool,
};

GraphsLegendPresenter.defaultProps = {
  t: /* istanbul ignore next */ () => null,
  onButtonClick: /* istanbul ignore next */ () => null,
  hiddenLink: false,
  linkLabel: '',
  hiddenTitle: false,
  inSummaryPage: false,
  agpLegend: false,
  weekView: false,
  weekViewWeb: false,
};

export default translate('GraphsLegend')(GraphsLegendPresenter);
