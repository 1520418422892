import i18next from '~/utils/I18n/I18n';

const t = i18next.getFixedT(null, 'AlarmKeys');

export default (key, _) => {
  switch (key) {
    case 'tandem_occlusion':
    case 'tandem_occlusion_popup':
      return t('tandemOcclusion');
    case 'tandem_altitude':
    case 'tandem_altitude_popup':
      return t('tandemAltitude');
    case 'tandem_auto_off':
    case 'tandem_auto_off_popup':
      return t('tandemAutoOff');
    case 'tandem_cartridge_removal':
    case 'tandem_cartridge_removal_popup':
      return t('tandemCartridgeRemoval');
    case 'tandem_empty_cartridge':
    case 'tandem_empty_cartridge_popup':
      return t('tandemEmptyCartridge');
    case 'tandem_occlusion_2':
    case 'tandem_occlusion_2_popup':
      return t('tandemOcclusion2');
    case 'tandem_resume_pump':
    case 'tandem_resume_pump_popup':
      return t('tandemResumePump');
    case 'tandem_cgm_low':
      return t('tandemCgmLow');
    case 'tandem_cgm_high':
      return t('tandemCgmHigh');
    case 'tandem_cgm_low_2':
      return t('tandemCgmLow2');
    case 'tandem_cgm_rise':
      return t('tandemCgmRise');
    case 'tandem_cgm_rise_2':
      return t('tandemCgmRise2');
    case 'tandem_cgm_fall':
      return t('tandemCgmFall');
    case 'tandem_cgm_fall_2':
      return t('tandemCgmFall2');
    case 'tandem_cgm_calibration_error':
      return t('tandemCgmCalibrationError');
    case 'tandem_cgm_failed_sensor':
      return t('tandemCgmFailedSensor');
    case 'tandem_cgm_sensor_expiring':
      return t('tandemCgmSensorExpiring');
    case 'tandem_cgm_replace_sensor':
      return t('tandemCgmReplaceSensor');
    case 'tandem_cgm_out_of_range':
      return t('tandemCgmOutOfRange');
    case 'tandem_cgm_calibrate':
      return t('tandemCgmCalibrate');
    case 'tandem_cgm_calibrate_2':
      return t('tandemCgmCalibrate2');
    case 'tandem_cgm_calibrate_3':
      return t('tandemCgmCalibrate3');
    case 'tandem_cgm_transmitter_error':
      return t('tandemCgmTransmitterError');
    case 'tandem_cgm_sensor_expired':
      return t('tandemCgmSensorExpired');
    case 'tandem_low_power_alert':
      return t('tandemLowPowerAlert');
    case 'tandem_very_low_power_alert':
      return t('tandemVeryLowPowerAlert');
    case 'tandem_low_transmitter_battery':
      return t('tandemLowTransmitterBattery');
    case 'tandem_low_transmitter_battery_2':
      return t('tandemLowTransmitterBattery2');
    case 'tandem_low_transmitter_battery_3':
      return t('tandemLowTransmitterBattery3');
    case 'tandem_cgm_unavailable':
      return t('tandemCgmUnavailable');
    case 'tandem_controliq_high':
      return t('tandemControliqHigh');
    case 'tandem_controliq_low':
      return t('tandemControliqLow');
    case 'tandem_max_insulin':
      return t('tandemMaxInsulin');
    default:
      return null;
  }
};
