export const hasControlIqData = (displayUser, statistics) => displayUser.hasControlIqData &&
  statistics.controlIqPumpModeManualPercentage !== undefined &&
  statistics.controlIqPumpModeAutomaticPercentage !== undefined;

export const hasBasalIqData = (displayUser, statistics) => displayUser.hasControlIqData &&
  statistics.basalIqPumpModeManualPercentage !== undefined &&
  statistics.basalIqPumpModeAutomaticPercentage !== undefined;

export const hasOp5Data = (displayUser, statistics) => displayUser.hasOmnipod5 &&
  statistics.op5PumpModeManualPercentage !== undefined &&
  statistics.op5PumpModeAutomaticPercentage !== undefined;

export const hasMedtronicData = (displayUser, statistics) => displayUser.hasMedtronicClosedLoopData &&
  statistics.genericPumpModeManualPercentage !== undefined &&
  statistics.genericPumpModeAutomaticPercentage !== undefined;

export const hasCamapsData = (statistics) => statistics.camapsPumpModeManualPercentage !== undefined &&
  statistics.camapsPumpModeAutomaticPercentage !== undefined;
