// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".InfoButton_button{background-color:rgba(0,0,0,0);border:none;position:relative;padding:0;margin:0;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.InfoButton_image{-webkit-transition:-webkit-filter .3s linear;transition:-webkit-filter .3s linear;transition:filter .3s linear;transition:filter .3s linear, -webkit-filter .3s linear;will-change:filter;cursor:pointer;width:20px;height:20px}.InfoButton_image:hover{-webkit-transition:-webkit-filter .3s linear;transition:-webkit-filter .3s linear;transition:filter .3s linear;transition:filter .3s linear, -webkit-filter .3s linear;will-change:filter;-webkit-filter:drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));filter:drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))}html[dir=rtl] .InfoButton_image{-webkit-transform:scaleX(-1);transform:scaleX(-1)}", "",{"version":3,"sources":["InfoButton.scss"],"names":[],"mappings":"AAKA,mBACE,8BAAA,CACA,WAAA,CACA,iBAAA,CACA,SAAA,CACA,QAAA,CAEA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CAGF,kBAfE,4CAAA,CAAA,oCAAA,CAAA,4BAAA,CAAA,uDAAA,CACA,kBAAA,CAiBA,cAAA,CACA,UAAA,CACA,WAAA,CAEA,wBAtBA,4CAAA,CAAA,oCAAA,CAAA,4BAAA,CAAA,uDAAA,CACA,kBAAA,CAuBE,2DAAA,CAAA,mDAAA,CAKF,gCACE,4BAAA,CAAA,oBAAA","file":"InfoButton.scss","sourcesContent":["@mixin filterTransition {\n  transition: filter 0.3s linear;\n  will-change: filter;\n}\n\n.button {\n  background-color: transparent;\n  border: none;\n  position: relative;\n  padding: 0;\n  margin: 0;\n\n  display: flex;\n  align-items: center;\n}\n\n.image {\n  @include filterTransition;\n\n  cursor: pointer;\n  width: 20px;\n  height: 20px;\n\n  &:hover {\n    @include filterTransition;\n    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));\n  }\n}\n\nhtml[dir='rtl'] {\n  .image {\n    transform: scaleX(-1);\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"button": "InfoButton_button",
	"image": "InfoButton_image"
};
module.exports = exports;
