import React from 'react';
import PropTypes from 'prop-types';
import { filter } from 'lodash';
import ImageStore from '~/utils/ImageStore';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import TagList from '../../../../shared/components/TagList/TagList';

import styles from './PatientCareProgramsColumn.scss';

const PatientCareProgramsColumn = (props) => {
  const {
    lastIndex,
    patientInfo,
    tagsMaxWidth,
    handleCareProgramAddOrEditClick,
  } = props;
  const handleAddOrEditClick = () => {
    handleCareProgramAddOrEditClick(patientInfo);
  };

  const careProgramTags = filter(patientInfo.tags, (item) => item.category === 'careProgramTags');
  return (
    <div
      className={styles.patientCareProgramsColumn}
    >
      <button
        className={styles.careProgramsButton}
        onClick={handleAddOrEditClick}
      >
        <img
          src={careProgramTags && careProgramTags[0] &&
          careProgramTags[0].tags.length > 0 ? ImageStore.tagsEdit : ImageStore.plusIcon}
          alt="edit-add-icon"
        />
      </button>
      <TagList
        id={`careprogram_${patientInfo.id}`}
        tags={careProgramTags}
        tooltipPosition={lastIndex ? 'top' : 'bottom'}
        tagClassName={styles.careProgramsTags}
        tagContentClassName={styles.careProgramTagsContent}
        maxWidth={tagsMaxWidth}
      />
    </div>
  );
};

PatientCareProgramsColumn.propTypes = {
  t: PropTypes.func.isRequired,
  patientInfo: PropTypes.object.isRequired,
  lastIndex: PropTypes.bool.isRequired,
  handleCareProgramAddOrEditClick: PropTypes.func.isRequired,
  tagsMaxWidth: PropTypes.number,
};

PatientCareProgramsColumn.defaultProps = {
  tagsMaxWidth: 0,
};

export default translate('PatientListTable')(PatientCareProgramsColumn);
