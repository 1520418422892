import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import { trackAddEmailBannerButtonClicked } from '~/services/eventLogging';
import Style from './AddEmailBanner.scss';

class AddEmailBanner extends React.Component {
  static propTypes = {
    glookoCode: PropTypes.string.isRequired,
    rowId: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    terminalSync: PropTypes.shape({}).isRequired,
    onClick: PropTypes.func.isRequired,
    hideBanner: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      bannerVisible: true,
    };
    this.hideBanner = this.hideBanner.bind(this);
  }

  hideBanner() {
    const { terminalSync, glookoCode } = this.props;

    trackAddEmailBannerButtonClicked(terminalSync, glookoCode, 'cancel');
    this.props.hideBanner(this.props.rowId);
  }

  showDialog(glookoCode, rowId) {
    const { terminalSync, onClick } = this.props;

    trackAddEmailBannerButtonClicked(terminalSync, glookoCode, 'add');
    onClick(glookoCode, rowId);
  }

  render() {
    if (this.state.bannerVisible) {
      const { t, glookoCode, rowId } = this.props;

      return [
        <tr
          key="rowMessage"
        >
          <td class={Style.bannerMessage} colSpan="3">
            <div class={Style.rowBorder} />
            <span class={Style.noPatientEmail}>
              {t('noPatientEmail')}
            </span>
            <button onClick={() => this.showDialog(glookoCode, rowId)} className={Style.addEmail} type="button">
              {t('addEmail')}
            </button>
            <div class={Style.buttonContainer}>
              <button onClick={this.hideBanner} className={Style.xCloseRow} type="button" />
            </div>
          </td>
        </tr>,
      ];
    }

    return null;
  }
}

export default translate('AddEmailBanner')(AddEmailBanner);
