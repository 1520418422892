import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button } from '@glooko/common-ui';
import {
  getPatientTablePageIndex,
} from '~/bundles/shared/helpers/localStorageHelper';
import {
  fetchFilterProviderPatientsThunk,
  fetchProfessionalTagsThunk,
} from '~/redux/thunks/providerGroupSite/providerGroupSite';
import {
  updateCreatedPopupVisibility,
} from '~/redux/modules/providerGroupSite/providerGroupSite';
import { OMNIPOD_WEB_UPLOAD_FEATURE } from '~/bundles/shared/constants/providerGroupSite';
import ProviderGroupSiteHelper from '~/redux/modules/providerGroupSite/ProviderGroupSiteHelper';
import { updateDialogVisibility } from '~/redux/modules/omnipod/omnipod';
import { buildFilterParams } from '~/bundles/shared/helpers/filterParamsHelper';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import FindPatientModal from '~/bundles/poptracker/FindPatientModal/FindPatientModal.jsx';
import AccountCreatedModal from '~/bundles/shared/components/AccountCreatedModal/AccountCreatedModal.jsx';
import Styles from './CreatePatientLink.scss';

const mapStateToProps = (state) => ({
  searchParams: state.searchParams,
  currentFilters: state.providerGroupSite.filters,
  createdPopupDisplayed: state.providerGroupSite.createdPopupDisplayed,
  createdPatient: state.providerGroupSite.createdPatient,
  pgsCarePrograms: state.providerGroupSite.carePrograms,
  showUploadOmnipod: ProviderGroupSiteHelper.hasSubscriptionFeature(
    state, OMNIPOD_WEB_UPLOAD_FEATURE,
  ),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchFilterProviderPatientsThunk,
    updateCreatedPopupVisibility,
    updateDialogVisibility,
    fetchProfessionalTagsThunk,
  }, dispatch),
});

class CreatePatientLink extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    actions: PropTypes.shape({
      fetchFilterProviderPatientsThunk: PropTypes.func,
      updateCreatedPopupVisibility: PropTypes.func,
      updateDialogVisibility: PropTypes.func,
      fetchProfessionalTagsThunk: PropTypes.func,
    }).isRequired,
    searchParams: PropTypes.object.isRequired,
    currentFilters: PropTypes.object.isRequired,
    createdPopupDisplayed: PropTypes.bool,
    createdPatient: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false,
      page: getPatientTablePageIndex(),
      sortBy: this.getDefaultSortBy(),
      direction: this.getDefaultSortDirection(),
    };
    this.showCreatePatientModal = this.showCreatePatientModal.bind(this);
    this.hideCreatePatientModal = this.hideCreatePatientModal.bind(this);
    this.hideCreatedPatientPopup = this.hideCreatedPatientPopup.bind(this);
    this.openInsuletWeb = this.openInsuletWeb.bind(this);
  }

  getDefaultSortBy = () => {
    const defaultSortBy = localStorage.getItem('defaultSortBy');
    if (defaultSortBy) {
      return defaultSortBy;
    }
    return 'last_sync_timestamp';
  }

  getDefaultSortDirection = () => {
    const defaultSortDirection = localStorage.getItem('defaultSortDirection');
    if (defaultSortDirection) {
      return defaultSortDirection;
    }
    return 'descending';
  }

  getCurrentFilters = () => {
    const currentFilters = window.sessionStorage.patients_list_view_filters ?
      JSON.parse(window.sessionStorage.patients_list_view_filters) :
      buildFilterParams(this.props.currentFilters);
    return currentFilters;
  }

  fetchData() {
    this.props.actions.fetchFilterProviderPatientsThunk({
      includeDeactivated: this.props.searchParams.includeDeactivated,
      page: this.state.page,
      searchBy: this.props.searchParams.searchBy,
      poptrackerSearchQuery: this.props.searchParams.poptrackerSearchQuery,
      sortBy: this.state.sortBy,
      direction: this.state.direction,
    },
    this.props.currentFilters,
    this.getCurrentFilters(),
    );
    this.props.actions.fetchProfessionalTagsThunk();
  }

  hideCreatePatientModal(isCreateNewPatient) {
    if (isCreateNewPatient === true) {
      this.fetchData();
    }
    this.setState({
      modalVisible: false,
    });
  }

  showCreatePatientModal() {
    this.setState({
      modalVisible: true,
    });
  }

  hideCreatedPatientPopup() {
    this.props.actions.updateCreatedPopupVisibility(false);
  }

  openInsuletWeb() {
    this.props.actions.updateDialogVisibility(true);
    this.hideCreatedPatientPopup();
  }

  render() {
    const { t, createdPopupDisplayed, createdPatient, pgsCarePrograms, showUploadOmnipod } = this.props;
    const { patient } = createdPatient;
    const { user, proConnect } = patient;
    const { modalVisible } = this.state;

    return (
      <div>
        <Button
          onClick={this.showCreatePatientModal}
          class={Styles['create-patient-link']}
          variation='link'
          dataAttributes={{ testid: 'create-patient-link-create-account' }}
        >
          {t('createPatientAccount')}
        </Button>
        <FindPatientModal
          visible={modalVisible}
          visibilityCallback={this.hideCreatePatientModal}
          createPatientData={createdPatient}
          pgsCarePrograms={pgsCarePrograms}
          patientView
        />
        {createdPopupDisplayed &&
        <AccountCreatedModal
          isOpen={createdPopupDisplayed}
          lastName={user.lastName}
          firstName={user.firstName}
          providerGroupSiteName={proConnect.groupName}
          handleCloseModal={this.hideCreatedPatientPopup}
          glookoCode={user.glookoCode}
          openInsuletWeb={this.openInsuletWeb}
          showUploadOmnipod={showUploadOmnipod}
        />}
      </div>
    );
  }
}

export const TranslatedCreatePatientLink = translate('CreatePatientLink')(CreatePatientLink);
export default connect(mapStateToProps, mapDispatchToProps)(TranslatedCreatePatientLink);
