import React from 'react';
import PropTypes from 'prop-types';
import ImageStore from '~/utils/ImageStore';
import SupportLinks from '~/utils/support';
import PopUp from '~/bundles/shared/components/PopUpContainer/PopUp/PopUp';
import translate from '~/bundles/shared/components/WithTranslate/WithTranslate';
import Style from './UploaderDownloadDialog.scss';
import envConfig from '../../../../../../config/GlobalEnvConfig';

const UploaderDownloadDialog = ({ t, onClose }) => {
  const body = (
    <div className={Style.body}>
      <img className={Style.deviceImage} src={ImageStore.dashDevice} alt="Omnipod Dash" />
      <div className={Style.info}>
        <p className={Style.text}>{t('installInstructions')}</p>
        <a href={envConfig.glookoUploaderOsxUrl}>
          <button className={Style.installBtn}>{t('installNow')}</button>
        </a>
        <p className={Style.text}>
          {t('needHelp')}&nbsp;
          <a
            href={SupportLinks.helpCenter()}
            className={Style.contactLink}
            rel="noopener noreferrer"
            target="_blank"
          >
            {t('contactSupport')}
          </a>.
        </p>
      </div>
    </div>
  );

  return (
    <PopUp
      showPopUp
      showOverlay={false}
      title={t('uploadDashOnMac')}
      body={body}
      footer={null}
      hidePopUp={onClose}
      style={{
        position: 'fixed',
        width: '600px',
        height: '255px',
        marginLeft: '-300px',
        top: '22%',
      }}
    />
  );
};

UploaderDownloadDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

UploaderDownloadDialog.propTypes = {
  onClose: () => {},
  t: () => {},
};

export default translate('UploaderDownloadDialog')(UploaderDownloadDialog);
