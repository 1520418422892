import React from 'react';
import PropTypes from 'prop-types';

import Styles from './Jumbotron.scss';

// eslint-disable-next-line import/prefer-default-export
const Jumbotron = (props) => {
  let jumbotronClass = Styles.jumbotron;
  let jumbotronContentClass = Styles.jumbotron__content;

  if (props.jumbotronClass) {
    jumbotronClass = `${jumbotronClass} ${props.jumbotronClass}`;
  }

  if (props.jumbotronContentClass) {
    jumbotronContentClass = `${jumbotronContentClass} ${props.jumbotronContentClass}`;
  }

  return (
    <div className={jumbotronClass}>
      <div className={jumbotronContentClass}>
        {props.children}
      </div>
    </div>
  );
};

Jumbotron.propTypes = {
  children: PropTypes.arrayOf(PropTypes.shape()),
  jumbotronClass: PropTypes.string,
  jumbotronContentClass: PropTypes.string,
};

Jumbotron.defaultProps = {
  children: null,
  jumbotronClass: null,
  jumbotronContentClass: null,
};
export default Jumbotron;
