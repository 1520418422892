import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@glooko/common-ui';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate';
import Style from './BlindedDevicesAlert.scss';

const BlindedDevicesAlert = (props) => {
  const { blindedDevices, t } = props;
  let deviceList = [];

  if (!blindedDevices || blindedDevices.length < 1) {
    return null;
  }

  deviceList = blindedDevices.map((device) => device.fullName).join(', ');

  return (
    <div className={Style.BlindedDeviceAlert}>
      <Alert severity="error" className={Style.blindedDeviceContentStyle} dataAttributes={{ testid: 'blinded-devices' }}>
        <div className={Style.blindedContent}>
          <div className={Style.blindedMessage} data-testid="BlindedDeviceAlert-message">
            {t('accountBlinded', { deviceList })}
          </div>
        </div>
      </Alert>
    </div>
  );
};

BlindedDevicesAlert.propTypes = {
  blindedDevices: PropTypes.arrayOf(PropTypes.shape({
    fullName: PropTypes.string.isRequired,
  })),
  t: PropTypes.func.isRequired,
};

export default translate('BlindedDevicesAlert')(BlindedDevicesAlert);
