import {
  FETCH_STATUS_NOT_CALLED,
  FETCH_STATUS_SUCCESSFUL,
} from '~/bundles/shared/constants/graphs';

export const UPDATE_PARTNER_INTEGRATIONS = 'UPDATE_PARTNER_INTEGRATIONS';
export const UPDATE_PARTNER_INTEGRATIONS_STATUS = 'UPDATE_PARTNER_INTEGRATIONS_STATUS';
export const UPDATE_CURRENT_INTEGRATION_STATUS = 'UPDATE_CURRENT_INTEGRATION_STATUS';
export const UPDATE_CONFIRMATION_POPUP_VISIBILITY = 'UPDATE_CONFIRMATION_POPUP_VISIBILITY';
export const UPDATE_CURRENT_INTEGRATION_DATA = 'UPDATE_CURRENT_INTEGRATION_DATA';
export const INTEGRATION_WAITING_FOR_INPUT = 'INTEGRATION_WAITING_FOR_INPUT';
export const INTEGRATION_FAILED = 'INTEGRATION_FAILED';
export const INTEGRATION_SUCCEEDED = 'INTEGRATION_SUCCEEDED';
export const INTEGRATION_CONNECTING = 'INTEGRATION_CONNECTING';
export const INTEGRATION_DISCONNECTING = 'INTEGRATION_DISCONNECTING';
export const INTEGRATION_DISCONNECT_FAILED = 'INTEGRATION_DISCONNECT_FAILED';
export const INTEGRATION_DISCONNECT_SUCCEEDED = 'INTEGRATION_DISCONNECT_SUCCEEDED';

export function updatePartnerIntegrations(partnerIntegrations) {
  return { type: UPDATE_PARTNER_INTEGRATIONS, partnerIntegrations };
}

export function updatePartnerIntegrationsStatus(status) {
  return { type: UPDATE_PARTNER_INTEGRATIONS_STATUS, status };
}

export function updateCurrentIntegrationStatus(status) {
  return { type: UPDATE_CURRENT_INTEGRATION_STATUS, status };
}

export function updateConfirmationPopupVisibility(popup) {
  return { type: UPDATE_CONFIRMATION_POPUP_VISIBILITY, popup };
}

export function updateCurrentIntegrationData(data) {
  return { type: UPDATE_CURRENT_INTEGRATION_DATA, data };
}

const initialState = {
  status: FETCH_STATUS_NOT_CALLED,
  integrations: [],
  currentIntegration: {
    popupVisible: false,
    status: INTEGRATION_WAITING_FOR_INPUT,
    data: {},
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_PARTNER_INTEGRATIONS:
      return {
        ...state,
        integrations: action.partnerIntegrations,
        status: FETCH_STATUS_SUCCESSFUL,
      };
    case UPDATE_PARTNER_INTEGRATIONS_STATUS:
      return { ...state, status: action.status };
    case UPDATE_CURRENT_INTEGRATION_STATUS:
      return {
        ...state,
        currentIntegration: { ...state.currentIntegration, status: action.status },
      };
    case UPDATE_CONFIRMATION_POPUP_VISIBILITY:
      return {
        ...state,
        currentIntegration: { ...state.currentIntegration, popupVisible: action.popup },
      };
    case UPDATE_CURRENT_INTEGRATION_DATA:
      return {
        ...state,
        currentIntegration: { ...state.currentIntegration, data: action.data },
      };
    default:
      return state;
  }
}
