import React from 'react';
import PropTypes from 'prop-types';
import { filter, sortBy } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { keyFromParams, cleanUp } from '~/redux/modules/graphs/graphs';
import { graphsFetchDataThunk, graphsUpdateSeriesThunk } from '~/redux/thunks/graphs';
import moment from 'moment';
import Readings, { MGDL } from '~/services/Readings';
import ChartsHelpers from '~/bundles/shared/components/graphs';
import UserHelper from '~/redux/modules/users/UserHelper';
import GraphsHelper from '~/redux/modules/graphs/GraphsHelper';
import { TYPE_CGM } from '~/bundles/shared/constants/readings';
import {
  CALENDAR_AXIS_WIDTH_PDF,
  CALENDAR_AXIS_WIDTH_WEB,
  CALENDAR_ONE_DAY_WIDTH_PDF,
  CALENDAR_ONE_DAY_WIDTH_WEB,
} from '~/bundles/shared/constants/pages/calendar';
import {
  FETCH_STATUS_NOT_CALLED,
  FETCH_STATUSES,
} from '~/bundles/shared/constants/graphs';
import CalendarGraphPresenter from '../CalendarGraphPresenter/CalendarGraphPresenter';

export const GRAPH_TYPE_NAME = 'CalendarGraphContainer';

const bgGraphSeriesName = [
  'bgLow', 'bgHigh', 'bgNormal',
  'bgLowManual', 'bgHighManual', 'bgNormalManual',
  'bgAbove400', 'bgAbove400Manual', 'bgTargets',
  'cgmLow', 'cgmHigh', 'cgmNormal',
  'cgmCalibrationLow', 'cgmCalibrationNormal', 'cgmCalibrationHigh',
];

const bolusSeriesName = [
  'deliveredBolus',
  'extendedBolusStep',
  'injectionBolus',
  'gkInsulinBolus',
  'gkInsulinBasal',
  'gkInsulinPremixed',
  'gkInsulinOther',
  'automaticBolus',
];

const basalSeriesName = [
  'scheduledBasal', 'temporaryBasal', 'suspendBasal',
  'reservoirChange', 'setSiteChange', 'pumpAlarm', 'pumpAdvisoryAlert',
  'lgsPlgs',
];
const carbsSeriesName = ['carbAll'];

const mapStateToProps = (state, ownProps) => {
  const meterUnits = UserHelper.displayUser(state).preference.meterUnits;

  const seriesKey = keyFromParams({
    startTimestamp: moment.unix(ownProps.startTimestamp).utc().toISOString(),
    endTimestamp: moment.unix(ownProps.endTimestamp).utc().toISOString(),
    id: ownProps.graphTypeName,
  });

  const { series, status } = GraphsHelper.retrieveFetchStatusAndSeries(state, seriesKey);
  const pdfProps = (state.pdf.inPDF) ? {
    oneDayWidth: CALENDAR_ONE_DAY_WIDTH_PDF,
    axisWidth: CALENDAR_AXIS_WIDTH_PDF,
    carbsGraphHeight: 48,
    inPDF: true,
    bolusSeriesMaxCoefficient: 1.4,
  } : {};

  const insulinOnly = !UserHelper.hasPump(state);

  return {
    meterUnits,
    fetchStatus: status,
    bgGraphSeries: filter(series, (obj) => bgGraphSeriesName.indexOf(obj.name) > -1),
    basalSeries: filter(series, (obj) => basalSeriesName.indexOf(obj.name) > -1),
    bolusSeries: filter(series, (obj) => bolusSeriesName.indexOf(obj.name) > -1),
    carbsSeries: filter(series, (obj) => carbsSeriesName.indexOf(obj.name) > -1),
    insulinOnly,
    ...pdfProps,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ graphsFetchDataThunk, cleanUp, graphsUpdateSeriesThunk }, dispatch),
});

@connect(mapStateToProps, mapDispatchToProps)
export default class CalendarGraphContainer extends React.Component {
  constructor(props) {
    super(props);
    this.fetchSeries = this.fetchSeries.bind(this);
    this.updateSeries = this.updateSeries.bind(this);
  }

  componentDidMount() {
    if (this.props.fetchStatus === FETCH_STATUS_NOT_CALLED) {
      this.fetchSeries(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const currentKey = keyFromParams({
      startTimestamp: this.props.startTimestamp,
      endTimestamp: this.props.endTimestamp,
      id: this.props.graphTypeName,
    });

    const newKey = keyFromParams({
      startTimestamp: nextProps.startTimestamp,
      endTimestamp: nextProps.endTimestamp,
      id: nextProps.graphTypeName,
    });

    if (currentKey !== newKey) {
      this.cleanup(this.props);
      this.fetchSeries(nextProps);
    }
  }

  componentWillUnmount() {
    this.cleanup(this.props);
  }

  cleanup(props) {
    props.actions.cleanUp({
      startTimestamp: moment.unix(this.props.startTimestamp).utc().toISOString(),
      endTimestamp: moment.unix(this.props.endTimestamp).utc().toISOString(),
      id: props.graphTypeName,
    });
  }

  fetchSeries(props) {
    if (props.inPDF) {
      return;
    }
    this.props.actions.graphsFetchDataThunk({
      startTimestamp: moment.unix(props.startTimestamp).utc().toISOString(),
      endTimestamp: moment.unix(props.endTimestamp).utc().toISOString(),
      series: sortBy([
        ...bgGraphSeriesName,
        ...basalSeriesName,
        ...bolusSeriesName,
        ...carbsSeriesName,
      ]),
      id: props.graphTypeName,
    });
  }

  updateSeries(seriesLookup, series) {
    const start = moment.unix(this.props.startTimestamp).utc().toISOString();
    const end = moment.unix(this.props.endTimestamp).utc().toISOString();
    const plotLookup = `${this.props.graphTypeName}_${start}_${end}`;
    this.props.actions.graphsUpdateSeriesThunk(plotLookup, seriesLookup, series);
  }

  render() {
    const {
      startTimestamp, endTimestamp, bolusSeries, bolusSeriesMaxCoefficient, basalSeries,
      bolusMaxDefault, meterUnits,
    } = this.props;
    const emptyBefore = (moment.unix(startTimestamp).utc().isoWeekday() + 6) % 7;
    const emptyAfter = 6 - ((moment.unix(endTimestamp).utc().isoWeekday() + 6) % 7);
    const bolusAxisEndIndex = Math.ceil(
      (
        (ChartsHelpers.maxValueInSeries(bolusSeries) || 0) * bolusSeriesMaxCoefficient ||
        bolusMaxDefault
      ) / 5,
    ) * 5;
    const basalWithoutLgsPlgs = filter(basalSeries, (obj) => obj.name !== 'lgsPlgs');
    const basalAxisEndIndex = Math.ceil(
      (ChartsHelpers.maxValueInSeries(basalWithoutLgsPlgs) || 0) * 1.9 || 2,
    );
    const bolusAxisTickStep = bolusAxisEndIndex / 2.0;
    const bgGraphAxisEndIndex = Math.round(
      Readings.displayValue(Readings.LIMITS[TYPE_CGM].hi, meterUnits) * 1.08,
    );

    return (
      <CalendarGraphPresenter
        {...this.props}
        emptyBefore={emptyBefore}
        emptyAfter={emptyAfter}
        bolusAxisEndIndex={bolusAxisEndIndex}
        basalAxisEndIndex={basalAxisEndIndex}
        bolusAxisTickStep={bolusAxisTickStep}
        bgGraphAxisEndIndex={bgGraphAxisEndIndex}
        updateSeries={this.updateSeries}
      />
    );
  }
}

CalendarGraphContainer.propTypes = {
  startTimestamp: PropTypes.number.isRequired,
  endTimestamp: PropTypes.number.isRequired,
  meterUnits: PropTypes.string.isRequired,
  graphsWindowWidth: PropTypes.number.isRequired,
  oneDayWidth: PropTypes.number.isRequired,
  axisWidth: PropTypes.number.isRequired,
  bgGraphSeries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  basalSeries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  bolusSeries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  carbsSeries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  insulinOnly: PropTypes.bool.isRequired,
  carbsGraphHeight: PropTypes.number,
  graphTypeName: PropTypes.string.isRequired,
  fetchStatus: PropTypes.oneOf(FETCH_STATUSES).isRequired,
  bolusMaxDefault: PropTypes.number,
  inPDF: PropTypes.bool.isRequired,
  bolusSeriesMaxCoefficient: PropTypes.number.isRequired,
  actions: PropTypes.shape({
    graphsFetchDataThunk: PropTypes.func.isRequired,
    graphsUpdateSeriesThunk: PropTypes.func.isRequired,
  }).isRequired,
};

CalendarGraphContainer.defaultProps = {
  meterUnits: MGDL,
  bgGraphSeries: [],
  basalSeries: [],
  bolusSeries: [],
  carbsSeries: [],
  insulinOnly: false,
  oneDayWidth: CALENDAR_ONE_DAY_WIDTH_WEB,
  axisWidth: CALENDAR_AXIS_WIDTH_WEB,
  carbsGraphHeight: 48,
  graphTypeName: GRAPH_TYPE_NAME,
  fetchStatus: FETCH_STATUS_NOT_CALLED,
  bolusMaxDefault: 5,
  inPDF: false,
  bolusSeriesMaxCoefficient: 1.2,
  actions: {
    graphsFetchDataThunk: () => null,
    graphsUpdateSeriesThunk: () => {},
  },
};
