import { WEEKDAYS } from '~/utils/syncTimestamps';

export const BLANK_STATS_VALUE = '-';

export const OVERALL = 'overall';
export const MEAL = 'meal';
export const TIME_OF_DAY = 'tod';
export const DAY_OF_WEEK = 'dow';
export const BI_HOURLY = 'bi_hourly';

export const TIME_OF_DAY_BUCKETS = ['morning', 'afternoon', 'evening', 'night'];
export const BI_HOURLY_BUCKETS = Array(12).fill(0).map((_, i) => (i * 2));

export const BUCKET_MAP = {
  [BI_HOURLY]: BI_HOURLY_BUCKETS,
  [TIME_OF_DAY]: TIME_OF_DAY_BUCKETS.map((t) => t.toLowerCase()),
  [DAY_OF_WEEK]: WEEKDAYS.map((d) => d.toLowerCase()),
};
