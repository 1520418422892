import i18next from '~/utils/I18n/I18n';

const t = i18next.getFixedT(null, 'AlarmKeys');

export default (key, _) => {
  switch (key) {
    case 'novopen_big_dose':
      return t('novopenBigDose');
    case 'novopen_big_dose_popup':
      return t('novopenBigDosePopup');
    case 'novopen_crc_corrupted':
      return t('novopenCrcCorrupted');
    case 'novopen_crc_corrupted_popup':
      return t('novopenCrcCorruptedPopup');
    case 'novopen_expired_eol':
      return t('novopenExpiredEol');
    case 'novopen_expired_eol_popup':
      return t('novopenExpiredEolPopup');
    case 'novopen_interrupted_dose':
      return t('novopenInterruptedDose');
    case 'novopen_interrupted_dose_popup':
      return t('novopenInterruptedDosePopup');
    case 'novopen_recoverable_err':
      return t('novopenRecoverableErr');
    case 'novopen_recoverable_err_popup':
      return t('novopenRecoverableErrPopup');
    case 'novopen_sensor_err':
      return t('novopenSensorErr');
    case 'novopen_sensor_err_popup':
      return t('novopenSensorErrPopup');
    case 'novopen_unrecoverable_err':
      return t('novopenUnrecoverableErr');
    case 'novopen_unrecoverable_err_popup':
      return t('novopenUnrecoverableErrPopup');
    case 'novopen_warning_eol':
      return t('novopenWarningEol');
    case 'novopen_warning_eol_popup':
      return t('novopenWarningEolPopup');
    default:
      return null;
  }
};
