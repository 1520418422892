import ExecuteApiCall from './apiClient';

export function insuletDashCloudConsent(consent) {
  return ExecuteApiCall('put', 'api/v2/partners/insulet/dash_cloud/consent', {
    consent,
  });
}

export function insuletOp5CloudConsent({ consent, proconnectCode }) {
  return ExecuteApiCall('put', 'api/v2/partners/insulet/op5_cloud/consent.json', {
    consent,
    proconnectCode,
  });
}

export function getPendingUserConsents(choice) {
  return ExecuteApiCall('get', 'api/v2/partners/partner_consents/list', { choice });
}

export function updateUserConsentChoice(partner, version, choice) {
  return ExecuteApiCall('post', 'api/v2/partners/partner_consents/consent', {
    partner,
    version,
    consent: choice,
  });
}

export function reviewParentalConsent(partner) {
  return ExecuteApiCall('post', 'api/v3/users/resend_parental_email', {
    partner,
  });
}

export function withdrawParentalConsent(partner) {
  return ExecuteApiCall('post', 'api/v3/users/withdraw_consent/email', {
    partner,
  });
}

export function updateUserConsentAlertChoice(partner, alertMinor) {
  return ExecuteApiCall('post', 'api/v3/users/minor/partner/consent/update', {
    partner,
    alertMinor,
  });
}
