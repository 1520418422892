import { FETCH_STATUS_NOT_CALLED, FETCH_STATUS_SUCCESSFUL } from
  '~/bundles/shared/constants/graphs';
import { BLANK_STATS_VALUE } from '~/bundles/shared/constants/statistics';

export const UPDATE_BG_OVERALL_STATS = 'UPDATE_BG_OVERALL_STATS';
export const UPDATE_BG_OVERALL_STATS_STATUS = 'UPDATE_BG_OVERALL_STATS_STATUS';

export function updateBgOverallStatistics(stats, params) {
  return { type: UPDATE_BG_OVERALL_STATS, stats, params };
}

export function updateBgOverallStatisticsStatus(status) {
  return { type: UPDATE_BG_OVERALL_STATS_STATUS, status };
}

const initialState = {
  status: FETCH_STATUS_NOT_CALLED,
  params: {},
  numOfBolusesPerDay: 0,
  bolusUnitsPerDay: 0,
  basalUnitsPerDay: 0,
  totalInsulinPerDay: 0,
  bolusPercentage: 0,
  basalPercentage: 0,
  averageIndividualBolus: 0,
  correctionBolusPercentage: 0,
  bolusOverridesCount: 0,
  bolusOverridePercentage: 0,
  numOfCorrectionBolusesPerDay: 0,
  averageSteps: BLANK_STATS_VALUE,
  averageDuration: BLANK_STATS_VALUE,
  carbsPerDay: -1,
  carbEntriesPerDay: -1,
  totalCarbs: -1,
  manualInsulinDosesPerDay: 0,
  hasGlookoExercise: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_BG_OVERALL_STATS:
      return {
        ...state,
        ...action.stats,
        status: FETCH_STATUS_SUCCESSFUL,
        params: action.params,
      };
    case UPDATE_BG_OVERALL_STATS_STATUS:
      return { ...state, status: action.status };
    default:
      return state;
  }
}
