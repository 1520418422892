import React, { useState, useImperativeHandle } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogHeader, LinkedText } from '@glooko/common-ui';
import moment from 'moment';
import SupportLinks from 'utils/support';
import { TIME_FORMATS } from 'utils/i18nFormats';
import { checkinPatient } from 'services/redoxApi';
import ImageStore from 'utils/ImageStore';
import translate from '../WithTranslate/WithTranslate';
import Style from './ManualCheckinDialog.scss';

export const manualCheckinDialogRef = React.createRef();

const mapStateToProps = (state) => (
  {
    clinicName: state.providerGroupSite.name,
  }
);

const STATUSES = {
  READY: 'ready',
  CONFIRMED: 'confirmed',
  UNCONFIRMED: 'unconfirmed',
  PENDING: 'pending',
  COMPLETED: 'completed',
  FAILED: 'failed',
};

const ManualCheckinDialog = ({ clinicName, t }) => {
  const [status, setStatus] = useState(STATUSES.READY);
  const [confirmationStatus, setConfirmationStatus] = useState(STATUSES.UNCONFIRMED);
  const [patient, setPatient] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  const confirm = () => {
    setConfirmationStatus(STATUSES.PENDING);
    checkinPatient(patient.glookoCode)
      .then((response) => response.data)
      .then((data) => {
        setConfirmationStatus(STATUSES.COMPLETED);
        setStatus(STATUSES.CONFIRMED);
      })
      .catch((e) => {
        // we will not show an error if fail as very rare
        console.log('Redox checkin API failed'); //eslint-disable-line
        setConfirmationStatus(STATUSES.FAILED);
      });
  };

  const cancel = () => setIsVisible(false);

  const show = (patientInfo) => {
    setStatus(STATUSES.READY);
    setPatient(patientInfo);
    setIsVisible(true);
  };

  useImperativeHandle(manualCheckinDialogRef, () => ({ cancel, show }));

  if (patient === null) {
    return null;
  }

  return (
    <Dialog
      open={isVisible}
      onClose={cancel}
    >
      <DialogHeader>
        {status === STATUSES.READY ? t('checkinTitle', { patientName: `${patient.firstName} ${patient.lastName}` }) :
          <>
            <img class={Style.headerImage} src={ImageStore.checkmarkSuccess} alt="checkMark" />
            {t('success')}
          </>}
      </DialogHeader>
      <DialogContent>
        {status === STATUSES.READY ?
          <>
            <div className={Style.patientInfo}>
              <div data-testid="CheckinConfirmNameVal">{patient.firstName} {patient.lastName}</div>
              <div className={Style.patientInfoLabeled}>
                <div className={Style.patientInfoLabel} data-testid="CheckinConfirmDobLbl">{t('dob')}</div>
                <div data-testid="CheckinConfirmDobVal">{moment(patient.dateOfBirth).format(TIME_FORMATS.MM_DD_YYYY)}</div>
              </div>
              <div className={Style.patientInfoLabeled}>
                <div className={Style.patientInfoLabel} data-testid="CheckinConfirmMrnLbl">{t('mrn')}</div>
                <div data-testid="CheckinConfirmMrnVal">{patient.mrn}</div>
              </div>
              <div className={Style.patientInfoLabeled}>
                <div className={Style.patientInfoLabel} data-testid="CheckinConfirmLastSyncLbl">{t('lastSync')}</div>
                <div data-testid="CheckinConfirmLastSyncVal">{moment(patient.lastSyncTimestamp).format(TIME_FORMATS.MMM_D_YYYY_H_MM_A)}</div>
              </div>
            </div>
            <p>
              <LinkedText
                template={t('checkinInfo', { clinicName })}
                linkContent={[{ title: t('learnMore'), url: SupportLinks.article('20652294624915') }]}
                openInNewTab
              />
            </p>
          </> :
          <p>{t('checkinComplete')}</p>}
      </DialogContent>
      <DialogActions>
        {status === STATUSES.READY ?
          <>
            <Button onClick={cancel} variation='secondary'>
              {t('cancel')}
            </Button>
            <Button onClick={confirm} disabled={confirmationStatus === STATUSES.PENDING}>
              {t('confirm')}
            </Button>
          </> :
          <Button onClick={cancel}>
            {t('done')}
          </Button>}
      </DialogActions>
    </Dialog>
  );
};

ManualCheckinDialog.propTypes = {
  clinicName: PropTypes.string,
};

ManualCheckinDialog.defaultProps = {
  clinicName: '',
};

export default connect(mapStateToProps)(translate('ManualCheckin')(ManualCheckinDialog));
