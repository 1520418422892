import React from 'react';
import PropTypes from 'prop-types';
import deepMerge from 'deepmerge';
import { connect } from 'react-redux';
import colors from '~/../assets/styles/export/colors';
import { ONE_DAY } from '~/bundles/shared/constants/time';
import PageHelper from '~/redux/modules/page/PageHelper';
import Tooltips from '~/bundles/shared/components/graphs/tooltips.jsx';
import GraphPresenter from '~/bundles/shared/components/graphs/GraphPresenter/GraphPresenter.jsx';
import Style from './BasalGraph.scss';

const mapStateToProps = (state) => ({
  hours: PageHelper.currentTimeFrameLength(state) === ONE_DAY,
});

@connect(mapStateToProps, null)
export default class BasalGraph extends React.Component {
  generatePlotBands() {
    const index = this.props.series.map((s) => (s.name)).indexOf('lgsPlgs');
    const lgsPlgs = this.props.series[index];
    if (!lgsPlgs) return null;

    const plotBands = lgsPlgs.data.map((d) => ({
      color: colors.basalLgsPlgs,
      from: d.x,
      to: d.x + d.duration,
      zIndex: 9,
    }));

    return plotBands.filter((d) => d !== null);
  }

  render() {
    const {
      yAxisMax, configOverride, hours, weekView, ...otherProps
    } = this.props;
    const tooltipFormatter = hours ? Tooltips.basalTooltip : null;
    const plotBands = (hours || weekView) ? null : this.generatePlotBands();

    return (
      <div class={Style.BasalGraph}>
        <GraphPresenter
          tooltipPositioner={Tooltips.tooltipHoursPositioner}
          tooltipFormatter={tooltipFormatter}
          {...otherProps}
          configOverride={deepMerge({
            yAxis: {
              max: yAxisMax,
              min: 0,
              marginBottom: 0,
            },
            xAxis: {
              plotBands,
            },
          }, configOverride)}
        />
      </div>
    );
  }
}

BasalGraph.propTypes = {
  startTimestamp: PropTypes.number.isRequired,
  endTimestamp: PropTypes.number.isRequired,
  series: PropTypes.arrayOf(PropTypes.object).isRequired,
  yAxisMax: PropTypes.number.isRequired,
  windowHeight: PropTypes.number.isRequired,
  windowWidth: PropTypes.number.isRequired,
  configOverride: PropTypes.shape({}).isRequired,
  hours: PropTypes.bool.isRequired,
  updateSeries: PropTypes.func.isRequired,
  weekView: PropTypes.bool,
};

BasalGraph.defaultProps = {
  yAxisMax: 2.2,
  configOverride: {},
  hours: false,
  updateSeries: () => null,
  weekView: false,
};
