import React, { ButtonHTMLAttributes } from 'react';
import ImageStore from 'utils/ImageStore';
import Style from './InfoButton.scss';

export interface InfoButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  alt?: string;
  src?: string;
  style?: React.CSSProperties;
}

const InfoButton = (props: InfoButtonProps) => {
  const {
    alt = 'Info image',
    onClick,
    src = ImageStore.infoGray,
    style,
  } = props;

  return (
    <button
      className={Style.button}
      onClick={onClick}
      type="button"
      {...props}
    >
      <img
        alt={alt}
        className={Style.image}
        src={src}
        style={style}
      />
    </button>
  );
};

export default InfoButton;
