import { fetchLgsPlgsData } from '~/services/graphsApi';
import { FETCH_STATUS_FAILED, FETCH_STATUS_IN_PROGRESS } from '~/bundles/shared/constants/graphs';
import { updateLgsPlgsStatistics, updateLgsPlgsStatisticsStatus } from
  '~/redux/modules/statistics/lgsPlgs/lgsPlgs';

export default function fetchLgsPlgsStatsThunk(params) {
  return (dispatch) => {
    dispatch(updateLgsPlgsStatisticsStatus(FETCH_STATUS_IN_PROGRESS));

    return fetchLgsPlgsData(params)
      .then((response) => response.data)
      .then((lgsPlgsStatistics) => {
        dispatch(updateLgsPlgsStatistics(lgsPlgsStatistics, params));
      })
      .catch(() => dispatch(updateLgsPlgsStatisticsStatus(FETCH_STATUS_FAILED)));
  };
}
