// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".SingleGlucoseColumn_SingleGlucoseColumn{display:inline-block;vertical-align:top}", "",{"version":3,"sources":["SingleGlucoseColumn.scss"],"names":[],"mappings":"AAAA,yCACE,oBAAA,CACA,kBAAA","file":"SingleGlucoseColumn.scss","sourcesContent":[".SingleGlucoseColumn {\n  display: inline-block;\n  vertical-align: top;\n}\n"]}]);
// Exports
exports.locals = {
	"SingleGlucoseColumn": "SingleGlucoseColumn_SingleGlucoseColumn"
};
module.exports = exports;
