import React from 'react';
import PropTypes from 'prop-types';
import { Chip, Button } from '@glooko/common-ui';
import Style from './ChipList.scss';

class ChipList extends React.Component {
  render() {
    const {
      clearAllText, showCategories, chips, onClearChip, onClearAll,
    } = this.props;
    const showClearAll = chips.length > 1 || (chips.length === 1 && chips[0].chips.length > 1);

    const chipComponents = chips.map((category) => category.chips.map((chip, index) => (
        <div key={`${chip.id}`} className={Style.categoryChipContainer}>
          {!index && showCategories &&
            <div className={Style.categoryLabel}>{category.label}</div>}
            <div className={Style.categoryChip}>
              <Chip
                id={chip.id}
                label={chip.label}
                onClick={onClearChip}
              />
            </div>
        </div>
      )));

    return (
      <div className={Style.chipList}>
        <div className={Style.clearContainer}>
          {showClearAll &&
          <Button variation="link" onClick={onClearAll} className={Style.clearButton}>
            <div className={Style.clearButtonText}>{clearAllText}</div>
          </Button>}
        </div>
        <div className={Style.chips}>
          {chipComponents}
        </div>
      </div>
    );
  }
}

ChipList.propTypes = {
  chips: PropTypes.array.isRequired,
  onClearChip: PropTypes.func.isRequired,
  onClearAll: PropTypes.func.isRequired,
  clearAllText: PropTypes.string.isRequired,
  showCategories: PropTypes.bool,
};

ChipList.defaultProps = {
  showCategories: true,
};

export default ChipList;
