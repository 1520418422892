import React from 'react';
import PropTypes from 'prop-types';
import GenericConsentModal from '~/bundles/shared/components/GenericConsentModal/GenericConsentModal.jsx';
import MinorPendingSharingConsentModal from '~/bundles/shared/components/MinorPendingSharingConsentModal/MinorPendingSharingConsentModal.jsx';

const ConsentManagerPresenter = (props) => {
  const { pendingConsents, isMinor, guardianEmail, actions } = props;

  if (pendingConsents.length === 0) {
    return null;
  }

  const { partner, cdnLocation, version } = pendingConsents[0];

  const agreeChosen = () => {
    updateChoice('agree');
  };

  const disagreeChosen = () => {
    updateChoice('disagree');
  };

  const updateChoice = (choice) => {
    actions.updatePatientConsentChoiceThunk(partner, version, choice);
  };

  const handleOk = (partnerName, value) => {
    actions.updateConsentAlertFlagThunk(partnerName, value);
  };

  if (isMinor) {
    return (
      <MinorPendingSharingConsentModal
        consents={pendingConsents}
        guardianEmail={guardianEmail}
        handleShowConsent={handleOk}
      />
    );
  } else {
    return (
      <div>
        <GenericConsentModal
          partnerName={partner}
          consentUrl={cdnLocation}
          handleAgree={agreeChosen}
          handleDisagree={disagreeChosen}
          handleCloseModal={/* istanbul ignore next */ () => {}}
          isOpen
        />
      </div>
    );
  }
};

ConsentManagerPresenter.propTypes = {
  pendingConsents: PropTypes.array,
  actions: PropTypes.shape({
    updatePatientConsentChoiceThunk: PropTypes.func.isRequired,
  }).isRequired,
};

ConsentManagerPresenter.defaultProps = {
  pendingConsents: [],
};

export default ConsentManagerPresenter;
