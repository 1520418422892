import { minBy } from 'lodash';
import moment from 'moment';
import GraphsHelper from '~/redux/modules/graphs/GraphsHelper';
import { SECONDS_IN_DAY, ONE_DAY } from '~/bundles/shared/constants/time';
import { WEEKDAYS } from '~/utils/syncTimestamps';
import { PAGE_OVERLAY, PAGE_TO_PAGES_INDEX } from '~/bundles/shared/constants/pages';
import { CALENDAR_ONE_DAY_WIDTH_WEB, CALENDAR_ONE_DAY_WIDTH_PDF } from
  '~/bundles/shared/constants/pages/calendar';

class PageHelper {
  static currentTimeframe(state, startDate = null, endDate = null) {
    const startTimestamp = startDate || state.page.startDate;
    const endTimestamp = endDate || state.page.endDate;
    const oneDayWidth = state.pdf.inPDF ? CALENDAR_ONE_DAY_WIDTH_PDF : CALENDAR_ONE_DAY_WIDTH_WEB;
    const pointWidthCoeff = state.pdf.inPDF ? 0.48 : 0.23;

    const value = Math.max(
      moment.utc(endTimestamp).startOf('day').diff(moment.utc(startTimestamp).startOf('day'), 'days') + 1,
      0,
    );

    const pointWidth = Math.ceil(((oneDayWidth * 7) / value) * pointWidthCoeff); // CALENDAR_ONE_DAY_WIDTH_PDF
    const percentilesWidth = Math.ceil(((oneDayWidth * 7) / value) * 0.92); // CALENDAR_ONE_DAY_WIDTH_PDF

    return { value, pointWidth, percentilesWidth };
  }

  static currentTimeFrameLength(state, startDate = null, endDate = null) {
    const startTimestamp = startDate || state.page.startDate;
    const endTimestamp = endDate || state.page.endDate;

    return Math.round(moment.utc(endTimestamp).diff(startTimestamp, 'seconds') / SECONDS_IN_DAY);
  }

  static availableOptionsOverview(state) {
    const enabled = PageHelper.currentTimeFrameLength(state) !== ONE_DAY;

    return {
      average: enabled,
      percentiles: enabled,
    };
  }

  static uniqueByWeekdayForStatistics(state) {
    return state.page.selectedPageIndex === PAGE_TO_PAGES_INDEX[PAGE_OVERLAY];
  }

  static keyForUniqueByWeekdayForStatistics(state) {
    if (PageHelper.uniqueByWeekdayForStatistics(state)) {
      return GraphsHelper.selectedDaysSum(state, true);
    }
    return 'ALL';
  }

  static includedDaysInStatistics(state) {
    if (PageHelper.uniqueByWeekdayForStatistics(state)) {
      return GraphsHelper.selectedDaysArray(state, true);
    }
    return WEEKDAYS;
  }

  static closestTimeFrame(availableTimeFrames, desiredTimeframeValue) {
    return minBy(availableTimeFrames, (frame) => Math.abs(desiredTimeframeValue - frame.value));
  }
}

export default PageHelper;
