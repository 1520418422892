/* global academyUrl */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LinkedText } from '@glooko/common-ui';
import {
  USER_TYPE_PATIENT,
  USER_TYPE_PROFESSIONAL,
  USER_TYPE_PRO_ADMIN,
} from '~/bundles/shared/constants/users';
import ImageStore from '~/utils/ImageStore';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import Spinner from '~/bundles/shared/components/Spinner/Spinner';
import Styles from './AcademyPresenter.scss';
import envConfig from '../../../../../config/GlobalEnvConfig';

const AcademyPresenter = (props) => {
  const { t, userType, userEncryptedData } = props;
  const userIsPatient = userType === USER_TYPE_PATIENT;
  const userIsProfessional = userType === USER_TYPE_PROFESSIONAL;
  const userIsProfessionalAdmin = userType === USER_TYPE_PRO_ADMIN;
  const buttonClass = classNames({
    [Styles.academyButton]: true,
    [Styles.goToAcademyButton]: !userIsProfessionalAdmin,
    [Styles.goToAcademyButtonProf]: userIsProfessionalAdmin,
  });

  const getProfAdminAccessProcess = () => {
    const link =
      'https://support.glooko.com/hc/en-us/articles/360001512757#users';
    return (
      <p>
        {t('profAdminAccessProcess')}&nbsp;
        <LinkedText
          template={t('helpArticle')}
          linkContent={[{ title: t('this'), url: link }]}
          openInNewTab
        />
      </p>
    );
  };

  return (
    <div className={Styles.academyContainer}>
      <div className={Styles.academyLogo}>
        <img src={ImageStore.academyLogo} height="36" alt="AcademyLogo" />
      </div>
      <div className={Styles.academyContent}>
        <div className={Styles.academyColumn1}>
          {!userIsPatient ? (
            <div className={Styles.academyText}>
              <p>{t('academyIntro')}</p>
              <p>{t('academyPartners')}</p>
              <p>{userIsProfessional && t('continueLearning')}</p>
              {userIsProfessionalAdmin ? getProfAdminAccessProcess() : <p>{t('accessReportsProcess')}</p>}
              <form action={envConfig.academyUrl} method="post" target="_blank">
                <input
                  type="hidden"
                  name="message"
                  value={userEncryptedData.message}
                />
                <input
                  type="hidden"
                  name="nonce"
                  value={userEncryptedData.nonce}
                />
                <input
                  type="submit"
                  value={
                    userIsProfessionalAdmin ?
                      t('accessAcademyReports') :
                      t('toAcademy')
                  }
                  className={buttonClass}
                />
              </form>
            </div>
          ) : (
            <Spinner />
          )}
        </div>
        <div className={Styles.academyColumn2}>
          <div className={Styles.academyIllustration}>
            <img
              src={ImageStore.academyIllustration}
              alt="Academy"
              width="330"
              height="255"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

AcademyPresenter.propTypes = {
  userType: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  userEncryptedData: PropTypes.shape({
    message: PropTypes.string.isRequired,
    nonce: PropTypes.string.isRequired,
  }).isRequired,
};

export default translate('AcademyPresenter')(AcademyPresenter);
