import axios from 'axios';
import qs from 'qs';
import envConfig from '../../config/GlobalEnvConfig';

const apiEndPointURL = (endpoint) => `${envConfig.apiUrl}/${endpoint}`;

export default function ExecuteApiCallTerminals(method, endpoint, params = {}) {
  const options = {
    method,
    url: apiEndPointURL(endpoint),
    params,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
  };

  switch (method) {
    case 'get':
      Object.assign(
        options,
      );
      break;
    default:
      break;
  }

  return axios(options);
}
