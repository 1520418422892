import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogHeader } from '@glooko/common-ui';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import Styles from './GenericConsentModal.scss';

export class GenericConsentModal extends React.Component {
  static propTypes = {
    partnerName: PropTypes.string.isRequired,
    consentUrl: PropTypes.string.isRequired,
    viewOnlyMode: PropTypes.bool,
    isOpen: PropTypes.bool,
    handleCloseModal: PropTypes.func.isRequired,
    handleAgree: PropTypes.func,
    handleDisagree: PropTypes.func,
    t: PropTypes.func.isRequired,
  }

  static defaultProps = {
    viewOnlyMode: false,
    isOpen: false,
    handleAgree: /* istanbul ignore next */ () => {},
    handleDisagree: /* istanbul ignore next */ () => {},
  };

  constructor(props) {
    super(props);

    this.setConsentsRef = (element) => {
      this.consentsRef = element;
    };
    this.setConsentsViewMode = this.setConsentsViewMode.bind(this);
    this.state = { consentsInitialized: false };
  }

  setConsentsViewMode() {
    const mode = this.props.viewOnlyMode ? 'viewOnlyMode' : 'agreeMode';
    this.consentsRef.contentWindow.postMessage(mode, '*');
    this.setState({ consentsInitialized: true });
  }

  render() {
    const {
      partnerName, consentUrl, isOpen, viewOnlyMode,
      handleAgree, handleDisagree, handleCloseModal,
      t,
    } = this.props;

    const partnerShareText = () => {
      if (partnerName.toLowerCase() === 'nndem') return t('shareDialoqIdWithNovo');

      return t('shareDataWith', { partnerName });
    };

    const footer = viewOnlyMode ?
      (<Button onClick={handleCloseModal} dataAttributes={{ testid: 'generic-consent-ok' }}>{t('ok')}</Button>) :
      (<div><Button variation="secondary" onClick={handleDisagree} dataAttributes={{ testid: 'generic-consent-disagree' }}>{t('disagree')}</Button>
      <Button onClick={handleAgree} dataAttributes={{ testid: 'generic-consent-agree' }}>{t('agree')}</Button>
       </div>);
    return (
          <Dialog focusTrap={{ active: false }} showCloseButton={false} size="medium" open={isOpen} onClose={handleCloseModal} dataAttributes={{ testid: 'generic-consent' }}>
          <DialogHeader dataAttributes={{ testid: 'generic-consent' }}><h2>{partnerShareText()}</h2></DialogHeader>
          <DialogContent dataAttributes={{ testid: 'generic-consent' }}>
          <iframe
            className={Styles.consentText}
            src={consentUrl}
            id="glooko-partner-consent-iframe"
            title="Glooko Partner Consent Container"
            onLoad={this.setConsentsViewMode}
            ref={this.setConsentsRef}
          />
          </DialogContent>
          {this.state.consentsInitialized && <DialogActions dataAttributes={{ testid: 'generic-consent' }}>
            {footer}
                                             </DialogActions>}
          </Dialog>
    );
  }
}

export default translate('GenericConsentModal')(GenericConsentModal);
