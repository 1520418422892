import React from 'react';
import PropTypes from 'prop-types';
import deepMerge from 'deepmerge';
import moment from 'moment';
import { range } from 'lodash';
import Colors from '~/../assets/styles/export/colors';
import classNames from 'classnames';
import { SECONDS_IN_DAY } from '~/bundles/shared/constants/time';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import Readings, { MMOLL, MGDL } from '~/services/Readings';
import {
  FETCH_STATUS_FAILED,
  FETCH_STATUS_NOT_CALLED,
  FETCH_STATUSES,
  FETCH_STATUS_SUCCESSFUL,
} from '~/bundles/shared/constants/graphs';
import Spinner from '~/bundles/shared/components/Spinner/Spinner';
import WithLoadingState from '~/bundles/shared/components/WithLoadingState/WithLoadingState';
import CalendarGraphRow from '../CalendarGraphRow/CalendarGraphRow';
import BGGraph from '../../Graphs/BGGraph/BGGraph';
import BasalGraph from '../../Graphs/BasalGraph/BasalGraph';
import BolusGraph from '../../Graphs/BolusGraph/BolusGraph';
import CarbsGraph from '../../Graphs/CarbsGraph/CarbsGraph';
import Style from './CalendarGraphPresenter.scss';

class CalendarGraphPresenter extends React.Component { // eslint-disable-line
  render() {
    const {
      emptyBefore, emptyAfter, bolusAxisEndIndex, bolusAxisTickStep, bgGraphAxisEndIndex,
      t, fetchStatus,
    } = this.props;

    const configOverride = {
      xAxis: {
        plotLines: range(
          Math.floor(this.props.startTimestamp / SECONDS_IN_DAY) * SECONDS_IN_DAY,
          (Math.ceil(this.props.endTimestamp / SECONDS_IN_DAY) * SECONDS_IN_DAY) - 0.1,
          SECONDS_IN_DAY,
        ).map((elem) => ({
          color: Colors.borderGrey,
          width: 1,
          dashStyle: 'solid',
          value: elem,
          zIndex: 2,
        })),
      },
    };

    const xAxisTickConfig = {
      xAxis: {
        offset: -5,
        tickWidth: 1,
        tickLength: 5,
        tickColor: Colors.borderGrey,
        tickPositioner: () => {
          const positions = range(
            moment.utc(this.props.startTimestamp).valueOf(),
            moment.utc(this.props.endTimestamp).valueOf(),
            21600,
          );
          positions.push(this.props.endTimestamp);
          return positions;
        },
        visible: true,
      },
    };

    return (
      <div class={Style.CalendarGraphPresenter}>
        <CalendarGraphRow
          startTimestamp={this.props.startTimestamp}
          endTimestamp={this.props.endTimestamp}
          windowHeight={150}
          emptyBefore={emptyBefore}
          emptyAfter={emptyAfter}
          axisStartIndex={0}
          axisEndIndex={bgGraphAxisEndIndex}
          axisTickStep={this.props.meterUnits === MMOLL ? 6 : 100}
          axisTitle={t('glucose')}
          axisSubtitle={`(${Readings.displayMeterUnits(this.props.meterUnits)})`}
          axisWidth={this.props.axisWidth}
          oneDayWidth={this.props.oneDayWidth}
          firstRow
        >
          <div class={classNames(Style.borderLeftRightBottom, Style.borderTop)}>
            <BGGraph
              startTimestamp={this.props.startTimestamp}
              endTimestamp={this.props.endTimestamp}
              series={this.props.bgGraphSeries}
              windowHeight={150}
              windowWidth={this.props.graphsWindowWidth}
              yAxisMax={bgGraphAxisEndIndex}
              fetchStatus={fetchStatus}
              configOverride={configOverride}
              showBeforeMaxLine
            />
          </div>
        </CalendarGraphRow>
        <CalendarGraphRow
          startTimestamp={this.props.startTimestamp}
          endTimestamp={this.props.endTimestamp}
          windowHeight={this.props.carbsGraphHeight}
          emptyBefore={emptyBefore}
          emptyAfter={emptyAfter}
          axisStartIndex={0}
          axisEndIndex={0}
          axisTickStep={0}
          axisTitle={t('carbs')}
          axisSubtitle={`(${t('grams')})`}
          axisWidth={this.props.axisWidth}
          axisHide
          oneDayWidth={this.props.oneDayWidth}
        >
          <div class={Style.borderLeftRightBottom}>
            <CarbsGraph
              startTimestamp={this.props.startTimestamp}
              endTimestamp={this.props.endTimestamp}
              series={this.props.carbsSeries}
              windowHeight={this.props.carbsGraphHeight}
              windowWidth={this.props.graphsWindowWidth}
              fetchStatus={fetchStatus}
              configOverride={configOverride}
            />
          </div>
        </CalendarGraphRow>
        <CalendarGraphRow
          startTimestamp={this.props.startTimestamp}
          endTimestamp={this.props.endTimestamp}
          windowHeight={64}
          emptyBefore={emptyBefore}
          emptyAfter={emptyAfter}
          axisStartIndex={0}
          axisEndIndex={bolusAxisEndIndex}
          axisTickStep={bolusAxisTickStep}
          axisTitle={this.props.insulinOnly ? t('insulin') : t('bolus')}
          axisSubtitle={`(${t('units')})`}
          axisWidth={this.props.axisWidth}
          oneDayWidth={this.props.oneDayWidth}
        >
          <div class={Style.borderLeftRightBottom}>
            <BolusGraph
              startTimestamp={this.props.startTimestamp}
              endTimestamp={this.props.endTimestamp}
              series={this.props.bolusSeries}
              windowHeight={64}
              windowWidth={this.props.graphsWindowWidth}
              yAxisMax={bolusAxisEndIndex}
              fetchStatus={fetchStatus}
              configOverride={
                deepMerge(configOverride, (this.props.insulinOnly ? xAxisTickConfig : {}))
              }
            />
          </div>
        </CalendarGraphRow>
        {((insulinOnly) => {
          if (insulinOnly) {
            return null;
          }
          return (
            <CalendarGraphRow
              startTimestamp={this.props.startTimestamp}
              endTimestamp={this.props.endTimestamp}
              windowHeight={56}
              emptyBefore={emptyBefore}
              emptyAfter={emptyAfter}
              axisStartIndex={0}
              axisEndIndex={this.props.basalAxisEndIndex}
              axisTickStep={this.props.basalAxisEndIndex / 2}
              axisTitle={t('basal')}
              axisSubtitle={`(${t('units_per_hour')})`}
              axisWidth={this.props.axisWidth}
              oneDayWidth={this.props.oneDayWidth}
            >
              <div class={Style.borderLeftRightBottom}>
                <BasalGraph
                  startTimestamp={this.props.startTimestamp}
                  endTimestamp={this.props.endTimestamp}
                  series={this.props.basalSeries}
                  windowHeight={56}
                  windowWidth={this.props.graphsWindowWidth}
                  fetchStatus={fetchStatus}
                  yAxisMax={this.props.basalAxisEndIndex}
                  configOverride={deepMerge(configOverride, xAxisTickConfig)}
                  updateSeries={this.props.updateSeries}
                />
              </div>
            </CalendarGraphRow>
          );
        })(this.props.insulinOnly)}
      </div>
    );
  }
}

CalendarGraphPresenter.propTypes = {
  startTimestamp: PropTypes.number.isRequired,
  endTimestamp: PropTypes.number.isRequired,
  meterUnits: PropTypes.string.isRequired,
  graphsWindowWidth: PropTypes.number.isRequired,
  oneDayWidth: PropTypes.number.isRequired,
  axisWidth: PropTypes.number.isRequired,
  bgGraphSeries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  carbsSeries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  bolusSeries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  basalSeries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  insulinOnly: PropTypes.bool.isRequired,
  carbsGraphHeight: PropTypes.number.isRequired,
  emptyBefore: PropTypes.number.isRequired,
  emptyAfter: PropTypes.number.isRequired,
  bolusAxisEndIndex: PropTypes.number.isRequired,
  basalAxisEndIndex: PropTypes.number.isRequired,
  bolusAxisTickStep: PropTypes.number.isRequired,
  bgGraphAxisEndIndex: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  updateSeries: PropTypes.func.isRequired,
  fetchStatus: PropTypes.oneOf(FETCH_STATUSES).isRequired,
};

CalendarGraphPresenter.defaultProps = {
  meterUnits: MGDL,
  t: (k) => `i18n.${k}`,
  fetchStatus: FETCH_STATUS_NOT_CALLED,
  updateSeries: () => null,
};

export const TranslatedCalendarGraphPresenter = translate('CalendarGraphPresenter')(CalendarGraphPresenter);
export default WithLoadingState(
  TranslatedCalendarGraphPresenter,
  (props) => props.fetchStatus !== FETCH_STATUS_SUCCESSFUL &&
    props.fetchStatus !== FETCH_STATUS_FAILED,
  {
    emptyComponent: () => (
      <div style={{
        left: 122,
        width: 'calc(100% - 122px)',
        height: '100%',
        position: 'absolute',
      }}
      >
        <Spinner />
      </div>
    ),
  },
);
