import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { map, range } from 'lodash';
import {
  CALENDAR_AXIS_WIDTH_PDF,
  CALENDAR_AXIS_WIDTH_WEB,
  CALENDAR_ONE_DAY_WIDTH_PDF,
  CALENDAR_ONE_DAY_WIDTH_WEB,
} from '~/bundles/shared/constants/pages/calendar';
import { TIME_FORMATS } from '~/utils/i18nFormats';
import Style from './CalendarWeekAxisAbove.scss';

const mapStateToProps = (state) => (
  state.pdf.inPDF ? {
    oneDayWidth: CALENDAR_ONE_DAY_WIDTH_PDF,
    axisWidth: CALENDAR_AXIS_WIDTH_PDF,
  } : {});

@connect(mapStateToProps)
export default class CalendarWeekAxisAbove extends React.Component {
  elements = () => map(
    range(0, 7),
    (day) => {
      const time = moment.unix(this.props.startTimestamp).utc().startOf('isoWeek').add(day, 'days');
      return {
        dow: time.format('dd'),
        date: time.format(TIME_FORMATS.M_D),
      };
    })

  render() {
    const { oneDayWidth, axisWidth } = this.props;

    return (
      <div className={Style.CalendarWeekAxisAbove}>
        <div
          className={Style.leftPadding}
          style={{ width: axisWidth }}
        >&nbsp;
        </div>
        {map(this.elements(), (element) => (
          <div
            key={element.dow}
            className={Style.day}
            style={{ width: oneDayWidth }}
          >
            <span className={Style.dow}>{element.dow}
            </span> - <span className={Style.date}>{element.date}</span>
          </div>
        ))}
      </div>
    );
  }
}

CalendarWeekAxisAbove.propTypes = {
  axisWidth: PropTypes.number.isRequired,
  oneDayWidth: PropTypes.number.isRequired,
  startTimestamp: PropTypes.number.isRequired,
};

CalendarWeekAxisAbove.defaultProps = {
  oneDayWidth: CALENDAR_ONE_DAY_WIDTH_WEB,
  axisWidth: CALENDAR_AXIS_WIDTH_WEB,
  startTimestamp: 0,
};
