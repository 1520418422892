import { groupBy } from 'lodash';
import moment from 'moment';

export const groupDataByTimeInterval = (data, timeValue, timeUnit) => {
  let startPoint, endPoint;
  const updateInterval = (timestamp) => {
    startPoint = timestamp;
    endPoint = moment.utc(startPoint).add(timeValue, timeUnit);
  };
  updateInterval(data[0].timestamp);

  return groupBy(data, (item) => {
    if (startPoint === item.timestamp || moment.utc(item.timestamp).isBefore(endPoint)) {
      return startPoint;
    }
    updateInterval(item.timestamp);
    return startPoint;
  });
};
