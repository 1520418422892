import React from 'react';
import PropTypes from 'prop-types';
import { filter } from 'lodash';
import PatientFlag from './PatientFlag/PatientFlag'; // eslint-disable-line
import styles from './PatientFlagsColumn.scss';
import TagList from '../../../../shared/components/TagList/TagList';

export default class PatientFlagsColumn extends React.Component {
  static propTypes = {
    flags: PropTypes.array.isRequired,
    lastIndex: PropTypes.bool.isRequired,
    tagsMaxWidth: PropTypes.number,
    rowIndex: PropTypes.number.isRequired,
  }

  static defaultProps = {
    tagsMaxWidth: 0,
  }

  constructor(props) {
    super(props);
    this.updateFlags = this.updateFlags.bind(this);
  }

  getFlagContent = (flag) => (
    <PatientFlag
      flag={flag}
    />)

  updateFlags(flags) {
    const updatedFlags = [{ label: 'Flag', tags: [] }];
    flags.forEach((flag, index) => {
      updatedFlags[0].tags.push({
        id: index,
        label: flag.label,
        hover: (this.getFlagContent(flag)),
        class: (flag.ownedByGlooko ? styles.glookoFlag : styles.customFlag),
      });
    });
    return updatedFlags;
  }

  render() {
    const { flags, tagsMaxWidth, rowIndex } = this.props;
    const flagsTags = filter(flags, (item) => item.category === 'flags');
    const updatedFlags = flagsTags && flagsTags.length > 0 ?
      this.updateFlags(flagsTags[0].tags) : this.updateFlags([]);
    return (
      <div
        className={styles.patientFlagsColumn}
      >
        <TagList
          id={`flags-${rowIndex}`}
          tooltipPosition="bottom"
          tags={updatedFlags}
          maxWidth={tagsMaxWidth}
        />
      </div>
    );
  }
}
