import React from 'react';
import PropTypes from 'prop-types';
import ImageStore from '~/utils/ImageStore';
import SupportLinks from '~/utils/support';
import PopUp from '~/bundles/shared/components/PopUpContainer/PopUp/PopUp';
import translate from '~/bundles/shared/components/WithTranslate/WithTranslate';
import Style from './ContactSupportDialog.scss';

const ContactSupportDialog = ({ t, onClose }) => {
  const body = (
    <div className={Style.body}>
      <img className={Style.deviceImage} src={ImageStore.dashDevice} alt="Insulet Dash" />
      <div className={Style.info}>
        <p className={Style.text}>{t('useWindows')}</p>
        <p className={Style.text}>
          {t('dontHaveWindows')}&nbsp;
          <a href={SupportLinks.fileTicket()} className={Style.contactLink}>{t('contactSupport')}</a>&nbsp;
          {t('forAssistance')}.
        </p>
      </div>
    </div>
  );

  return (
    <PopUp
      showPopUp
      showOverlay={false}
      title={t('uploadData')}
      body={body}
      footer={null}
      hidePopUp={onClose}
      style={{
        position: 'fixed',
        width: '600px',
        height: '255px',
        marginLeft: '-300px',
        top: '22%',
      }}
    />
  );
};

ContactSupportDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

ContactSupportDialog.propTypes = {
  onClose: () => {},
  t: () => {},
};

export default translate('ContactSupportDialog')(ContactSupportDialog);
