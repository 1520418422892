import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Style from './GraphsLegend.scss';

const Item = (props) => (
  <div
    className={classNames(Style.groupElement, { [Style.hidden]: !props.visible })}
    data-testid={props.testId}
  >
    <img alt="icon" className={Style.asset} src={props.src} style={props.assetStyle} />
    <div className={classNames({
      [Style.text]: true,
      [Style.inPdf]: props.inPdf,
    })}
    >
      {props.label}
    </div>
  </div>
);

Item.propTypes = {
  src: PropTypes.string.isRequired,
  assetStyle: PropTypes.shape({}),
  label: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  inPdf: PropTypes.bool,
  testId: PropTypes.string,
};

Item.defaultProps = {
  assetStyle: {},
  visible: false,
  inPdf: false,
};

export default Item;
