import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Styles from './Button.scss';

const Button = (props) => {
  const { onClickHandler, text, secondary, buttonClass } = props;

  return (
    <button
      onClick={onClickHandler}
      type="button"
      className={
        classNames(Styles.button, { [Styles['button--secondary']]: secondary }, buttonClass)
      }
    >
      {text}
    </button>
  );
};

Button.propTypes = {
  onClickHandler: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  secondary: PropTypes.bool,
  buttonClass: PropTypes.string,
};

Button.defaultProps = {
  secondary: false,
  buttonClass: null,
};

export default Button;
