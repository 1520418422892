import envConfig from '../../../../config/GlobalEnvConfig';

export const OMNIPOD_WEB_UPLOAD_FEATURE = 'OmniPod Web Upload';
export const OMNIPOD_FEATURE = 'OmniPod';
export const UPLOADER_FEATURE = 'Uploader';
export const CASE_MANAGMENT_FEATURE = 'Case Managment';
export const MIDS_FEATURE = 'MIDS';
export const PATIENT_DEACTIVATION_FEATURE = 'Patient Deactivation';
export const POPULATION_TRACKER_V2 = 'Population Tracker V2';
export const TWO_FACTOR_AUTHENTICATION = 'Two Factor Authentication';
export const INSULET_SPONSOR = 'Insulet';
export const POP_INSIGHTS_FEATURE = 'Population Insights';
export const DETAILED_BG_TAGGING_FEATURE = 'Detailed BG Tagging';
export const PATH_POP_INSIGHTS_API_URL = envConfig.popInsightsApiUrl;

export const KIOSK_SYNC_DASHBOARD = 'kioskSyncDashboard';

export const SUBSCRIPTION_MODEL_OPTIONS = { KIOSK_SYNC_DASHBOARD };
