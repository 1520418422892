import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ImageStore from '~/utils/ImageStore';
import Style from './OverviewBreadcrumbs.scss';

const OverviewBreadcrumbsItem = (props) => {
  const { label, onClick, lastItem, clickable, id } = props;

  return (
    <div
      role="presentation"
      class={classNames({
        [Style.OverviewBreadcrumbsItem]: true,
        [Style.clickable]: clickable,
      })}
      onClick={() => onClick(id)}
    >
      <span>{label}</span>
      <img
        class={classNames({
          [Style.arrow]: true,
          [Style.visible]: !lastItem,
        })}
        src={ImageStore.ArrowRight}
        alt=""
      />
    </div>
  );
};

OverviewBreadcrumbsItem.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  lastItem: PropTypes.bool.isRequired,
  clickable: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
};

OverviewBreadcrumbsItem.defaultProps = {
  onClick: () => {},
};

export default OverviewBreadcrumbsItem;
