import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '@glooko/common-ui';
import Images from '~/utils/ImageStore';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import useSanitizer from '~/hooks/useSanitizer';
import Style from '../PartnerIntegrationsPresenter/PartnerIntegrationsPresenter.scss';
import { GreyOverlay } from '../../../../shared/components/Overlay/Overlay';
import {
  POPUP_CONNECT_SUCCEEDED,
  POPUP_DISCONNECT_SUCCEEDED,
} from '../PartnerIntegrationsPresenter/PartnerIntegrationsPresenter';

const PopupActionSucceeded = (props) => {
  const { data, closePopup, state, t } = props;
  const sanitizer = useSanitizer();

  return (
    <div>
      <GreyOverlay />
      <div className={Style.popup}>

        <div
          className={Style.closeButton}
          role="button"
          tabIndex={0}
          onClick={closePopup}
        >
          <img src={Images.closeButton} alt="x" />
        </div>

        <div className={Style.innerPopup}>
          <div className={Style.header}>
            <div className={classNames(Style.brandImage,
              { [Style.success]: state === POPUP_CONNECT_SUCCEEDED })}
            >
              <img src={data.iconUrl} alt={data.name} />
            </div>
            {state === POPUP_CONNECT_SUCCEEDED && (
              <span>{t('success')}</span>
            )}
            {state === POPUP_DISCONNECT_SUCCEEDED && (
              <span>{data.name}</span>
            )}
          </div>

          {state === POPUP_CONNECT_SUCCEEDED && (
            <div className={Style.content}>
              <p dangerouslySetInnerHTML={{ __html: sanitizer(data.successText) }} />
            </div>
          )}

          {state === POPUP_DISCONNECT_SUCCEEDED && (
            <div className={Style.content}>
              {t('disconnected', { integration: data.name })}
            </div>
          )}

          <div className={Style.actions}>
            <Button
              onClick={closePopup}
              dataAttributes={{ testid: 'popup-action-succeeded-done' }}
            >
              {t('done')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

PopupActionSucceeded.propTypes = {
  data: PropTypes.object.isRequired,
  closePopup: PropTypes.func.isRequired,
  state: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

PopupActionSucceeded.defaultProps = {
  data: {},
  closePopup: /* istanbul ignore next */ () => {},
  state: '',
  t: /* istanbul ignore next */ () => {},
};

export default translate('PartnerIntegrationsPresenter')(PopupActionSucceeded);
