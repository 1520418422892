import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogHeader } from '@glooko/common-ui';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import Style from './AccountCreatedModal.scss';

const AccountCreatedModal = (props) => {
  const {
    t, isOpen, firstName, lastName, providerGroupSiteName,
    handleCloseModal, glookoCode, openInsuletWeb, showUploadOmnipod,
  } = props;
  return (
        <Dialog size={showUploadOmnipod ? 'medium' : 'small'} open={isOpen} onClose={handleCloseModal} dataAttributes={{ testid: 'account-created' }}>
        <DialogHeader dataAttributes={{ testid: 'account-created' }}><h2>{t('title')}</h2></DialogHeader>
        <DialogContent dataAttributes={{ testid: 'account-created' }}>
        <div className={Style.accountCreatedBody}>
        <div
          class={classNames({
            [Style.accountCreatedInfo]: true,
            [Style.firstItem]: true,
          })}
        >
        {t('name')}: {firstName} {lastName}
        </div>
        <div className={Style.accountCreatedInfo}>{t('proconnectInfo')} {providerGroupSiteName}!</div>
        </div>
        </DialogContent>
        <DialogActions dataAttributes={{ testid: 'account-created' }}>
        <div className={Style.accountCreatedFooter}>
        <Button
          class={classNames({
            [Style.LinkButton]: true,
            [Style.btnLink]: true,
          })}
          onClick={() => window.location.assign(`/settings?patient=${glookoCode}`)}
          variation='link'
          dataAttributes={{ testid: 'account-created-patient-settings' }}
        >
          {t('patientSettings')}
        </Button>
        {showUploadOmnipod &&
          <Button
            class={classNames({
              [Style.LinkButton]: true,
              [Style.btnLink]: true,
            })}
            onClick={openInsuletWeb}
            variation='link'
            dataAttributes={{ testid: 'account-created-upload-omnipod' }}
          >
            {t('uploadOmnipod')}
          </Button>}
        <Button
          class={classNames({
            [Style.Button]: true,
            [Style.primary]: true,
            [Style.buttonOk]: true,
          })}
          onClick={handleCloseModal}
          dataAttributes={{ testid: 'account-created-modal-ok' }}
        >
          {t('ok')}
        </Button>
        </div>
        </DialogActions>
        </Dialog>
  );
};

AccountCreatedModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  providerGroupSiteName: PropTypes.string.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  glookoCode: PropTypes.string.isRequired,
  showUploadOmnipod: PropTypes.bool.isRequired,
};

export default translate('AccountCreatedModal')(AccountCreatedModal);
