import i18next from 'i18next';
import { reactI18nextModule } from 'react-i18next';
import moment from 'moment';
import { I18nextKeysOnDemand } from 'i18next-keys-ondemand';
import elementClass from 'element-class';
import { germanLocaleClassName } from '~/../assets/styles/gl_classNames.scss';
import * as ENV from '~/bundles/shared/constants/env';
import translations from './Translations';
import { defaultLocale, locale } from './FromRails';
import './UpdateMomentConfig';

const KeysOnDemand = new I18nextKeysOnDemand({
  translationGetter: (keys, lang, namespace) => (
    new Promise((resolve) => {
      const t = i18next.getFixedT(defaultLocale, namespace);
      setTimeout(() => {
        resolve(keys.reduce((acc, currentItem) => {
          const translated = t(currentItem);
          const output = translated === '' ? 'MISSING_FALLBACK_TRANSLATION' : `'${translated}'`;
          return {
            ...acc,
            [currentItem]: `${lang}:${output}`,
          };
        }, {}));
      }, 1);
    })
  ),
});

i18next
  .use(reactI18nextModule)
  .use(ENV.debug() ? KeysOnDemand : {}) // Empty object works as nothing
  .init({
    fallbackLng: ENV.debug() ? false : defaultLocale,
    lng: locale,
    debug: ENV.debug(),
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
    },
    resources: translations,
    defaultNS: 'translation',
  });

i18next.on('languageChanged', (newLocale) => {
  moment.locale(newLocale);

  elementClass(
    document.getElementsByTagName('html')[0],
  )[(newLocale === 'de' ? 'add' : 'remove')](germanLocaleClassName);
});

i18next.changeLanguage(locale);

export const getCollator = () => new Intl.Collator(i18next.language.substr(0, 2));

export const getTextDirection = () => i18next.dir(i18next.language);

export default i18next;
