import {
  graphsFetchSuccess,
  overviewHoursMaximum,
  graphsInProgress,
  graphsHasErrored,
  statisticsFetchSuccess,
  statisticsHasErrored,
  graphsUpdateSeries,
  graphsUpdateWeekViewSeries,
  overviewHoursLegendConfig,
  overviewDaysLegendConfig,
  graphsDevices,
} from '~/redux/modules/graphs/graphs';
import {
  fetchCalendarGraphSeries,
  fetchOverlayScatterDowGraphSeries,
  fetchOverlayAgpOverallData,
  fetchOverlayAgpDowData,
  fetchStatisticsData,
  fetchOverviewExercise,
  fetchOverlayBoxPlotOverallData,
  fetchOverlayBoxPlotDowData,
  fetchOverviewAverageAndPercentiles,
} from '~/services/graphsApi';
import { updateOverallStatistics } from '~/redux/modules/statistics/overall/overall';
import ApiWrappers from '~/services/apiWrappers';
import { calculateAxisMaxFromProps }
  from '~/bundles/graphs/components/OverviewTabContainer/OverviewGraphHours/OverviewGraphHoursContainer/HoursGraphHelpers';
import { COMPONENT_ID as OVERVIEW_TOOLTIP_CONTAINER_ID }
  from '~/bundles/graphs/components/OverviewTabContainer/OverviewTooltip/OverviewTooltipContainer/OverviewTooltipContainer.jsx';
import throwIfStatusCodeNotExpected from '~/utils/throwIfStatusCodeNotExpected';

export const fetchGenerator = (options) => {
  const { fetchMethod, wrapperMethod } = options;
  const { errorHandler, inProgressHandler, successHandler } = options;
  return (params) => (
    (dispatch) => {
      if (inProgressHandler) {
        dispatch(inProgressHandler(params));
      }

      return fetchMethod(params)
        .then(throwIfStatusCodeNotExpected(200))
        .then((response) => (wrapperMethod ? wrapperMethod(params, response) : response))
        .then((response) => dispatch(successHandler(params, response)))
        .catch(() => dispatch(errorHandler(params)));
    }
  );
};

const graphsStateHandlers = {
  successHandler: graphsFetchSuccess,
  inProgressHandler: graphsInProgress,
  errorHandler: graphsHasErrored,
};

export function graphStatisticsFetchSuccessThunk(params, overall) {
  return (dispatch) => {
    dispatch(statisticsFetchSuccess(params, overall));
    // when retrieving stats for the tooltip we don't want to update the overall statistics
    if (params.id !== OVERVIEW_TOOLTIP_CONTAINER_ID) {
      // updating overall statistics allows for hiding exercise controls
      dispatch(updateOverallStatistics(overall, params));
    }
  };
}

export function graphsUpdateSeriesThunk(plot, lookup, series) {
  return (dispatch) => {
    dispatch(graphsUpdateSeries(plot, lookup, series));
  };
}

export function graphsUpdateSeriesFromCacheThunk(params, series) {
  return (dispatch) => dispatch(graphsFetchSuccess(params, series));
}

const statisticsStateHandlers = {
  successHandler: graphStatisticsFetchSuccessThunk,
  inProgressHandler: null,
  errorHandler: statisticsHasErrored,
};

const overviewDayFetchSuccess = (params, response) => (dispatch) => {
  dispatch(graphsFetchSuccess(params, response.series));
  dispatch(overviewHoursMaximum(params, calculateAxisMaxFromProps(response.series, params.meterUnits)));
  dispatch(graphsDevices(params, response.devices));
};

const overviewDayHoursOverviewFetchSuccess = (params, response) => (dispatch) => {
  dispatch(graphsFetchSuccess(params, response));
  dispatch(overviewHoursMaximum(params, calculateAxisMaxFromProps(response, params.meterUnits)));
};

export const graphsFetchDataThunk = fetchGenerator({
  fetchMethod: fetchCalendarGraphSeries,
  wrapperMethod: (_, response) => ApiWrappers.calendarAll(response.data.series),
  ...graphsStateHandlers,
});

export const graphsFetchOverviewDayDataThunk = fetchGenerator({
  fetchMethod: fetchCalendarGraphSeries,
  wrapperMethod: (params, response) => ({ series: ApiWrappers.overviewHours(params, response.data.series), devices: response.data.devices }),
  successHandler: overviewDayFetchSuccess,
  inProgressHandler: graphsInProgress,
  errorHandler: graphsHasErrored,
});

export const graphsUpdateOverviewHoursLegendConfigThunk = (config) => (dispatch) => {
  dispatch(overviewHoursLegendConfig(config));
};

export const graphsFetchOverviewThunk = fetchGenerator({
  fetchMethod: fetchCalendarGraphSeries,
  wrapperMethod: (params, response) => ApiWrappers.overviewAll(params, response.data.series),
  ...graphsStateHandlers,
});

export const graphsFetchWeekViewWebDataThunk = fetchGenerator({
  fetchMethod: fetchCalendarGraphSeries,
  wrapperMethod: (params, response) => ApiWrappers.weekViewWeb(params, response.data.data),
  ...graphsStateHandlers,
});

export function graphsUpdateWeekViewDaySeriesThunk(reduxKey, day, seriesName, series) {
  return (dispatch) => {
    dispatch(graphsUpdateWeekViewSeries(reduxKey, day, seriesName, series));
  };
}

export const graphsUpdateOverviewDaysLegendConfigThunk = (config) => (dispatch) => {
  dispatch(overviewDaysLegendConfig(config));
};

export const graphsFetchExerciseOverviewThunk = fetchGenerator({
  fetchMethod: fetchOverviewExercise,
  wrapperMethod: (params, response) => ApiWrappers.overviewExercise(params, response.data.series),
  ...graphsStateHandlers,
});

export const graphsFetchExerciseHoursOverviewThunk = fetchGenerator({
  fetchMethod: fetchOverviewExercise,
  wrapperMethod: (params, response) => ApiWrappers.overviewExerciseHours(params, response.data.series),
  successHandler: overviewDayHoursOverviewFetchSuccess,
  inProgressHandler: graphsInProgress,
  errorHandler: graphsHasErrored,
});

export const graphsFetchStatisticsDataThunk = fetchGenerator({
  fetchMethod: fetchStatisticsData,
  wrapperMethod: (params, response) => ApiWrappers.processEmptyStatistics(response.data),
  ...statisticsStateHandlers,
});

export const graphFetchAverageAndPercentilesThunk = fetchGenerator({
  fetchMethod: fetchOverviewAverageAndPercentiles,
  wrapperMethod: (params, response) => ApiWrappers.calendarAll(response.data),
  ...graphsStateHandlers,
});

export const graphsFetchScatterDowDataThunk = fetchGenerator({
  fetchMethod: fetchOverlayScatterDowGraphSeries,
  wrapperMethod: (params, response) => ApiWrappers.scatterDow(response.data, params.type),
  ...graphsStateHandlers,
});

export const graphsFetchAgpOverallDataThunk = fetchGenerator({
  fetchMethod: fetchOverlayAgpOverallData,
  wrapperMethod: (params, response) => (
    ApiWrappers.agpOverall(response.data.agpOverall, params.meterUnits, params.type)
  ),
  ...graphsStateHandlers,
});

export const graphsFetchBoxPlotOverallThunk = fetchGenerator({
  fetchMethod: fetchOverlayBoxPlotOverallData,
  wrapperMethod: (params, response) => (
    ApiWrappers.agpOverall(response.data, params.meterUnits, params.type)
  ),
  ...graphsStateHandlers,
});

export const graphsFetchBoxPlotDowDataThunk = fetchGenerator({
  fetchMethod: fetchOverlayBoxPlotDowData,
  wrapperMethod: (params, response) => (
    ApiWrappers.agpDow(response.data, params.meterUnits, params.type)
  ),
  ...graphsStateHandlers,
});

export const graphsFetchAgpDowDataThunk = fetchGenerator({
  fetchMethod: fetchOverlayAgpDowData,
  wrapperMethod: (params, response) => (
    ApiWrappers.agpDow(response.data.agpDow, params.meterUnits, params.type)
  ),
  ...graphsStateHandlers,
});
