import React from 'react';
import shortid from 'shortid';
import _ from 'lodash';
import PropTypes from 'prop-types';

const TextHighlighter = (props) => {
  const { search, text } = props;

  const matcher = (keyword, text) => {
    const results = [];
    let sliceModifier = 0;

    while (text.indexOf(keyword) !== -1) {
      const start = text.indexOf(keyword);
      const end = (start + keyword.length) - 1;

      results.push([start + sliceModifier, end + sliceModifier]);
      // eslint-disable-next-line no-param-reassign
      text = text.replace(new RegExp(keyword), '');
      sliceModifier += keyword.length;
    }

    return results;
  };

  const mapper = () => {
    const keywords = search.trim().toLowerCase().split(' ').filter((element) => element !== '');
    const textLowercase = text.toLowerCase();
    const results = [];

    keywords.forEach((keyword) => results.push(...matcher(keyword, textLowercase)));

    return results.sort((a, b) => a[0] - b[0]);
  };

  const highlightMap = () => {
    const maps = mapper();
    let acc = maps[0];
    const results = [];

    for (let i = 1; i < maps.length; i += 1) {
      let allElems;
      const curr = maps[i];
      const currElems = _.range(curr[0], curr[1] + 1);
      const accElems = _.range(acc[0], acc[1] + 1);

      if (_.intersection(accElems, currElems).length > 0) {
        allElems = accElems.concat(currElems);
        acc = [_.min(allElems), _.max(allElems)];
      } else {
        results.push(acc);
        acc = [...curr];
      }

      if (i === maps.length - 1) results.push(acc);
    }

    return maps.length === 1 ? [acc] : results;
  };

  const highlighter = () => {
    const highlightMaps = highlightMap();
    const highlightedText = [];
    let start = 0;

    highlightMaps.forEach((map) => {
      if (start !== map[0]) {
        highlightedText.push(text.substring(start, map[0]));
      }
      highlightedText.push(<strong key={shortid.generate()}>{text.substring(map[0], map[1] + 1)}</strong>);
      start = map[1] + 1;
    });

    if (start !== text.length) {
      highlightedText.push(text.substring(start));
    }

    return highlightedText;
  };

  return search.trim() === '' ? [text] : highlighter();
};

TextHighlighter.propTypes = {
  text: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
};

export default TextHighlighter;
