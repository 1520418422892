import moment from 'moment';
import classNames from 'classnames';
import { TIME_FORMATS } from 'utils/i18nFormats';
import { ReactNode } from 'react';
import WithTranslate from 'bundles/shared/components/WithTranslate/WithTranslate';
import Style from './SetSiteChangeTooltip.scss';

interface SetSiteChangeTooltipItem {
  type: string;
  timestamp: string;
}

interface SetSiteChangeTooltipProps extends WithTranslationFunction {
  pumpName: string;
  items: SetSiteChangeTooltipItem[];
}

export const SetSiteChangeTooltip = (props: SetSiteChangeTooltipProps) => {
  const { t, pumpName, items } = props;
  const date = moment.utc(items[0].timestamp).format(TIME_FORMATS.MMM_DD_YYYY as unknown as string);
  const sortItems = (a: SetSiteChangeTooltipItem, b: SetSiteChangeTooltipItem) => moment.utc(a.timestamp).unix() - moment.utc(b.timestamp).unix();
  items.sort(sortItems);
  const translationMap: Record<string, string> = {
    prime_cannula: t('primeCannula'),
    prime_tubing: t('primeTubing'),
  };

  const generateRows = (): ReactNode[] => items.map(({ type, timestamp }) => {
    const time = moment.utc(timestamp).format(TIME_FORMATS.H_MM__A as unknown as string);

    return (
      <div key={`${time}-${type}`}>
        <div
          className={
            classNames({
              [Style.row]: true,
            })
          }
        >
          <div className={Style.itemRow}>
            <span>{translationMap[type] || type}</span>
            <span>{time}</span>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className={Style.SetSiteChangeTooltip}>
      <div className={Style.header}>
        <span className={Style.date}>{date}</span>
      </div>

      <div className={Style.rows}>
        <div className={Style.deviceNameRow}>
          <span>{pumpName}</span>
        </div>

        {generateRows()}
      </div>
    </div>
  );
};

export default WithTranslate('SetSiteChangeTooltip')(SetSiteChangeTooltip);
