import { FETCH_STATUS_NOT_CALLED, FETCH_STATUS_SUCCESSFUL } from
  '~/bundles/shared/constants/graphs';
import { BLANK_STATS_VALUE } from '~/bundles/shared/constants/statistics';
import { keyFromParams } from '~/redux/modules/graphs/graphs';

export const UPDATE_OVERALL_STATS = 'UPDATE_OVERALL_STATS';
export const UPDATE_OVERALL_STATS_STATUS = 'UPDATE_OVERALL_STATS_STATUS';
export const UPDATE_WEEK_VIEW_STATS = 'UPDATE_WEEK_VIEW_STATS';

export function updateOverallStatistics(stats, params) {
  return { type: UPDATE_OVERALL_STATS, stats, params };
}

export function updateOverallStatisticsStatus(status) {
  return { type: UPDATE_OVERALL_STATS_STATUS, status };
}

export function updateWeekViewOverallStatistics(stats, params) {
  return { type: UPDATE_WEEK_VIEW_STATS, stats, params };
}

const initialState = {
  status: FETCH_STATUS_NOT_CALLED,
  params: {},
  numOfBolusesPerDay: 0,
  bolusUnitsPerDay: 0,
  basalUnitsPerDay: 0,
  premixedUnitsPerDay: 0,
  daysWithoutInsulin: 0,
  totalInsulinPerDay: 0,
  bolusPercentage: 0,
  basalPercentage: 0,
  premixedPercentage: 0,
  totalPumpInsulinPerDay: 0,
  manualInsulinPerDay: 0,
  averageIndividualBolus: 0,
  correctionBolusPercentage: 0,
  bolusOverridesCount: 0,
  bolusOverridePercentage: 0,
  numOfCorrectionBolusesPerDay: 0,
  averageSteps: BLANK_STATS_VALUE,
  averageDuration: BLANK_STATS_VALUE,
  carbsPerDay: -1,
  carbEntriesPerDay: -1,
  totalCarbs: -1,
  manualInsulinDosesPerDay: 0,
  hasGlookoExercise: false,
  otherBasalUnitsPerDay: 0,
  otherBolusUnitsPerDay: 0,
  otherPremixedUnitsPerDay: 0,
  otherBolusPercentage: 0,
  otherBasalPercentage: 0,
  otherPremixedPercentage: 0,
  numOfOtherBolusesPerDay: 0,
  hasPump: false,
  hasPen: false,
  hasPrimeDeviceData: false,
};

const getMatchingStateKeys = (state, keyMatcher) => (
  Object.keys(state).filter((stateKey) => keyMatcher(stateKey))
);

const clearKeys = (keys, actionStats) => {
  keys.forEach((key) => {
    if (!Object.keys(actionStats).includes(key)) {
      actionStats[key] = undefined;
    }
  });
};

const clearSystemData = (state, actionStats) => {
  [
    'controlIqPumpMode', 'basalIqPumpMode', 'op5PumpMode',
    'genericPumpMode', 'camapsPumpMode',
  ].forEach((key) => {
    if (
      actionStats[`${key}ManualPercentage`] === undefined &&
      actionStats[`${key}AutomaticPercentage`] === undefined
    ) {
      Object.keys(state).filter((stateKey) => stateKey.indexOf(key) >= 0)
        .forEach((stateKey) => { actionStats[stateKey] = undefined; });
    }
  });
};

const clearOmittedData = (state, actionStats) => {
  // clear bloodPressure* and weight fields as API does not return them if no reading
  const keysToClear = [];
  keysToClear.push(...getMatchingStateKeys(state, (key) => key.indexOf('bloodPressure') === 0));
  keysToClear.push(...getMatchingStateKeys(state, (key) => key.indexOf('weight') === 0));
  clearKeys(keysToClear, actionStats);
};

export default function reducer(state = initialState, action = {}) {
  const actionStats = Object.assign({}, action.stats);

  switch (action.type) {
    case UPDATE_OVERALL_STATS:
      // workaround to make sure state is cleared for pump* fields
      if (action.params.includePumpModes) clearSystemData(state, actionStats);
      // clear any data that is omitted if not present
      clearOmittedData(state, actionStats);

      return {
        ...state,
        ...actionStats,
        status: FETCH_STATUS_SUCCESSFUL,
        params: action.params,
      };
    case UPDATE_OVERALL_STATS_STATUS:
      return { ...state, status: action.status };
    case UPDATE_WEEK_VIEW_STATS:
      return {
        ...state,
        [keyFromParams(action.params)]: {
          ...actionStats,
          status: FETCH_STATUS_SUCCESSFUL,
          params: action.params,
        },
      };
    default:
      return state;
  }
}
