import moment from 'moment';
import MainStore from '~/redux/store/MainStore';
import { USER_TYPE_PATIENT } from '~/bundles/shared/constants/users';
import { updateEndTimestampMax, updateTimestamps } from '~/redux/modules/page/page';
import { updatePdfWizardVisibilityThunk } from '~/redux/thunks/page';
import { usersReplacePatient, updateInsuletConsent } from '~/redux/modules/users/users';
import { updateDialogVisibility } from '~/redux/modules/omnipod/omnipod';
import { manualCheckinDialogRef } from '~/bundles/shared/components/ManualCheckin/ManualCheckinDialog';

class ExposedActions {
  static preparePDfWizardForPatient(patient) {
    let endDate;
    const subscriptionModel = MainStore.getState().providerGroupSite.subscriptionModel;
    const limitPdfToKioskSync = subscriptionModel.kioskSyncPdf;
    const { lastSyncTimestamp, lastKioskSync } = patient;

    if (limitPdfToKioskSync) {
      endDate = lastKioskSync === '-' ? moment.utc('1970-01-01').toISOString() : lastKioskSync;
    } else if (lastSyncTimestamp === '-') {
      endDate = moment.utc().toISOString();
    } else {
      endDate = lastSyncTimestamp;
    }

    const startDate = moment.utc(endDate).subtract(13, 'days').toISOString();

    MainStore.dispatch(usersReplacePatient({
      firstName: patient.firstName || '',
      lastName: patient.lastName || '',
      dateOfBirth: patient.dob || patient.dateOfBirth,
      diabetesType: patient.tod || patient.diabetesType,
      userType: 'cannot be Patient',
      admin: false,
      lastSyncTimestamps: {
        lastSyncTimestamp: lastSyncTimestamp === '-' ? null : lastSyncTimestamp,
        meter: patient.meterEnabled ? lastSyncTimestamp : null,
        pump: patient.pumpEnabled ? lastSyncTimestamp : null,
        cgmDevice: patient.cgmEnabled ? lastSyncTimestamp : null,
        kiosk: lastKioskSync === '-' ? null : lastKioskSync,
      },
      id: patient.id,
    }));
    MainStore.dispatch(updateEndTimestampMax(endDate, endDate));
    MainStore.dispatch(updateTimestamps(startDate, endDate));
  }

  static openPdfWizard() {
    MainStore.dispatch(updatePdfWizardVisibilityThunk(true));
  }

  static openManualCheckin(patient) {
    manualCheckinDialogRef.current.show(patient);
  }

  // Insulet Uploads
  static prepareInsuletUpload(patient) {
    const { lastSyncTimestamp, lastKioskSync } = patient;
    MainStore.dispatch(usersReplacePatient({
      id: patient.id,
      firstName: patient.firstName || '',
      lastName: patient.lastName || '',
      glookoCode: patient.glookoCode,
      insuletConsent: patient.insuletConsent,
      lastSyncTimestamps: {
        lastSyncTimestamp,
        meter: patient.meterEnabled ? lastSyncTimestamp : null,
        pump: patient.pumpEnabled ? lastSyncTimestamp : null,
        cgmDevice: patient.cgmEnabled ? lastSyncTimestamp : null,
        kiosk: lastKioskSync,
      },
      userType: USER_TYPE_PATIENT,
    }));
  }

  static openInsuletWebSync() {
    MainStore.dispatch(updateDialogVisibility(true));
  }

  static updateInsuletConsent(consent) {
    MainStore.dispatch(updateInsuletConsent(consent));
  }
}

export default ExposedActions;
window.ReactExposedActions = ExposedActions;
