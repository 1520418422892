import { getPatientICPAData, updatePatientICPAData } from '~/services/ICPA';
import throwIfStatusCodeNotExpected from '~/utils/throwIfStatusCodeNotExpected';
import {
  updatePatientICPADataInProgress,
  updatePatientICPADataSuccess,
  updatePatientICPADataFailed,
  resetUpdatePatientICPADataStatus,
  recentlySyncedUserICPAData,
  recentlySyncedUserICPADataSuccess,
  recentlySyncedUserICPADataFailed,
  currentPatientICPADataFetchSuccess,
  currentPatientICPADataFetchFailed,
} from '~/redux/modules/providerGroupSite/providerGroupSite';

export function updatePatientICPADetailsThunk(params, onFailure = undefined,
  onSuccess = undefined) {
  return (dispatch) => {
    dispatch(updatePatientICPADataInProgress());
    return updatePatientICPAData(params)
      .then(throwIfStatusCodeNotExpected(200))
      .then(() => {
        onSuccess();
        dispatch(updatePatientICPADataSuccess());
      })
      .catch((e) => {
        dispatch(updatePatientICPADataFailed());
        if (e.response.data && e.response.data.error_key) {
          onFailure(e.response.data.error_key);
        }
        console.log(e);
      });
  };
}
export function getRecentlySyncedUserICPAData(patientId) {
  return (dispatch) => {
    dispatch(recentlySyncedUserICPAData());
    return getPatientICPAData(patientId)
      .then(throwIfStatusCodeNotExpected(200))
      .then((res) => {
        const userDataObj = { [res.data.id]: res.data };
        dispatch(recentlySyncedUserICPADataSuccess(userDataObj));
      })
      .catch((e) => {
        dispatch(recentlySyncedUserICPADataFailed());
        console.log(e);
      });
  };
}

export function resetPatientICPADetailsStatusInStore() {
  return (dispatch) => dispatch(resetUpdatePatientICPADataStatus());
}

export function getCurrentPatientICPADataThunk(patientId) {
  return (dispatch) => getPatientICPAData(patientId)
    .then(throwIfStatusCodeNotExpected(200))
    .then((res) => {
      dispatch(currentPatientICPADataFetchSuccess(res.data));
    })
    .catch((e) => {
      dispatch(currentPatientICPADataFetchFailed());
      console.log(e);
    });
}
