import { FETCH_STATUS_NOT_CALLED, FETCH_STATUS_SUCCESSFUL } from
  '~/bundles/shared/constants/graphs';
import { BLANK_STATS_VALUE } from '~/bundles/shared/constants/statistics';

export const UPDATE_LGS_PLGS_STATS = 'UPDATE_LGS_PLGS_STATS';
export const UPDATE_LGS_PLGS_STATS_STATUS = 'UPDATE_LGS_PLGS_STATS_STATUS';

export function updateLgsPlgsStatistics(stats, params) {
  return { type: UPDATE_LGS_PLGS_STATS, stats, params };
}

export function updateLgsPlgsStatisticsStatus(status) {
  return { type: UPDATE_LGS_PLGS_STATS_STATUS, status };
}

const initialState = {
  status: FETCH_STATUS_NOT_CALLED,
  params: {},
  timeSuspendedPerDay: BLANK_STATS_VALUE,
  avgSuspensionsPerDay: BLANK_STATS_VALUE,
  avgSuspensionsMorningPer: BLANK_STATS_VALUE,
  avgSuspensionsAfternoonPer: BLANK_STATS_VALUE,
  avgSuspensionsEveningPer: BLANK_STATS_VALUE,
  avgSuspensionsNightPer: BLANK_STATS_VALUE,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_LGS_PLGS_STATS:
      return {
        ...state,
        ...action.stats,
        status: FETCH_STATUS_SUCCESSFUL,
        params: action.params,
      };
    case UPDATE_LGS_PLGS_STATS_STATUS:
      return { ...state, status: action.status };
    default:
      return state;
  }
}
