import React from 'react';
import PropTypes from 'prop-types';
import deepMerge from 'deepmerge';
import GraphPresenter from '~/bundles/shared/components/graphs/GraphPresenter/GraphPresenter.jsx';

export default class SingleColumnChart extends React.Component {
  render() {
    const baseConfig = {
      chart: {
        type: 'column',
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        height: 123,
        width: 33,
        showInLegend: false,
        borderRadius: 2,
      },
      credits: false,
      plotOptions: {
        allowPointSelect: false,
        column: {
          borderWidth: 0,
          stacking: 'percent',
          enabled: true,
          verticalAlign: 'middle',
          x: 30,
        },
        series: {
          pointWidth: 24,
          states: {
            hover: false,
          },
        },
      },
      legend: {
        enabled: false,
      },
      title: {
        text: null,
      },
      tooltip: false,
      xAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        labels: {
          enabled: false,
          style: {
            fontWeight: 500,
            fontSize: 14,
          },
        },
        minorTickLength: 0,
        tickLength: 0,
      },
      yAxis: {
        lineWidth: 0,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        labels: {
          enabled: false,
        },
        minorTickLength: 0,
        tickLength: 0,
        title: null,
        reversedStacks: false,
      },
    };
    const { data, chartName } = this.props;

    const series = data.map((mode) => (
      {
        name: chartName,
        data: [{
          y: mode.value,
          color: mode.color,
        }],
        dataLabels: {
          enabled: false,
        },
      }
    ));

    return (
      <GraphPresenter
        windowWidth={56}
        windowHeight={56}
        configOverride={deepMerge(baseConfig, this.props.configOverride)}
        series={series}
        dataLabelsEnabled={false}
        dataLabelsOverride
      />
    );
  }
}

SingleColumnChart.propTypes = {
  chartName: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string,
    value: PropTypes.number,
    alignment: PropTypes.string,
  })).isRequired,
  configOverride: PropTypes.shape({}),
};

SingleColumnChart.defaultProps = {
  configOverride: {},
};
