import { connect } from 'react-redux';
import images from '~/utils/ImageStore';
import colors from '~/../assets/styles/export/colors';
import UserHelper from '~/redux/modules/users/UserHelper';
import SystemStats from './SystemStats';
import translate from '../../../../shared/components/WithTranslate/WithTranslate';
import { hasMedtronicData } from '../../../../../services/hasDeviceData';

const mapStateToProps = (state, ownProps) => {
  const data = state.statistics.overall;
  const { t } = ownProps;

  const hasData = hasMedtronicData(UserHelper.displayUser(state), data);

  if (!hasData) {
    return {};
  }

  return {
    systemLabel: t('systemNameGeneric'),
    activeTime: data.genericPumpModeDurationString,
    data: [
      {
        label: t('automaticGeneric'),
        image: images.pumpAutomaticMode,
        value: data.genericPumpModeAutomaticPercentage,
        activeTime: data.genericPumpModePerModeDurationStrings.automatic,
      },
      {
        label: t('manualGeneric'),
        image: images.pumpManualMode,
        value: data.genericPumpModeManualPercentage,
        activeTime: data.genericPumpModePerModeDurationStrings.manual,
      },
    ],
    chartData: [
      {
        color: colors.systemModeGraphLineAuto,
        value: data.genericPumpModeAutomaticPercentage,
      },
      {
        color: colors.systemModeGraphLineManual,
        value: data.genericPumpModeManualPercentage,
      },
    ],
  };
};

export default translate('SystemStats')(connect(mapStateToProps)(SystemStats));
