import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import { Button, Dialog, DialogActions, DialogContent, DialogHeader } from '@glooko/common-ui';

const MinorPendingSharingConsentModal = (props) => {
  const { guardianEmail, consents, handleShowConsent, t } = props;
  const [showDialog, setShowDialog] = useState(true);

  useEffect(() => {
    setShowDialog(true);
  }, [consents]);

  const partner = consents.find((item) => item.alertMinor)?.partner;

  if (!partner) {
    return null;
  }

  const handleClick = (partnerName) => {
    handleShowConsent(partnerName, false);
    setShowDialog(false);
  };

  return (
    <Dialog onClose={() => handleClick(partner)} open={showDialog}>
      <DialogHeader>
        <h2>{t('sharingDataWith', { partner })}</h2>
      </DialogHeader>
      <DialogContent>
        <p>
          {t('dialoqContent', { partner, guardianEmail })}
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClick(partner)}>{t('Ok')}</Button>
      </DialogActions>
    </Dialog>
  );
};

MinorPendingSharingConsentModal.propTypes = {
  guardianEmail: PropTypes.string,
  partner: PropTypes.string,
  handleShowConsent: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

MinorPendingSharingConsentModal.defaultProps = {
  guardianEmail: '',
  partner: '',
};

export default translate('MinorPendingSharingConsentModal')(MinorPendingSharingConsentModal);
