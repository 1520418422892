import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { TIME_FORMATS, numberFormatterOneDecimal } from '~/utils/i18nFormats';
import { translate } from '../../../../WithTranslate/WithTranslate';
import Style from './CarbsTooltip.scss';

@translate('CarbsTooltip')
export default class CarbsTooltip extends React.Component {
  generateRows = () => {
    const { point, t } = this.props;
    const { children } = point;
    return children.sort((a, b) => a.x - b.x).map((item, i) => {
      const time = moment.utc(item.timestamp).format(TIME_FORMATS.H_MM__A);
      const name = [
        'Pumps Normal Bolus', 'Pumps Extended Bolus',
      ].includes(item.name) ? t('carbs') : (item.name || t('carbs'));

      return (
        <div key={`${i}-${time}-${item.carbs}`}>
          <div
            className={
              classNames({
                [Style.row]: true,
                [Style.first]: i === 0,
              })
            }
          >
            <div className={Style.itemRow}>
              <span>{time} {name}</span>
              <span>{`${numberFormatterOneDecimal(item.carbs)} g`}</span>
            </div>
          </div>
        </div>
      );
    });
  }

  render() {
    const { point, t } = this.props;
    const { children } = point;
    const date = moment.utc(point.timestamp).format(TIME_FORMATS.MMM_DD_YYYY);

    return (
      <div className={Style.CarbsTooltip}>
        <div className={Style.header}>
          <span className={Style.date}>{date}</span>
        </div>
        <div className={Style.rows}>
          {children && children.length > 0 && this.generateRows()}
          <div>
            <div className={Style.totalRow}>
              <div className={classNames(Style.itemRow, Style.weighted)}>
                <span>{t('total')}</span>
                <span>{`${numberFormatterOneDecimal(point.carbs)} g`}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CarbsTooltip.propTypes = {
  point: PropTypes.shape({
    timestamp: PropTypes.string.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

CarbsTooltip.defaultProps = {
  t: () => (0),
};
