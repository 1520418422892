import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { openGraphsPage } from '~/services/graphsApi';
import { OpenPageGeneric } from '~/services/apiClient';
import UserHelper from '~/redux/modules/users/UserHelper';
import {
  fileUploadStatus,
  uploadOmnipodFile,
  updateDialogVisibility,
  UPLOAD,
  STATUS,
  CONSENT,
  SELECT_DEVICE,
} from '~/redux/modules/omnipod/omnipod';
import { USER_TYPE_PATIENT } from '~/bundles/shared/constants/users';
import { ASSIGN_DEVICES_TAB_ID, storeTabId } from '~/bundles/poptracker/PopTrackerV2/PopTrackerContainer/PopTrackerContainer';
import OmnipodModalRenderer from './OmniPodModalRenderer/OmnipodModalRenderer';

const mapStateToProps = (state) => ({
  users: state.users.currentUsers,
  currentPatient: UserHelper.currentPatient(state),
  currentPatientName: UserHelper.userFullName(state),
  currentPatientIsMinor: UserHelper.currentPatientIsMinor(state),
  currentPatientGuardianEmail: UserHelper.currentPatientGuardianEmail(state),
  omnipod: state.omnipod,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { uploadOmnipodFile, fileUploadStatus, updateDialogVisibility },
    dispatch,
  ),
});

@connect(mapStateToProps, mapDispatchToProps)
class OmnipodUploadContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: '',
      incorrectFormat: false,
      selectedFile: null,
      isProvider: this.isProvider(),
      fileSelect: !this.isProvider(),
      modal: null,
    };
    this.uploadOmnipod = this.uploadOmnipod.bind(this);
    this.fileSelectedHandler = this.fileSelectedHandler.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setState({ modal: this.setModal() });
    this.props.actions.fileUploadStatus(null);
  }

  uploadOmnipod(file) {
    this.props.actions.uploadOmnipodFile(file);
  }

  resetStateAndReduxAfterUpload = () => {
    this.props.actions.fileUploadStatus(null);
    this.setState({
      selectedFile: null,
    });
  }

  validateIbfAndUpload = () => {
    if (!/\.ibf$/i.test(this.state.selectedFile.name)) {
      this.setState({ incorrectFormat: true });

      this.props.actions.fileUploadStatus(null);
      return this.switchModal(STATUS);
    }

    this.updateFileName(this.state.selectedFile.name);

    return this.fileUploadHandler();
  }

  fileSelectedHandler = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
      fileSelect: false,
    }, () => this.validateIbfAndUpload());
  };

  fileUploadHandler = () => {
    this.switchModal(STATUS);
    this.uploadOmnipod(this.state.selectedFile);
  };

  isProvider = () => this.props.users.currentUser.userType !== USER_TYPE_PATIENT;

  insuletConsent = () => {
    const currentPatient = this.props.users.currentPatient;
    return currentPatient && currentPatient.insuletConsent;
  };

  showFileSelect = () => {
    this.switchModal(UPLOAD);
  };

  hideOmnipodModal = () => {
    this.setState({
      incorrectFormat: false,
    });
    this.switchModal(this.setModal());
    this.props.actions.updateDialogVisibility(false);
  };

  switchModal = (set) => {
    this.setState({ modal: set });
  };

  updateFileName = (set) => {
    this.setState({ fileName: set });
  };

  navigateToData = () => {
    this.hideOmnipodModal();

    if (this.isProvider()) {
      storeTabId(ASSIGN_DEVICES_TAB_ID);
      OpenPageGeneric('/patients', {}, '_self');
      return true;
    }

    UserHelper.setGraphSelectionsLocally({
      timeframe: 1,
      startDate: moment.utc().startOf('day').toISOString(),
      endDate: moment.utc().endOf('day').toISOString(),
      readingsType: 'bg',
    });
    openGraphsPage({});
  };

  setModal = () => {
    if (!this.isProvider() && !this.insuletConsent()) {
      return CONSENT;
    } else if (this.props.omnipod.isMacOS) {
      return SELECT_DEVICE;
    }
    return UPLOAD;
  };

  render() {
    if (!this.props.omnipod.dialogVisible ||
        this.state.modal === null) {
      return null;
    }

    return (
      <OmnipodModalRenderer
        currentModal={this.state.modal}
        hideOmnipodModal={this.hideOmnipodModal}
        showFileSelect={this.showFileSelect}
        uploadDialogProps={{ patientName: this.props.currentPatientName }}
        isMinor={this.props.currentPatientIsMinor}
        guardianEmail={this.props.currentPatientGuardianEmail}
        switchModal={this.switchModal}
        fileUploadDialogProps={{
          onChange: this.fileSelectedHandler,
        }}
        statusModalProps={{
          onViewData: this.navigateToData,
          incorrectFormat: this.state.incorrectFormat,
          fileName: this.state.fileName,
          uploadStatus: this.props.omnipod.uploadStatus,
          resetStateAndReduxAfterUpload: this.resetStateAndReduxAfterUpload,
        }}
        insuletConsentModalProps={{ fileUpload: this.showFileSelect }}
      />
    );
  }
}

OmnipodUploadContainer.propTypes = {
  actions: PropTypes.shape({
    fileUploadStatus: PropTypes.func,
  }),
};

OmnipodUploadContainer.defaultProps = {
  actions: {
    fileUploadStatus: () => null,
  },
};

export default OmnipodUploadContainer;
