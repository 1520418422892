import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@glooko/common-ui';
import styles from './PatientTagsEditable.scss';

const ChipWrapper = (props) => (
  <div className={styles.chipWrapper}>
    <Chip
      className={styles.chip}
      label={props.value.label}
      onClick={() => props.onRemove(props.value)}
    />
  </div>
);

ChipWrapper.propTypes = {
  value: PropTypes.shape().isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default ChipWrapper;
