import React from 'react';
import PropTypes from 'prop-types';
import deepMerge from 'deepmerge';
import { connect } from 'react-redux';
import { sumBy, forEach } from 'lodash';
import { groupDataByTimeInterval } from '~/bundles/shared/helpers/series';
import { ONE_DAY } from '~/bundles/shared/constants/time';
import PageHelper from '~/redux/modules/page/PageHelper';
import Tooltips from '~/bundles/shared/components/graphs/tooltips.jsx';
import GraphPresenter from '~/bundles/shared/components/graphs/GraphPresenter/GraphPresenter.jsx';
import Style from './CarbsGraph.scss';

const mapStateToProps = (state) => ({
  inPDF: state.pdf.inPDF,
  pointWidth: PageHelper.currentTimeframe(state).pointWidth,
  hours: PageHelper.currentTimeFrameLength(state) === ONE_DAY,
});

@connect(mapStateToProps, null)
export default class CarbsGraph extends React.Component {
  getThirtyMinutesData = () => {
    const { series } = this.props;
    const newSeries = [];
    forEach(series, (seriesItem) => {
      const newSeriesData = [];
      if (seriesItem.data && seriesItem.data.length > 0) {
        const grouped = groupDataByTimeInterval(seriesItem.data, 30, 'minutes');
        forEach(Object.keys(grouped), (item) => {
          const totalCarbs = sumBy(grouped[item], 'carbs');

          newSeriesData.push({
            ...grouped[item][0],
            children: grouped[item],
            carbs: totalCarbs,
            yOrig: totalCarbs === undefined ? grouped[item][0].yOrig : totalCarbs,
          });
        });
      }
      newSeries.push({
        ...seriesItem,
        data: newSeriesData,
      });
    });
    return newSeries;
  }

  render() {
    const { yAxisMax, pointWidth, hours, weekView, configOverride, inPDF, ...otherProps } = this.props;
    const tooltipFormatter =
      hours ? Tooltips.carbsTooltip : null;
    const seriesData = hours || weekView ? this.getThirtyMinutesData() : this.props.series;

    return (
      <div class={Style.CarbsGraph}>
        <GraphPresenter
          dataLabelsEnabled={inPDF}
          tooltipPositioner={Tooltips.tooltipHoursPositioner}
          tooltipFormatter={tooltipFormatter}
          {...otherProps}
          series={seriesData}
          configOverride={deepMerge({
            yAxis: {
              max: yAxisMax,
            },
            plotOptions: {
              series: {
                pointWidth,
                dataLabels: {
                  verticalAlign: 'middle',
                },
                dataGrouping: {
                  units: [
                    ['minute', [10]],
                  ],
                  forced: true,
                  enabled: true,
                  groupAll: true,
                },
              },
            },
          }, configOverride)}
        />
      </div>
    );
  }
}

CarbsGraph.propTypes = {
  startTimestamp: PropTypes.number.isRequired,
  endTimestamp: PropTypes.number.isRequired,
  series: PropTypes.arrayOf(PropTypes.object).isRequired,
  windowHeight: PropTypes.number.isRequired,
  windowWidth: PropTypes.number.isRequired,
  inPDF: PropTypes.bool.isRequired,
  yAxisMax: PropTypes.number.isRequired,
  pointWidth: PropTypes.number.isRequired,
  configOverride: PropTypes.shape({}).isRequired,
  hours: PropTypes.bool.isRequired,
  weekView: PropTypes.bool,
};

CarbsGraph.defaultProps = {
  inPDF: false,
  yAxisMax: 100,
  pointWidth: 1,
  configOverride: {},
  hours: false,
  weekView: false,
};
