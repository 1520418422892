import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import Style from './IntegrationButton.scss';
import { POPUP_CONNECT, POPUP_DISCONNECT } from '../PartnerIntegrationsPresenter/PartnerIntegrationsPresenter';
import { trackSelectedAppsDevices } from '../../../../../services/eventLogging';

const IntegrationButton = (props) => {
  const { integration, setPopupData, setPopupState, t } = props;

  const isConnected = !!integration.startDate;

  const integrationPopup = () => {
    setPopupData(integration);
    setPopupState(isConnected ? POPUP_DISCONNECT : POPUP_CONNECT);
  };
  const onClickIntegration = () => {
    trackSelectedAppsDevices(integration);
    integrationPopup();
  };

  const deviceDisconnected = integration.userDeviceExists && !isConnected;
  const deviceStatusText = deviceDisconnected ? t('disconnected') : t('connected');

  const displayText = (isConnected || deviceDisconnected) ?
    `${integration.name}:` : t('connectPartner', { partnerName: integration.name });

  // Hide the button if the current integration both has a legacy and a CCM implementation and
  // the user is connected to the legacy.
  if (integration.userConnectedToLegacy) {
    return null;
  }

  return (
    <div
      className={Style.IntegrationButton}
      role="button"
      tabIndex={0}
      onClick={onClickIntegration}
    >
      <div className={Style.content}>
        <img className={Style.image} src={integration.iconUrl} alt="integration" />
        <div className={Style.name}>
          <span>{displayText}</span>
          {(isConnected || deviceDisconnected) && (
            <span className={isConnected ? Style.connected : Style.disconnected}>
              {deviceStatusText}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

IntegrationButton.propTypes = {
  t: PropTypes.func.isRequired,
  integration: PropTypes.object.isRequired,
  setPopupData: PropTypes.func.isRequired,
  setPopupState: PropTypes.func.isRequired,
};

IntegrationButton.defaultProps = {
  integration: null,
  setPopupData: /* istanbul ignore next */ () => {},
  setPopupState: /* istanbul ignore next */ () => {},
};

export default translate('IntegrationButton')(IntegrationButton);
