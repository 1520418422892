// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".GenericConsentModal_consentText{width:535px;height:300px}", "",{"version":3,"sources":["GenericConsentModal.scss"],"names":[],"mappings":"AAAA,iCACE,WAAA,CACA,YAAA","file":"GenericConsentModal.scss","sourcesContent":[".consentText {\n  width: 535px;\n  height: 300px;\n}\n"]}]);
// Exports
exports.locals = {
	"consentText": "GenericConsentModal_consentText"
};
module.exports = exports;
