// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".CreatePatientLink_create-patient-link{float:right}.CreatePatientLink_create-patient-link:after{clear:both}html[dir=rtl] .CreatePatientLink_create-patient-link{float:left}", "",{"version":3,"sources":["CreatePatientLink.scss"],"names":[],"mappings":"AAAA,uCACE,WAAA,CACA,6CACE,UAAA,CAKF,qDACE,UAAA","file":"CreatePatientLink.scss","sourcesContent":[".create-patient-link {\n  float: right;\n  &:after {\n    clear: both;\n  }\n}\n\nhtml[dir='rtl'] {\n  .create-patient-link {\n    float: left;\n  }\n}"]}]);
// Exports
exports.locals = {
	"create-patient-link": "CreatePatientLink_create-patient-link"
};
module.exports = exports;
