// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".PatientFlag_glookoCohort{max-width:300px;white-space:normal;margin:15px;pointer-events:auto !important}.PatientFlag_glookoCohortFooter{float:left;width:300px}.PatientFlag_glookoCohortFooterLeft{float:left}.PatientFlag_glookoCohortFooterRight{float:right}", "",{"version":3,"sources":["PatientFlag.scss"],"names":[],"mappings":"AAAA,0BACE,eAAA,CACA,kBAAA,CACA,WAAA,CACA,8BAAA,CAGF,gCACE,UAAA,CACA,WAAA,CAGF,oCACE,UAAA,CAGF,qCACE,WAAA","file":"PatientFlag.scss","sourcesContent":[".glookoCohort{\n  max-width: 300px;\n  white-space: normal;\n  margin: 15px;\n  pointer-events: auto !important;\n}\n\n.glookoCohortFooter {\n  float: left;\n  width: 300px;\n}\n\n.glookoCohortFooterLeft {\n  float: left;\n}\n\n.glookoCohortFooterRight {\n  float: right;\n}\n\n"]}]);
// Exports
exports.locals = {
	"glookoCohort": "PatientFlag_glookoCohort",
	"glookoCohortFooter": "PatientFlag_glookoCohortFooter",
	"glookoCohortFooterLeft": "PatientFlag_glookoCohortFooterLeft",
	"glookoCohortFooterRight": "PatientFlag_glookoCohortFooterRight"
};
module.exports = exports;
