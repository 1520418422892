import { TIME_OF_DAY_BUCKETS, BI_HOURLY_BUCKETS } from '~/bundles/shared/constants/statistics';
import { WEEKDAYS } from '~/utils/syncTimestamps';
import { FETCH_STATUS_NOT_CALLED, FETCH_STATUS_SUCCESSFUL } from
  '~/bundles/shared/constants/graphs';

export const UPDATE_BG_STATS = 'UPDATE_BG_STATS';
export const UPDATE_BG_STATS_STATUS = 'UPDATE_BG_STATS_STATUS';

export function updateBGStatistics(overall, meal, timeOfDay, dayOfWeek, biHourly, params) {
  return {
    type: UPDATE_BG_STATS,
    overall,
    meal,
    timeOfDay,
    dayOfWeek,
    biHourly,
    params,
  };
}

export function updateBGStatisticsOfTimeOfDay(timeOfDay, params) {
  return {
    type: UPDATE_BG_STATS,
    timeOfDay,
    params,
  };
}

export function updateBGStatisticsStatus(status) {
  return { type: UPDATE_BG_STATS_STATUS, status };
}

export const emptyReadingStatistics = {
  average: 0,
  count: 0,
  min: 0,
  max: 0,
  lowPercentage: 0,
  normalPercentage: 0,
  highPercentage: 0,
  median: 0,
  stdDev: 0,
  activity: 0,
};

const initialState = {
  status: FETCH_STATUS_NOT_CALLED,
  params: {},
  overall: emptyReadingStatistics,
  meal: {
    before: emptyReadingStatistics,
    after: emptyReadingStatistics,
  },
  timeOfDay: TIME_OF_DAY_BUCKETS.map((bucket) => bucket.toLowerCase()).reduce((memo, tod) => {
    memo[tod] = emptyReadingStatistics;
    return memo;
  }, {}),
  dayOfWeek: WEEKDAYS.map((day) => day.toLowerCase()).reduce((memo, day) => {
    memo[day] = emptyReadingStatistics;
    return memo;
  }, {}),
  biHourly: BI_HOURLY_BUCKETS.reduce((memo, val) => {
    memo[val] = emptyReadingStatistics;
    return memo;
  }, {}),
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_BG_STATS:
      return {
        ...state,
        overall: action.overall,
        meal: action.meal,
        timeOfDay: action.timeOfDay,
        dayOfWeek: action.dayOfWeek,
        biHourly: action.biHourly,
        params: action.params,
        status: FETCH_STATUS_SUCCESSFUL,
      };
    case UPDATE_BG_STATS_STATUS:
      return { ...state, status: action.status };
    default:
      return state;
  }
}
