import React from 'react';
import PropTypes from 'prop-types';
import deepMerge from 'deepmerge';
import { connect } from 'react-redux';
import PageHelper from '~/redux/modules/page/PageHelper';
import GraphPresenter from '~/bundles/shared/components/graphs/GraphPresenter/GraphPresenter.jsx';
import Colors from '~/../assets/styles/export/colors';
import Style from './InsulinInlineGraph.scss';

const mapStateToProps = (state) => ({
  inPDF: state.pdf.inPDF,
  pointWidth: PageHelper.currentTimeframe(state).pointWidth,
});

@connect(mapStateToProps, null)
export default class InsulinInlineGraph extends React.Component {
  render() {
    const { yAxisMax, pointWidth, configOverride, ...otherProps } = this.props;
    return (
      <div class={Style.InsulinInlineGraph}>
        <GraphPresenter
          {...otherProps}
          configOverride={deepMerge({
            chart: {
              backgroundColor: Colors.backgroundDarker,
            },
            yAxis: {
              max: yAxisMax,
            },
            plotOptions: {
              series: {
                pointWidth,
                dataLabels: {
                  verticalAlign: 'middle',
                },
              },
            },
          }, configOverride)}
        />
      </div>
    );
  }
}

InsulinInlineGraph.propTypes = {
  startTimestamp: PropTypes.number.isRequired,
  endTimestamp: PropTypes.number.isRequired,
  series: PropTypes.arrayOf(PropTypes.object).isRequired,
  windowHeight: PropTypes.number.isRequired,
  windowWidth: PropTypes.number.isRequired,
  inPDF: PropTypes.bool.isRequired,
  yAxisMax: PropTypes.number.isRequired,
  pointWidth: PropTypes.number.isRequired,
  configOverride: PropTypes.shape({}).isRequired,
};

InsulinInlineGraph.defaultProps = {
  inPDF: false,
  yAxisMax: 100,
  pointWidth: 1,
  configOverride: {},
};
