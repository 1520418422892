import Style from './InfoPanel.scss';

export interface InfoPanelProps {
  children?: React.ReactNode | React.ReactNode[],
  style?: object,
}

const InfoPanel = ({ children, style }: InfoPanelProps) => (
  <div className={Style.InfoPanel} style={style}>
    {children}
  </div>
);

export default InfoPanel;
