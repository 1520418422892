import Spinner from 'bundles/shared/components/Spinner/Spinner';
import classNames from 'classnames';
import Style from './WithLoadingState.scss';

const DEFAULT_OPTIONS = {
  renderWhenEmpty: true,
  emptyComponent: () => <Spinner />,
};

const emptyDiv = (inner: React.ReactNode) => <div className={Style.empty}>{inner}</div>;

export interface WithLoadingStateWrapperProps {
  style?: object;
  children: React.ReactNode;
}

interface CustomOptions {
  style?: object;
  renderWhenEmpty?: boolean;
  emptyComponent?: (props?: object) => JSX.Element;
}

const WithLoadingState = <C extends object>(
  WrappedComponent: React.ComponentType<C>,
  checkWith: (props: C) => boolean,
  customOptions?: CustomOptions,
) => function (props: C) {
    const options = {
      ...DEFAULT_OPTIONS,
      ...customOptions,
    };
    const inLoadingState = checkWith(props);
    return (
      <section className={classNames(Style.WithLoadingState, { [Style.loading]: inLoadingState })} style={options.style}>
        {options.renderWhenEmpty || inLoadingState ? <WrappedComponent {...props} /> : null}
        {inLoadingState ? emptyDiv(options.emptyComponent(props)) : null}
      </section>
    );
  };

export default WithLoadingState;
