import React from 'react';
import classNames from 'classnames';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import RecentTerminalSyncs from '~/bundles/recentTerminalSyncs/RecentTerminalSyncs/RecentTerminalSyncs.jsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import { POPULATION_TRACKER_V2 } from '~/bundles/shared/constants/providerGroupSite';
import ProviderGroupSiteHelper from '~/redux/modules/providerGroupSite/ProviderGroupSiteHelper';
import PopTrackerPdfWizard from '~/bundles/shared/components/PdfWizard/PopTrackerPdfWizard.jsx';
import BlindedDevicesAlertContainer from
  '~/bundles/shared/components/BlindedDevicesAlert/BlindedDevicesAlertContainer';
import PatientListTable from '../../PatientList/PatientListTable/PatientListTable';
import FilterPatientsContainer from '../../FilterPatients/FilterPatientsContainer/FilterPatientsContainer';
import CreatePatientLink from '../../CreatePatient/CreatePatientLink/CreatePatientLink';
import BetaToggleContainer from '../../BetaToggle/BetaToggleContainer/BetaToggleContainer';
import AcademyContainer from '../../Academy/AcademyContainer/AcademyContainer';
import Style from './PopTrackerContainer.scss';

const mapStateToProps = (state) => ({
  academyProperty: state.providerGroupSite.academyProperty,
  providerGroupSiteInitialized: !!state.providerGroupSite.authCode,
  hasToggleFeature: ProviderGroupSiteHelper.hasSubscriptionFeature(state, POPULATION_TRACKER_V2),
  isPopulationInsightsFilter: state.providerGroupSite.isPopulationInsightsFilter,
});

export const VIEW_PATIENTS_TAB_ID = 'view_patients';
export const ASSIGN_DEVICES_TAB_ID = 'assign_devices';
const SELECTED_TAB_ID_STORAGE_KEY = 'selectedTabId';
const VIEW_ACADEMY_TAB_ID = 'academy_tab';

export function storeTabId(tabId) {
  localStorage.setItem(SELECTED_TAB_ID_STORAGE_KEY, tabId);
}

export class PopTrackerContainer extends React.Component {
  static defaultProps = {
    isPopulationInsightsFilter: false,
    providerGroupSiteInitialized: false,
  }

  static propTypes = {
    t: PropTypes.func.isRequired,
    academyProperty: PropTypes.bool.isRequired,
    providerGroupSiteInitialized: PropTypes.bool,
    hasToggleFeature: PropTypes.bool.isRequired,
    isPopulationInsightsFilter: PropTypes.bool,
  }

  constructor(props) {
    super(props);
    this.onTabSelect = this.onTabSelect.bind(this);

    this.updateSelectedTabsIfForceBetaTracker();

    const selectedTabId = localStorage.getItem(SELECTED_TAB_ID_STORAGE_KEY);
    const legacyPopTrackerLastVisitedTab = localStorage.getItem('legacyPopTrackerLastVisitedTab');
    const tabId = !selectedTabId && legacyPopTrackerLastVisitedTab === 'patient_view' ?
      VIEW_PATIENTS_TAB_ID :
      selectedTabId;
    const tabIndex = this.getTabIndex(tabId);

    this.state = {
      tabIndex,
      tabId,
    };
  }

  onTabSelect(tabIndex) {
    const tabId = this.getTabId(tabIndex);

    storeTabId(tabId);
    this.setState({ tabIndex, tabId });
  }

  getTabId(tabIndex) {
    switch (tabIndex) {
      case 0:
        return ASSIGN_DEVICES_TAB_ID;
      case 1:
        return VIEW_PATIENTS_TAB_ID;
      case 2:
        return VIEW_ACADEMY_TAB_ID;
      default:
        return ASSIGN_DEVICES_TAB_ID;
    }
  }

  getTabIndex(tabId) {
    if (!tabId) return this.getLegacyPopTrackerLastVisitedTab(0);

    switch (tabId) {
      case ASSIGN_DEVICES_TAB_ID:
        return 0;
      case VIEW_PATIENTS_TAB_ID:
        return 1;
      case VIEW_ACADEMY_TAB_ID:
        return this.props.academyProperty ? 2 : 0;
      default:
        return 0;
    }
  }

  // persist current tab when toggling from old to New pop tracker
  getLegacyPopTrackerLastVisitedTab(tabIndex) {
    const legacyPopTrackerLastVisitedTab = localStorage.getItem('legacyPopTrackerLastVisitedTab');
    const hasLastTabValue = !!legacyPopTrackerLastVisitedTab;
    const getTabName = hasLastTabValue ? legacyPopTrackerLastVisitedTab : null;
    if (getTabName === 'patient_view') { return 1; }
    return tabIndex;
  }

  updateSelectedTabsIfForceBetaTracker() {
    if (window.location.href.indexOf('forceBetaTracker') > -1) {
      storeTabId(VIEW_PATIENTS_TAB_ID);
    }
  }

  renderTabPanels() {
    const { t, isPopulationInsightsFilter, academyProperty } = this.props;
    if (isPopulationInsightsFilter) { localStorage.setItem('isPopulationInsightsFilter', true); }

    return (
      <div>
        <TabPanel>
          <div className={Style.terminalSync}>
            <RecentTerminalSyncs />
            <PopTrackerPdfWizard />
          </div>
        </TabPanel>
        <TabPanel>
          <div className={Style.filterPatients}>
            <FilterPatientsContainer
              atRiskFilterMessage={t('atRiskPopulationResults')}
              clearAtRiskFilterMessage={t('clearCurrentPatientList')}
            />
          </div>
          <PatientListTable />
        </TabPanel>
        {academyProperty &&
        <div className={Style.academyTab}>
          <TabPanel>
            <AcademyContainer />
          </TabPanel>
        </div>}
      </div>
    );
  }

  renderBetaToggle = () => {
    const {
      hasToggleFeature,
      isPopulationInsightsFilter,
      academyProperty,
    } = this.props;
    const isAcademyTabView = this.state.tabId === VIEW_ACADEMY_TAB_ID && academyProperty;
    if (!hasToggleFeature || isPopulationInsightsFilter || isAcademyTabView) return null;

    const betaToggleClasses = classNames(
      Style.betaToggle,
      { [Style['betaToggle--twoTabs']]: !academyProperty },
      { [Style['betaToggle--threeTabs']]: academyProperty },
    );

    return (
      <div className={betaToggleClasses}>
        <BetaToggleContainer />
      </div>
    );
  }

  render() {
    const { t, academyProperty, providerGroupSiteInitialized } = this.props;

    if (!providerGroupSiteInitialized) return '';

    return (
      <div className={Style.popTracker}>
        <BlindedDevicesAlertContainer />
        <div>
          {this.state.tabId === VIEW_PATIENTS_TAB_ID ? <CreatePatientLink /> : null}
          {this.renderBetaToggle()}
        </div>
        <Tabs
          selectedIndex={this.state.tabIndex}
          onSelect={this.onTabSelect}
        >
          <TabList>
            <Tab
              data-testid="poptracker-tab-assign-devices"
              key={ASSIGN_DEVICES_TAB_ID}
            >
                {t('assignDevices')}
            </Tab>
            <Tab
              data-testid="poptracker-tab-view-patients"
              key={VIEW_PATIENTS_TAB_ID}
            >
              {t('viewPatients')}
            </Tab>
            {academyProperty &&
              <Tab
                data-testid="poptracker-tab-academy"
                key={VIEW_ACADEMY_TAB_ID}
              >
                  Academy™
              </Tab>}
          </TabList>
          {this.renderTabPanels()}
        </Tabs>
      </div>
    );
  }
}

export const TranslatedPopTrackerContainer = translate('PopTrackerContainer')(PopTrackerContainer);
export default connect(mapStateToProps, null)(TranslatedPopTrackerContainer);
